import { isMobile as isCapacitor } from "../mobile"

export default function (destination) {

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = destination

    if (!isCapacitor) {
        a.target = "_blank"
    }

    a.click()
    document.body.removeChild(a)
}