// Libraries
import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  CircularProgress,
} from '@material-ui/core'
import has from 'lodash/has'
// Hooks
import { useForm } from 'hooks/use-form'
import { useAuth } from 'hooks/use-auth'
import { useTranslation } from 'hooks/use-translation'
// Form Validators
import { createBankAccountValidate } from 'formValidators/userPage'

function CreateBankAccountModal({ open, setOpen, callback }) {
  const auth = useAuth()
  const [allErrors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const { translate } = useTranslation()

  const handleClose = () => {
    setOpen(false)
    handleChange({ target: { name: 'nameMobile', value: '' } }, { iban: '' })
  }

  const INITIAL_FORM_STATE = {
    nameMobile: '',
    iban: '',
  }

  const { values, handleChange } = useForm(
    INITIAL_FORM_STATE,
    () => {},
    () => {},
    auth,
    null,
    'async'
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    const errors = createBankAccountValidate(values)
    setErrors(errors)

    try {
      setLoading(true)
      if (!has(errors, 'nameMobile') && !has(errors, 'iban')) {
        await callback(values.nameMobile, values.iban)
        handleChange(
          { target: { name: 'nameMobile', value: '' } },
          { iban: '' }
        )
        setOpen(false)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <DialogContentText>
          {translate('c-form.create-bank-account.info')}
        </DialogContentText>
        <FormControl>
          <TextField
            margin="dense"
            name="nameMobile"
            label={translate('c-form.create-bank-account.name')}
            type="text"
            error={allErrors && allErrors.nameMobile ? true : false}
            helperText={allErrors && allErrors.nameMobile}
            value={values.nameMobile}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            margin="dense"
            name="iban"
            label="IBAN"
            type="text"
            error={allErrors && allErrors.iban ? true : false}
            helperText={allErrors && allErrors.iban}
            value={values.iban}
            onChange={handleChange}
            fullWidth
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate('c-form.create-bank-account.close')}
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit}
        >
          {loading ? (
            <CircularProgress size={25} thickness={2.7} color="primary.white" />
          ) : (
            translate('c-form.create-bank-account.add')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateBankAccountModal
