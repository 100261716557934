// Libraries
import React, { useState } from 'react'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@material-ui/core'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useTranslation } from '../../../hooks/use-translation'
// Form Validators
import { linkEmailProviderValidate } from '../../../formValidators/linkEmailProvider'

export default function ConnectEmailAndPasswordDialog({
  open,
  setOpen,
  callback,
}) {
  const { user, hasPasswordProvider } = useAuth()
  const [errors, setErrors] = useState({})
  const { translate } = useTranslation()

  const initialState = {
    email: hasPasswordProvider ? user.email : '',
    password: '',
  }
  const [values, setValues] = useState(initialState)

  const handleConfirm = async (e) => {
    try {
      e.preventDefault()

      const validateErrors = linkEmailProviderValidate({
        ...values,
        hasPasswordProvider,
      })
      setErrors(validateErrors)

      if (isEmpty(validateErrors)) {
        setOpen(false)
        await callback(values)

        setValues({ email: '', password: '' })
        setErrors([])
      }
    } catch (e) {
      console.error('Errow while confirm values', e)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {hasPasswordProvider
              ? translate('c-form.connect-email-and-password-dialog.info-1')
              : translate('c-form.connect-email-and-password-dialog.info-2')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email_connect"
            label={translate('c-form.connect-email-and-password-dialog.email')}
            type="email"
            name="email"
            defaultValue={values.email}
            onChange={handleChange}
            error={has(errors, 'email')}
            helperText={errors.email}
            fullWidth
          />
          {!hasPasswordProvider && (
            <TextField
              margin="dense"
              id="password_connect"
              name="password"
              label={translate('c-form.connect-email-and-password-dialog.pass')}
              type="password"
              defaultValue={values.password}
              onChange={handleChange}
              error={has(errors, 'password')}
              helperText={errors.password}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            {translate('c-form.connect-email-and-password-dialog.close')}
          </Button>
          <Button
            onClick={(e) => handleConfirm(e)}
            variant="contained"
            color="primary"
          >
            {translate('c-form.connect-email-and-password-dialog.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
