import { fetch } from '../hooks/use-fetch';

export const loginBroker = async (data, auth) => {
  return await fetch.post('/loginBrokerUser', {
    body: JSON.stringify(data)
  }, auth)
}

export const updatePasswordBroker = async (data, auth) => {
  return await fetch.post('/updateBroker', {
    body: JSON.stringify(data)
  }, auth)
}