// Lib
import { useEffect } from 'react'
// Mobile integration
import { useCapacitorEvents } from '../index'
// Hooks
import { useNavigate } from 'react-router-dom-v5-compat'

/**
 * Listens to the deepLink event from Capacitor if
 * running inside the mobile wrapper.
 */
export default function UrlListener() {
  const navigate = useNavigate()

  // Will receive a path to nav to (/something)
  const navUrl = useCapacitorEvents('deepLink')

  // Listen to navUrl changes
  useEffect(() => {
    if (navUrl !== null) navigate(navUrl)
  }, [navUrl, navigate])
  // Render nothing
  return null
}
