import React from 'react'
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useTranslation } from '../../../hooks/use-translation'
import { useNavigate } from 'react-router-dom-v5-compat'

const useMenuItemStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid #e1e1e1',
    paddingTop: '0.7em',
    paddingBottom: '0.7em',

    '&:last-child': {
      border: 'none',
    },
  },
}))

const useMenuStyles = makeStyles(() => ({
  list: {
    padding: '0',
  },
}))

export default function InsurancesDropdown() {
  const menuItemStyles = useMenuItemStyles()
  const menuStyles = useMenuStyles()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)

  // translation hook
  const { translate } = useTranslation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const goTo = (path) => {
    navigate(path)
    handleClose()
  }

  return (
    <div style={{ marginRight: '1em' }}>
      <Button
        aria-controls="insurances-dropdown"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {translate('online-insurances')}
      </Button>
      <Menu
        classes={menuStyles}
        id="insurances-dropdown"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          classes={menuItemStyles}
          onClick={() => goTo('/buy-travel-insurance')}
        >
          {translate('c-client-dashboard.buy-online.label-travel')}
        </MenuItem>
        <MenuItem
          classes={menuItemStyles}
          onClick={() => goTo('/buy-accident-insurance')}
        >
          {translate('c-client-dashboard.buy-online.label-accident')}
        </MenuItem>
        <MenuItem
          classes={menuItemStyles}
          onClick={() => goTo('/buy-multitrip-insurance')}
        >
          {translate('c-client-dashboard.buy-online.label-multitrip')}
        </MenuItem>
        <MenuItem
          classes={menuItemStyles}
          onClick={() => goTo('/buy-foreigners-insurance')}
        >
          {translate('c-client-dashboard.buy-online.label-foreigners')}
        </MenuItem>
      </Menu>
    </div>
  )
}
