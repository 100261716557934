import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { useTranslation } from '../../../hooks/use-translation'

export default function PasswordConfirm({ open, setOpen, callback }) {
  const [password, setPassword] = useState('')
  const { translate } = useTranslation()

  const handleConfirm = () => {
    setOpen(false)
    callback(password)
    setPassword('')
  }

  const handleClose = () => {
    setPassword('')
    setOpen(false)
  }

  const handlePass = (e) => setPassword(e.target.value)

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {translate('c-form.password-confirm.info')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label={translate('c-form.password-confirm.pass')}
            type="password"
            defaultValue={password}
            onChange={handlePass}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('c-form.password-confirm.close')}
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            {translate('c-form.password-confirm.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
