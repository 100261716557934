// Libraries
import omit from 'lodash/omit'
// Actions
import { TARIFF_CONFIG_FETCH } from '../actions/tariffConfig/actionTypes'

/**
 * Holds state for tariffConfig
 */
export const tariffConfig = (state = {}, action) => {
  switch (action.type) {
    case TARIFF_CONFIG_FETCH:
      return {
        ...state,
        tranliteration: action.tranliteration,
        config: action.tariffConfig,
        stepsData: action.tariffConfig
          .filter(i => i !== null)
          .map(sd => ({
            id: sd.id,
            inputCharacterType: sd.input_data,
            dateDurationStep: `${sd.duration_step || ''}`.toLowerCase(),
            dateRangeType: `${sd.range_type || ''}`.toLowerCase(),
            tariffTerritories: !sd.tarrifTerritories
              ? null
              : sd.tarrifTerritories
                  .filter(i => i !== null)
                  .map(({ id, name, name_latin }) => ({
                    name,
                    idx: id,
                    nameLatin: name_latin
                  })),
            tariffPremiumEntries: !sd.tarrifPremiumEntry
              ? null
              : sd.tarrifPremiumEntry
                  .filter(i => i !== null)
                  .map(
                    ({
                      id,
                      sum_insured,
                      period_units,
                      period_from,
                      period_to,
                      premium,
                      evp,
                      premium_calc_type
                    }) => ({
                      evp,
                      premium,
                      idx: id,
                      periodFrom: period_from,
                      periodTo: period_to,
                      sumInsured: sum_insured,
                      periodUnits: period_units,
                      premiumCalcType: premium_calc_type
                    })
                  ),
            tariffCurrencies: !sd.tarrifCurrencies
              ? null
              : sd.tarrifCurrencies
                  .filter(i => i !== null)
                  .map(({ id, curr_code }) => ({
                    idx: id,
                    currCode: curr_code
                  })),
            checkboxPages: !sd.tarrifDecreaseIncrease
              ? null
              : Object.values(
                  sd.tarrifDecreaseIncrease
                    .filter(i => i !== null)
                    .filter(i => i.type === 'sel')
                    .map(({ id, text, text_latin, header, header_latin, typeHeader }) => ({
                      idx: id,
                      text,
                      textLatin: text_latin,
                      header,
                      headerLatin: header_latin,
                      typeHeader
                    }))
                    .reduce((r, i) => {
                      const th = i.typeHeader
                      
                      // Added the fields that we need
                      if (!r[th]) {
                        r[th] = {
                          header: i.header,
                          headerLatin: i.headerLatin,
                          checkboxes: []
                        }
                      }

                      r[th].checkboxes = [
                        ...r[th].checkboxes,
                        // Remove header and headerLatin fields from checkboxes
                        omit(i, ['header', 'headerLatin']),
                      ]
                      
                      return r
                    }, {})
                ),
              // Product id
              // It's used for the determining each insurance page in its template
              product_id: sd.product_id
          }))
      }
    default:
      return state
  }
}
