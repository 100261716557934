import React from 'react'
import { Container, Box } from '@material-ui/core'
import Message from '../../components/Message'
import { getMessages } from '../../services/messagesService'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import { useMobileVersion } from '../../hooks/use-mobile-version'
import Notifications from '../../components/Notifications'
import { isMobile as isCapacitor } from '../../mobile'
import { useQuery } from 'react-query'

const Messages = () => {
  const auth = useAuth()
  const { translate } = useTranslation()
  const isMobile = useMobileVersion()
  const {
    data: messages,
    isLoading,
    error,
  } = useQuery({
    queryKey: 'messages',
    queryFn: () => getMessages(auth.activeUser.clientId),
  })

  return (
    <Container
      maxWidth="lg"
      className="custom-spacing"
      style={{ marginTop: 20 }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <h1 className="cm-heading-onboarding">
          <span className="text-span">{translate('page-messages.title')}</span>
        </h1>

        {auth.claims.role === 'client' && !isCapacitor && <Notifications />}
      </Box>

      <div style={{ marginTop: '2rem' }}>
        {error && <span>{translate('page-messages.error')}</span>}

        {isLoading && !messages ? (
          <div>{translate('page-messages.loading')}</div>
        ) : null}

        {messages && messages.length > 0
          ? messages.map((message) => <Message key={message.id} {...message} />)
          : null}

        {!isLoading && messages?.length === 0 ? (
          <span>{translate('page-messages.empty')}</span>
        ) : null}
      </div>
    </Container>
  )
}

export default Messages
