import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '50%',
  },
  skeletonMargin: {
    margin: '0.7rem',
  },
}))

const NavbarSkeleton = () => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Skeleton animation="wave" width={200} height={35} />
      <Skeleton
        variant="circle"
        animation="wave"
        width={35}
        height={35}
        className={classes.skeletonMargin}
      />
      <Skeleton
        variant="circle"
        animation="wave"
        width={35}
        height={35}
        className={classes.skeletonMargin}
      />
      <Skeleton
        variant="circle"
        animation="wave"
        width={35}
        height={35}
        className={classes.skeletonMargin}
      />
      <Skeleton animation="wave" width={150} height={35} />
    </Box>
  )
}

export default NavbarSkeleton
