// Libraries
import React from 'react'
import {
  TextField,
  InputAdornment,
  Icon,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core'
import has from 'lodash/has'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useRouter } from '../../../hooks/use-router'
import { useForm } from '../../../hooks/use-form'
import { resetPasswordSubmit } from '../../../hooks/use-submit'
import { useTranslation } from '../../../hooks/use-translation'
// Form Validators
import { resetPasswordValidate } from '../../../formValidators/resetPassword'

function ForgottenPassword() {
  const auth = useAuth()
  const router = useRouter()
  const INITIAL_FORM_STATE = {
    email: '',
  }
  const { translate } = useTranslation()

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = useForm(
    INITIAL_FORM_STATE,
    resetPasswordValidate,
    resetPasswordSubmit,
    auth,
    router,
    'async'
  )

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        style={{ marginBottom: '1.4em' }}
        name="email"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon>person</Icon>
            </InputAdornment>
          ),
        }}
        error={has(errors, 'email')}
        label={translate('c-form.forgotten-password.email')}
        helperText={has(errors, 'email') && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <Box display="flex" alignItems="cener" justifyContent="flex-end">
        <Button
          disabled={isSubmitting}
          variant="outlined"
          color="primary"
          type="submit"
        >
          {isSubmitting ? (
            <CircularProgress size={25} thickness={2.7} color="primary" />
          ) : (
            translate('c-form.forgotten-password.confirm')
          )}
        </Button>
      </Box>
    </form>
  )
}

export default ForgottenPassword
