import T from '../translations.json'
import {LANG_LOCAL_STORAGE_KEY, LANG_BG} from '../config/languageTypes'

/**
 * Gets the active language from localStorage
 * and sets a default if it's missing
 *
 * @returns {string} the active language
 */
export const getLang = () => {
  // Read lang from localStorage
  const lang = localStorage.getItem(LANG_LOCAL_STORAGE_KEY)
  // Set default if not set
  if (!lang) localStorage.setItem(LANG_LOCAL_STORAGE_KEY, LANG_BG)
  // Return the lang or the set default
  return lang || LANG_BG
}

/**
 * Given a path, returns the translation in the current language
 *
 * @param {string} path - dot separated path to the text key
 *
 * @returns {string} the translation
 */
export const translate = (path) => {
  // Remove trailing dots
  if (path.startsWith('.')) path = path.substring(1)
  if (path.endsWith('.')) path = path.substring(0, path.length - 1)
  // Get the current language
  const lang = localStorage.getItem(LANG_LOCAL_STORAGE_KEY) || LANG_BG
  // Build result
  let result = T[lang]
  path.split('.').forEach((key) => (result = result[key]))
  // Done
  return result || ''
}
