// Libraries
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
// Config
import { LANG_BG } from '../../../../config/languageTypes'
// Form validators
import { makeAppointmentValidateStepDetails } from '../../../../formValidators/makeAppointment'
import { useAuth } from '../../../../hooks/use-auth'
// Hooks
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
// import { useAuth } from '../../../../hooks/use-auth'
import { useTranslation } from '../../../../hooks/use-translation'
// Services
import { getAvailableDoctorsFromSuperDoc } from '../../../../services/makeAppointmentService'
// Components
import SuperDoc from './SuperDoc'

const StepDetails = ({ nextStep, prevStep, specialties }) => {
  const [showSuperDocComponent, setShowSuperDocComponent] = useState(false)

  // use persistent form values and methods
  const { values, errors, validate, handleChange } =
    usePersistentForm('make-appointment')

  const { activeUserCard } = useAuth()

  const { translate, activeLanguage } = useTranslation()

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      // validate form step
      const hasErrors = validate(makeAppointmentValidateStepDetails)
      // check for errors
      if (hasErrors) {
        return
      }

      const { city, speciality } = values

      const citySuperDocId = JSON.parse(city)?.city_superdocId
      const specialtySuperDocId = JSON.parse(speciality)?.spec_superdocId

      // Skip SuperDoc if no data for the city / speciality
      if (!citySuperDocId || !specialtySuperDocId) {
        console.warn('StepDetails: no citySuperDocId / specialtySuperDocId')
        nextStep()
        return
      }

      const superDoctorsCount = await getAvailableDoctorsFromSuperDoc({
        citySuperDocId,
        specialtySuperDocId,
        userCardNumber: activeUserCard.cardNumber,
      })

      if (superDoctorsCount > 0) {
        setShowSuperDocComponent(true)
      } else {
        nextStep()
      }
    } catch (err) {
      nextStep()
      console.error(err)
    }
  }

  const selectSpecialtyOption = useCallback(
    (option) =>
      activeLanguage === LANG_BG ? option.name || '' : option.name_latin || '',
    [activeLanguage]
  )

  if (showSuperDocComponent) {
    return <SuperDoc nextStepHandler={nextStep} />
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <TextField
        error={errors && errors.symptoms ? true : false}
        multiline
        maxRows={3}
        style={{ marginBottom: '2em' }}
        name="symptoms"
        label={translate(
          [
            'c-client-dashboard',
            'make-appointment',
            'step-details',
            'label-symptoms',
          ].join('.')
        )}
        value={values.symptoms}
        onChange={handleChange}
        helperText={errors && errors.symptoms}
      />

      <FormControl
        error={errors && errors.speciality ? true : false}
        style={{ marginBottom: '4em' }}
      >
        <Autocomplete
          noOptionsText={translate('no-results')}
          name="speciality"
          options={specialties}
          value={values.speciality ? JSON.parse(values.speciality) : ''}
          getOptionLabel={selectSpecialtyOption}
          onChange={(e, value) => {
            handleChange(e, {
              speciality: !value ? '' : JSON.stringify(value),
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate(
                [
                  'c-client-dashboard',
                  'make-appointment',
                  'step-details',
                  'label-specialty',
                ].join('.')
              )}
            />
          )}
        />

        <FormHelperText>{errors && errors.speciality}</FormHelperText>
      </FormControl>

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            onClick={prevStep}
          >
            {translate(
              [
                'c-client-dashboard',
                'make-appointment',
                'step-details',
                'back',
              ].join('.')
            )}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {translate(
              [
                'c-client-dashboard',
                'make-appointment',
                'step-details',
                'next',
              ].join('.')
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

StepDetails.propTypes = {
  nextStep: PropTypes.func.isRequired,
}

export default StepDetails
