// Libraries
import has from 'lodash/has'
// Services
import { makeAppointment } from '../services/makeAppointmentService'
import { makeReimbursement } from '../services/reimbursementService'
import { addDocuments, uploadDocument } from '../services/fileService'
import { makeSpecializedExamination } from '../services/makeSpecializedExaminationService'
import { getSequence } from '../services/sequenceService'
// Utils
import { analytics } from '../utils/firebase'
// Config
import { LANG_EN } from '../config/languageTypes'

export const useLoginSubmit = async (
  { email, password },
  { signin, signinBroker },
  router
) => {
  let channelType, channelId

  if (has(router.query, 'channelType') && has(router.query, 'channelId')) {
    channelType = router.query.channelType
    channelId = router.query.channelId
  }

  analytics.logEvent('vhod_email')

  if (email.includes('@')) {
    await signin(email, password, channelType, channelId)
    router.push('/')
  } else {
    const userBrokerStatus = await signinBroker(email, password)

    if (userBrokerStatus === 'change_pass') {
      router.push('/change-broker-password')

      sessionStorage.setItem('brokerStatus', 'changePassword')
    } else {
      router.push('/')
    }
  }
}

export const useRegisterSubmit = async (
  { personalNumber, phoneNumber, names, email, password, step },
  { signup },
  router
) => {
  let channelType, channelId

  if (has(router.query, 'channelType') && has(router.query, 'channelId')) {
    channelType = router.query.channelType
    channelId = router.query.channelId
  }

  await signup(
    { personalNumber, phoneNumber, names, email, password, step },
    channelType,
    channelId
  )

  router.push('/')
}

export const resetPasswordSubmit = async (
  { email },
  { resetPassword },
  router
) => {
  await resetPassword(email)
  router.push('/')
  analytics.logEvent('vhod_forgot_password_send')
}

export const makeSpecializedExaminationSubmit = async (
  { comment, examination, files },
  auth,
  router
) => {
  const sequenceRes = await getSequence(auth)

  const sequence = sequenceRes.sequence

  let data = {
    clientId: auth.activeUser.clientId,
    symptoms: comment,
    origin: 'Web',
    city: '',
    speciality: '',
    hospital: '',
    requestedDate: '',
    requestedTime: '',
    sequence,
    typeCase: '2',
    subject: examination,
  }

  let createSpecializedExaminationRes = await makeSpecializedExamination(
    data,
    auth
  )

  const allDocuments = addDocuments(files, sequence, auth)

  for (let data of allDocuments) {
    await uploadDocument(data, auth)
  }

  return createSpecializedExaminationRes
}

export const makeAppointmentSubmit = async (
  { city, hospital, speciality, date, time, symptoms },
  auth,
  activeLanguage
) => {
  if (activeLanguage === LANG_EN) {
    city = JSON.parse(city).city_en
    hospital = hospital ? JSON.parse(hospital).name_en : ''
    speciality = speciality ? JSON.parse(speciality).name_latin : ''
  } else {
    city = JSON.parse(city).city
    hospital = hospital ? JSON.parse(hospital).name : ''
    speciality = speciality ? JSON.parse(speciality).name : ''
  }

  let [from, to] = time
    .replace('[', '')
    .replace(']', '')
    .replace('.5', ':30')
    .replace('.5', ':30')
    .replace(' ', '')
    .split(',')

  if (!from.includes(':30')) {
    from = `${from}:00`
  }

  if (!to.includes(':30')) {
    to = `${to}:00`
  }

  const validFormat = `${from}-${to}`

  let data = {
    clientId: auth.activeUser.clientId,
    symptoms,
    origin: 'Web',
    city,
    speciality,
    hospital,
    requestedDate: date.format('YYYY-MM-DD'),
    requestedTime: validFormat,
    typeCase: '1',
    subject: '',
  }

  return await makeAppointment(data, auth)
}

export const reimburesmentSubmit = async (
  { service, invoiceNumber, dateEvent, dateInvoice, bankAccount, sum, files },
  auth,
  activeLanguage
) => {
  const serviceObject = JSON.parse(service)
  const serviceBG = serviceObject.title
  const serviceEN = serviceObject.title_en

  if (activeLanguage === LANG_EN) {
    service = serviceEN
  } else {
    service = serviceBG
  }

  if (invoiceNumber.length < 10) {
    let invoiceNumberLength = invoiceNumber.length

    invoiceNumber = `${'0'.repeat(10 - invoiceNumberLength)}${invoiceNumber}`
  }

  dateEvent = dateEvent.format('YYYY-MM-DD')
  dateInvoice = dateInvoice.format('YYYY-MM-DD')

  const sequenceRes = await getSequence(auth)

  const sequence = sequenceRes.sequence

  const { role } = auth.activeUser
  const { activeUserCard } = auth

  let data = {
    clientId: auth.activeUser.clientId,
    subject: service,
    dateEvent,
    origin: 'Web',
    iban: bankAccount,
    amount: parseFloat(sum),
    sequence,
    cardNumber: activeUserCard.cardNumber,
    parentId: role === 'children' ? auth.claims.clientId : '',
    invoiceNumber,
    invoiceDate: dateInvoice,
  }

  let createClaimRes = await makeReimbursement(data)

  let isFromReimbursement = true

  const allDocuments = addDocuments(files, sequence, auth, isFromReimbursement)

  const totalNumberOfDocumentsAttached = allDocuments.length

  for (let [i, data] of allDocuments.entries()) {
    await uploadDocument(
      {
        ...data,
        finalCounterAllFiles: totalNumberOfDocumentsAttached,
        counterFile: i + 1,
      },
      auth
    )
  }

  return createClaimRes
}
