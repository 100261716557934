import PublicRoute from 'components/PublicRoute'
import React from 'react'
import { Outlet } from 'react-router-dom-v5-compat'

const PublicLayout = () => {
  return (
    <PublicRoute>
      <Outlet />
    </PublicRoute>
  )
}

export default PublicLayout
