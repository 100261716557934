import { Geolocation } from '@capacitor/geolocation'
import { Keyboard } from '@capacitor/keyboard'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import React, { lazy, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import '../../assets/css/custom-webflow.css'
import { authContext } from '../../hooks/use-auth'
import { ProvideAuth } from '../../hooks/use-auth.js'
import { FormStoreProvider } from '../../hooks/use-form-store'
import { ProvideMobileVersion } from '../../hooks/use-mobile-version'
import { ProvideTranslations } from '../../hooks/use-translation'
// Mobile integration
import { isMobile as isCapacitor, init as mobileInit } from '../../mobile'

import { CompatRouter, Route, Routes } from 'react-router-dom-v5-compat'

import UpdateAppListener from '../../mobile/components/UpdateAppListener'
import ClientHome from 'pages/Home/ClientHome'
import UserSettings from 'pages/UserSettings'
import MakeAppointment from 'pages/MakeAppointment'
import MakeSpecializedExamination from 'pages/MakeSpecializedExamination'
import CoveragePage from 'pages/CoveragePage'
import UserSocial from 'pages/UserSocial'
import Messages from 'pages/Messages'
import Other from 'pages/Other'
import ReimbursementLayout from 'components/ClientDashboard/Reimbursement/ReimbursementLayout'
import Map from 'pages/Map'
import BotRedirect from '../BotRedirect'
import ErrorHandler from '../ErrorHandler'
import LoadingSpinner from '../LoadingSpinner'
import { ErrorBoundary } from '@sentry/react'
import RootLayout from 'layouts/RootLayout'
import NotFound from 'components/NotFound'
import ClaimInfo from 'pages/Reimbursement/ClaimInfo'
import ActiveReimbursements from 'pages/Reimbursement/ActiveReimbursements'
import ArchivedReimbursements from 'pages/Reimbursement/ArchivedReimbursements'
import Reimbursement from 'pages/Reimbursement/ReimbursementRequest'
import BrokerHome from 'pages/Home/BrokerHome'
import UserSettingsBroker from 'pages/UserSettingsBroker'
import BrokerChangePassword from 'pages/BrokerChangePassword'
import BrokerLayout from 'layouts/BrokerLayout'
import ClientLayout from 'layouts/ClientLayout'
import PublicLayout from 'layouts/PublicLayout'
import PublicHome from 'pages/Home/PublicHome'
import About from 'pages/About'
import Login from 'pages/Login'
import Register from 'pages/Register'
import Actions from 'pages/Actions'

// Lazy loads
const ThankYou = lazy(() => import('../../pages/ThankYou'))
const Logout = lazy(() => import('../../pages/Logout'))
// const BrokerRoutes = lazy(() => import('./BrokerRoutes'))
const BuyAccidentInsurance = lazy(() =>
  import('../../pages/BuyAccidentInsurance')
)
const BuyForeignersInsurance = lazy(() =>
  import('../../pages/BuyForeignersInsurance')
)
const BuyMultitripInsurance = lazy(() =>
  import('../../pages/BuyMultitripInsurance')
)
const BuyTravelInsurance = lazy(() => import('../../pages/BuyTravelInsurance'))
const PublicAppointment = lazy(() => import('../../pages/PublicAppointment'))
const Installments = lazy(() => import('../../pages/Installments'))

// Material UI Theme
const theme = createTheme({
  palette: {
    primary: { main: '#D42F42', grey: '#E1E1E1', white: '#FFFFFF' },
    secondary: { main: '#333' },
  },
  typography: {
    header3: {
      fontSize: '1.4em',
    },
    header5: {
      fontSize: '1.2rem',
    },
  },
})

const useStyles = makeStyles((theme) => ({
  root: { zIndex: 999999999, width: '70%' },
  success: { backgroundColor: '#D33244' },
  info: { backgroundColor: '#fefefe', color: '#333' },
  error: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D33244',
    color: '#D33244',
  },
  warning: { backgroundColor: 'yellow' },
}))

const App = () => {
  const classes = useStyles()

  // usePortalNotifications()

  useEffect(() => {
    if (isCapacitor) {
      Keyboard.setAccessoryBarVisible({ isVisible: true })

      async function checkLocationPermission() {
        const permissionStatus = await Geolocation.checkPermissions()

        if (permissionStatus.location === 'prompt') {
          await Geolocation.requestPermissions()
          return
        }

        if (permissionStatus.location !== 'granted') {
          console.error('Location permission denied')
          // throw new Error('User denied permissions!')
        }
      }

      checkLocationPermission()
    }
  }, [])

  return (
    <FormStoreProvider>
      <ThemeProvider theme={theme}>
        <ProvideMobileVersion>
          <ProvideTranslations>
            <ErrorBoundary fallback={<p>Something went wrong</p>}>
              <SnackbarProvider
                autoHideDuration={10000}
                disableWindowBlurListener
                maxSnack={3}
                classes={{
                  root: classes.root,
                  variantSuccess: classes.success,
                  variantError: classes.error,
                  variantInfo: classes.info,
                  variantWarning: classes.warning,
                }}
              >
                <UpdateAppListener />
                <ProvideAuth>
                  <authContext.Consumer>
                    {({ claims, isUserLoading, user }) => {
                      if (isUserLoading) {
                        return <LoadingSpinner global />
                      }

                      // Signal capacitor
                      mobileInit()

                      const isClient = claims && claims.role === 'client'
                      const isBroker = claims && claims.role === 'broker'
                      const hasHealthCare = claims && claims?.zg === 'true'

                      return (
                        <BrowserRouter>
                          <CompatRouter>
                            <RootLayout>
                              <Routes>
                                {!user ? (
                                  <Route path="/" element={<PublicLayout />}>
                                    <Route element={<PublicHome />} path="/" />
                                    <Route element={<About />} path="/about" />
                                    <Route element={<Login />} path="/login" />
                                    <Route
                                      element={<Register />}
                                      path="/register"
                                    />
                                    <Route
                                      element={<Actions />}
                                      path="/actions"
                                    />
                                  </Route>
                                ) : null}

                                <Route
                                  path="/buy-travel-insurance"
                                  element={<BuyTravelInsurance />}
                                />
                                <Route
                                  path="/buy-accident-insurance"
                                  element={<BuyAccidentInsurance />}
                                />
                                <Route
                                  path="/buy-multitrip-insurance"
                                  element={<BuyMultitripInsurance />}
                                />
                                <Route
                                  path="/buy-foreigners-insurance"
                                  element={<BuyForeignersInsurance />}
                                />

                                <Route
                                  path="/installments/:type"
                                  element={<Installments />}
                                />

                                <Route
                                  path="/public/appointment/:appointmentId"
                                  element={<PublicAppointment />}
                                />

                                <Route
                                  path="/bot/credit-card-redirect"
                                  element={
                                    <BotRedirect type="credit-card-redirect" />
                                  }
                                />

                                <Route
                                  path="/bot/epay-redirect"
                                  element={<BotRedirect type="epay-redirect" />}
                                />

                                <Route
                                  path="/thank-you"
                                  element={<ThankYou />}
                                />

                                <Route path="/logout" element={<Logout />} />

                                {isClient ? (
                                  <Route path="/" element={<ClientLayout />}>
                                    <Route path="/" element={<ClientHome />} />

                                    {hasHealthCare ? (
                                      <>
                                        <Route
                                          path="/user"
                                          element={<UserSettings />}
                                        />
                                        <Route
                                          path="/make-appointment"
                                          element={<MakeAppointment />}
                                        />
                                        <Route
                                          path="/make-specialized-examination"
                                          element={
                                            <MakeSpecializedExamination />
                                          }
                                        />

                                        <Route
                                          path="/reimbursements"
                                          element={<ReimbursementLayout />}
                                        >
                                          <Route
                                            path="active/:reimbursementUuid"
                                            element={<ClaimInfo />}
                                          />

                                          <Route
                                            path="archive/:reimbursementUuid"
                                            element={<ClaimInfo />}
                                          />

                                          <Route
                                            path="active"
                                            element={<ActiveReimbursements />}
                                          />

                                          <Route
                                            path="archive"
                                            element={<ArchivedReimbursements />}
                                          />

                                          <Route
                                            path="request"
                                            element={<Reimbursement />}
                                          />
                                        </Route>

                                        <Route
                                          path="/coverages"
                                          element={<CoveragePage />}
                                        />

                                        <Route
                                          path="/coverages/:id"
                                          element={<CoveragePage />}
                                        />
                                      </>
                                    ) : (
                                      <Route
                                        path="/user"
                                        element={<UserSocial />}
                                      />
                                    )}

                                    <Route
                                      path="/messages"
                                      element={<Messages />}
                                    />
                                    <Route path="/map" element={<Map />} />
                                    <Route path="/other" element={<Other />} />
                                  </Route>
                                ) : null}

                                {isBroker ? (
                                  <Route path="/" element={<BrokerLayout />}>
                                    <Route path="/" element={<BrokerHome />} />

                                    <Route
                                      path="/user"
                                      element={<UserSettingsBroker />}
                                    />

                                    <Route
                                      path="/change-broker-password"
                                      element={<BrokerChangePassword />}
                                    />
                                  </Route>
                                ) : null}

                                <Route path="*" element={<NotFound />} />
                              </Routes>
                            </RootLayout>
                          </CompatRouter>
                        </BrowserRouter>
                      )
                    }}
                  </authContext.Consumer>
                  <ErrorHandler />
                </ProvideAuth>
              </SnackbarProvider>
            </ErrorBoundary>
          </ProvideTranslations>
        </ProvideMobileVersion>
      </ThemeProvider>
    </FormStoreProvider>
  )
}

export default App
