import { Container } from '@material-ui/core'
import React from 'react'
import { translate } from '../utils/lang'

const Fallback = ({ error, componentStack, resetError }) => {
  return (
    <React.Fragment>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px 10px',
          border: '1px solid red',
          borderRadius: 20,
          margin: '50px auto',
        }}
        maxWidth={'sm'}
      >
        <h4>{translate('errorBoundary.title')}</h4>

        <p
          style={{
            fontSize: 16,
            color: '#222',
            textAlign: 'center',
            margin: '15px 0',
          }}
        >
          {translate('errorBoundary.message')}
        </p>
        <a
          href="/"
          style={{
            padding: '10px 20px',
            backgroundColor: '#d42f42',
            color: '#fff',
          }}
        >
          {translate('errorBoundary.button')}
        </a>
        {/* <div>{componentStack}</div> */}
      </Container>
    </React.Fragment>
  )
}

export default Fallback
