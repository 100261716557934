import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  TablePagination,
  IconButton,
} from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { useTranslation } from '../../../../hooks/use-translation'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  message: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
})

const labelDisplayedRows =
  (fromText, moreThanText) =>
  ({ from, to, count }) => {
    return `${from}-${to === -1 ? count : to} ${fromText} ${
      count !== -1 ? count : `${moreThanText} ${to}`
    }`
  }

function CoverageList({ coverages }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const { translate } = useTranslation()

  const rowsPerPage = 5

  const renderCoverages = () => {
    let startIndex = page === 0 ? 0 : page * rowsPerPage
    let endIndex = page === 0 ? rowsPerPage : startIndex + rowsPerPage

    let filtered = coverages.filter(
      (coverage, idx) => idx >= startIndex && idx < endIndex
    )

    return filtered.map((coverage) => {
      return (
        <Fragment key={coverage.id}>
          <ListItem className={classes.listItem}>
            <ListItemText primary={coverage.name} />

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '2em' }}>
                {coverage.limitAmount > 0 && coverage.limitAmount}
              </div>
              <div style={{ marginRight: '1em' }}>{coverage.type}</div>

              <Link to={`/coverages/${coverage.id}`}>
                <IconButton>
                  <ArrowForwardIos />
                </IconButton>
              </Link>
            </div>
          </ListItem>
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      <List className={classes.root}>
        {coverages.length === 0 ? (
          <p className={classes.message}>
            {translate(
              'c-client-dashboard.coverage.coverage-list.info-no-coverage'
            )}
          </p>
        ) : (
          renderCoverages()
        )}
      </List>
      {coverages.length > 0 && (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
          count={coverages.length}
          onChangePage={(e, newPage) => setPage(newPage)}
          labelDisplayedRows={labelDisplayedRows(
            translate('c-client-dashboard.coverage.coverage-list.w-from'),
            translate('c-client-dashboard.coverage.coverage-list.w-more-than')
          )}
        />
      )}
    </Fragment>
  )
}

export default CoverageList
