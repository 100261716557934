import React, { useEffect, useState, Fragment } from 'react'
import { Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { analytics } from 'utils/firebase'
import EditableCard from 'components/ClientDashboard/User/EditableCard'
import UserGrid from 'components/UserGrid'
import UserGridCard from 'components/UserGrid/UserGridCard'
import UserCard from 'components/ClientDashboard/User/UserCard'
import { useAuth } from 'hooks/use-auth'
import { useTranslation } from 'hooks/use-translation'
import { useRouter } from 'hooks/use-router'
import { useMobileVersion } from 'hooks/use-mobile-version'
import { addCoInsuredChild, getCoInsuredChild } from 'services/coInsuredService'
import {
  loadUserCards,
  loadCoverage,
  loadMarketingSubscription,
  changeMarketingSubscription,
} from 'services/userDataService'
import CoInsuredPersons from 'components/ClientDashboard/User/CoInsured/CoInsuredPersons'
import CoInsuredSkeleton from 'components/ClientDashboard/User/CoInsured/CoInsuredSkeleton'
import BankAccounts from 'components/ClientDashboard/User/Bank/BankAccounts'
import BankSkeleton from 'components/ClientDashboard/User/Bank/BankSkeleton'
import BoxSkeleton from 'components/ClientDashboard/User/UserCard/BoxSkeleton'
import PersonalInfo from 'components/ClientDashboard/User/Profile/PersonalInfo'
import UserSocialCard from 'components/ClientDashboard/User/Profile/UserSocialCard'
import MarketingSubscription from 'components/ClientDashboard/User/Profile/MarketingSubscription'
import ProfileSkeleton from 'components/ClientDashboard/User/Profile/ProfileSkeleton'
import useBankAccounts from 'hooks/BankAccount/useBankAccounts'
import CreateBankAccountButton from 'components/ClientDashboard/User/Bank/CreateBankAccountButton'
import GoBack from 'components/GoBack'

export default function UserSettings() {
  const { translate } = useTranslation()
  const auth = useAuth()
  const snackbar = useSnackbar()
  const router = useRouter()
  const isMobile = useMobileVersion()
  const [coverages, setCoverages] = useState([])
  const [userCards, setUserCards] = useState(null)
  const [addCoInsuredPersonModal, setAddCoInsuredPersonModal] = useState(false)
  const [isSubscribeForMarketing, setIsSubscribeForMarketing] = useState(null)

  const { clientId } = auth.claims

  const { data: bankAccounts, refetch: refetchBankAccounts } =
    useBankAccounts(clientId)

  const changePersonHandler = (e) => {
    analytics.logEvent('profile_change_insured')

    const clientsCards = JSON.parse(localStorage.getItem('clientsCards'))

    const activeUser = clientsCards.filter(
      (card) => card.clientId === e.target.value
    )[0]

    const activeUserCard = activeUser?.validCards[0]

    if (!activeUser) {
      return null
    }

    auth.setState((draft) => {
      draft.activeUser = activeUser
      draft.activeUserCard = activeUserCard
    })

    localStorage.setItem('activeUser', JSON.stringify(activeUser))
    localStorage.setItem('activeUserCard', JSON.stringify(activeUserCard))
    localStorage.setItem('showExtraCareDialog', true)

    snackbar.enqueueSnackbar(
      [
        translate('page-user-settings.messages.user-changed'),
        `${activeUser.firstName} ${activeUser.lastName}`,
      ].join(' '),
      {
        variant: 'success',
        autoHideDuration: 3000,
      }
    )

    setCoverages([])
  }

  const changeActiveUserCardHandler = (e) => {
    analytics.logEvent('profile_change_card')

    const { activeUser } = auth
    const selectedCard = e.target.value

    const [activeUserCard] = activeUser.validCards.filter(
      (card) => card.cardNumber === selectedCard
    )

    auth.setState((draft) => {
      draft.activeUserCard = activeUserCard
    })

    localStorage.setItem('activeUserCard', JSON.stringify(activeUserCard))

    snackbar.enqueueSnackbar(
      [
        translate('page-user-settings.messages.card-changed'),
        activeUserCard.firm,
      ].join(' '),
      {
        variant: 'success',
        autoHideDuration: 3000,
      }
    )

    setCoverages([])

    window.location.reload()
  }

  const addCoInsuredHandler = async (payload) => {
    try {
      analytics.logEvent('profile_add_insured')

      const getChildRes = await getCoInsuredChild(
        clientId,
        auth,
        payload?.cardNumber,
        payload?.personalNumber,
        payload?.dateOfBirth
      )

      const childCliendId = getChildRes.clientId

      let data = {
        clientId: childCliendId,
        primary_insured: clientId,
        deviceOs: 'MacOS',
        release_version: '3.0.0',
        ...payload,
      }

      const addChildRes = await addCoInsuredChild(auth, data)

      snackbar.enqueueSnackbar(addChildRes.message, { variant: 'success' })

      const fetchedUserCards = await loadUserCards(
        auth.activeUser.clientId,
        auth
      )

      setUserCards(fetchedUserCards)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const changeMarketingSubsriptionHandler = async (e) => {
    try {
      const isChecked = e.target.checked

      const data = {
        clientId: auth.activeUser.clientId,
        check_marketing: isChecked ? 3 : 2,
      }

      const res = await changeMarketingSubscription(data, auth)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })
      setIsSubscribeForMarketing(isChecked)

      analytics.logEvent(
        isChecked
          ? 'profile_marketingova_check'
          : 'profile_marketingova_uncheck'
      )
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    async function getUserProfileData() {
      const clientId =
        auth.activeUser.role === 'parent'
          ? auth.activeUser.clientId
          : auth.claims.clientId

      try {
        const fetchedUserCards = await loadUserCards(clientId, auth)

        await refetchBankAccounts()

        const fetchedMarketingSubscription = await loadMarketingSubscription(
          auth.activeUser.clientId,
          auth
        )

        const isSubscribe =
          fetchedMarketingSubscription.check_marketing === 3 ? true : false

        setIsSubscribeForMarketing(isSubscribe)

        setUserCards(fetchedUserCards)
      } catch (e) {
        console.error(e)
      }
    }

    if (auth.activeUser.role) {
      getUserProfileData()
    }
    // eslint-disable-next-line
  }, [auth.activeUser])

  useEffect(() => {
    async function getCoverage() {
      try {
        const fetchedCoverages = await loadCoverage(
          auth.activeUser.clientId,
          auth,
          auth.activeUserCard.contractId,
          auth.activeUserCard.cardId
        )

        setCoverages(fetchedCoverages)
      } catch (e) {
        console.error(e)
      }
    }

    getCoverage()
    // eslint-disable-next-line
  }, [auth.activeUser.clientId, auth.activeUserCard])

  const logoutHandler = async () => {
    try {
      await auth.signout()

      router.push('/')
    } catch (e) {
      console.error('User Social Logout', e)
    }
  }

  return (
    <>
      <GoBack />
      <Container
        maxWidth="lg"
        className="custom-spacing"
        style={{ marginTop: 20 }}
      >
        <UserGrid isMobile={isMobile}>
          <Grid item xs={12} md={6}>
            {coverages.length === 0 ? (
              <BoxSkeleton />
            ) : (
              <UserCard coverages={coverages} />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <UserGridCard>
              {userCards === null ? (
                <CoInsuredSkeleton />
              ) : (
                <EditableCard
                  title={translate('page-user-settings.title-co-insured')}
                  body={
                    <CoInsuredPersons
                      coInsuredPersons={userCards}
                      changePersonHandler={changePersonHandler}
                      addCoInsuredPersonModal={addCoInsuredPersonModal}
                      setAddCoInsuredPersonModal={setAddCoInsuredPersonModal}
                      addCoInsuredHandler={addCoInsuredHandler}
                      changeActiveUserCardHandler={changeActiveUserCardHandler}
                    />
                  }
                />
              )}
            </UserGridCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <UserGridCard>
              {!bankAccounts ? (
                <BankSkeleton />
              ) : (
                <>
                  <EditableCard
                    title={translate('page-user-settings.title-bank-accounts')}
                    body={<BankAccounts bankAccounts={bankAccounts} />}
                    footer={
                      auth?.activeUser?.role === 'parent' ? (
                        <CreateBankAccountButton />
                      ) : null
                    }
                  />
                </>
              )}
            </UserGridCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <UserGridCard>
              {isSubscribeForMarketing === null ? (
                <ProfileSkeleton isHasHealthCare={true} />
              ) : (
                <Fragment>
                  {auth.signInProvider === 'password' && (
                    <EditableCard
                      title={translate('page-user-settings.title-profile')}
                      body={<PersonalInfo />}
                    />
                  )}

                  <MarketingSubscription
                    isSubscribeForMarketing={isSubscribeForMarketing}
                    changeMarketingSubsriptionHandler={
                      changeMarketingSubsriptionHandler
                    }
                  />

                  <UserSocialCard
                    isShowedUserCard={
                      auth.signInProvider === 'password' ? false : true
                    }
                    logoutHandler={logoutHandler}
                  />
                </Fragment>
              )}
            </UserGridCard>
          </Grid>
        </UserGrid>
      </Container>
    </>
  )
}
