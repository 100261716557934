import moment from 'moment'
import {
  validateEgn,
  validateIdentificationNumber,
} from './egnAndIdentificationNumber'
import { translate } from '../utils/lang'

export const connectWithHealthCareValidate = ({
  personalNumber,
  phoneNumber,
  names,
  email,
  gdpr,
  cardNumber,
  dateOfBirth,
  radioChoice,
}) => {
  const errors = {}

  let phoneRegex = /^\+?\d{10,14}$/
  const dateFormat = 'YYYY-MM-DD'
  const dateToFormat = moment(new Date(dateOfBirth)).format(dateFormat)
  const isDateOfBirthValid = moment(dateToFormat, dateFormat, true).isValid()

  let emailRegex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  if (!email || !emailRegex.test(email)) {
    errors.email = translate('validate-connect-with-healthcare.email')
  }

  switch (radioChoice) {
    case 'personalNumber':
      const isEGN = validateEgn(personalNumber)
      const lnchError = validateIdentificationNumber(personalNumber)

      const egnIsValid = isEGN.error === ''
      const lnchIsValid = lnchError === ''

      if (egnIsValid || lnchIsValid) break

      errors.personalNumber = translate(
        'c-form.add-co-insured-person.eng-lnch-error'
      )

      break
    case 'dateOfBirth':
      if (!dateOfBirth) {
        errors.dateOfBirth = translate(
          'installments.regular-payment.validate.birthdate'
        )
      }

      if (!isDateOfBirthValid) {
        errors.dateOfBirth = translate(
          'c-form.add-co-insured-person.dateOfBirthFormatError'
        )
      }
      break
    default:
      break
  }

  if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
    errors.phoneNumber = translate('validate-connect-with-healthcare.phone')
  }

  if (!names) {
    errors.names = translate('validate-connect-with-healthcare.names')
  }

  if (!gdpr) {
    errors.gdpr = translate('validate-connect-with-healthcare.terms')
  }

  if (!cardNumber || cardNumber.length === 0) {
    errors.cardNumber = translate('validate-connect-with-healthcare.cardNumber')
  }

  return errors
}
