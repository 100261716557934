import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import EditableCard from '../../components/ClientDashboard/User/EditableCard'
import PersonalInfo from '../../components/BrokerDashboard/User/PersonalInfo'
import UserGridCard from '../../components/UserGrid/UserGridCard'
import GoBack from '../../components/GoBack'
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from '../../hooks/use-router'
import { useTranslation } from '../../hooks/use-translation'
import { updatePasswordBroker } from '../../services/brokerService'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2em',
  },
}))

export default function UserSettingsBroker() {
  const { translate } = useTranslation()
  const auth = useAuth()
  const classes = useStyles()
  const router = useRouter()
  const snackbar = useSnackbar()
  const [passwordChangeModal, setPasswordChangeModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updatePasswordHandler = async (oldPassword, newPassword) => {
    try {
      const data = {
        username: auth.claims.username,
        oldPassword,
        newPassword,
        deviceId: '123123',
        deviceOs: 'Web',
      }

      const res = await updatePasswordBroker(data, auth)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })

      setIsSubmitting(false)
      setPasswordChangeModal(false)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })

      setIsSubmitting(false)
    }
  }

  const logoutHandler = async () => {
    try {
      await auth.signout()

      router.push('/')
    } catch (e) {
      console.error('User Social Logout', e)
    }
  }

  return (
    <>
      <GoBack />

      <Container
        maxWidth="lg"
        className={`${classes.container} custom-spacing`}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <UserGridCard twoColumns={true}>
              <EditableCard
                title={translate('page-user-settings-broker.title-profile')}
                body={
                  <PersonalInfo
                    updatePasswordHandler={updatePasswordHandler}
                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}
                    passwordChangeModal={passwordChangeModal}
                    setPasswordChangeModal={setPasswordChangeModal}
                    logoutHandler={logoutHandler}
                  />
                }
              />
            </UserGridCard>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
