import React from 'react'
import isEmpty from 'lodash/isEmpty'
import LoadingSpinner from 'components/LoadingSpinner'
import { useAuth } from 'hooks/use-auth'
import { Navigate, useLocation } from 'react-router-dom-v5-compat'

function PrivateRoute(props) {
  const { user, claims, activeUser } = useAuth()
  const location = useLocation()

  if (user === null || isEmpty(claims)) {
    return <Navigate to="/" state={{ from: location }} />
  }

  if (user === undefined && !activeUser) {
    return <LoadingSpinner global />
  }

  if (user && activeUser && !isEmpty(claims)) {
    return <>{props?.children}</>
  }

  return null
}

export default PrivateRoute
