import React, { useCallback } from 'react'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import { useSnackbar } from 'notistack'
import PermissionHandler from './PermissionHandler'
import Alert from '@material-ui/lab/Alert'
import { firebase } from './../../utils/firebase'
import { sendToken } from '../../services/notificationService'

function Notifications() {
  const auth = useAuth()
  const notifier = useSnackbar()
  const { translate } = useTranslation()
  const isNotificationAPIEnabled =
    'Notification' in window && firebase.messaging.isSupported()

  const sendMessagingTokenToServer = useCallback(async () => {
    if (isNotificationAPIEnabled && Notification.permission === 'granted') {
      sendToken({ notifier }, auth)
    }
  }, [auth, notifier, isNotificationAPIEnabled])

  return (
    <>
      {isNotificationAPIEnabled ? (
        <PermissionHandler handleToken={sendMessagingTokenToServer} />
      ) : (
        <Alert severity="error">
          {translate('c-notifications.not-supported')}
        </Alert>
      )}
    </>
  )
}

export default Notifications
