// Lib
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Capacitor } from '@capacitor/core'
// Hooks
import { useAuth } from '../../hooks/use-auth'
// Mobile integration
import { linkers, useCapacitorEvents } from '..'
// Services
import { updateDeviceToken } from '../../services/notificationService'
// Utils
import { translate } from '../../utils/lang'

/**
 * Captures and sends a firebase messaging token
 * coming from Capacitor.
 */
export default function MsgTokenSenderNative() {
  const [deviceIdToken, setDeviceIdToken] = useState(null)
  const msgToken = useCapacitorEvents('msgToken')
  const auth = useAuth()
  const notifier = useSnackbar()

  // Send token on dependencies change
  useEffect(() => {
    if (deviceIdToken && auth && auth.claims) {
      sendToken(deviceIdToken, notifier, auth)
    }

    // eslint-disable-next-line
  }, [deviceIdToken, auth.claims])

  useEffect(() => {
    if (msgToken) {
      setDeviceIdToken(msgToken)
    }
  }, [msgToken])

  return null
}

// UTIL

/**
 * Send the token to the backend
 *
 * @param {string} token
 * @param {ProviderContext} notifier
 * @param {any} auth
 */
async function sendToken(token, notifier, auth) {
  try {
    const platform = Capacitor.getPlatform()

    if (linkers.appConfig?.value?.maintenance_mode) return

    // send refreshed token to the server
    if (auth.claims.role === 'broker') {
      await updateDeviceToken(
        {
          deviceId: token,
          userId: auth.claims.userId,
          deviceOs: platform,
          isLogin: false,
        },
        auth
      )
    } else {
      await updateDeviceToken(
        {
          deviceId: token,
          clientId: auth.claims.clientId,
          deviceOs: platform,
          isLogin: false,
        },
        auth
      )
    }
  } catch (e) {
    notifier.enqueueSnackbar(translate('s-notification.error-init'), {
      variant: 'error',
    })
    console.error('Error getting FCM token', e)
  }
}
