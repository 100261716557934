import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import HamburgerIcon from '@material-ui/icons/Menu'
import Person from '@material-ui/icons/Person'

import { analytics } from '../../../utils/firebase'
import { useAuth } from '../../../hooks/use-auth'
import { useMobileVersion } from '../../../hooks/use-mobile-version'
import { isMobile as isCapacitor, linkers } from '../../../mobile'

import LoginButton from '../../LoginButton'
import MenuDrawer from '../MenuDrawer'
import MessageCount from '../MessageCount'
import { BAssistLogo, BulstradLogo } from '../Logo'
import CapacitorNavbar from '../CapacitorNavbar'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

const useStyles = makeStyles(() => ({
  grid: {
    display: 'grid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  threeColumns: {
    gridTemplateColumns: '20% 20% 20%',
  },
  twoColumns: {
    gridTemplateColumns: '30% 35%',
  },
  oneColumn: {
    gridTemplateColumns: '1fr',
  },
}))

export default function MobileNavbar({ navBarOptions }) {
  const [drawerState, setDrawerState] = useState(false)
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const auth = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleMessagesClick = () => {
    navigate('/messages')
    analytics.logEvent('messages')
  }

  useEffect(() => {
    setDrawerState(false)
  }, [pathname])

  return (
    <>
      <div className={`nav ${navBarOptions ? 'nav-border' : ''}`}>
        <div
          className={`${classes.grid} container ${
            auth.user
              ? classes.threeColumns
              : isCapacitor
              ? classes.oneColumn
              : classes.twoColumns
          }`}
        >
          {auth?.user ? (
            <>
              {isCapacitor ? (
                <>
                  {linkers?.appConfig?.value?.maintenance_mode === false ? (
                    <IconButton
                      color="secondary"
                      aria-label="User information"
                      onClick={() => {
                        navigate('/user')
                        analytics.logEvent('profile')
                      }}
                      size="medium"
                    >
                      <Person fontSize="large" />
                    </IconButton>
                  ) : (
                    <div></div>
                  )}
                </>
              ) : (
                <></>
              )}

              {!isCapacitor ? (
                <IconButton
                  color="secondary"
                  onClick={() => setDrawerState(true)}
                  aria-label="Navigation menu"
                >
                  <HamburgerIcon fontSize={isMobile ? 'large' : 'default'} />
                </IconButton>
              ) : null}
            </>
          ) : null}

          {!auth.user ? (
            isCapacitor ? (
              <CapacitorNavbar />
            ) : (
              <BulstradLogo />
            )
          ) : (
            <BAssistLogo />
          )}

          {linkers?.appConfig?.value?.maintenance_mode === false ? (
            <>
              {auth.user && auth.claims && auth.claims.role === 'client' && (
                <IconButton onClick={handleMessagesClick} size="medium">
                  <MessageCount />
                </IconButton>
              )}
            </>
          ) : null}

          {!auth.user && !isCapacitor && <LoginButton />}
        </div>
      </div>

      <MenuDrawer open={drawerState} setOpen={setDrawerState} />
    </>
  )
}
