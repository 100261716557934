// Libraries
import React, { createContext, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import has from 'lodash/has'
// Hooks
import { fetch } from 'hooks/use-fetch'
// Services
import {
  loadUserCards,
  createEmptyUser,
  changePassword,
  linkEmail,
} from 'services/userDataService'
import { loginBroker } from 'services/brokerService'
// Utils
import { firebase, analytics } from 'utils/firebase'
import { translate } from 'utils/lang'
// Actions
import setAuthDialog from 'actions/snackbar/setAuthDialog'
import { isMobile as isCapacitor } from 'mobile'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { FacebookLogin } from '@capacitor-community/facebook-login'
import { Capacitor } from '@capacitor/core'
import { sha256 } from 'utils/crypto'
import { getLatestInactiveCard } from 'utils/user'
import {
  getIdToken,
  getUserDetails,
  sendPasswordResetEmail,
} from 'services/auth'
import { useImmer } from 'use-immer'

export const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const dispatch = useDispatch()

  const snackbar = useSnackbar()

  const { language, dialog } = useSelector(({ lang, snackbar }) => ({
    language: lang.codeDisplay,
    dialog: snackbar.authDialog,
  }))

  const [state, setState] = useImmer({
    user: undefined,
    loading: false,
    activeUser: JSON.parse(localStorage.getItem('activeUser') ?? null),
    shouldChangeUserState: true,
    signInProvider: null,
    hasPasswordProvider: null,
    claims: null,
    shouldLogout: false,
    activeUserCard: localStorage.getItem('activeUserCard')
      ? JSON.parse(localStorage.getItem('activeUserCard'))
      : null,
    isUserLoading: true,
  })

  const getClientCards = async () => {
    try {
      const { isClient, hasHealthCare, clientId } = await getUserDetails()

      if (!isClient || !hasHealthCare) return

      const fetchedClientCards = await loadUserCards(clientId, {
        getIdToken,
      })

      const hasNoUsableCards =
        fetchedClientCards.length === 0 ||
        fetchedClientCards.validCards?.length === 0

      if (hasNoUsableCards) {
        snackbar.enqueueSnackbar(translate('error-no-cards'), {
          variant: 'error',
        })
        await signout()
        return
      }

      const initialActiveUser = JSON.parse(localStorage.getItem('activeUser'))

      const prevActiveUserClientId = initialActiveUser?.clientId

      const activeUser = prevActiveUserClientId
        ? fetchedClientCards.find(
            (user) => user.clientId === prevActiveUserClientId
          )
        : fetchedClientCards[0]

      setState((draft) => {
        draft.activeUser = activeUser
      })

      // Set clients cards in local storage
      localStorage.setItem('clientsCards', JSON.stringify(fetchedClientCards))

      localStorage.setItem('activeUser', JSON.stringify(activeUser))

      // Check if current card is not set already and set its initial value
      const initialActiveCard = JSON.parse(
        localStorage.getItem('activeUserCard')
      )

      const prevCardId = initialActiveCard?.cardId

      const previouslyActiveCard = prevCardId
        ? activeUser?.validCards.find((card) => card.cardId === prevCardId)
        : null

      const activeCards = activeUser?.validCards?.filter(
        (card) => card?.shouldBeActive === 'yes'
      )
      const hasActiveCards = activeCards?.length

      // show only if there are not active cards
      const latestInactiveCard = getLatestInactiveCard(
        activeUser?.validCards?.filter((card) => card?.shouldBeActive === 'no')
      )

      const defaultChosenCard = previouslyActiveCard
        ? previouslyActiveCard
        : hasActiveCards
        ? activeCards[0]
        : latestInactiveCard

      const activeUserCard =
        activeUser?.validCards?.length > 0 ? defaultChosenCard : null

      if (!activeUserCard) {
        snackbar.enqueueSnackbar(translate('error-no-cards'), {
          variant: 'error',
        })
        return
      }

      localStorage.setItem('activeUserCard', JSON.stringify(activeUserCard))

      setState((draft) => {
        draft.activeUserCard = activeUserCard
      })
    } catch (e) {
      console.error('Error while get client cards in login', e)
    }
  }

  const currentProviderInstance = () => {
    if (state.signInProvider === 'google.com') {
      return new firebase.auth.GoogleAuthProvider()
    } else if (state.signInProvider === 'facebook.com') {
      return new firebase.auth.FacebookAuthProvider()
    }
  }

  const linkEmailProvider = async ({ email, password }) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    )

    setState((draft) => {
      draft.shouldChangeUserState = false
    })

    try {
      await firebase
        .auth()
        .currentUser.reauthenticateWithPopup(currentProviderInstance())
      await firebase.auth().currentUser.linkWithCredential(credential)

      const data = {
        email,
        newPassword: password,
        tryChange: 1,
      }

      await linkEmail(data, { user: state.user, getIdToken })

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.connected-email'), {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })

      console.error('Error linking account with Email & Password', e)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const unlinkEmailProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      analytics.logEvent('profile_unlink_mail')
      const emailProvider = new firebase.auth.EmailAuthProvider()

      await firebase.auth().currentUser.unlink(emailProvider.providerId)
      await firebase.auth().currentUser.getIdTokenResult(true)

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.removed-email'), {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      console.error(e.message)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const linkFacebookProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      if (isCapacitor) {
        const FACEBOOK_PERMISSIONS = ['email']

        await FacebookLogin.initialize()

        await FacebookLogin.logout()

        const FacebookLoginResult = await FacebookLogin.login({
          permissions: FACEBOOK_PERMISSIONS,
        })

        const credential = firebase.auth.FacebookAuthProvider.credential(
          FacebookLoginResult.accessToken.token
        )
        await firebase.auth().currentUser.linkWithCredential(credential)
      } else {
        const provider = new firebase.auth.FacebookAuthProvider()
        await firebase.auth().currentUser.linkWithPopup(provider)
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.connected-facebook'), {
        variant: 'success',
      })

      analytics.logEvent('profile_connect_facebook')
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      console.error('Error linking account with Facebook', e)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const unlinkFacebookProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      analytics.logEvent('profile_unlink_facebook')
      const facebookProvider = new firebase.auth.FacebookAuthProvider()

      await firebase.auth().currentUser.unlink(facebookProvider.providerId)
      await firebase.auth().currentUser.getIdTokenResult(true)

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.removed-facebook'), {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      console.error('Error unlinking account with Facebook', e)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const unlinkGoogleProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      analytics.logEvent('profile_unlink_google')
      const googleProvider = new firebase.auth.GoogleAuthProvider()

      await firebase.auth().currentUser.unlink(googleProvider.providerId)
      await firebase.auth().currentUser.getIdTokenResult(true)

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.removed-google'), {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      console.error('Error unlinking account with Google', e)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const linkGoogleProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      if (isCapacitor) {
        GoogleAuth.initialize({
          clientId:
            '596140806462-unp4k4d7sgssm79r846qtig6d82phlt6.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: false,
        })
        const googleUser = await GoogleAuth.signIn()
        const credential = firebase.auth.GoogleAuthProvider.credential(
          googleUser.authentication.idToken
        )
        await firebase.auth().currentUser.linkWithCredential(credential)
      } else {
        const provider = new firebase.auth.GoogleAuthProvider()
        await firebase.auth().currentUser.linkWithPopup(provider)
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.connected-google'), {
        variant: 'success',
      })

      analytics.logEvent('profile_connect_google')
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })

      console.error('Error linking account with Google', JSON.stringify(e))

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  // Sign the user in using Apple Provider
  const signinWithApple = async (channelType, channelId) => {
    // If the query parameters channelType and channelId are present
    // We set them in the session storage, so that we use them in the next step where the user connect his account to Zdravna Grija
    if (channelId && channelType) {
      sessionStorage.setItem('channelId', channelId)
      sessionStorage.setItem('channelType', channelType)
    }
    try {
      setState((draft) => {
        draft.loading = true
        draft.shouldChangeUserState = false
      })

      let result

      if (isCapacitor && Capacitor.getPlatform() === 'ios') {
        const { SignInWithApple } = await import(
          '@capacitor-community/apple-sign-in'
        )

        const nonce = 'nonce'
        const hashedNonceHex = await sha256(nonce)

        let options = {
          clientId: process.env.REACT_APP_APPLE_SIGN_IN_AUDIENCE,
          redirectURI: '',
          scopes: 'email, name',
          state: '12345',
          nonce: hashedNonceHex,
        }

        const appleResult = await SignInWithApple.authorize(options)

        if (appleResult.response && appleResult.response.identityToken) {
          const provider = new firebase.auth.OAuthProvider('apple.com')

          provider.addScope('name')
          provider.addScope('email')

          const credential = provider.credential({
            idToken: appleResult.response.identityToken,
            rawNonce: nonce,
          })

          result = await firebase.auth().signInWithCredential(credential)
        } else {
          throw new Error('No identity token')
        }
      } else {
        const provider = new firebase.auth.OAuthProvider('apple.com')
        result = await firebase.auth().signInWithPopup(provider)
      }

      // Get the value that shows if the user is logging in for the first time
      const { isNewUser } = result.additionalUserInfo

      // Check if the user is signin in with Goolge for the first time
      if (isNewUser) {
        // Handle new provider account login
        await handleNewProviderAccountLogin()
      } else {
        // Handle existing provider account login
        await handleExistingProviderAccountLogin()
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })
    } catch (e) {
      if (e.code === 'auth/account-exists-with-different-credential') {
        snackbar.enqueueSnackbar(
          translate('use-auth.apple-already-connected'),
          { variant: 'error' }
        )
      } else {
        snackbar.enqueueSnackbar(translate('use-auth.apple-error'), {
          variant: 'error',
        })
      }

      console.error('Error logging in with Apple', e?.message)

      setState((draft) => {
        draft.loading = false
      })

      analytics.logEvent('vhod_failed_apple')

      signout()
    }
  }

  const linkAppleProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      if (isCapacitor && Capacitor.getPlatform() === 'ios') {
        const { SignInWithApple } = await import(
          '@capacitor-community/apple-sign-in'
        )

        const nonce = 'nonce'
        const hashedNonceHex = await sha256(nonce)

        let options = {
          clientId: process.env.REACT_APP_APPLE_SIGN_IN_AUDIENCE,
          redirectURI: '',
          scopes: 'email, name',
          state: '12345',
          nonce: hashedNonceHex,
        }

        const appleResult = await SignInWithApple.authorize(options)

        if (appleResult.response && appleResult.response.identityToken) {
          const provider = new firebase.auth.OAuthProvider('apple.com')

          provider.addScope('name')
          provider.addScope('email')

          const credential = provider.credential({
            idToken: appleResult.response.identityToken,
            rawNonce: nonce,
          })

          await firebase.auth().currentUser.linkWithCredential(credential)
        } else {
          throw new Error('No identity token')
        }
      } else {
        const provider = new firebase.auth.OAuthProvider('apple.com')
        await firebase.auth().currentUser.linkWithPopup(provider)
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.connected-apple'), {
        variant: 'success',
      })

      analytics.logEvent('profile_connect_apple')
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      console.error('Error linking account with Apple', e)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const unlinkAppleProvider = async () => {
    setState((draft) => {
      draft.loading = true
      draft.shouldChangeUserState = false
    })

    try {
      analytics.logEvent('profile_unlink_apple')
      const appleProvider = new firebase.auth.OAuthProvider('apple.com')

      await firebase.auth().currentUser.unlink(appleProvider.providerId)
      await firebase.auth().currentUser.getIdTokenResult(true)

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      snackbar.enqueueSnackbar(translate('use-auth.removed-apple'), {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      console.error('Error unlinking account with Apple', e)

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  // Sign the user in using Facebook Provider
  const signinWithFacebook = async (channelType, channelId) => {
    // If the query parameters channelType and channelId are present
    // We set them in the session storage, so that we use them in the next step where the user connect his account to Zdravna Grija
    if (channelId && channelType) {
      sessionStorage.setItem('channelId', channelId)
      sessionStorage.setItem('channelType', channelType)
    }
    try {
      setState((draft) => {
        draft.loading = true
        draft.shouldChangeUserState = false
      })

      let result

      if (isCapacitor) {
        const FACEBOOK_PERMISSIONS = ['email']

        await FacebookLogin.initialize()

        await FacebookLogin.logout()

        const FacebookLoginResult = await FacebookLogin.login({
          permissions: FACEBOOK_PERMISSIONS,
        })

        console.info(
          'FacebookLoginResult',
          FacebookLoginResult.accessToken.token
        )

        const credential = firebase.auth.FacebookAuthProvider.credential(
          FacebookLoginResult.accessToken.token
        )
        result = await firebase.auth().signInWithCredential(credential)
      } else {
        const provider = new firebase.auth.FacebookAuthProvider()
        result = await firebase.auth().signInWithPopup(provider)
      }

      // Get the value that shows if the user is logging in for the first time
      const { isNewUser } = result.additionalUserInfo

      // Check if the user is signin in with Goolge for the first time
      if (isNewUser) {
        // Handle new provider account login
        await handleNewProviderAccountLogin()
      } else {
        // Handle existing provider account login
        await handleExistingProviderAccountLogin()
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })
    } catch (e) {
      if (e.code === 'auth/account-exists-with-different-credential') {
        snackbar.enqueueSnackbar(
          translate('use-auth.facebook-already-connected'),
          { variant: 'error' }
        )
      } else {
        snackbar.enqueueSnackbar(translate('use-auth.facebook-error'), {
          variant: 'error',
        })
      }

      console.error('Error logging in with Facebook', e)
      setState((draft) => {
        draft.loading = false
      })

      analytics.logEvent('vhod_failed_facebook')

      signout()
    }
  }

  // Log the user in using Google Provider
  const signinWithGoogle = async (channelType, channelId) => {
    // If the query parameters channelType and channelId are present
    // We set them in the session storage, so that we use them in the next step where the user connect his account to Zdravna Grija
    if (channelId && channelType) {
      sessionStorage.setItem('channelId', channelId)
      sessionStorage.setItem('channelType', channelType)
    }

    try {
      setState((draft) => {
        draft.loading = true
        draft.shouldChangeUserState = false
      })

      let result

      if (isCapacitor) {
        GoogleAuth.initialize({
          clientId:
            '596140806462-unp4k4d7sgssm79r846qtig6d82phlt6.apps.googleusercontent.com',
          scopes: ['profile', 'email'],
          grantOfflineAccess: false,
        })
        const googleUser = await GoogleAuth.signIn()
        const credential = firebase.auth.GoogleAuthProvider.credential(
          googleUser.authentication.idToken
        )
        result = await firebase.auth().signInWithCredential(credential)
      } else {
        const provider = new firebase.auth.GoogleAuthProvider()
        result = await firebase.auth().signInWithPopup(provider)
      }

      // Get the value that shows if the user is logging in for the first time
      const { isNewUser } = result.additionalUserInfo

      // Check if the user is signin in with Goolge for the first time
      if (isNewUser) {
        // Handle new provider account login
        await handleNewProviderAccountLogin()
      } else {
        // Handle existing provider account login
        await handleExistingProviderAccountLogin()
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })
    } catch (e) {
      snackbar.enqueueSnackbar(translate('use-auth.google-error'), {
        variant: 'error',
      })

      console.error('Error logging in with Google', e)

      setState((draft) => {
        draft.loading = false
      })

      analytics.logEvent('vhod_failed_google')

      signout()
    }
  }

  // Handles Provider Login for existing users
  const handleExistingProviderAccountLogin = async () => {
    // If the user is not new, get the current token result
    const currentToken = await firebase.auth().currentUser.getIdTokenResult()
    // Check if the user has Zdravna Grija claim and if channelType and channelId are set
    // chanelType and channelId are properties that come from the URL which are set
    // in the ChatBot. We need these properties in order to login the user in the ChatBot.
    // Note: A user can be logged into the ChatBot only when they've Zdravna Grija present as a claim in the token result.
    const channelType = sessionStorage.getItem('channelType')
    const channelId = sessionStorage.getItem('channelId')

    if (currentToken.claims.zg === 'true' && channelType && channelId) {
      // Call the chatbot authentication service
      await authenticateChatBotUserInPortal(channelType, channelId)
    } else if (
      currentToken.claims.zg === 'false' &&
      channelType !== null &&
      channelId !== null
    ) {
      // Set this value to true so that we show the connect to Zdravna Grija modal immediatelly after ChatBot login
      sessionStorage.setItem('displayImmediateDialog', true)
      // The user is not new and does not have Zdravna grija
      // Tell him/her to connect it
      snackbar.enqueueSnackbar(translate('use-auth.healthcare-error'), {
        variant: 'info',
      })
    }
  }

  // Handles Provider Login for new users
  const handleNewProviderAccountLogin = async () => {
    // Create an empty user record
    await createEmptyUser(
      {
        channelType: sessionStorage.getItem('channelType'),
        channelId: sessionStorage.getItem('channelId'),
        firebaseUid: firebase.auth().currentUser.uid,
        isNewUser: true,
      },
      { getIdToken }
    )

    // Get the token after we've created an empty user record
    const currentToken = await firebase
      .auth()
      .currentUser.getIdTokenResult(true)

    // Check if the newly created user does not have Zdravna Grija
    if (
      has(currentToken.claims, 'zg') &&
      currentToken.claims.zg !== 'true' &&
      sessionStorage.getItem('channelType') !== null &&
      sessionStorage.getItem('channelId') !== null
    ) {
      // Set this value to true so that we show the connect to Zdravna Grija modal immediatelly
      sessionStorage.setItem('displayImmediateDialog', true)
    } else {
      sessionStorage.setItem('displayImmediateDialog', false)
    }
  }

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(email)

      dispatch(setAuthDialog(false))

      snackbar.enqueueSnackbar(translate('use-auth.pass-reset-email-sent'), {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const changePasswordWithResetPassword = async ({ code, password, email }) => {
    try {
      await firebase.auth().verifyPasswordResetCode(code)
      await firebase.auth().confirmPasswordReset(code, password)

      const data = {
        email,
        newPassword: password,
        tryChange: 1,
      }

      const user = await signin(email, password, null, null)

      setState((draft) => {
        draft.loading = true
      })

      await changePassword(data, { user, getIdToken })

      snackbar.enqueueSnackbar(translate('use-auth.pass-changed'), {
        variant: 'success',
      })
    } catch (e) {
      await signout()

      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const signin = async (email, password, channelType, channelId) => {
    try {
      setState((draft) => {
        draft.loading = true
      })
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)

      const currentToken = await firebase.auth().currentUser.getIdTokenResult()

      // Login the user comming from chatbot in TBI's backend, too
      if (
        has(currentToken.claims, 'zg') &&
        currentToken.claims.zg === 'false' &&
        channelType &&
        channelId
      ) {
        sessionStorage.setItem('displayImmediateDialog', true)
        sessionStorage.setItem('channelType', channelType)
        sessionStorage.setItem('channelId', channelId)
      }

      setState((draft) => {
        draft.shouldChangeUserState = true
      })

      return result.user
    } catch (e) {
      let errorMessage = ''

      if (e.code === 'auth/wrong-password') {
        errorMessage = translate('use-auth.pass-invalid')
      } else if (e.code === 'auth/user-not-found') {
        errorMessage = translate('use-auth.email-non-existent')
      } else if (e.code === 'auth/invalid-email') {
        errorMessage = translate('use-auth.email-invalid')
      } else if (e.code === 'auth/user-disabled') {
        errorMessage = translate('use-auth.user-deactivated')
      } else if (e.code === 'auth/too-many-requests') {
        errorMessage = translate('use-auth.too-many-tries')
      } else {
        errorMessage = e.message
      }

      snackbar.enqueueSnackbar(errorMessage, { variant: 'error' })
      analytics.logEvent('vhod_failed_login')

      console.error('Error while signing in .')
      setState((draft) => {
        draft.loading = false
      })
      signout()
    }
  }

  const signInWithToken = async (token) => {
    try {
      const firebaseRes = await firebase.auth().signInWithCustomToken(token)

      return firebaseRes
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const signinBroker = async (username, password) => {
    try {
      setState((draft) => {
        draft.loading = true
      })

      const data = {
        username,
        password,
        deviceId: '123123',
        deviceOs: 'Web',
      }

      const auth = { user: state.user }

      // Get broker data
      let brokerRes = await loginBroker(data, auth)

      localStorage.setItem('broker_name', brokerRes.names)

      if (brokerRes.userBrokerStatus === 'login_success') {
        // Login with custom token
        const firebaseRes = await signInWithToken(brokerRes.customFirebaseToken)

        return firebaseRes.user
      } else if (brokerRes.userBrokerStatus === 'locked') {
        snackbar.enqueueSnackbar(translate('use-auth.account-locked'), {
          variant: 'error',
        })
      } else if (brokerRes.userBrokerStatus === 'change_pass') {
        await signInWithToken(brokerRes.customFirebaseToken)

        return brokerRes.userBrokerStatus
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  const authenticateChatBotUserInPortal = async (channelType, channelId) => {
    try {
      setState((draft) => {
        draft.loading = true
      })
      const idToken = await getIdToken()

      const data = {
        firebaseToken: idToken,
        channelId,
        channelType,
        deviceId: 'asdasd123123',
        deviceOs: 'Web',
        language,
        release_version: '3.0.3',
      }

      await fetch.post(
        '/loginChatBotPortalUser',
        {
          body: JSON.stringify(data),
        },
        {}
      )

      clearChatBotStoredData()
    } catch (e) {
      snackbar.enqueueSnackbar(translate('use-auth.bot-connection-error'), {
        variant: 'error',
      })
      console.error('Error during sign in.', e)
      setState((draft) => {
        draft.loading = false
      })
    }
  }
  const signup = async (
    { email, password, phoneNumber, personalNumber, language, names, step },
    channelType,
    channelId
  ) => {
    setState((draft) => {
      draft.loading = true
    })

    try {
      const data = {
        email,
        password,
        phoneNumber,
        names,
        language,
        deviceId: 'Web123',
        deviceOs: 'Web',
        release_version: '3.0.2',
        check_marketing: 3,
        check_gdpr: true,
      }

      const isLoginViaBot = channelType && channelId

      if (isLoginViaBot) {
        await fetch.post(
          '/registerLoginChatBotUser',
          {
            body: JSON.stringify({
              ...data,
              channelId,
              channelType,
            }),
          },
          {}
        )

        await signin(email, password, channelType, channelId)
      } else {
        await fetch.post(
          '/registerPortalUser',
          {
            body: JSON.stringify(data),
          },
          {}
        )

        step === 0
          ? analytics.logEvent(`register-agree-all`)
          : analytics.logEvent(`register-other-choice-finished`)

        await signin(email, password)
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, {
        variant: 'error',
      })
      console.error('Error during registration.', e)

      analytics.logEvent(`registration-failed`, {
        email,
        name: names,
      })

      setState((draft) => {
        draft.loading = false
      })
    }
  }

  // Clears session storage that is used for the ChatBot's channelId and channelType
  const clearChatBotStoredData = () => {
    // remove the flag for showing the connect ZG modal to the user
    sessionStorage.removeItem('displayImmediateDialog')
    // Both channelId and channelType are used for loggin the user into the chat bot when connecting their account to use Zdravna Grija
    // remove channelId for the session
    sessionStorage.removeItem('channelId')
    // remove channelType for the session
    sessionStorage.removeItem('channelType')
  }

  const signout = () => {
    localStorage.removeItem('clientsCards')
    localStorage.removeItem('activeUser')
    localStorage.removeItem('activeUserCard')
    localStorage.removeItem('broker_name')

    setState((draft) => {
      draft.loading = true
      draft.shouldLogout = true
    })

    return firebase
      .auth()
      .signOut()
      .then(() => {
        setState((draft) => {
          draft.claims = null
          draft.user = null
          draft.activeUser = null
          draft.activeUserCard = null
          draft.loading = false
          draft.shouldLogout = true
          draft.isUserLoading = false
        })

        window.scrollTo(0, 0)
      })
  }

  const createRecaptchaVerifier = (buttonId, callback) => {
    return new firebase.auth.RecaptchaVerifier(buttonId, {
      size: 'invisible',
      callback,
    })
  }

  // Change user pass
  const updatePassword = async (oldPassword, newPassword) => {
    const currentUser = firebase.auth().currentUser

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        oldPassword
      )

      await currentUser.reauthenticateWithCredential(credential)

      await currentUser.updatePassword(newPassword)

      const data = {
        email: currentUser.email,
        newPassword,
        tryChange: 1,
      }

      await changePassword(data, { user: state.user, getIdToken })

      let successMessage = translate('use-auth.pass-changed')

      return successMessage
    } catch (e) {
      switch (e.code) {
        case 'auth/user-token-expired':
          console.warn(`${e.message} -> ${e.code}`)
          // Login the user again
          await signin(currentUser.email, newPassword)
          break
        default:
          console.error(e)
      }

      return Promise.reject(translate('use-auth.pass-change-error'))
    }
  }

  useEffect(() => {
    if (dialog && state.user) {
      dispatch(setAuthDialog(false))
    }
  }, [state.user, dialog, dispatch])

  useEffect(() => {
    setState((draft) => {
      draft.loading = true
    })

    const unsubTokenChangeListener = firebase
      .auth()
      .onIdTokenChanged(async (user) => {
        try {
          if (user) {
            if (state.shouldChangeUserState) {
              const { claims, signInProvider } = await getUserDetails()

              await getClientCards()

              const { providerData } = user

              const hasPasswordProvider =
                providerData.filter(
                  (provider) => provider.providerId === 'password'
                )?.length > 0

              setState((draft) => {
                draft.signInProvider = signInProvider
                draft.hasPasswordProvider = hasPasswordProvider
                draft.claims = claims
                draft.user = user
                draft.loading = false
                draft.isUserLoading = false
              })

              analytics.logEvent('login-session-initiated/reinitiated')
            }
          } else {
            await signout()
          }
        } catch (e) {
          console.error('Error while token changed', e)
        }
      })

    // Cleanup subscription on unmount
    return () => unsubTokenChangeListener()

    // eslint-disable-next-line
  }, [state.shouldChangeUserState])

  // Return the user object and auth methods
  return {
    user: state.user,
    claims: state.claims,
    activeUser: state.activeUser,
    activeUserCard: state.activeUserCard,
    hasPasswordProvider: state.hasPasswordProvider,
    loading: state.loading,
    isUserLoading: state.isUserLoading,
    signInWithToken,
    signin,
    signinBroker,
    signup,
    signout,
    resetPassword,
    changePasswordWithResetPassword,
    signinWithFacebook,
    signinWithGoogle,
    signinWithApple,
    linkFacebookProvider,
    unlinkFacebookProvider,
    linkAppleProvider,
    unlinkAppleProvider,
    linkGoogleProvider,
    unlinkGoogleProvider,
    linkEmailProvider,
    unlinkEmailProvider,
    signInProvider: state.signInProvider,
    authenticateChatBotUserInPortal,
    createRecaptchaVerifier,
    updatePassword,
    setState,
    getClientCards,
  }
}
