import React from 'react'

const UserPosition = () => {
  return (
    <div
      style={{
        width: 15,
        height: 15,
        borderRadius: '100%',
        backgroundColor: '#4386F3',
        border: '2px solid #fff',
      }}
    ></div>
  )
}

export default UserPosition
