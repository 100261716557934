// Libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Box,
  Button,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
// Hooks
import { useTranslation } from '../../../../../hooks/use-translation'
import { useAuth } from '../../../../../hooks/use-auth'
import { usePersistentForm } from '../../../../../hooks/use-persistent-form'
// Components
import SuperDocDialog from './SuperDocDialog'
import { analytics } from '../../../../../utils/firebase'
import { useEffect } from 'react'
import openInNewTab from '../../../../../utils/openInNewTab'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: 'fit-content',
    margin: '0 auto',
  },
  title: {
    margin: '0 auto 2em auto',
    textAlign: 'center',
  },
  allButtonsContainer: {
    display: 'grid',
    gridTemplateColumns: '7fr 1fr',
    gridGap: '0.5em',
    margin: '0 auto',
  },
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  superDocButton: {
    marginBottom: '2em',
  },
  iconButton: {
    justifySelf: 'flex-end',

    width: 'fit-content',
    height: 'fit-content',
    padding: '9.2px',
    marginRight: '-12px',
  },
}))

function redirectToSuperDoc({
  citySuperDocId,
  specialtySuperDocId,
  userCardNumber,
}) {
  const reqParams = [
    `?specialty_id=${specialtySuperDocId}`,
    `city_id=${citySuperDocId}`,
    `user_policy=${userCardNumber}`,
    'insurance_id=4',
  ].join('&')

  openInNewTab(`${process.env.REACT_APP_SUPERDOC_URL}${reqParams}`)
}

const SuperDoc = ({ nextStepHandler }) => {
  const classes = useStyles()
  const { translate } = useTranslation()
  const { activeUserCard } = useAuth()

  const hideInfoMessage = activeUserCard.superDocMsg

  const [infoDialogOpen, setInfoDialogOpen] = useState(false)

  const { values } = usePersistentForm('make-appointment')

  const handleRedirect = () => {
    const { city, speciality } = values

    const citySuperDocId = JSON.parse(city)?.city_superdocId
    const specialtySuperDocId = JSON.parse(speciality)?.spec_superdocId

    // Skip SuperDoc if no data for the city / speciality
    if (!citySuperDocId || !specialtySuperDocId) {
      console.warn('StepDetails: no citySuperDocId / specialtySuperDocId')
      nextStepHandler()
      return
    }

    analytics.logEvent('ZG_pregledi_zaiavka_choice_superdoc')

    redirectToSuperDoc({
      citySuperDocId,
      specialtySuperDocId,
      userCardNumber: activeUserCard.cardNumber,
    })
  }

  useEffect(() => {
    analytics.logEvent('ZG_pregledi_zaiavka_choice')
  }, [])

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        {translate('superdoc.choose-option')}
      </Typography>

      <Box className={classes.allButtonsContainer}>
        <Box className={classes.buttonGroupContainer}>
          <Button
            className={classes.superDocButton}
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleRedirect}
          >
            {translate('superdoc.with-superdoc')}
          </Button>

          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              analytics.logEvent('ZG_pregledi_zaiavka_choice_basist')
              nextStepHandler()
            }}
          >
            {translate('superdoc.with-b-assist')}
          </Button>
        </Box>

        {!hideInfoMessage && (
          <>
            <IconButton
              className={classes.iconButton}
              onClick={() => setInfoDialogOpen(true)}
            >
              <InfoOutlined color={'primary'} />
            </IconButton>

            <SuperDocDialog open={infoDialogOpen} setOpen={setInfoDialogOpen} />
          </>
        )}
      </Box>
    </Box>
  )
}

SuperDoc.propTypes = {
  nextStepHandler: PropTypes.func.isRequired,
}

export default SuperDoc
