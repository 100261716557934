import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  socialCardsContainer: {
    padding: '1em',
    border: '2px solid #e1e1e1',
    borderRadius: '0.5em',
  },
  socialCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userInfoBox: {
    marginBottom: '2em',
    border: '2px solid #e1e1e1',
    borderRadius: '0.5em',
    padding: '1em',
  },
  title: {
    marginBottom: '1.5em',
  },
  inputField: {
    marginBottom: '0.75em',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spacingBottom: {
    marginBottom: '2em',
  },
  logoutButton: {
    marginTop: 'auto',
  },
}))

function ProfileSkeleton({ isHasHealthCare }) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {/* Title */}
      <Skeleton
        className={classes.spacingBottom}
        variant="text"
        width="20%"
        height="2.5em"
      />

      {/* User info */}
      {isHasHealthCare && (
        <Box className={classes.userInfoBox}>
          {/* Title */}
          <Skeleton
            className={classes.title}
            variant="text"
            width="12em"
            height="2.5em"
          />

          {/* Email field */}
          <Skeleton
            className={classes.inputField}
            variant="text"
            width="100%"
            height="4em"
          />

          {/* Phone field */}
          <Skeleton
            className={classes.inputField}
            variant="text"
            width="100%"
            height="4em"
          />

          {/* Buttons */}
          <Box className={classes.buttonsContainer}>
            <Skeleton variant="rect" width="10em" height="4em" />
            <Skeleton variant="rect" width="10em" height="4em" />
          </Box>
        </Box>
      )}

      {/* Marketing communication */}
      <Skeleton
        className={classes.spacingBottom}
        variant="rect"
        width="100%"
        height="3em"
      />

      {/* Social cards */}
      <Box
        className={`${classes.socialCardsContainer} ${classes.spacingBottom}`}
      >
        <Box className={classes.socialCard}>
          <Skeleton
            variant="circle"
            width="4em"
            height="4em"
            style={{ marginRight: '2rem' }}
          />

          <Skeleton variant="text" width="80%" height="2.5em" />
        </Box>
      </Box>

      <Box
        className={`${classes.socialCardsContainer} ${classes.spacingBottom}`}
      >
        <Box className={classes.socialCard}>
          <Skeleton
            variant="circle"
            width="4em"
            height="4em"
            style={{ marginRight: '2rem' }}
          />

          <Skeleton variant="text" width="80%" height="2.5em" />
        </Box>
      </Box>

      {!isHasHealthCare && (
        <Box className={classes.socialCardsContainer}>
          <Box className={classes.socialCard}>
            <Skeleton
              variant="circle"
              width="4em"
              height="4em"
              style={{ marginRight: '2rem' }}
            />

            <Skeleton variant="text" width="80%" height="2.5em" />
          </Box>
        </Box>
      )}

      {/* Logout button */}
      {isHasHealthCare && (
        <Skeleton
          className={classes.logoutButton}
          variant="rect"
          width="100%"
          height="4em"
        />
      )}
    </Box>
  )
}

export default ProfileSkeleton
