import { MAX_UPLOAD_FILE_SIZE } from '../utils/config'
import { translate } from '../utils/lang'

export const makeSpecializedExaminationValidate = (comment, files) => {
  const errors = {}

  if (!comment) {
    errors.comment = translate('validate-make-specialized-examination.comment')
  }

  let fileGroupsThatHaveZeroElements =
    files &&
    Object.entries(files).filter(
      ([fileGroupName, fileGroupValues]) => fileGroupValues.length === 0
    )

  if (
    fileGroupsThatHaveZeroElements &&
    fileGroupsThatHaveZeroElements.length > 0
  ) {
    errors.files = translate('validate-make-specialized-examination.files')
  }

  const fileWithInvalidSize = Object.values(files ?? {})
    .flatMap((file) => file)
    .find((file) => file.size > MAX_UPLOAD_FILE_SIZE)

  if (fileWithInvalidSize) {
    errors.files = `${translate('validate-reimbursement.files-max-size-1')} ${
      fileWithInvalidSize.name
    } ${translate('validate-reimbursement.files-max-size-2')}`
  }

  return errors
}
