import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  coInsuredCardsContainer: {
    padding: '1.5em',
    border: '2px solid #e1e1e1',
    borderRadius: '0.5em',
  },
  flex: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  card: {
    height: '3em',
  },
  fullWidth: {
    width: '100%',
  },
  icon: {
    marginRight: '0.5em',
  },
  title: {
    marginBottom: '1.5em',
  },
  spacingBottom: {
    marginBottom: '2em',
  },
  logoutButton: {
    marginTop: 'auto',
  },
}))

function CoInsuredSkeleton() {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {/* Title */}
      <Skeleton
        className={classes.spacingBottom}
        variant="text"
        width="30%"
        height="2.5em"
      />

      <Box
        className={`${classes.coInsuredCardsContainer} ${classes.spacingBottom}`}
      >
        <Box
          className={`${classes.card} ${classes.flex} ${classes.spacingBottom}`}
        >
          <Box className={classes.fullWidth}>
            <Skeleton variant="text" width="50%" height="1.5em" />
            <Skeleton variant="text" width="45%" height="1.5em" />
          </Box>

          <Skeleton variant="circle" width="2.5em" height="2.5em" />
        </Box>

        <Skeleton
          className={classes.spacingBottom}
          variant="rect"
          width="100%"
          height="0.3em"
        />

        <Box className={`${classes.card} ${classes.flex}`}>
          <Box className={classes.flex}>
            <Skeleton
              className={classes.icon}
              variant="rect"
              width="2em"
              height="0.8em"
            />
            <Skeleton variant="text" width="50%" height="1.5em" />
          </Box>

          <Skeleton variant="circle" width="2.5em" height="2.5em" />
        </Box>
      </Box>

      {/* Add child button */}
      <Skeleton
        className={classes.logoutButton}
        variant="rect"
        width="100%"
        height="4em"
      />
    </Box>
  )
}

export default CoInsuredSkeleton
