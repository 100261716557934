import React, { useState, useEffect, useMemo, Fragment } from 'react'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { Grid, Container, Box } from '@material-ui/core'
import { analytics } from '../../utils/firebase'
import StepDetails from '../../components/ClientDashboard/Other/StepDetails'
import StepContract from '../../components/ClientDashboard/Other/StepContract'
import StepConfirm from '../../components/ClientDashboard/Other/StepConfirm'
import BoxSkeleton from '../../components/ClientDashboard/Other/BoxSkeleton'
import FileUploadContainer from '../../components/ClientDashboard/FileUploadContainer'
import BoxSkeletonImages from '../../components/ClientDashboard/FileUploadContainer/BoxSkeleton'
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from '../../hooks/use-router'
import { useTranslation } from '../../hooks/use-translation'
import {
  loadStructure,
  makeClaim,
  confirmClaim,
  resendEmail,
  verifyCode,
} from '../../services/otherService'
import { getSequence } from '../../services/sequenceService'
import { uploadDocument, addDocuments } from '../../services/fileService'
import GoBack from '../../components/GoBack'
import { usePersistentForm } from '../../hooks/use-persistent-form'
import { useClearForm } from '../../hooks/use-form-store'
import { LANG_BG, LANG_EN } from '../../config/languageTypes'

const Other = () => {
  const auth = useAuth()
  const router = useRouter()
  const snackbar = useSnackbar()
  const { clear } = useClearForm('other')
  const { translate, activeLanguage } = useTranslation()
  const [step, setStep] = useState(0)

  const nextStep = () => {
    setStep(step + 1)
    analytics.logEvent(
      step === 0 ? 'uvedomlenie_uvedomitel' : 'uvedomlenie_contract_event'
    )
  }
  const prevStep = () => setStep(step - 1)

  const [files, setFiles] = useState(null)

  const [isSubmittingClaim, setSubmittingClaim] = useState(false)

  const [emailInfo, setEmailInfo] = useState({
    isSubmitting: false,
    isSent: false,
  })

  const [emailCodeInfo, setEmailCodeInfo] = useState({
    value: '',
    error: '',
    isSubmitting: false,
    isChecked: false,
  })

  const [structure, setStructure] = useState({})
  const [claimOtherId, setClaimOtherId] = useState(null)
  const [sequence, setSequence] = useState('')

  const maximumAllowedFiles = 5

  const uploadContainers = [
    {
      doc_name: 'Заявяване на претенция документ',
      doc_name_en: 'Other document',
      doc_info: 'Прикачи докумeнт',
      doc_info_en: 'Attach document',
    },
  ]

  const initialState = {
    personType: 'person',
    name: '',
    companyName: '',
    egn: '',
    eik: '',
    legalPosition: '',
    type: '',
    street: '',
    streetNumber: '',
    city: '',
    phoneNumber: '',
    nationality: '',
    policyNumber: '',
    datePolicy: moment(),
    insurer: '',
    eventType: '',
    dateEvent: moment(),
    comment: '',
    privacy: false,
    email: auth.activeUser ? auth.activeUser.email : '',
    files: {},
  }

  const { values, handleChange } = usePersistentForm('other', initialState)

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      handleChange({ target: { name: 'files', value: files } })
    }
    // eslint-disable-next-line
  }, [files])

  useMemo(async () => {
    try {
      const structureRes = await loadStructure(auth)

      setStructure(structureRes)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
    // eslint-disable-next-line
  }, [])

  useMemo(() => {
    let allFilesTypes = {}

    uploadContainers.forEach((type) => {
      if (activeLanguage === LANG_BG) {
        allFilesTypes[type.doc_name] = []
      } else if (activeLanguage === LANG_EN) {
        allFilesTypes[type.doc_name_en] = []
      }
    })

    setFiles(allFilesTypes)
    // eslint-disable-next-line
  }, [activeLanguage])

  const sendEmailHandler = async ({
    personType,
    name,
    companyName,
    egn,
    eik,
    legalPosition,
    type,
    street,
    streetNumber,
    city,
    phoneNumber,
    nationality,
    policyNumber,
    datePolicy,
    insurer,
    eventType,
    dateEvent,
    comment,
    email,
  }) => {
    try {
      const sequenceRes = await getSequence(auth)
      const newSequence = sequenceRes.sequence
      setSequence(newSequence)

      const clientId = auth.activeUser ? auth.activeUser.clientId : ''

      const data = {
        clientId,
        numberPolicy: policyNumber,
        datePolicy: moment(datePolicy).format('YYYY-MM-DD'),
        policyInsurer: insurer,
        email,
        sequence: newSequence,
        claimantName: name,
        claimantLegalName: personType === 'legal' ? companyName : '',
        claimantEGN: egn,
        claimantEIK: personType === 'legal' ? eik : '',
        claimantLegalPosition: legalPosition,
        claimantPosition: type,
        claimantStreet: street,
        claimantStrNum: streetNumber,
        claimantCity: city,
        claimantPhone: phoneNumber,
        claimantCitizenship: nationality,
        claimantType: personType,
        insEvent: eventType,
        dateEvent: moment(dateEvent).format('YYYY-MM-DD'),
        commentClaim: comment,
      }

      analytics.logEvent('uvedomlenie_declare_email')

      const res = await makeClaim(data, auth)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })

      setClaimOtherId(res.claimOtherId)

      setEmailInfo({ isSubmitting: false, isSent: true })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      setEmailInfo({ ...emailInfo, isSubmitting: false })
    }
  }

  const resendEmailHandler = async ({ email }) => {
    try {
      analytics.logEvent('uvedomlenie_code_resend')

      const data = {
        id: claimOtherId,
        email,
      }

      const res = await resendEmail(data, auth)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })
      setEmailInfo({ isSubmitting: false, isSent: true })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      setEmailInfo({ ...emailInfo, isSubmitting: false })
    }
  }

  const verifyEmailCodeHandler = async (codeEmail) => {
    try {
      const data = {
        id: claimOtherId,
        codeEmail,
      }

      await verifyCode(data, auth)

      setEmailCodeInfo({
        isSubmitting: false,
        isChecked: true,
        value: codeEmail,
      })

      analytics.logEvent('uvedomlenie_confirm_code')
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      setEmailCodeInfo({ ...emailCodeInfo, isSubmitting: false })
    }
  }

  const confirmClaimHandler = async () => {
    try {
      const data = {
        id: claimOtherId,
      }

      const clientId = auth.activeUser ? auth.activeUser.clientId : ''

      analytics.logEvent('uvedomlenie_send')

      const res = await confirmClaim(data, clientId, auth)

      const allDocuments = addDocuments(files, sequence, auth)

      for (let data of allDocuments) {
        await uploadDocument(data, auth)
      }

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })
      setSubmittingClaim(false)
      clear()

      router.push('/')
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      setSubmittingClaim(false)
    }
  }

  return (
    <Fragment>
      <GoBack />
      <Container className="custom-spacing">
        <Box style={{ wordBreak: 'break-word' }}>
          <h1 style={{ margin: '1em 0' }} className="cm-heading-onboarding">
            <span className="text-span">
              {translate('other.header.primary').toUpperCase()}
            </span>
            <span className="text-span-2">
              {' '}
              {translate('other.header.secondary').toUpperCase()}
            </span>
          </h1>
        </Box>

        <div style={{ height: '10px' }} />

        <Grid
          container
          justifyContent="center"
          style={{
            border: '2px solid #e1e1e1',
            borderRadius: '3px',
          }}
          className="grid-m-c-1"
        >
          <Grid item lg={6} xs={12}>
            {Object.keys(structure).length === 0 ? (
              <BoxSkeletonImages hasTextEnabled={true} />
            ) : (
              <FileUploadContainer
                uploadContainers={uploadContainers}
                maximumAllowedFiles={maximumAllowedFiles}
                filesSetter={setFiles}
                uploadedFiles={files}
                hasTextEnabled={true}
              />
            )}
          </Grid>

          <Grid item lg={6} xs={12} style={{ padding: '30px' }}>
            <div style={{ padding: '30px 0', height: '100%' }}>
              {Object.keys(structure).length === 0 ? (
                <BoxSkeleton />
              ) : (
                <>
                  {step === 0 && (
                    <StepDetails
                      structure={structure}
                      files={files}
                      setFiles={setFiles}
                      nextStep={nextStep}
                      initialState={initialState}
                      setEmailInfo={setEmailInfo}
                    />
                  )}

                  {step === 1 && (
                    <StepContract
                      structure={structure}
                      files={files}
                      nextStep={nextStep}
                      prevStep={prevStep}
                    />
                  )}

                  {step === 2 && (
                    <StepConfirm
                      files={files}
                      prevStep={prevStep}
                      //   products={products}
                      emailInfo={emailInfo}
                      setEmailInfo={setEmailInfo}
                      sendEmailHandler={sendEmailHandler}
                      resendEmailHandler={resendEmailHandler}
                      verifyEmailCodeHandler={verifyEmailCodeHandler}
                      confirmClaimHandler={confirmClaimHandler}
                      emailCodeInfo={emailCodeInfo}
                      setEmailCodeInfo={setEmailCodeInfo}
                      isSubmittingClaim={isSubmittingClaim}
                      setSubmittingClaim={setSubmittingClaim}
                    />
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default Other
