// Lib
import { PushNotifications } from '@capacitor/push-notifications'
import * as Sentry from '@sentry/react'

// Linkers
import { linkers } from '..'
// Config
import { isMobile } from '../config'

/** Native logic to run on login */
export default async function registerNotificationListener() {
  // Must run only on mobile
  if (!isMobile) return
  // Request notifications permission
  PushNotifications.requestPermissions().then((permissionStatus) => {
    if (permissionStatus.receive === 'granted') {
      PushNotifications.register()

      // Push notification registration listener
      PushNotifications.addListener('registration', (token) => {
        // Send to linker if present
        if (token && token.value) {
          linkers.msgToken.next(token.value)
          // Debug
          // console.log('[MSG_TOKEN]', token.value)
        }
      })

      PushNotifications.addListener('registrationError', (error) => {
        Sentry.captureMessage(
          'PushNotifications registration error - ' + JSON.stringify(error)
        )
      })

      // Push notification main listener
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          // Send to linker
          linkers.pushNotification.next(notification)
        }
      )

      // Push notification action listener
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          // Send to linker
          linkers.pushNotification.next(notification.notification)
        }
      )
    }
  })
}
