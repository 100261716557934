import React from 'react'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { useAuth } from '../../../../hooks/use-auth'
import { useTranslation } from '../../../../hooks/use-translation'
import styles from './user-card.module.css'

const renderServiceType = (serviceType) => {
  let result

  if (!serviceType) {
    return null
  }

  switch (serviceType.trim()) {
    case 'Subscription and Refund':
      result = (
        <>
          <td>
            <span className={styles.dot}></span>
          </td>
          <td>
            <span className={styles.dot}></span>
          </td>
        </>
      )
      break
    case 'Refund':
      result = (
        <>
          <td />
          <td>
            <span className={styles.dot}></span>
          </td>
        </>
      )
      break
    case 'Subscription':
      result = (
        <>
          <td>
            <span className={styles.dot}></span>
          </td>
          <td />
        </>
      )
      break
    default:
      result = null
  }

  return result
}

function UserCard({ coverages }) {
  const { activeUser, activeUserCard } = useAuth()
  const { translate, activeLanguage } = useTranslation()

  const { firstName, lastName, personalNumber } = activeUser
  const { cardNumber, shouldBeActive } = activeUserCard

  const endDate = moment(activeUser.validityEndDate).format(
    'DD.MM.YYYY HH:MM:SS'
  )
  return (
    <section className={styles.container}>
      <Grid container spacing={2} className={styles['first-row']}>
        <Grid item xs={6}>
          <img
            src={`/images/bulstrad-logo-white${
              activeLanguage === 'en' ? '-en.svg' : '.png'
            }`}
            alt="Bulstrad"
          ></img>
        </Grid>
        <Grid item xs={6} className={styles.about}>
          <p>{translate('c-client-dashboard.user.user-card.info-phone')}</p>
          <p>{translate('c-client-dashboard.user.user-card.info-address')}</p>
          <p>www.bulstradlife.bg</p>
        </Grid>
      </Grid>

      <section className={styles['second-row']}>
        <section className={`${styles.name} ${styles['second-row-item']}`}>
          <section className={styles['white-field']}>
            {firstName} {lastName}
          </section>
        </section>
        <section className={styles['second-row-item']}>
          <section className={styles['grid-item-first-column']}>
            {translate('c-client-dashboard.user.user-card.id-num')}
          </section>
          <section className={styles['white-field']}>{cardNumber}</section>
        </section>
        <section className={styles['second-row-item']}>
          <section className={styles['grid-item-first-column']}>
            {translate('c-client-dashboard.user.user-card.personal-number')}:
          </section>
          <section className={styles['white-field']}>{personalNumber}</section>
        </section>
        <section className={styles['second-row-item']}>
          <section className={styles['grid-item-first-column']}>
            {translate('c-client-dashboard.user.user-card.validity')}
          </section>
          <section className={styles['white-field']}>{endDate}</section>
        </section>
        <section className={styles['second-row-item']}>
          <section className={styles['grid-item-first-column']}>
            {translate('c-client-dashboard.user.user-card.status')}
          </section>
          <section className={styles['white-field']}>
            {shouldBeActive === 'yes'
              ? translate('c-client-dashboard.user.user-card.active')
              : translate('c-client-dashboard.user.user-card.inactive')}
          </section>
        </section>
      </section>

      <section className={styles.line}></section>

      <section className={styles['third-row']}>
        <table>
          <thead>
            <tr>
              <th>{translate('c-client-dashboard.user.user-card.section')}</th>
              <th>{translate('c-client-dashboard.user.user-card.option')}</th>
              <th>
                {translate('c-client-dashboard.user.user-card.sub-service')}
              </th>
              <th>
                {translate('c-client-dashboard.user.user-card.reimbursement')}
              </th>
            </tr>
          </thead>

          <tbody>
            {coverages &&
              coverages.map(({ name, type, serviceType, id }) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{type}</td>
                  {renderServiceType(serviceType)}
                </tr>
              ))}
          </tbody>
        </table>
      </section>

      <section className={styles['last-row']}>
        <p>{translate('c-client-dashboard.user.user-card.info-1')}</p>
        <p>{translate('c-client-dashboard.user.user-card.info-2')}</p>
      </section>
    </section>
  )
}

export default UserCard
