// Libraries
import React from 'react'
import { useAuth } from '../../../../hooks/use-auth'
import { IconButton, Button, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
// Styles
import iconStyles from './icon-styles.module.css'
import HealeeDialog from './HealeeDialog'
import openInNewTab from '../../../../utils/openInNewTab'
import { analytics } from '../../../../utils/firebase'

const useStyles = makeStyles(() => ({
  newServiceButton: {
    paddingRight: '0.78em !important',
  },
  newServiceButtonLabel: {
    marginRight: 5,
    fontSize: '0.6em',
    textTransform: 'uppercase',
  },
  active: {
    color: '#D42F42',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
}))

export default function HealeeButton({ classes, disabled = false }) {
  const extraCareButtonClasses = useStyles()

  const auth = useAuth()
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const hasExtraCare = auth?.activeUserCard !== null

  const constructHealeeLink = () => {
    const { cardNumber, consultationsLimit, validityEndDate } =
      auth.activeUserCard

    const healeeData = [
      `?code=${cardNumber}`,
      `count=${consultationsLimit}`,
      `date=${validityEndDate}`,
    ].join('&')

    const healeeLink = `${process.env.REACT_APP_HEALEE_URL}${healeeData}`

    return healeeLink
  }

  const isDisabled =
    disabled ||
    !hasExtraCare ||
    auth.activeUserCard?.shouldBeActiveHealee !== 'yes'

  return (
    <>
      <Button
        style={isMobile ? { paddingLeft: '1.55rem' } : {}}
        disabled={isDisabled}
        onClick={() => {
          analytics.logEvent('dashboard_ZG_healee')

          if (!hasExtraCare) return
          openInNewTab(constructHealeeLink())
        }}
        fullWidth
        classes={{
          root: classes._button,
          label: classes._buttonLabel,
          iconSizeMedium: classes._buttonEndIconSize,
          startIcon: classes._buttonStartIcon,
        }}
        startIcon={
          <span
            className={`${iconStyles.icon} ${iconStyles['extra-care-icon']} ${
              isDisabled ? iconStyles.disabled : iconStyles.enabled
            }`}
            aria-label={translate('healee.consultation')}
          />
        }
      >
        <span>{translate('healee.consultation')}</span>
      </Button>

      {!auth.activeUserCard?.healeeMsg && (
        <IconButton
          disabled={isDisabled}
          className={`${classes.iconButton} ${extraCareButtonClasses.newServiceButton}`}
          onClick={() => {
            analytics.logEvent('dashboard_ZG_healee_info')
            setDialogOpen(true)
          }}
        >
          <span
            className={`${extraCareButtonClasses.newServiceButtonLabel} ${
              isDisabled
                ? extraCareButtonClasses.disabled
                : extraCareButtonClasses.active
            }`}
          >
            {translate('healee.new')}
          </span>
          <InfoOutlined color={isDisabled ? 'disabled' : 'primary'} />
        </IconButton>
      )}

      <HealeeDialog open={dialogOpen} setOpen={setDialogOpen} />
    </>
  )
}
