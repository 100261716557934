import React from 'react'
import BankAccountCard from './BankAccountCard'
import { useTranslation } from 'hooks/use-translation'

function BankAccounts({ bankAccounts }) {
  const { translate } = useTranslation()

  if (!bankAccounts) return null

  return (
    <section style={{ marginBottom: '2em' }}>
      {!bankAccounts?.length ? (
        <p>{translate('c-client-dashboard.user.bank.bank-accounts.empty')}</p>
      ) : (
        bankAccounts?.map((bankAccount) => (
          <BankAccountCard key={bankAccount.bankAccid} {...bankAccount} />
        ))
      )}
    </section>
  )
}

export default BankAccounts
