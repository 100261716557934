import React, { useState, useContext, createContext } from 'react'
import translations from '../translations.json'
import {LANG_BG, LANG_LOCAL_STORAGE_KEY} from '../config/languageTypes'

export const LangContext = createContext()

export const ProvideTranslations = ({ children }) => {
  const contextValue = useTranslationProvider()
  return (
    <LangContext.Provider value={contextValue}>{children}</LangContext.Provider>
  )
}

export const useTranslation = () => useContext(LangContext)

const useTranslationProvider = () => {
  const defaultLanguage = (() => {
    const lang = localStorage.getItem(LANG_LOCAL_STORAGE_KEY)
    if (!lang) {
      localStorage.setItem(LANG_LOCAL_STORAGE_KEY, LANG_BG)
    }
    return lang
  })()

  const [activeLanguage, setActiveLanguage] = useState(
    defaultLanguage ? defaultLanguage : LANG_BG
  )

  const changeLanguage = (language) => {
    setActiveLanguage(language)
    localStorage.setItem(LANG_LOCAL_STORAGE_KEY, language)

    window.location.reload()
  }

  const translate = (path) => {
    let translation = translations[activeLanguage]
    path.split('.').forEach((key) => {
      translation = translation[key]
    })

    return translation
  }

  return {
    activeLanguage,
    translations,
    changeLanguage,
    translate,
  }
}
