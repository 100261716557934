import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Request from 'components/ClientDashboard/Reimbursement/Request'
import { useTranslation } from 'hooks/use-translation'
import { usePersistentForm } from 'hooks/use-persistent-form'
import { LANG_BG, LANG_EN } from 'config/languageTypes'

const ReimbursementRequest = () => {
  const { activeLanguage } = useTranslation()
  const [files, setFiles] = useState({})
  const [typeDocumentsList, setTypeDocumentsList] = useState([])

  const initialState = {
    bankAccount: '',
    service: '',
    invoiceNumber: '',
    sum: '',
    dateEvent: moment(),
    dateInvoice: moment(),
    files: {},
  }

  const { values, handleChange } = usePersistentForm(
    'reimbursement',
    initialState
  )

  useEffect(() => {
    if (Object.keys(values).length > 0) {
      handleChange({ target: { name: 'files', value: files } })
    }
    // eslint-disable-next-line
  }, [files])

  useEffect(() => {
    if (values && values.service) {
      let typeDocumentsList = JSON.parse(values.service).typeDocumentsList
      setTypeDocumentsList(typeDocumentsList)

      let allFilesTypes = {}
      typeDocumentsList.forEach((type) => {
        if (activeLanguage === LANG_BG) {
          allFilesTypes[type.doc_name] = []
        } else if (activeLanguage === LANG_EN) {
          allFilesTypes[type.doc_name_en] = []
        }
      })

      setFiles(allFilesTypes)
    }
    // eslint-disable-next-line
  }, [values.service, activeLanguage])

  return (
    <Request
      files={files}
      setFiles={setFiles}
      typeDocumentsList={typeDocumentsList}
    />
  )
}

export default ReimbursementRequest
