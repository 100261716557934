import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Tooltip, IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import EmailIcon from '@material-ui/icons/Email'
import Person from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { analytics } from '../../utils/firebase'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import { useMobileVersion } from '../../hooks/use-mobile-version'
import AuthDialog from '../AuthDialog'
import LoginButton from '../LoginButton'
import MobileNavbar from './MobileNavbar'
import MessageCount from './MessageCount'
import Logo from './Logo'
import NavbarSkeleton from './NavbarSkeleton'
import InsurancesDropdown from './InsurancesDropdown'
import s from './s.module.css'
import { linkers } from '../../mobile'
import styles from '../GoBack/back-btn.module.css'
import { NavLink, useLocation, useNavigate } from 'react-router-dom-v5-compat'

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(2),
    marginBottom: '0',
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2em',

    '& > svg': {
      width: '24px',
      height: '24px',
      marginLeft: theme.spacing(2),
    },
  },
  loginButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  googleIcon: {
    width: '24px',
    height: '24px',
    marginLeft: theme.spacing(2),
  },
  installmentsButtonContainer: {
    borderColor: '#d42f42',
    borderWidth: '1px',
    color: '#d42f42',
    padding: '6px 25px',
    marginRight: '20px',

    // '&:hover': {
    //   backgroundColor: '#d42f42',
    //   color: '#fff',
    // },
  },
}))

const SignInProviderIcon = () => {
  const auth = useAuth()
  const classes = useStyles()

  return (
    <>
      {auth.signInProvider === 'facebook.com' && (
        <FacebookIcon style={{ color: '#FFFFFF' }} />
      )}
      {auth.signInProvider === 'google.com' && (
        <img
          className={classes.googleIcon}
          src="images/google-icon.svg"
          alt="Google Icon"
        />
      )}
      {auth.signInProvider === 'password' && (
        <EmailIcon style={{ color: '#FFFFFF' }} />
      )}
    </>
  )
}

const LoginProviderTooltip = () => {
  const auth = useAuth()
  const classes = useStyles()
  const { translate } = useTranslation()

  const loggedWith =
    auth.signInProvider === 'facebook.com'
      ? 'Facebook'
      : auth.signInProvider === 'google.com'
      ? 'Google'
      : translate('c-navbar.email')

  const name = auth.activeUser
    ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}`
    : auth.user
    ? auth.user.displayName
    : ''

  if (auth.claims.role === 'broker') {
    return <p className={classes.name}>{localStorage.getItem('broker_name')}</p>
  }

  return (
    <Tooltip
      style={{ background: 'transparent' }}
      title={
        <Box className={classes.tooltip}>
          {translate('c-navbar.logged-in-as') + `${loggedWith} `}
          <SignInProviderIcon />
        </Box>
      }
    >
      <p className={classes.name}>{name}</p>
    </Tooltip>
  )
}

// Logged in menu
const LoggedInAsRegularUser = () => {
  const auth = useAuth()
  const { translate } = useTranslation()
  const navigate = useNavigate()

  const handleMessagesClick = () => {
    navigate('/messages')
    analytics.logEvent('messages')
  }

  const handleProfileClick = () => {
    navigate('/user')
    analytics.logEvent('profile')
  }

  const logoutHandler = async () => {
    try {
      await auth.signout()
      navigate('/')
    } catch (e) {
      console.error('User Social Logout', e)
    }
  }

  return (
    <>
      <LoginProviderTooltip />

      {linkers?.appConfig?.value?.maintenance_mode === false ? (
        <>
          <Tooltip
            id="user-details-link"
            title={translate('c-navbar.my-profile')}
          >
            <IconButton
              color="secondary"
              aria-label="User information"
              onClick={handleProfileClick}
            >
              <Person />
            </IconButton>
          </Tooltip>

          {auth.claims.role === 'client' ? (
            <Tooltip
              id="notifications-tooltip"
              title={translate('c-navbar.messages')}
            >
              <IconButton
                onClick={handleMessagesClick}
                color="secondary"
                aria-label="Messages"
              >
                <MessageCount />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      ) : null}

      {linkers?.appConfig?.value?.maintenance_mode === true ? (
        <Button
          className={styles['logout-button']}
          startIcon={<ExitToAppIcon />}
          onClick={logoutHandler}
        >
          {translate('c-go-back.exit')}
        </Button>
      ) : null}
    </>
  )
}

const Navbar = () => {
  const classes = useStyles()
  const auth = useAuth()
  const isMobile = useMobileVersion()
  const navigate = useNavigate()
  const location = useLocation()
  const { translate } = useTranslation()

  // Auth dialog
  const { isAuthDialogVisible } = useSelector(({ snackbar }) => ({
    isAuthDialogVisible: snackbar.authDialog,
  }))

  const navBarOptions =
    location.pathname === '/' && !auth.user ? '' : 'nav-border'

  if (isMobile) {
    return (
      <div>
        <MobileNavbar navBarOptions={navBarOptions} />
        <AuthDialog visible={isAuthDialogVisible} />
      </div>
    )
  }

  return (
    <>
      <div className={`nav ${navBarOptions}`}>
        <div className="container w-clearfix cm-nav-logged-in">
          <Logo />

          {auth.loading ? (
            <NavbarSkeleton />
          ) : (
            <div className="nav-bar-logged-in">
              {auth.user ? (
                <LoggedInAsRegularUser />
              ) : (
                <Box className={classes.loginButtonContainer}>
                  <NavLink to="/about" activeClassName={s.selected}>
                    <Button>{translate('about.menu-label')}</Button>
                  </NavLink>
                  <InsurancesDropdown />
                  <Button
                    className={classes.installmentsButtonContainer}
                    variant="outlined"
                    type="button"
                    onClick={() => {
                      analytics.logEvent('header_menu_pay_online')
                      navigate('/installments/regular-payment')
                    }}
                  >
                    {translate('installments.header-title')}
                  </Button>
                  <LoginButton />
                </Box>
              )}
            </div>
          )}
        </div>
      </div>

      <AuthDialog visible={isAuthDialogVisible} />
    </>
  )
}

export default Navbar
