// Libraries
import React, { Fragment, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import has from 'lodash/has'
// Components
import ButtonContainer from '../../../../ButtonContainer'
import ConnectEmailAndPasswordDialog from '../../../../Form/ConnectEmailAndPasswordDialog'
// Hooks
import { useAuth } from '../../../../../hooks/use-auth'
import { useMobileVersion } from '../../../../../hooks/use-mobile-version'
import { useTranslation } from '../../../../../hooks/use-translation'
// Config
import {
  FACEBOOK,
  GOOGLE,
  PASSWORD,
  APPLE,
} from '../../../../../config/signInProviderTypes'
import { canLoginWithApple } from '../../../../../mobile'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em',
    cursor: 'pointer',
    border: `2px solid ${theme.palette.primary.grey}`,
    borderRadius: '0.5em',
    outline: 'none',

    '&:not(:last-child)': {
      marginBottom: '2em',
    },

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },

    '&.active': {
      borderColor: theme.palette.primary.main,
    },
  },

  infoContainer: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
  },

  infoContainerMobile: {
    marginRight: '0',
    width: '100%',
  },
  logo: {
    width: '4em',
    height: '4em',
    marginRight: '2rem',
  },
  name: {
    margin: '0 2rem 0 0',
    fontSize: theme.typography.header5.fontSize,
    fontWeight: '400',
    textAlign: 'left',
  },
  loader: {
    marginLeft: 'auto',
  },
  button: {
    marginRight: '0',

    '&:hover': {
      opacity: '1',
    },
  },
}))

function UserSocialCard({ logoutHandler, isShowedUserCard }) {
  const classes = useStyles()
  const auth = useAuth()
  const { translate } = useTranslation()
  const isMobile = useMobileVersion()
  const [confirmRegistrationModal, setConfirmRegistrationModal] =
    useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submittingProvider, setSubmittingProvider] = useState('')

  const name = auth.user.displayName
  const { hasPasswordProvider, signInProvider, claims } = auth

  const allProviders = claims.firebase.identities

  const handleClick = async (provider) => {
    try {
      setIsSubmitting(true)

      if (provider === FACEBOOK) {
        setSubmittingProvider(FACEBOOK)

        has(allProviders, FACEBOOK)
          ? await auth.unlinkFacebookProvider()
          : await auth.linkFacebookProvider()
      } else if (provider === GOOGLE) {
        setSubmittingProvider(GOOGLE)

        has(allProviders, GOOGLE)
          ? await auth.unlinkGoogleProvider()
          : await auth.linkGoogleProvider()
      } else if (provider === APPLE) {
        setSubmittingProvider(APPLE)

        has(allProviders, APPLE)
          ? await auth.unlinkAppleProvider()
          : await auth.linkAppleProvider()
      } else if (provider === PASSWORD) {
        setSubmittingProvider(PASSWORD)

        if (hasPasswordProvider) {
          await auth.unlinkEmailProvider()
        } else {
          if (!confirmRegistrationModal) {
            setConfirmRegistrationModal(true)
          }
        }
      }

      setIsSubmitting(false)
      setSubmittingProvider('')
    } catch (e) {
      console.error(e)

      setIsSubmitting(false)
      setSubmittingProvider('')
    }
  }

  const linkEmailHandler = async ({ email, password }) => {
    try {
      await auth.linkEmailProvider({ email, password })

      setConfirmRegistrationModal(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Fragment>
      <Box
        className={`${classes.container} ${
          signInProvider === FACEBOOK && 'active'
        } custom-social-card-container`}
        onClick={() => signInProvider !== FACEBOOK && handleClick(FACEBOOK)}
      >
        <Box
          className={`${classes.infoContainer} ${
            isMobile ? classes.infoContainerMobile : ''
          }`}
        >
          <img
            className={classes.logo}
            src="images/facebook.png"
            alt="Facebook Logo"
          />
          {signInProvider === FACEBOOK ? (
            <p className={`${classes.name} custom-social-card-name`}>
              {isSubmitting ? (
                <CircularProgress size={25} thickness={2.7} color="primary" />
              ) : (
                name
              )}
            </p>
          ) : (
            <p className={`${classes.name} custom-social-card-name`}>
              {has(allProviders, FACEBOOK)
                ? translate(
                    'c-client-dashboard.user.profile.user-social-card.remove-facebook'
                  )
                : translate(
                    'c-client-dashboard.user.profile.user-social-card.connect-facebook'
                  )}
            </p>
          )}

          {isSubmitting && submittingProvider === FACEBOOK && (
            <span className={classes.loader}>
              <CircularProgress size={25} thickness={2.7} color="primary" />
            </span>
          )}
        </Box>

        {signInProvider === FACEBOOK && (
          <ButtonContainer isFromUserSocial={true}>
            <button
              className={`${classes.button} cm-button outlined`}
              onClick={logoutHandler}
            >
              {translate(
                'c-client-dashboard.user.profile.user-social-card.exit'
              )}
            </button>
          </ButtonContainer>
        )}
      </Box>

      <Box
        className={`${classes.container} ${
          signInProvider === GOOGLE && 'active'
        } custom-social-card-container`}
        onClick={() => signInProvider !== GOOGLE && handleClick(GOOGLE)}
      >
        <Box
          className={`${classes.infoContainer} ${
            isMobile ? classes.infoContainerMobile : ''
          }`}
        >
          <img
            className={classes.logo}
            src="images/google.png"
            alt="Google Logo"
          />
          {signInProvider === GOOGLE ? (
            <p className={`${classes.name} custom-social-card-name`}>{name}</p>
          ) : (
            <p className={`${classes.name} custom-social-card-name`}>
              {has(allProviders, GOOGLE)
                ? translate(
                    'c-client-dashboard.user.profile.user-social-card.remove-google'
                  )
                : translate(
                    'c-client-dashboard.user.profile.user-social-card.connect-google'
                  )}
            </p>
          )}

          {isSubmitting && submittingProvider === GOOGLE && (
            <span className={classes.loader}>
              <CircularProgress size={25} thickness={2.7} color="primary" />
            </span>
          )}
        </Box>

        {signInProvider === GOOGLE && (
          <ButtonContainer isFromUserSocial={true}>
            <button
              className={`${classes.button} cm-button outlined`}
              onClick={logoutHandler}
            >
              {translate(
                'c-client-dashboard.user.profile.user-social-card.exit'
              )}
            </button>
          </ButtonContainer>
        )}
      </Box>

      {/* Link / unlink APPLE */}
      {canLoginWithApple && (
        <Box
          className={`${classes.container} ${
            signInProvider === APPLE && 'active'
          } custom-social-card-container`}
          onClick={() => signInProvider !== APPLE && handleClick(APPLE)}
        >
          <Box
            className={`${classes.infoContainer} ${
              isMobile ? classes.infoContainerMobile : ''
            }`}
          >
            <img
              className={classes.logo}
              src="images/apple_logo_dark.svg"
              alt="Apple Logo"
            />
            {signInProvider === APPLE ? (
              <p className={`${classes.name} custom-social-card-name`}>
                {name}
              </p>
            ) : (
              <p className={`${classes.name} custom-social-card-name`}>
                {has(allProviders, APPLE)
                  ? translate(
                      'c-client-dashboard.user.profile.user-social-card.remove-apple'
                    )
                  : translate(
                      'c-client-dashboard.user.profile.user-social-card.connect-apple'
                    )}
              </p>
            )}

            {isSubmitting && submittingProvider === APPLE && (
              <span className={classes.loader}>
                <CircularProgress size={25} thickness={2.7} color="primary" />
              </span>
            )}
          </Box>

          {signInProvider === APPLE && (
            <ButtonContainer isFromUserSocial={true}>
              <button
                className={`${classes.button} cm-button outlined`}
                onClick={logoutHandler}
              >
                {translate(
                  'c-client-dashboard.user.profile.user-social-card.exit'
                )}
              </button>
            </ButtonContainer>
          )}
        </Box>
      )}

      {isShowedUserCard && (
        <Box
          className={`${classes.container} ${
            signInProvider === PASSWORD && 'active'
          } custom-social-card-container`}
          onClick={() => signInProvider !== PASSWORD && handleClick(PASSWORD)}
        >
          <Box
            className={`${classes.infoContainer} ${
              isMobile ? classes.infoContainerMobile : ''
            }`}
          >
            <img
              className={classes.logo}
              src="images/user.png"
              alt="User Logo"
            />
            {signInProvider === PASSWORD ? (
              <p className={`${classes.name} custom-social-card-name`}>
                {name}
              </p>
            ) : (
              <p className={`${classes.name} custom-social-card-name`}>
                {hasPasswordProvider
                  ? translate(
                      'c-client-dashboard.user.profile.user-social-card.remove-email'
                    )
                  : translate(
                      'c-client-dashboard.user.profile.user-social-card.connect-email'
                    )}
              </p>
            )}
            {isSubmitting && submittingProvider === PASSWORD && (
              <span className={classes.loader}>
                <CircularProgress size={25} thickness={2.7} color="primary" />
              </span>
            )}
          </Box>

          {signInProvider === PASSWORD && (
            <ButtonContainer isFromUserSocial={true}>
              <button
                className={`${classes.button} cm-button outlined`}
                onClick={logoutHandler}
              >
                {translate(
                  'c-client-dashboard.user.profile.user-social-card.exit'
                )}
              </button>
            </ButtonContainer>
          )}

          {auth.signInProvider && auth.signInProvider !== PASSWORD && (
            <ConnectEmailAndPasswordDialog
              open={confirmRegistrationModal}
              setOpen={setConfirmRegistrationModal}
              callback={linkEmailHandler}
            />
          )}
        </Box>
      )}
    </Fragment>
  )
}

export default UserSocialCard
