import moment from 'moment'
import { translate } from '../utils/lang'
import { isSumValid } from './helpers'
import { MAX_UPLOAD_FILE_SIZE } from '../utils/config'

export const reimbursementValidateStepDetails = ({
  service,
  dateEvent,
  dateInvoice,
  invoiceNumber,
  sum,
  bankAccount,
  files,
}) => {
  const errors = {}

  let fileGroupsThatHaveZeroElements =
    files &&
    Object.entries(files).filter(
      ([fileGroupName, fileGroupValues]) => fileGroupValues.length === 0
    )

  if (
    fileGroupsThatHaveZeroElements &&
    fileGroupsThatHaveZeroElements.length > 0
  ) {
    errors.files = translate('validate-reimbursement.files')
  }

  const fileWithInvalidSize = Object.values(files ?? {})
    .flatMap((file) => file)
    .find((file) => file.size > MAX_UPLOAD_FILE_SIZE)

  if (fileWithInvalidSize) {
    errors.files = `${translate('validate-reimbursement.files-max-size-1')} ${
      fileWithInvalidSize.name
    } ${translate('validate-reimbursement.files-max-size-2')}`
  }

  if (!service) {
    errors.service = translate('validate-reimbursement.service')
  }

  if (!dateEvent || !moment(dateEvent).isValid()) {
    errors.dateEvent = translate('validate-reimbursement.event-date-val')
  }

  if (moment(dateEvent).isAfter(moment())) {
    errors.dateEvent = translate('validate-reimbursement.event-date-after')
  }

  if (!dateInvoice || !moment(dateInvoice).isValid()) {
    errors.dateInvoice = translate('validate-reimbursement.date-invoice-val')
  }

  if (moment(dateInvoice).isAfter(moment())) {
    errors.dateInvoice = translate('validate-reimbursement.date-invoice-after')
  }

  if (moment(dateInvoice).endOf('day').isBefore(moment(dateEvent))) {
    errors.dateInvoice = translate('validate-reimbursement.date-invoice-before')
  }

  if (!invoiceNumber || invoiceNumber.length > 10) {
    errors.invoiceNumber = translate(
      'validate-reimbursement.invoice-number-length'
    )
  }

  if (isSumValid(sum)) {
    errors.sum = translate('validate-reimbursement.sum')
  }

  if (!bankAccount) {
    errors.bankAccount = translate('validate-reimbursement.bank-acc')
  }

  return errors
}
