// Libraries
import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import has from 'lodash/has'
import { useLocation } from 'react-router-dom-v5-compat'
import moment from 'moment'
// Components
import Request from 'components/ClientDashboard/MakeAppointment/Request'
import Archive from 'components/ClientDashboard/MakeAppointment/Archive'
import SliderTabs from 'components/ClientDashboard/SliderTabs'
import UpToDate from 'components/ClientDashboard/MakeAppointment/UpToDate'
import Modal from 'components/Modal'
// Hooks
import { useAuth } from 'hooks/use-auth'
import { useTranslation } from 'hooks/use-translation'
import { usePersistentForm } from 'hooks/use-persistent-form'
// Services
import {
  loadCitites,
  loadFacilities,
  loadSpecialties,
  loadAllAppointments,
  loadHolidays,
  changeTicketStatus,
} from 'services/makeAppointmentService'
// Utills
import { manipulateDateAndTime } from 'components/ClientDashboard/MakeAppointment/StepDate/timeAndDateUtil'
import GoBack from 'components/GoBack'

const MakeAppointment = () => {
  const location = useLocation()
  const defaultTab = has(location.state, 'tab') ? location.state.tab : 2

  const auth = useAuth()
  const snackbar = useSnackbar()
  const { translate, activeLanguage } = useTranslation()
  const [tab, setTab] = useState(defaultTab)
  const [cities, setCities] = useState([])
  const [facilities, setFacilities] = useState([])
  const [specialties, setSpecialties] = useState([])
  const [hospitalsOnMap, setHospitalsOnMap] = useState(null)
  const [holidays, setHolidays] = useState([])
  const [archivedAppointments, setArchivedAppointments] = useState(null)
  const [upToDateAppointments, setUpToDateAppointments] = useState(null)
  const [ticketStatusData, setTicketStatusData] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [header, setHeader] = useState(null)

  const { date, time } = manipulateDateAndTime(moment(), '')

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    message: '',
  })

  const isAllowedToMakeAppoinment = auth.activeUserCard.shouldBeActive === 'yes'

  const showModalMessageAndChangeToArchiveTab = () => {
    const translatedModalMessage = translate('make-appointment.modal-message')

    setModalOptions({ isOpen: true, message: translatedModalMessage })
    setTab(0)
  }

  // If user card is deactivated, user cannot make appointment
  useMemo(() => {
    if (tab === 2 && !isAllowedToMakeAppoinment) {
      showModalMessageAndChangeToArchiveTab()
      return
    }

    // eslint-disable-next-line
  }, [tab])

  const changeTabHandler = (newTab) => {
    // User cannot make appointment is his card is not active
    if (newTab === 2 && !isAllowedToMakeAppoinment) {
      showModalMessageAndChangeToArchiveTab()

      return
    }

    setTab(newTab)
  }

  const closeModalHandler = () => {
    setModalOptions({ isOpen: false, message: '' })
    window.history.replaceState({}, document.title)
  }

  const { values, handleChange } = usePersistentForm('make-appointment', {
    city: '',
    hospital: '',
    speciality: '',
    symptoms: '',
    date,
    time,
    holidays,
  })

  useEffect(() => {
    if (holidays.length > 0) {
      // set default value for holidays
      handleChange({
        target: {
          name: 'holidays',
          value: holidays,
        },
      })
    }

    // eslint-disable-next-line
  }, [holidays])

  // Fetch Cities, Facilities, Specialties, All Appointments and Holidays
  useMemo(async () => {
    try {
      const fetchedCities = await loadCitites(auth.activeUser.clientId, auth)

      setCities(fetchedCities)

      const fetchedFacilities = await loadFacilities(
        auth.activeUser.clientId,
        auth
      )

      setFacilities(fetchedFacilities)
      setHospitalsOnMap(fetchedFacilities)

      const fetchedSpecialties = await loadSpecialties(
        auth.activeUser.clientId,
        auth
      )

      setSpecialties(fetchedSpecialties)

      const fetchedAllAppointments = await loadAllAppointments(
        auth.activeUser.clientId,
        auth
      )

      // Archived appointments
      const fetchedArchivedAppointments = fetchedAllAppointments.filter(
        (appointment) =>
          appointment.status === 'Customer rejected' ||
          moment(
            appointment.approvedTimeDate
              ? appointment.approvedTimeDate
              : appointment.requestedDate
          ).isBefore(moment(), 'day')
      )

      setArchivedAppointments(fetchedArchivedAppointments)

      // Up to date appointments
      const fetchedUpToDateAppointments = fetchedAllAppointments.filter(
        (appointment) =>
          appointment.status !== 'Customer rejected' &&
          moment(
            appointment.approvedTimeDate
              ? appointment.approvedTimeDate
              : appointment.requestedDate
          ).isSameOrAfter(moment(), 'day')
      )

      setUpToDateAppointments(fetchedUpToDateAppointments)

      const fetchedHolidays = await loadHolidays(auth.activeUser.clientId, auth)

      setHolidays(fetchedHolidays)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (values.city && values.hospital) {
      setHospitalsOnMap([JSON.parse(values.hospital)])
    }

    if (values.city && values.city !== '' && values.city !== 'null') {
      const hospitalsInCurrentCity = facilities.filter(
        (facility) => facility.city === JSON.parse(values.city)?.city
      )
      setHospitalsOnMap(hospitalsInCurrentCity)
    } else if (values.city === 'null') {
      setHospitalsOnMap(facilities)
    }

    // eslint-disable-next-line
  }, [values.city, values.hospital])

  useEffect(() => {
    async function changeStatus() {
      try {
        const data = {
          clientId: auth.activeUser.clientId,
          ...ticketStatusData,
        }

        const res = await changeTicketStatus(data, auth)

        snackbar.enqueueSnackbar(res.message, { variant: 'success' })

        const fetchedAllAppointments = await loadAllAppointments(
          auth.activeUser.clientId,
          auth
        )

        // Archived appointments
        const fetchedArchivedAppointments = fetchedAllAppointments.filter(
          (appointment) =>
            appointment.status === 'Customer rejected' ||
            moment(
              appointment.approvedTimeDate
                ? appointment.approvedTimeDate
                : appointment.requestedDate
            ).isBefore(moment(), 'day')
        )

        setArchivedAppointments(fetchedArchivedAppointments)

        // Up to date appointments
        const fetchedUpToDateAppointments = fetchedAllAppointments.filter(
          (appointment) =>
            appointment.status !== 'Customer rejected' &&
            moment(
              appointment.approvedTimeDate
                ? appointment.approvedTimeDate
                : appointment.requestedDate
            ).isSameOrAfter(moment(), 'day')
        )

        setUpToDateAppointments(fetchedUpToDateAppointments)
        setIsSubmitting(false)

        if (ticketStatusData.status === 'Customer rejected') {
          setTab(1)
        }
      } catch (e) {
        setIsSubmitting(false)
        snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      }
    }

    if (has(ticketStatusData, 'caseId') && has(ticketStatusData, 'status')) {
      changeStatus()
    }
    // eslint-disable-next-line
  }, [ticketStatusData])

  useEffect(() => {
    const allHeaders = translate('make-appointment.header')

    setHeader({
      0: allHeaders['up-to-date'],
      1: allHeaders['archive'],
      2: allHeaders['request'],
    })

    // eslint-disable-next-line
  }, [activeLanguage])

  return (
    <>
      <GoBack />
      <Container className="custom-spacing">
        <Grid
          container
          className="grid-m-c-1"
          alignItems="center"
          justifyContent="space-between"
        >
          <h1 style={{ margin: '1em 0' }} className="cm-heading-onboarding">
            <span className="text-span">
              {header && header[tab].primary.toUpperCase()}
            </span>
            <span className="text-span-2">
              {' '}
              {header && header[tab].secondary.toUpperCase()}
            </span>
          </h1>

          <SliderTabs
            tab={tab}
            setTab={changeTabHandler}
            upToDateAppointmentsLength={
              upToDateAppointments && upToDateAppointments.length
            }
          />
        </Grid>

        <div style={{ height: '10px' }} />

        <Grid
          container
          justifyContent="center"
          style={
            tab === 2
              ? {
                  border: '2px solid #e1e1e1',
                  borderRadius: '3px',
                }
              : {}
          }
          className="grid-m-c-1"
        >
          {tab === 0 && (
            <UpToDate
              upToDateAppointments={upToDateAppointments}
              ticketStatusData={ticketStatusData}
              setTicketStatusData={setTicketStatusData}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          )}

          {tab === 1 && <Archive archivedAppointments={archivedAppointments} />}

          {tab === 2 && (
            <Request
              values={values}
              cities={cities}
              facilities={facilities}
              specialties={specialties}
              hospitalsOnMap={hospitalsOnMap}
              setHospitalsOnMap={setHospitalsOnMap}
              setUpToDateAppointments={setUpToDateAppointments}
              setTab={setTab}
            />
          )}
        </Grid>

        {/* Modal */}
        {modalOptions.isOpen && (
          <Modal
            isOpen={modalOptions.isOpen}
            closeModalHandler={closeModalHandler}
            message={modalOptions.message}
          />
        )}
      </Container>
    </>
  )
}

export default MakeAppointment
