import React, { Fragment } from 'react'
import moment from 'moment'
import {
  ListItem,
  ListItemText,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AccessTime, Room, AssignmentInd, AddBox } from '@material-ui/icons'
import { useMobileVersion } from '../../../../../hooks/use-mobile-version'
import { useTranslation } from '../../../../../hooks/use-translation'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inProgressContainer: {
    width: '100%',
    border: '2px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '0.3em',
    padding: '1em',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  // On mobile
  headers: {
    '& > span': {
      fontSize: '0.95rem',
    },

    '& > p': {
      fontSize: '0.8rem',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: '0.3em',
    },
  },
  specialist: {
    fontSize: '0.875rem',
  },
  mobileDate: {
    flex: '1 0 auto',
    textAlign: 'right',
  },
  date: {
    textAlign: 'right',
  },
  info: {
    textAlign: 'right',
  },
  dateAndPlace: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.5em',
  },
  marginTop: {
    marginTop: '1.5em',
  },
  description: {
    marginBottom: '1.5em',
  },
  cancelButton: {
    marginRight: '1em',
  },
}))

function AppointmentCard({
  appointment,
  isSubmitting,
  setIsSubmitting,
  ticketStatusData,
  setTicketStatusData,
}) {
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()

  const [approvedDate, approvedTime] = appointment.approvedTimeDate.split(' ')

  return (
    <Fragment>
      <ListItem className={classes.listItem}>
        <Box className={classes.header}>
          <div>
            <ListItemText
              className={isMobile ? classes.headers : ''}
              primary={
                <span className={classes.title}>
                  <AddBox
                    color={
                      appointment.status === 'Closed' ? 'primary' : 'disabled'
                    }
                  />
                  {appointment.status === 'In Progress' ||
                  !appointment.speciality
                    ? appointment.requestedSpeciality
                    : appointment.speciality}
                </span>
              }
              secondary={
                <span>
                  {appointment.status === 'In Progress' || !appointment.city
                    ? appointment.requestedCity
                    : appointment.city}
                  {(appointment.hospital || appointment.requestedHospital) && (
                    <span>
                      ,{' '}
                      {appointment.status === 'In Progress' ||
                      !appointment.hospital
                        ? appointment.requestedHospital
                        : appointment.hospital}
                    </span>
                  )}
                </span>
              }
            />

            {appointment.status !== 'In Progress' && (
              <Typography className={classes.specialist}>
                {appointment.specialistName}
              </Typography>
            )}
          </div>

          <Box className={isMobile ? classes.mobileDate : classes.date}>
            <Box>
              {moment(
                appointment.status === 'In Progress' ||
                  !appointment.approvedTimeDate
                  ? appointment.requestedDate
                  : approvedDate
              ).format('DD MMMM YYYY')}
            </Box>
            <Box>
              {appointment.status === 'In Progress' ||
              !appointment.approvedTimeDate
                ? appointment.requestedTime
                : approvedTime}
            </Box>
          </Box>
        </Box>

        {appointment.status === 'In Progress' && (
          <Box
            className={`${classes.marginTop} ${classes.inProgressContainer}`}
          >
            <p className={classes.description}>{appointment.description}</p>

            <Box className={classes.dateAndPlace}>
              <AccessTime className={classes.icon} />
              <span>
                {moment(approvedDate).format('dddd')},{' '}
                {moment(approvedDate).format('DD MMMM YYYY')}, {approvedTime}
              </span>
            </Box>

            <Box className={classes.dateAndPlace}>
              <Room className={classes.icon} />
              <span>
                {appointment.city
                  ? appointment.city
                  : appointment.requestedCity}
                {(appointment.hospital || appointment.requestedHospital) && (
                  <span>
                    ,{' '}
                    {appointment.hospital
                      ? appointment.hospital
                      : appointment.requestedHospital}
                  </span>
                )}
              </span>
            </Box>

            <Box className={classes.dateAndPlace}>
              <AssignmentInd className={classes.icon} />
              <span>{appointment.specialistName}</span>
            </Box>

            <Box className={classes.marginTop}>
              <Button
                className={classes.cancelButton}
                disabled={
                  isSubmitting && ticketStatusData.caseId === appointment.caseId
                }
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => {
                  setIsSubmitting(true)
                  setTicketStatusData({
                    caseId: appointment.caseId,
                    status: 'Cancel',
                  })
                }}
              >
                {isSubmitting &&
                ticketStatusData.caseId === appointment.caseId ? (
                  <CircularProgress size={25} thickness={2.7} color="primary" />
                ) : (
                  translate(
                    [
                      'c-client-dashboard',
                      'make-appointment',
                      'up-to-date',
                      'appointment-card',
                      'reject',
                    ].join('.')
                  )
                )}
              </Button>

              <Button
                disabled={
                  isSubmitting && ticketStatusData.caseId === appointment.caseId
                }
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  setIsSubmitting(true)
                  setTicketStatusData({
                    caseId: appointment.caseId,
                    status: 'Closed',
                  })
                }}
              >
                {isSubmitting &&
                ticketStatusData.caseId === appointment.caseId ? (
                  <CircularProgress size={25} thickness={2.7} color="primary" />
                ) : (
                  translate(
                    [
                      'c-client-dashboard',
                      'make-appointment',
                      'up-to-date',
                      'appointment-card',
                      'confirm',
                    ].join('.')
                  )
                )}
              </Button>
            </Box>
          </Box>
        )}
      </ListItem>
    </Fragment>
  )
}

export default AppointmentCard
