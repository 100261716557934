import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from '../../hooks/use-router'
import { updatePasswordBroker } from '../../services/brokerService'
import PasswordChange from '../../components/Form/PasswordChange'
import setAuthDialog from '../../actions/snackbar/setAuthDialog'

export default function BrokerChangePassword() {
  const auth = useAuth()
  const snackbar = useSnackbar()
  const router = useRouter()
  const dispatch = useDispatch()
  const [passwordChangeModal, setPasswordChangeModal] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const updatePasswordHandler = async (oldPassword, newPassword) => {
    try {
      const data = {
        username: auth.claims.username,
        oldPassword,
        newPassword,
        deviceId: '123123',
        deviceOs: 'Web',
      }

      const res = await updatePasswordBroker(data, auth)
      setPasswordChangeModal(false)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })

      setIsSubmitting(false)

      await auth.signInWithToken(res.generatedToken)

      sessionStorage.removeItem('brokerStatus')
      router.push('/')
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    async function logoutBroker() {
      try {
        await auth.signout()
        sessionStorage.removeItem('brokerStatus')

        router.push('/login')
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      }
    }

    if (passwordChangeModal === false && isSubmitting === false) {
      logoutBroker()
    }

    // eslint-disable-next-line
  }, [passwordChangeModal])

  useEffect(() => {
    dispatch(setAuthDialog(false))

    // eslint-disable-next-line
  }, [])

  const brokerStatus = sessionStorage.getItem('brokerStatus')

  if (brokerStatus !== 'changePassword') return null

  return (
    <Fragment>
      {/* Password change Dialog */}
      <PasswordChange
        open={passwordChangeModal}
        setOpen={setPasswordChangeModal}
        callback={updatePasswordHandler}
        setIsSubmitting={setIsSubmitting}
        isSubmitting={isSubmitting}
      />
    </Fragment>
  )
}
