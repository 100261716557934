import React, { Fragment, useState } from 'react'
import {
  Grid,
  Step,
  Stepper,
  StepLabel,
  Icon,
  StepConnector,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { withStyles } from '@material-ui/core/styles'
import { analytics } from '../../../../utils/firebase'
import MakeAppointmentMap from '../../GoogleMap/MakeAppointmentMap'
import StepDate from '../StepDate'
import StepDetails from '../StepDetails'
import StepLocation from '../StepLocation'
import StepConfirm from '../StepConfirm'
import BoxSkeleton from '../BoxSkeleton'
import { useTranslation } from '../../../../hooks/use-translation'

const COLOR_RED = '#d42f42'

const stepIcon =
  (name) =>
  ({ active, completed }) => {
    return (
      <div
        style={{
          borderRadius: '100%',
          width: '50px',
          height: '50px',
          backgroundColor: active || completed ? COLOR_RED : '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: `1px solid ${active || completed ? COLOR_RED : '#000'}`,
        }}
      >
        <Icon
          style={{
            color: active || completed ? '#fff' : '#000',
            fontSize: '27px',
          }}
        >
          {name}
        </Icon>
      </div>
    )
  }

const CustomConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 23,
    left: 'calc(-50% + 25px)',
    right: 'calc(50% + 25px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector)

function Request({
  values,
  cities,
  facilities,
  specialties,
  hospitalsOnMap,
  setHospitalsOnMap,
  setUpToDateAppointments,
  setTab,
}) {
  const [step, setStep] = useState(0)
  const { translate } = useTranslation()

  const nextStep = () => {
    if (step === 0) {
      analytics.logEvent('ZG_pregledi_zaiavka_location')
    } else if (step === 1) {
      analytics.logEvent('ZG_pregledi_zaiavka_details')
    } else if (step === 2) {
      analytics.logEvent('ZG_pregledi_zaiavka_time')
    }

    setStep(step + 1)
  }

  const prevStep = () => setStep(step - 1)

  return (
    <Fragment>
      <Grid item lg={6} xs={12}>
        {cities.length === 0 || facilities.length === 0 ? (
          <Skeleton
            style={{ minHeight: '250px' }}
            width="100%"
            height="100%"
            variant="rect"
          />
        ) : (
          <MakeAppointmentMap markers={hospitalsOnMap} />
        )}
      </Grid>

      <Grid item lg={6} xs={12} style={{ padding: '30px' }}>
        {cities.length === 0 || facilities.length === 0 ? (
          <BoxSkeleton />
        ) : (
          <Fragment>
            {step < 3 && (
              <Stepper
                alternativeLabel
                activeStep={step}
                connector={<CustomConnector />}
                style={{ padding: '24px 0' }}
              >
                <Step key={1}>
                  <StepLabel StepIconComponent={stepIcon('location_city')}>
                    {translate(
                      'c-client-dashboard.make-appointment.request.label-location'
                    )}
                  </StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel StepIconComponent={stepIcon('add_box')}>
                    {translate(
                      'c-client-dashboard.make-appointment.request.label-details'
                    )}
                  </StepLabel>
                </Step>
                <Step key={3}>
                  <StepLabel StepIconComponent={stepIcon('access_time')}>
                    {translate(
                      'c-client-dashboard.make-appointment.request.label-datetime'
                    )}
                  </StepLabel>
                </Step>
              </Stepper>
            )}

            <div style={{ padding: '30px 0 0 0' }}>
              {step === 0 && (
                <StepLocation
                  cities={cities}
                  facilities={facilities}
                  nextStep={nextStep}
                />
              )}

              {step === 1 && (
                <StepDetails
                  nextStep={nextStep}
                  prevStep={prevStep}
                  specialties={specialties}
                />
              )}

              {step === 2 && (
                <StepDate prevStep={prevStep} nextStep={nextStep} />
              )}

              {step === 3 && (
                <StepConfirm
                  prevStep={prevStep}
                  formValue={values}
                  setUpToDateAppointments={setUpToDateAppointments}
                  setTab={setTab}
                  specialties={specialties}
                  setHospitalsOnMap={setHospitalsOnMap}
                  facilites={facilities}
                />
              )}
            </div>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}

export default Request
