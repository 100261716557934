import React, { useState } from 'react'
import { Box, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Room, Close } from '@material-ui/icons'
import { useTranslation } from '../../../hooks/use-translation'
// import { Capacitor } from '@capacitor/core'
// import { usePersistentForm } from '../../../hooks/use-persistent-form'

const useStyles = makeStyles((theme) => ({
  infoWindow: {
    background: '#FFFFFF',
    padding: '0.5em',
    borderRadius: '0.5em',
    width: '20em',
    position: 'absolute',
    zIndex: '7',
  },
  iconContainer: {
    display: 'block',
    marginLeft: 'auto',
  },
  nameAndAddress: {
    width: '100%',
    wordBreak: 'break-all',
    fontSize: '0.875rem',
  },
  name: {
    marginBottom: '0.5em',
  },
}))

function Marker({ text, address, dto }) {
  const classes = useStyles()
  const { translate } = useTranslation()
  const [isOpenInfoWindow, setIsOpenInfoWindow] = useState(false)

  return (
    <Box className={classes.container}>
      <Room
        onClick={() => setIsOpenInfoWindow(!isOpenInfoWindow)}
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -100%)',
        }}
        color="primary"
        fontSize="large"
      />

      {isOpenInfoWindow && (
        <Box className={classes.infoWindow}>
          <IconButton
            className={classes.iconContainer}
            aria-label="close"
            size="small"
            onClick={() => setIsOpenInfoWindow(false)}
          >
            <Close fontSize="inherit" color="primary" />
          </IconButton>

          <Typography className={classes.nameAndAddress}>
            {translate('c-client-dashboard.google-map.label-hospital')}
          </Typography>
          <Typography className={`${classes.nameAndAddress} ${classes.name}`}>
            {text}
          </Typography>
          <Typography className={classes.nameAndAddress}>
            {translate('c-client-dashboard.google-map.label-address')}
          </Typography>
          <Typography
            className={`${classes.nameAndAddress} ${classes.address}`}
          >
            {address}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default Marker
