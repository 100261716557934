import moment from 'moment'
import { translate } from '../utils/lang'

export const validateEgn = (personalNumber) => {
  let data = {
    error: '',
    sex: '',
    birthDate: '',
  }

  const egnRegex = /^([0-9]{6})([0-9]{3})([0-9]{1})$/

  if (!personalNumber || !egnRegex.test(personalNumber)) {
    data.error = translate('validate-egn-and-identification-number.egn')

    return data
  }

  let test = personalNumber.match(egnRegex)
  let birthDateFromEgn = test[1]

  let year = +birthDateFromEgn.substring(0, 2)
  let month = +birthDateFromEgn.substring(2, 4)
  let day = +birthDateFromEgn.substring(4, 6)

  if (month <= 12) {
    year = 1900 + year
  } else if (month > 12 && month <= 32) {
    year = 1800 + year
    month = month - 20
  } else if (month > 32) {
    year = 2000 + year
    month = month - 40
  }

  if (month < 10) {
    month = `0${month}`
  }

  if (day < 10) {
    day = `0${day}`
  }

  let egnSymbols = personalNumber.split('').map((symbol) => (symbol = +symbol))

  let birthDate = moment(`${year}-${month}-${day}`)
  let sex = egnSymbols[8] % 2 === 0 ? 'm' : 'f'

  let isValidBirthDate =
    birthDate.isValid() &&
    moment(birthDate)
      .startOf('day')
      .isBefore(moment().add(1, 'day').startOf('day'))

  if (!isValidBirthDate) {
    data.error = translate('validate-egn-and-identification-number.egn')

    return data
  }

  let sum =
    egnSymbols[0] * 2 +
    egnSymbols[1] * 4 +
    egnSymbols[2] * 8 +
    egnSymbols[3] * 5 +
    egnSymbols[4] * 10 +
    egnSymbols[5] * 9 +
    egnSymbols[6] * 7 +
    egnSymbols[7] * 3 +
    egnSymbols[8] * 6

  let controlNumber = sum % 11 === 10 ? 0 : sum % 11

  if (controlNumber !== egnSymbols[9]) {
    data.error = translate('validate-egn-and-identification-number.egn')

    return data
  }

  data.birthDate = birthDate.format('YYYY-MM-DD')
  data.sex = sex

  return data
}

export const validateIdentificationNumber = (identificationNumber) => {
  let error = ''

  if (!identificationNumber) {
    return null
  }

  if (identificationNumber.length >= 0 && identificationNumber.length < 10) {
    error = translate('validate-egn-and-identification-number.personal-number')

    return error
  }

  if (identificationNumber.length === 10) {
    let identificationNumberSymbols = identificationNumber
      .split('')
      .map((symbol) => (symbol = +symbol))

    let sum =
      identificationNumberSymbols[0] * 21 +
      identificationNumberSymbols[1] * 19 +
      identificationNumberSymbols[2] * 17 +
      identificationNumberSymbols[3] * 13 +
      identificationNumberSymbols[4] * 11 +
      identificationNumberSymbols[5] * 9 +
      identificationNumberSymbols[6] * 7 +
      identificationNumberSymbols[7] * 3 +
      identificationNumberSymbols[8] * 1

    let controlNumber = sum % 10

    if (controlNumber !== identificationNumberSymbols[9]) {
      error = translate(
        'validate-egn-and-identification-number.personal-number'
      )

      return error
    }
  }

  return error
}
