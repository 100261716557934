import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const ArchivedReimbursementsSkeleton = () => {
  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    padding: '1.7em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',
  }

  return (
    <Box style={{ width: '100%' }}>
      <Box style={styles}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '6em', marginRight: '1em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={styles}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '6em', marginRight: '1em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={styles}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '5em', marginRight: '1em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={styles}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '5em', marginRight: '1em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={{ ...styles, borderBottom: '2px solid rgba(0, 0, 0, 0.12)' }}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '5em', marginRight: '1em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>

          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '1.7em 0',
        }}
      >
        <Skeleton variant="text" width="7%" style={{ marginRight: '1em' }} />
        <Skeleton
          variant="circle"
          width="2em"
          height="2em"
          style={{ marginRight: '1em' }}
        />
        <Skeleton variant="circle" width="2em" height="2em" />
      </Box>
    </Box>
  )
}

export default ArchivedReimbursementsSkeleton
