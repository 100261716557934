// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Hooks
import { useTranslation } from '../../hooks/use-translation'
import { useMobileVersion } from '../../hooks/use-mobile-version'

// Classes
const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  mobileCloseButton: {
    marginLeft: '0 !important',
  },
  checkboxContainer: {
    '& .MuiFormControlLabel-label': {
      lineHeight: 1,
    },
  },
  content: {
    fontSize: '1.1em',
  },
  mobileCheckboxContainer: {
    marginBottom: theme.spacing(3.25),
  },
}))

const Modal = ({ isOpen, closeModalHandler, message }) => {
  const { translate } = useTranslation()
  const isMobile = useMobileVersion()
  const classes = useStyles()

  // Close Button
  const closeButtonLabel = translate('c-form.connect-with-health-care.close')

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={isOpen}
      onClose={closeModalHandler}
    >
      {/* Close modal icon */}
      <DialogTitle>
        <IconButton
          aria-label="close"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{
            position: 'absolute',
            top: 5,
            right: 10,
          }}
          onClick={closeModalHandler}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Message */}
      <DialogContent>
        <Typography className={classes.content}>{message}</Typography>
      </DialogContent>

      {/* Close modal button */}
      <DialogActions
        className={`${classes.dialogActions} ${
          isMobile ? classes.mobileDialogActions : ''
        }`}
      >
        <Button
          className={isMobile ? classes.mobileCloseButton : ''}
          fullWidth={isMobile ? true : false}
          onClick={closeModalHandler}
          color="primary"
          type="button"
          variant="contained"
        >
          {closeButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}

export default Modal
