// Libraries
import React, { Fragment } from 'react'
import has from 'lodash/has'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { Check } from '@material-ui/icons'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'
// Config
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'

const useStyles = makeStyles({
  header: {
    marginBottom: '1em',
    fontWeight: 'normal',
    textAlign: 'left',
    width: '100%',
  },
  root: {
    width: '100%',
    border: '2px solid rgba(0, 0, 0, 0.12)',
    padding: '2em',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  value: {
    marginLeft: '2em',
  },
})

function CoverageItem({ name, riskList }) {
  const classes = useStyles()
  const { activeLanguage } = useTranslation()

  const allItems = [...riskList]

  const firstHalf = allItems.splice(0, Math.ceil(allItems.length / 2))
  const secondHalf = [...allItems]

  const renderItems = (items) => {
    return items.map((riskItem) => {
      let value = ''

      if (has(riskItem, 'riskLimitCheck')) {
        value = riskItem.riskLimitCheck.split('|')[1]
      } else if (has(riskItem, 'riskLimitNum')) {
        value = riskItem.riskLimitNum.split('|')[1]
      } else if (has(riskItem, 'riskLimitCurr')) {
        value = riskItem.riskLimitCurr.split('|')[1]
      }

      return (
        <Fragment key={riskItem.riskCode}>
          <ListItem className={classes.listItem}>
            {activeLanguage === LANG_BG && (
              <ListItemText primary={riskItem.name} />
            )}
            {activeLanguage === LANG_EN && (
              <ListItemText primary={riskItem.name_latin} />
            )}

            <div className={classes.value}>
              {value === 'check' ? <Check /> : value}
            </div>
          </ListItem>
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      <h3 className={classes.header}>{name}</h3>
      <Grid
        container
        justifyContent="space-between"
        className="grid-m-c-1"
        spacing={6}
      >
        <Grid item xs={12} md={6}>
          <List className={classes.root}>
            {firstHalf.length > 0 && renderItems(firstHalf)}
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <List className={classes.root}>
            {secondHalf.length > 0 && renderItems(secondHalf)}
          </List>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default CoverageItem
