// Libraries
import React from 'react'
import has from 'lodash/has'
// Components
import BtnLoginSocial from '../index'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useRouter } from '../../../hooks/use-router'
import { useTranslation } from '../../../hooks/use-translation'
// Utils
import { analytics } from '../../../utils/firebase'

const BtnLoginGoogle = () => {
  const auth = useAuth()
  const router = useRouter()
  const { translate } = useTranslation()

  let channelType, channelId

  if (has(router.query, 'channelType') && has(router.query, 'channelId')) {
    channelType = router.query.channelType
    channelId = router.query.channelId
  }

  return (
    <BtnLoginSocial
      text={translate('c-btn-login-social.title-google')}
      backgroundColor="#fff"
      textColor="#767676"
      border="1px solid rgba(0, 0, 0, 0.1)"
      iconSrc="/images/google-icon.svg"
      onClick={async () => {
        await auth.signinWithGoogle(channelType, channelId)
        router.push('/')
        analytics.logEvent('vhod_google')
      }}
    />
  )
}

export default BtnLoginGoogle
