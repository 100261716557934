import React from 'react'
import { Grid } from '@material-ui/core'
import styles from './user-grid.module.css'

const UserGrid = ({ children, isMobile }) => {
  return (
    <Grid container spacing={isMobile ? 2 : 7} className={styles.container}>
      {children}
    </Grid>
  )
}

export default UserGrid
