import { translate } from '../utils/lang'

export const resetPasswordValidate = ({ email }) => {
  const errors = {}

  // eslint-disable-next-line
  let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  if (!email || !emailRegex.test(email)) {
    errors.email = translate('validate-reset-password.email')
  }

  return errors
}

export const changePasswordValidate = ({ password, rePassword }) => {
  const errors = {}

  if (!password || password.length < 8) {
    errors.password = translate('validate-reset-password.pass')
  }

  if (!rePassword) {
    errors.rePassword = translate('validate-reset-password.pass-confirm')
  }

  if (!errors.password && !errors.rePassword && rePassword !== password) {
    errors.rePassword = translate('validate-reset-password.pass-no-match')
  }

  return errors
}
