import React from 'react'
import { useSnackbar } from 'notistack'
import { Box, IconButton } from '@material-ui/core'
import { Publish, Close } from '@material-ui/icons'
import { useTranslation } from '../../../hooks/use-translation'
import styles from './file-upload.module.css'
import { LANG_BG, LANG_EN } from '../../../config/languageTypes'
import { MAX_UPLOAD_FILE_SIZE } from '../../../utils/config'

function renderFiles(files, removeFile) {
  const getReadableFileSizeInMB = (size) => {
    return (size / (1024 * 1024)).toFixed(2)
  }

  const getLabelColor = (size) => {
    return size > MAX_UPLOAD_FILE_SIZE ? '#d42f42' : undefined
  }

  return files.map((file, index) => {
    return (
      <li className={styles.file} key={index}>
        <span style={{ color: getLabelColor(file.size) }}>{file.name}</span>
        <span style={{ marginLeft: 'auto', color: getLabelColor(file.size) }}>
          {getReadableFileSizeInMB(file.size)} MB
        </span>
        <Close className={styles.close} onClick={() => removeFile(file)} />
      </li>
    )
  })
}

function ImageUploaderCard({
  doc_info,
  doc_info_en,
  doc_name,
  doc_name_en,
  id,
  maximumAllowedFiles,
  uploadedFiles,
  filesSetter,
}) {
  const snackbar = useSnackbar()
  const { translate, activeLanguage } = useTranslation()

  let currentFiles = []

  if (activeLanguage === LANG_BG) {
    currentFiles = uploadedFiles[doc_name] || []
  } else if (activeLanguage === LANG_EN) {
    currentFiles = uploadedFiles[doc_name_en] || []
  }

  const handleChangeFiles = (changedFiles) => {
    ;(async () => {
      try {
        let filesToUpload = []

        for (let file of changedFiles) {
          if (file.body) {
            filesToUpload.push(file)
          } else {
            let base64Image = await getBase64(file)
            let body = base64Image.replace(/^data:image\/[a-z]+;base64,/, '')

            let type = file.type.split('/')[1]

            let fileToUpload = {
              name: file.name,
              file_type: type,
              type_img: '',
              body,
              size: file.size,
            }

            if (activeLanguage === LANG_BG) {
              fileToUpload.type_img = doc_name
            } else if (activeLanguage === LANG_EN) {
              fileToUpload.type_img = doc_name_en
            }

            filesToUpload.push(fileToUpload)
          }
        }

        filesSetter((prevState) => {
          let newState = {
            ...prevState,
          }

          if (activeLanguage === LANG_BG) {
            newState[doc_name] = filesToUpload
          } else if (activeLanguage === LANG_EN) {
            newState[doc_name_en] = filesToUpload
          }

          return newState
        })
      } catch (e) {
        console.error(e)
      }
    })()
    // eslint-disable-next-line
  }

  const removeFile = (fileForRemove) => {
    let newFiles = currentFiles.filter(
      (file) => file.name !== fileForRemove.name
    )
    handleChangeFiles(newFiles)
  }

  const addFile = (addedFiles) => {
    if (currentFiles.length + addedFiles.length > maximumAllowedFiles) {
      const msg = translate('c-client-dashboard.file-upload.max-files-allowed')
      snackbar.enqueueSnackbar(`${msg} ${maximumAllowedFiles}`, {
        variant: 'error',
      })
    } else {
      handleChangeFiles([...currentFiles, ...addedFiles])
    }
  }

  const isDisabled = currentFiles && currentFiles.length === maximumAllowedFiles

  return (
    <Box className={styles.container}>
      <div className={`${styles['title-icons-container']} grid-m-c-1`}>
        {activeLanguage === LANG_BG && (
          <div className={styles.title}>{doc_info}</div>
        )}
        {activeLanguage === LANG_EN && (
          <div className={styles.title}>{doc_info_en}</div>
        )}

        <div className={styles.button}>
          <input
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            id={`${doc_name_en}-icon-button-file`}
            type="file"
            multiple="multiple"
            disabled={isDisabled}
            value=""
            onChange={(e) => addFile(e.target.files)}
          />
          <label htmlFor={`${doc_name_en}-icon-button-file`}>
            <IconButton aria-label="upload file" component="span">
              <Publish
                className={isDisabled ? styles.disabled : ''}
                fontSize="large"
                size="large"
              />
            </IconButton>
            {translate('c-client-dashboard.file-upload.label-attach')}
          </label>
        </div>
      </div>

      {currentFiles && currentFiles.length > 0 && (
        <ul className={styles.files}>
          {renderFiles(currentFiles, removeFile)}
        </ul>
      )}
    </Box>
  )
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
}

export default ImageUploaderCard
