// Libraries
import omit from 'lodash/omit'
import isPlainObject from 'lodash/isPlainObject'
// Actions
import { FORM_CLEAR, FORM_CLEAR_ERROR, FORM_CLEAR_ERRORS, FORM_CLEAR_FIELD, FORM_CLEAR_FIELDS, FORM_RESET, FORM_RESET_FIELD, FORM_SET_ERROR, FORM_SET_VALUE } from '../actions/form/actionTypes'

/**
 * Holds state for forms
 */
export const form = (state = {}, action) => {
  switch (action.type) {
    case FORM_SET_VALUE:
      return {
        ...state,
        [action.formName]: {
          ...(state[action.formName] || {}),
          values: {
            ...((state[action.formName] || {}).values || {}),
            [action.fieldName]: action.fieldValue
          }
        }
      }
    case FORM_RESET_FIELD:
      // field will be reset only if the form exists
      if (!state[action.formName]) return state

      return {
        ...state,
        [action.formName]: {
          ...(state[action.formName] || {}),
          values: {
            ...((state[action.formName] || {}).values || {}),
            [action.fieldName]: action.fieldValue
          }
        }
      }
    case FORM_CLEAR_FIELD:
      // field will be cleared only if the form exists
      if (!state[action.formName]) return state

      return {
        ...state,
        [action.formName]: {
          ...(state[action.formName] || {}),
          values: omit((state[action.formName] || {}).values || {}, [
            action.fieldName
          ])
        }
      }
    case FORM_CLEAR_ERROR:
      // field will be cleared only if the form exists
      if (!state[action.formName]) return state

      return {
        ...state,
        [action.formName]: {
          ...(state[action.formName] || {}),
          errors: omit((state[action.formName] || {}).errors || {}, [
            action.fieldName
          ])
        }
      }
    case FORM_CLEAR_FIELDS:
      // fields will be cleared only if the form exists
      if (!state[action.formName]) return state

      return {
        ...state,
        [action.formName]: {
          ...(state[action.formName] || {}),
          values: omit(
            (state[action.formName] || {}).values || {},
            action.fieldNames
          )
        }
      }
    case FORM_SET_ERROR:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          errors: {
            ...((state[action.formName] || {}).errors || {}),
            [action.fieldName]: action.fieldError
          }
        }
      }
    case FORM_CLEAR_ERRORS:
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          errors: {}
        }
      }
    case FORM_CLEAR:
      return omit(state, [action.formName])
    case FORM_RESET:
      const targetForm = state[action.formName]
      // can only reset a form that exists
      if (!targetForm) return state

      const { values } = targetForm
      const cleanFields = {}

      for (const fieldName of Object.keys(values)) {
        const fieldValue = values[fieldName]
        let r = null
        switch (typeof fieldValue) {
          case 'string':
            r = ''
            break
          case 'number':
            r = 0
            break
          default:
            if (Array.isArray(fieldValue)) r = []
            else if (isPlainObject(fieldValue)) r = {}
        }
        cleanFields[fieldName] = r
      }

      return {
        ...state,
        [action.formName]: {
          ...(state[action.formName] || {}),
          values: cleanFields
        }
      }
    default:
      return state
  }
}
