import React from 'react'
import { Radio, Box, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import PropTypes from 'prop-types';
import InfoCard from '../../../../InfoCard'
import { useAuth } from '../../../../../hooks/use-auth'
import { useTranslation } from '../../../../../hooks/use-translation'
import { getLatestInactiveCard } from '../../../../../utils/user'

const useStyles = makeStyles((theme) => ({
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '&:not(:last-child)': {
      marginBottom: '0',
    },
  },

  divider: {
    width: '100%',
    height: '2px',
    margin: '2em 0',
  },
  subCardsContainer: {
    width: '100%',
  },
  cardImage: {
    marginRight: '0.5em',
  },
}))

const renderSubCard = (
  cards,
  activeUserCard,
  changeActiveUserCardHandler,
  classes,
  titleText
) => {
  const activeCards = cards?.filter((card) => card?.shouldBeActive === 'yes')

  const hasActiveCards = activeCards?.length > 0

  let cardsToRender = []

  if (!hasActiveCards) {
    const latestInactiveCard = getLatestInactiveCard(
      cards?.filter((card) => card?.shouldBeActive === 'no')
    )

    cardsToRender = latestInactiveCard ? [latestInactiveCard] : []
  } else {
    cardsToRender = activeCards
  }

  if (!cardsToRender?.length) return 'Няма карти'

  return cardsToRender.map((card, index) => (
    <Box key={card.cardId} className={classes.cardContainer}>
      <div>
        <p>
          <span className={classes.cardImage}>
            <img src="images/karta.svg" alt="My card" />
          </span>
          {titleText} {index + 1}: {card.firm}
        </p>
      </div>

      <Radio
        checked={activeUserCard.cardNumber === card.cardNumber}
        onChange={changeActiveUserCardHandler}
        value={card.cardNumber}
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'A' }}
      />
    </Box>
  ))
}

function CoInsuredCard({
  firstName,
  lastName,
  role,
  changePersonHandler,
  changeActiveUserCardHandler,
  clientId,
  validCards,
}) {
  const classes = useStyles()
  const { activeUser, activeUserCard } = useAuth()
  const { translate } = useTranslation()

  const checkRole = (role) => {
    return role === 'parent'
      ? translate(
          'c-client-dashboard.user.co-insured.co-insured-card.role-insured'
        )
      : translate(
          'c-client-dashboard.user.co-insured.co-insured-card.role-child'
        )
  }

  return (
    <InfoCard directionColumn={true}>
      <Box className={classes.userContainer}>
        <div>
          <p>
            {firstName} {lastName}
          </p>
          <p className="subtitle">{checkRole(role)}</p>
        </div>
        <Radio
          checked={activeUser.clientId === clientId}
          onChange={changePersonHandler}
          value={clientId}
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'A' }}
        />
      </Box>

      {activeUser.clientId === clientId && (
        <Divider className={classes.divider} />
      )}

      <Box className={classes.subCardsContainer}>
        {validCards?.length > 0 &&
          activeUser.clientId === clientId &&
          renderSubCard(
            validCards,
            activeUserCard,
            changeActiveUserCardHandler,
            classes,
            translate('c-client-dashboard.user.co-insured.co-insured-card.card')
          )}
      </Box>
    </InfoCard>
  )
}

// CoInsuredCard.propTypes = {
//     name: PropTypes.string.isRequired,
//     subtitle: PropTypes.string.isRequired,
// }

export default CoInsuredCard
