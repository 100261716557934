import React, { useMemo } from 'react'
import { useTranslation } from 'hooks/use-translation'
import { useLocation } from 'react-router-dom-v5-compat'

const ReimbursementWrapperTitle = () => {
  const { translate } = useTranslation()
  const { pathname } = useLocation()

  const [primary, secondary] = useMemo(() => {
    if (pathname.includes('/reimbursements/active')) {
      return [
        translate('reimbursement.header.up-to-date.primary')?.toUpperCase(),
        translate('reimbursement.header.up-to-date.secondary')?.toUpperCase(),
      ]
    }

    if (pathname.includes('/reimbursements/archive')) {
      return [
        translate('reimbursement.header.archive.primary')?.toUpperCase(),
        translate('reimbursement.header.archive.secondary')?.toUpperCase(),
      ]
    }

    if (pathname.includes('/reimbursements/request')) {
      return [
        translate('reimbursement.header.request.primary')?.toUpperCase(),
        translate('reimbursement.header.request.secondary')?.toUpperCase(),
      ]
    }
  }, [pathname, translate])

  return (
    <h1 style={{ margin: '1em 0' }} className="cm-heading-onboarding">
      <span className="text-span">{primary}</span>
      <span className="text-span-2"> {secondary}</span>
    </h1>
  )
}

export default ReimbursementWrapperTitle
