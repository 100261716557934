import { translate } from '../utils/lang'

export default function validate({ email, password }) {
  const errors = {}

  if (!email) errors.email = translate('validate-login.login')

  if (!password) errors.password = translate('validate-login.pass')

  return errors
}
