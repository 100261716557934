import React, { useState, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  Grid,
  Button,
  OutlinedInput,
  InputAdornment,
  Box,
  CircularProgress,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core'
import has from 'lodash/has'
import { makeStyles } from '@material-ui/core/styles'
import { Check } from '@material-ui/icons'
import { validateStepConfirm } from '../../../../formValidators/other'
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { useTranslation } from '../../../../hooks/use-translation'
// import { useAuth } from '../../../../hooks/use-auth'
import { useSnackbar } from 'notistack'

const CheckboxLabel = ({ classes }) => {
  const { translate } = useTranslation()

  return (
    <span>
      {translate('c-client-dashboard.other.step-confirm.checkbox-label-info')}
      <a
        className={classes.red}
        href="https://www.bulstradlife.bg/customer-service/privacy-policy-documents/1/download"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        {translate(
          'c-client-dashboard.other.step-confirm.checkbox-label-link-text'
        )}
        .
      </a>
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    marginBottom: '4em',
  },
  checkbox: {
    display: 'block',
    margin: '0',

    '& .MuiButtonBase-root': {
      padding: '0 0.5em 0 0',
    },
  },
  spacingBottom: {
    marginBottom: '0.5em',
  },
  red: {
    color: '#D42F42',
  },
  description: {
    fontSize: '0.875rem',
    fontStyle: 'italic',
  },
  emailContainer: {
    marginBottom: '4em',
  },
}))

const StepConfirm = ({
  prevStep,
  files,
  emailInfo,
  emailCodeInfo,
  sendEmailHandler,
  resendEmailHandler,
  verifyEmailCodeHandler,
  confirmClaimHandler,
  setEmailInfo,
  setEmailCodeInfo,
  isSubmittingClaim,
  setSubmittingClaim,
}) => {
  const { translate } = useTranslation()
  const classes = useStyles()
  const [timeLimit, setTimeLimit] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const snackbar = useSnackbar()

  const { values, errors, validate, handleChange } = usePersistentForm('other')

  const emailCodeLabel = translate(
    'c-client-dashboard.other.step-confirm.email-code-label'
  )

  const sendAgainEmailCodeHandler = async () => {
    try {
      setSeconds(30)
      setTimeLimit(true)
      setEmailInfo({ ...emailInfo, isSubmitting: true })

      await resendEmailHandler(values)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (timeLimit === true && seconds > 0) {
      let timer = setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    } else {
      setTimeLimit(false)
    }
  }, [timeLimit, seconds])

  const handleChangeCode = async (newCode) => {
    if (newCode.length < 8) {
      setEmailCodeInfo({ ...emailCodeInfo, value: newCode })
    }

    try {
      if (newCode.length === 8) {
        setEmailCodeInfo({
          ...emailCodeInfo,
          isSubmitting: true,
        })

        await verifyEmailCodeHandler(newCode)

        setTimeLimit(false)
        setSeconds(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // validate form step
    const hasErrors = validate(validateStepConfirm)

    // check for errors
    if (hasErrors) {
      return
    }

    try {
      if (emailCodeInfo.isChecked) {
        setSubmittingClaim(true)
        await confirmClaimHandler()
      } else {
        setEmailInfo({ ...emailInfo, isSubmitting: true })
        await sendEmailHandler(values)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (has(errors, 'files')) {
      snackbar.enqueueSnackbar(errors.files, {
        variant: 'error',
        preventDuplicate: true,
      })
    }

    // eslint-disable-next-line
  }, [errors])

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        className={classes.checkboxContainer}
        error={errors && errors.privacy ? true : false}
      >
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              color="primary"
              checked={values.privacy}
              onChange={(e) =>
                handleChange({
                  target: { name: 'privacy', value: e.target.checked },
                })
              }
            />
          }
          label={<CheckboxLabel classes={classes} />}
        />
        <FormHelperText>{errors && errors.privacy}</FormHelperText>
      </FormControl>

      {emailInfo.isSent && (
        <Box className={classes.emailContainer}>
          <FormControl variant="outlined" className={classes.spacingBottom}>
            <InputLabel htmlFor="outlined-adornment-password">
              {emailCodeLabel}
            </InputLabel>
            <OutlinedInput
              disabled={emailCodeInfo.isChecked}
              id="outlined-adornment-password"
              value={emailCodeInfo.value}
              onChange={(e) => handleChangeCode(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {emailCodeInfo.isSubmitting && (
                    <CircularProgress
                      size={25}
                      thickness={2.7}
                      color="primary"
                    />
                  )}
                  {emailCodeInfo.isChecked && <Check />}
                  {!emailCodeInfo.isSubmitting && !emailCodeInfo.isChecked && (
                    <Button
                      disabled={timeLimit}
                      type="button"
                      color="primary"
                      onClick={sendAgainEmailCodeHandler}
                    >
                      {translate(
                        'c-client-dashboard.other.step-confirm.label-send-again-button'
                      )}
                    </Button>
                  )}
                </InputAdornment>
              }
              labelWidth={emailCodeLabel.length * 8.6}
            />
          </FormControl>

          {!emailCodeInfo.isChecked && (
            <Box>
              {timeLimit && (
                <p className={classes.red}>
                  {translate(
                    'c-client-dashboard.other.step-confirm.time-limit-message'
                  )}{' '}
                  {seconds}{' '}
                  {translate(
                    'c-client-dashboard.other.step-confirm.time-limit'
                  )}
                </p>
              )}

              <p>
                {translate(
                  'c-client-dashboard.other.step-confirm.email-code-message'
                )}{' '}
                <span className={classes.red}>{values.email}</span>.
              </p>
            </Box>
          )}
        </Box>
      )}

      {!emailInfo.isSent && (
        <Box className={classes.emailContainer}>
          <TextField
            className={classes.spacing}
            variant="outlined"
            name="email"
            label={translate(
              'c-client-dashboard.other.step-confirm.label-email'
            )}
            value={values.email}
            onChange={handleChange}
            error={errors && errors.email ? true : false}
            helperText={errors && errors.email}
          />

          <Typography className={classes.description}>
            {translate(
              'c-client-dashboard.other.step-confirm.email-confirm-message'
            )}
          </Typography>
        </Box>
      )}

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            onClick={prevStep}
          >
            {translate(
              'c-client-dashboard.other.step-confirm.label-back-button'
            )}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          {!emailInfo.isSent && (
            <Button
              disabled={emailInfo.isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {emailInfo.isSubmitting ? (
                <CircularProgress size={25} thickness={2.7} color="primary" />
              ) : (
                `${translate(
                  'c-client-dashboard.other.step-confirm.label-code-button'
                )}`
              )}
            </Button>
          )}

          {emailInfo.isSent && (
            <Button
              disabled={!emailCodeInfo.isChecked || isSubmittingClaim}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {isSubmittingClaim ? (
                <CircularProgress size={25} thickness={2.7} color="primary" />
              ) : (
                `${translate(
                  'c-client-dashboard.other.step-confirm.label-send-button'
                )}`
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default StepConfirm
