// Libraries
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useState } from 'react'
import moment from 'moment'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useForm } from '../../../hooks/use-form'
import { useTranslation } from '../../../hooks/use-translation'
// Form Validators
import { addCoInsuredValidate } from '../../../formValidators/userPage'

const useStyles = makeStyles((theme) => ({
  radio: {
    marginTop: theme.spacing(2),
    marginBottom: -theme.spacing(1),
  },
  datepicker: {
    marginTop: 8,
    paddingTop: 12,
  },
  error: {
    color: theme.palette.primary.main,
  },
}))

function AddCoInsuredPerson({ open, setOpen, callback }) {
  const auth = useAuth()
  const classes = useStyles()
  const [errors, setErrors] = useState({})
  const { translate } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setErrors({})
    setOpen(false)
    handleChange(
      { target: { name: 'cardNumber', value: '' } },
      { personalNumber: '' }
    )
  }

  const INITIAL_FORM_STATE = {
    personalNumber: '',
    cardNumber: '',
    dateOfBirth: new Date(),
    radioChoice: 'personalNumber',
  }

  const { values, handleChange, reset } = useForm(
    INITIAL_FORM_STATE,
    () => {},
    () => {},
    auth,
    null,
    'async'
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validationErrors = addCoInsuredValidate(values)

    setErrors(validationErrors)

    const canSubmit = Object.keys(validationErrors).length === 0

    try {
      setLoading(true)

      if (canSubmit) {
        const payload = {
          cardNumber: values.cardNumber,
          ...(values.radioChoice === 'personalNumber'
            ? { personalNumber: values.personalNumber }
            : {
                dateOfBirth: moment(values.dateOfBirth).format('YYYY-MM-DD'),
              }),
        }

        await callback(payload)

        reset({
          radioChoice: 'personalNumber',
          personalNumber: '',
          cardNumber: '',
          dateOfBirth: new Date(),
        })

        setOpen(false)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {translate('c-form.add-co-insured-person.info')}
          </DialogContentText>
          <FormControl>
            <TextField
              margin="dense"
              name="cardNumber"
              label={translate('c-form.add-co-insured-person.card-number')}
              type="text"
              error={errors && errors.cardNumber ? true : false}
              helperText={errors && errors.cardNumber}
              value={values.cardNumber}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl component="fieldset" className={classes.radio}>
            <RadioGroup
              onChange={handleChange}
              name="radioChoice"
              value={values.radioChoice}
              row
            >
              <FormControlLabel
                value="personalNumber"
                control={<Radio />}
                label={translate('c-form.add-co-insured-person.egn')}
              />
              <FormControlLabel
                value="dateOfBirth"
                control={<Radio />}
                label={translate(
                  'c-form.add-co-insured-person.dateOfBirthLabel'
                )}
              />
            </RadioGroup>
          </FormControl>

          {values.radioChoice === 'personalNumber' && (
            <TextField
              margin="dense"
              name="personalNumber"
              label={translate('c-form.add-co-insured-person.egn')}
              type="text"
              error={errors && errors.personalNumber ? true : false}
              helperText={errors && errors.personalNumber}
              value={values.personalNumber}
              onChange={handleChange}
              fullWidth
            />
          )}

          {values.radioChoice === 'dateOfBirth' && (
            <FormControl error={errors && errors.dateOfBirth ? true : false}>
              <KeyboardDatePicker
                className={classes.datepicker}
                error={errors && errors.dateOfBirth ? true : false}
                name="dateOfBirth"
                format="YYYY-MM-DD"
                invalidDateMessage={null}
                value={values.dateOfBirth}
                onChange={(date) =>
                  handleChange({ target: { name: 'dateOfBirth', value: date } })
                }
                autoComplete="off"
                InputAdornmentProps={{ position: 'end' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors && errors.dateOfBirth && (
                <FormHelperText>{errors.dateOfBirth}</FormHelperText>
              )}
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('c-form.add-co-insured-person.close')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            onClick={handleSubmit}
          >
            {translate('c-form.add-co-insured-person.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default AddCoInsuredPerson
