import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Container } from '@material-ui/core'
import CoveragesList from './CoveragesList'
import { Grid } from '@material-ui/core'
import { useTranslation } from '../../hooks/use-translation'
import Information from './Information'
import { useLocation } from 'react-router-dom-v5-compat'
import GoBack from 'components/GoBack'

const PageHeading = ({ heading }) => {
  const [boldUnderlinedText, regularText] = heading
  return (
    <h1
      style={{ margin: '1em 0', textTransform: 'uppercase' }}
      className="cm-heading-onboarding"
    >
      <span className="text-span">{boldUnderlinedText} </span>
      {regularText && <span className="text-span-2"> {regularText}</span>}
    </h1>
  )
}

function CoveragePage() {
  const [activeTab, setActiveTab] = React.useState(0)
  const { translate } = useTranslation()
  const location = useLocation()

  const onTabChange = (selectedTab) => {
    setActiveTab(selectedTab)
  }

  const generateHeaderHeading = () => {
    let heading

    switch (activeTab) {
      case 0:
        heading = [
          translate('c-client-dashboard.coverage.header.primary'),
          translate('c-client-dashboard.coverage.header.secondary'),
        ]
        break
      default:
        heading = [
          translate('coverage-info.heading-primary'),
          translate('coverage-info.heading-secondary'),
        ]
        break
    }

    return heading
  }

  return (
    <>
      <GoBack />
      <Container className="custom-spacing" style={{ marginTop: 20 }}>
        <Grid
          justifyContent="space-between"
          container
          className="grid-m-c-1"
          alignItems="center"
        >
          <PageHeading heading={generateHeaderHeading()} />
          <CoverageTabs value={activeTab} onTabChange={onTabChange} />
        </Grid>

        {activeTab === 0 && <CoveragesList pathname={location.pathname} />}
        {activeTab === 1 && <Information />}
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  tab: {
    opacity: 1,

    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },

    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },

    '&$selected': {
      color: theme.palette.primary.main,
    },
  },
}))

function CoverageTabs({ value, onTabChange }) {
  const classes = useStyles()
  const { translate } = useTranslation()

  // Used to set the underline indicator on page refresh
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  }, [])

  return typeof value !== 'undefined' ? (
    <Tabs
      value={value}
      onChange={(_, newTab) => onTabChange(newTab)}
      indicatorColor="primary"
    >
      <Tab
        className={classes.tab}
        disableRipple
        label={translate('coverage-info.tabs.coverage')}
      />
      <Tab
        className={classes.tab}
        disableRipple
        label={translate('coverage-info.tabs.info')}
      />
    </Tabs>
  ) : null
}

export default CoveragePage
