import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

function BoxSkeleton() {
  return (
    <Box>
      {/* Select lists */}
      <Skeleton
        style={{ marginBottom: '2em' }}
        variant="rect"
        width="100%"
        height="3em"
      />
      <Skeleton
        style={{ marginBottom: '2em' }}
        variant="rect"
        width="100%"
        height="3em"
      />

      {/* Buttons */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2em'
        }}
      >
        <Skeleton variant="rect" width="10em" height="3em" />
        <Skeleton variant="rect" width="10em" height="3em" />
      </Box>
    </Box>
  )
}

export default BoxSkeleton
