import React from 'react'
import moment from 'moment'
import { List, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AppointmentCard from '../AppointmentCard'
import { useTranslation } from '../../../../../hooks/use-translation'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  message: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
}))

const renderAppointments = (
  dataForRender,
  page,
  rowsPerPage,
  isSubmitting,
  setIsSubmitting,
  ticketStatusData,
  setTicketStatusData
) => {
  let startIndex = page === 0 ? 0 : page * rowsPerPage
  let endIndex = page === 0 ? rowsPerPage : startIndex + rowsPerPage

  let filtered = dataForRender.filter(
    (appointment, idx) => idx >= startIndex && idx < endIndex
  )

  return filtered.map((appointment) => {
    return (
      <AppointmentCard
        key={appointment.caseId}
        appointment={appointment}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        ticketStatusData={ticketStatusData}
        setTicketStatusData={setTicketStatusData}
      />
    )
  })
}

function AppointmentList({
  dataForRender,
  page,
  rowsPerPage,
  isSubmitting,
  setIsSubmitting,
  ticketStatusData,
  setTicketStatusData,
  upToDateAppointments,
  date,
  setDate,
}) {
  const classes = useStyles()
  const { translate } = useTranslation()

  return (
    <List className={classes.root}>
      {dataForRender.length === 0 ? (
        <Box className={classes.message}>
          <p>
            {date
              ? `${translate(
                  [
                    'c-client-dashboard',
                    'make-appointment',
                    'up-to-date',
                    'appointment-list',
                    'no-records-for',
                  ].join('.')
                )} ${moment(date).format('DD MMMM YYYY')}`
              : translate(
                  [
                    'c-client-dashboard',
                    'make-appointment',
                    'up-to-date',
                    'appointment-list',
                    'no-latest-examinations',
                  ].join('.')
                )}
          </p>
          {upToDateAppointments.length > 0 && (
            <Button color="primary" onClick={() => setDate(null)}>
              {translate(
                [
                  'c-client-dashboard',
                  'make-appointment',
                  'up-to-date',
                  'appointment-list',
                  'show-all',
                ].join('.')
              )}
            </Button>
          )}
        </Box>
      ) : (
        renderAppointments(
          dataForRender,
          page,
          rowsPerPage,
          isSubmitting,
          setIsSubmitting,
          ticketStatusData,
          setTicketStatusData
        )
      )}
    </List>
  )
}

export default AppointmentList
