import { fetch } from '../hooks/use-fetch';


export const uploadDocument = async (data, auth) => {
  return await fetch.post('/uploadDocument', {
    body: JSON.stringify(data)
  }, auth);
}

export const addDocuments = (files, sequence, auth, isFromReimbursement) => {
  let allDocuments = [];

  for (let [categoryName, categoryFiles] of Object.entries(files)) {
    let categoryIndex = Object.keys(files).indexOf(categoryName)
    let isLastCategory = Object.keys(files).length - 1 === categoryIndex

    for (let file of categoryFiles) {
      let isLast = false

      if (
        isLastCategory &&
        categoryFiles.indexOf(file) === categoryFiles.length - 1
      ) {
        isLast = true
      }

      let data = {
        clientId: auth.activeUser ? auth.activeUser.clientId : '',
        fileName: file.name,
        file_type: file.file_type,
        body: file.body,
        type_img: isFromReimbursement ? file.type_img : '',
        isLast,
        sequence
      }

      allDocuments.push(data);
    }
  }

  return allDocuments;
}