import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import setAuthDialog from '../../actions/snackbar/setAuthDialog'
import AuthTabs from './AuthTabs'

const AuthDialog = ({ visible, onClose }) => {
  const dispatch = useDispatch()

  if (!visible) return null

  const rootRef = React.createRef()

  const handleClose = e => {
    if (e.target === rootRef.current) dispatch(setAuthDialog(false))
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        backgroundColor: 'rgba(0,0,0,.3)',
        zIndex: '1000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onMouseDown={handleClose}
      ref={rootRef}
    >
      <AuthTabs />
    </div>
  )
}

AuthDialog.propTypes = {
  visible: PropTypes.bool.isRequired
}

export default AuthDialog
