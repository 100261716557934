import { translate } from '../utils/lang'

export const transliterate = (letter, inputCharacterType) => {
  const pattern =
    inputCharacterType === 'Cyrillic'
      ? /^[\u0410-\u044f\s-/'/".,\d]*$/
      : /^[a-z-A-Z\s-/'/".,\d]*$/
  return pattern.test(letter)
}

export const getTransliterationError = (inputCharacterType) => {
  const lang =
    inputCharacterType === 'Cyrillic'
      ? translate('transliteration-error.cyrillic-letters')
      : translate('transliteration-error.latin-letters')
  return `${translate(
    'transliteration-error.primary-message'
  )} ${lang} ${translate('transliteration-error.secondary-message')}`
}

export const isOnlyNumbers = (v) => /^[0-9]*$/.test(v)

export const emailRegex =
  // eslint-disable-next-line
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const firstNameCyrillicRegex = /^[\u0410-\u044f-.']+$/
export const lastNameCyrillicRegex = /^[\u0410-\u044f-.']+$/

// validate and allow only numbers, dot and a commas with no more than 2 decimal places
export const sumRegex = /^\d*((\.|,)\d{0,2})?$/
