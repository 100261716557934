import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { useTranslation } from '../../../../hooks/use-translation'
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'
import styles from './filled-data.module.css'

function renderDocuments(files) {
  return Object.entries(files).map(([groupName, value], index) => {
    if (index === 0) {
      return ''
    }

    return (
      <li className={styles['file-group']} key={groupName}>
        {groupName}
        {value && value.length > 0 && <Check />}
      </li>
    )
  })
}

function FilledData({ prevStep }) {
  const { values } = usePersistentForm('reimbursement')
  const { translate, activeLanguage } = useTranslation()

  const { sum, files } = values

  const serviceObject = JSON.parse(values?.service ?? {})
  const serviceBG = serviceObject?.title
  const serviceEN = serviceObject?.title_en

  const bankAccount = values.bankAccount
  let invoiceNumber = values.invoiceNumber

  const dateEvent = values.dateEvent.format('YYYY-MM-DD')
  const dateInvoice = values.dateInvoice.format('YYYY-MM-DD')

  if (invoiceNumber.length < 10) {
    let invoiceNumberLength = invoiceNumber.length

    invoiceNumber = `${'0'.repeat(10 - invoiceNumberLength)}${invoiceNumber}`
  }

  return (
    <div
      className={styles.container}
      style={{ padding: '60px 40px', background: '#F2F5F7' }}
    >
      <div className={styles.services}>
        <ul className={styles['services-list']}>
          <li>
            <span>
              {translate(
                'c-client-dashboard.reimbursement.filled-data.service'
              )}
            </span>
            <span>
              {activeLanguage === LANG_BG && serviceBG}{' '}
              {activeLanguage === LANG_EN && serviceEN}
            </span>
          </li>
          <li>
            <span>
              {translate(
                'c-client-dashboard.reimbursement.filled-data.date-event'
              )}
            </span>
            <span>{dateEvent}</span>
          </li>
          <li>
            <span>
              {translate(
                'c-client-dashboard.reimbursement.filled-data.date-invoice'
              )}
            </span>
            <span>{dateInvoice}</span>
          </li>
          <li>
            <span>
              {translate(
                'c-client-dashboard.reimbursement.filled-data.invoice-number'
              )}
            </span>
            <span>{invoiceNumber}</span>
          </li>
          <li>
            <span>
              {translate('c-client-dashboard.reimbursement.filled-data.sum')}
            </span>
            <span>{sum}</span>
          </li>
          <li>
            <span>
              {translate(
                'c-client-dashboard.reimbursement.filled-data.bank-acc'
              )}
            </span>
            <span>{bankAccount}</span>
          </li>
        </ul>
      </div>

      <div className={styles.documents}>
        <ul className={styles['documents-list']}>
          <li>
            <span>
              {translate(
                'c-client-dashboard.reimbursement.filled-data.attachments'
              )}
            </span>
            <span className={styles['file-group']}>
              {files && Object.keys(files)[0]}
              {files && Object.values(files)[0].length > 0 && <Check />}
            </span>
          </li>

          {files && renderDocuments(files)}
        </ul>
      </div>

      <Grid item xs={6} style={{ display: 'flex', marginTop: 'auto' }}>
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          size="large"
          onClick={prevStep}
        >
          {translate('c-client-dashboard.reimbursement.filled-data.back')}
        </Button>
      </Grid>
    </div>
  )
}

export default FilledData
