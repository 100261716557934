import axios from 'axios'
import { LANG_LOCAL_STORAGE_KEY, LANG_BG } from 'config/languageTypes'
import { getIdToken } from 'services/auth'

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_TBI_INFO_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    language: localStorage.getItem(LANG_LOCAL_STORAGE_KEY) || LANG_BG,
  },
})

ApiClient.interceptors.request.use(
  async (config) => {
    const token = await getIdToken()

    if (token) {
      config.headers['token'] = token
    }

    return config
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      // should logout
    }

    return Promise.reject(error)
  }
)

export default ApiClient
