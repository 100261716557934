import React, { Fragment } from 'react'
import ChangePassword from '../../components/ChangePassword'

function Actions(props) {
  const params = new URLSearchParams(props.location.search)
  const mode = params.get('mode')
  const code = params.get('oobCode')
  const email = params.get('email');

  return (
    <Fragment>
      {mode === 'resetPassword' && <ChangePassword code={code} email={email} />}
    </Fragment>
  )
}

export default Actions
