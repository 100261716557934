// Libraries
import React, { useEffect, useRef, useState } from 'react'
import { FormControl, TextField } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import firebase from 'firebase'
// Components
import PasswordChange from '../../../../Form/PasswordChange'
import PasswordConfirm from '../../../../Form/PasswordConfirm'
import ButtonContainer from '../../../../ButtonContainer'
import PhoneInputField from '../../../../PhoneInputField'
// Hooks
import { useForm } from '../../../../../hooks/use-form'
import { useAuth } from '../../../../../hooks/use-auth'
import { useTranslation } from '../../../../../hooks/use-translation'
// Services
import { updateEmailAndPhone } from '../../../../../services/userDataService'
// Utils
import { analytics } from '../../../../../utils/firebase'
// Form Validators
import { changeEmailAndPhoneValidate } from '../../../../../formValidators/userPage'

export default function PersonalInfo() {
  const auth = useAuth()
  const snackbar = useSnackbar()
  const { translate } = useTranslation()
  const { user, activeUser, createRecaptchaVerifier, updatePassword } = auth
  const updateButton = useRef()
  const [emailChanged, setEmailChanged] = useState(false)
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false)
  const [passwordChangeModal, setPasswordChangeModal] = useState(false)
  const [passwordConfirmModal, setPasswordConfirmModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false)

  const INITIAL_STATE = {
    email: user.email ? user.email : '',
    phoneNumber: user.phoneNumber ? user.phoneNumber : '',
  }

  const { values, handleChange } = useForm(
    INITIAL_STATE,
    () => {},
    () => {},
    auth,
    null,
    'async'
  )

  const inputHandler = (type) => (e) => {
    switch (type) {
      case 'email':
        handleChange(e)

        if (e.target.value !== user.email) {
          setEmailChanged(true)
        } else {
          setEmailChanged(false)
        }

        break
      case 'phoneNumber':
        handleChange(e)

        if (e.target.value !== user.phoneNumber) {
          setPhoneNumberChanged(true)
        } else {
          setPhoneNumberChanged(false)
        }

        break
      default:
        return
    }
  }

  const shouldDisableSubmit = () => {
    let state = true

    if (emailChanged) state = false
    else if (phoneNumberChanged) state = false
    else if (emailChanged && phoneNumberChanged) state = false

    return state
  }

  const triggerChangePasswordDialog = () => {
    setPasswordChangeModal(true)
  }

  const triggerConfirmPasswordDialog = () => {
    setPasswordConfirmModal(true)
  }

  const _updatePassword = async (oldPassword, newPassword) => {
    try {
      analytics.logEvent('profile_change_password')
      const successMessage = await updatePassword(oldPassword, newPassword)

      snackbar.enqueueSnackbar(successMessage, { variant: 'success' })

      setIsSubmittingPassword(false)
      setPasswordChangeModal(false)
    } catch (errorMessage) {
      snackbar.enqueueSnackbar(errorMessage, { variant: 'error' })

      setIsSubmittingPassword(false)
    }
  }

  const handleProfileUpdate = async (password) => {
    try {
      // get the updated values
      const { email, phoneNumber } = values

      // validate values
      const newErrors = changeEmailAndPhoneValidate(values)
      setErrors(newErrors)

      // check which values match and then update values
      if (
        isEmpty(newErrors) &&
        (email !== user.email || phoneNumber !== user.phoneNumber)
      ) {
        const data = {
          email: values.email,
          phoneNumber: values.phoneNumber,
        }

        const res = await updateEmailAndPhone(data, auth)

        snackbar.enqueueSnackbar(res.message, { variant: 'success' })

        if (res.userStatus === 'token_revoked') {
          let credential = firebase.auth.EmailAuthProvider.credential(
            values.email,
            password
          )

          await firebase
            .auth()
            .currentUser.reauthenticateWithCredential(credential)
        } else if (res.userStatus === 'token_invalid') {
          await auth.signout()
        }
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (updateButton.current !== undefined && updateButton.current !== null) {
      window.recaptchaVerifier = createRecaptchaVerifier(
        updateButton.current.id,
        () => console.log('Captcha created;')
      )
    }
  }, [createRecaptchaVerifier, user])

  return (
    <div
      style={{
        marginBottom: '2em',
        border: '2px solid #D42F42',
        borderRadius: '0.5em',
        padding: '1em',
      }}
    >
      {/* Person's name */}
      <h4
        style={{ fontWeight: 'normal', marginTop: 0, marginBottom: '1.5rem' }}
      >
        {activeUser.firstName} {activeUser.lastName}
      </h4>
      {/* Form Fields for email and phone number */}
      <FormControl>
        <TextField
          error={errors && errors.email ? true : false}
          label={translate(
            'c-client-dashboard.user.profile.personal-info.email'
          )}
          name="email"
          value={values.email}
          helperText={errors && errors.email}
          type="email"
          onChange={inputHandler('email')}
        />

        {/* Spacer */}
        <div style={{ padding: '.75em 0' }}></div>

        <PhoneInputField
          variant="standart"
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={inputHandler('phoneNumber')}
          errorMessage={errors && errors.phoneNumber}
          placeholder={translate(
            'c-client-dashboard.user.profile.personal-info.phone'
          )}
        />
      </FormControl>

      <div style={{ padding: '.75em 0' }}></div>

      <ButtonContainer columns={2}>
        <button
          className="cm-button outlined"
          onClick={triggerChangePasswordDialog}
        >
          {translate(
            'c-client-dashboard.user.profile.personal-info.change-pass'
          )}
        </button>
        <button
          style={{ color: '#FFF' }}
          disabled={shouldDisableSubmit()}
          onClick={triggerConfirmPasswordDialog}
          className="cm-button"
          ref={updateButton}
          id="updateProfileBtn"
        >
          {translate('c-client-dashboard.user.profile.personal-info.save')}
        </button>
      </ButtonContainer>

      {/* Password change Dialog */}
      <PasswordChange
        open={passwordChangeModal}
        setOpen={setPasswordChangeModal}
        callback={_updatePassword}
        isSubmitting={isSubmittingPassword}
        setIsSubmitting={setIsSubmittingPassword}
      />

      {/* Password confirm Dialog */}
      <PasswordConfirm
        open={passwordConfirmModal}
        setOpen={setPasswordConfirmModal}
        callback={handleProfileUpdate}
      />
    </div>
  )
}
