import { useEffect } from 'react'
import { useRouter } from '../../hooks/use-router'

const ScrollToTop = () => {
  const { pathname } = useRouter()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
