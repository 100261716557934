import React, { Suspense } from 'react'
import { Badge } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import { getMessageCount } from 'services/messagesService'
import { useAuth } from 'hooks/use-auth'
import { isMobile as isCapacitor } from 'mobile'
import { useQuery } from 'react-query'

const MessageCount = () => {
  const { activeUser } = useAuth()

  const { data } = useQuery({
    queryKey: 'get-message-count',
    queryFn: () => getMessageCount(activeUser?.clientId),
    enabled: !!activeUser?.clientId,
  })

  const messagesCount = data?.message_counter_not_read

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Badge badgeContent={messagesCount} color="primary">
        <MessageIcon
          color="secondary"
          {...(isCapacitor && { fontSize: 'large' })}
        />
      </Badge>
    </Suspense>
  )
}

export default MessageCount
