// Libraries
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
// Hooks
import { useTranslation } from '../../hooks/use-translation'
import { useMobileVersion } from '../../hooks/use-mobile-version'

const useCommonStyles = makeStyles((theme) => ({
  container: {
    '& input.form-control': {
      width: '100% !important',

      '&:hover': {
        borderColor: 'rgba(0, 0, 0, 0.87) !important',
      },
    },
  },
  containerWithFocus: {
    '& .special-label': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '0.75rem',
    margin: '3px 0 0 0',
    letterSpacing: '0.03333em',
  },
  mobileDropdown: {
    width: '245px !important',
  },
}))

const useStandartVariantStyles = makeStyles((theme) => ({
  container: {
    '& .special-label': {
      left: '0',
      padding: 0,
    },

    '& .selected-flag .flag': {
      top: '58% !important',
    },

    '& input.form-control': {
      paddingTop: `${theme.spacing(2.5)}px !important`,
      paddingBottom: `${theme.spacing(0.75)}px !important`,
      border: '0 !important',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
      borderRadius: '0 !important',

      '&:hover': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.87) !important',
        boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.87) !important',
      },

      '&:focus': {
        borderBottom: `1px solid ${theme.palette.primary.main} !important`,
        boxShadow: `inset 0 -1px 0 0 ${theme.palette.primary.main} !important`,
      },
    },
  },
  containerWithError: {
    '& .special-label': {
      color: `#f44336 !important`,
    },

    '& input.form-control': {
      borderBottom: '1px solid #f44336 !important',
      boxShadow: 'inset 0 -1px 0 0 #f44336 !important',

      '&:hover': {
        borderColor: '#f44336 !important',
        boxShadow: 'inset 0 -1px 0 0 #f44336 !important',
      },
    },
  },
}))

const useOutlinedVariantStyles = makeStyles((theme) => ({
  container: {
    '& .special-label': {
      left: '9px',
    },

    '& input.form-control:focus': {
      border: `1px solid ${theme.palette.primary.main} !important`,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
    },
  },
  containerWithError: {
    '& .special-label': {
      color: '#f44336 !important',
    },

    '& input.form-control': {
      border: '1px solid #f44336 !important',

      '&:hover': {
        borderColor: '#f44336 !important',
      },

      '&:focus': {
        borderColor: '#f44336 !important',
        boxShadow: '0 0 0 1px #f44336 !important',
      },
    },
  },
}))

const PhoneInputField = ({
  variant,
  name,
  id = '',
  value,
  onChange,
  onBlur,
  placeholder,
  errorMessage = '',
  classes: containerClass,
}) => {
  const standartVariantClasses = useStandartVariantStyles()
  const outlinedVariantClasses = useOutlinedVariantStyles()
  const commonClasses = useCommonStyles()

  const {activeLanguage, translate} = useTranslation()
  const isMobile = useMobileVersion()

  const [isOnFocus, setIsOnFocus] = useState(false)

  // We need to add prefix "+" in value, because all form validators requires it.
  const handleChange = (phoneNumber) => {
    const prefix = '+'
    onChange({ target: { name, value: prefix + phoneNumber } })
  }

  const handleOnBlur = () => {
    setIsOnFocus(false)

    if (onBlur) {
      onBlur()
    }
  }

  return (
    <div className={containerClass}>
      <PhoneInput
        country="bg"
        localization={activeLanguage}
        countryCodeEditable={false}
        enableSearch={true}
        searchPlaceholder={translate('search-label')}
        placeholder={placeholder}
        specialLabel={placeholder}
        name={name}
        id={id}
        value={value}
        onChange={handleChange}
        onFocus={() => setIsOnFocus(true)}
        onBlur={handleOnBlur}
        containerClass={`
          ${commonClasses.container}
          ${isOnFocus ? commonClasses.containerWithFocus : ''}
           ${
             variant === 'standart'
               ? standartVariantClasses.container
               : outlinedVariantClasses.container
           }
           ${
             variant === 'standart' && errorMessage
               ? standartVariantClasses.containerWithError
               : ''
           }
           ${
             variant === 'outlined' && errorMessage
               ? outlinedVariantClasses.containerWithError
               : ''
           }`}
        dropdownClass={`${isMobile ? commonClasses.mobileDropdown : ''}`}
      />

      {errorMessage && (
        <p className={commonClasses.errorMessage}>{errorMessage}</p>
      )}
    </div>
  )
}

PhoneInputField.propTypes = {
  variant: PropTypes.oneOf(['standart', 'outlined']).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  classes: PropTypes.string,
}

export default PhoneInputField
