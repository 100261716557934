import React, { Fragment } from 'react'
import CoInsuredCard from '../CoInsuredCard'
import ButtonContainer from '../../../../ButtonContainer'
import AddCoInsuredPerson from '../../../../Form/AddCoInsuredPerson'
import { useAuth } from '../../../../../hooks/use-auth'
import { useTranslation } from '../../../../../hooks/use-translation'

const renderCards = (
  coInsuredPersons,
  changePersonHandler,
  changeActiveUserCardHandler
) => {
  return (
    coInsuredPersons.length > 0 &&
    coInsuredPersons.map((person, idx) => {
      return (
        <CoInsuredCard
          key={person.clientId + '_' + idx}
          {...person}
          changePersonHandler={changePersonHandler}
          changeActiveUserCardHandler={changeActiveUserCardHandler}
        />
      )
    })
  )
}

function CoInsuredPersons({
  coInsuredPersons,
  changePersonHandler,
  addCoInsuredPersonModal,
  setAddCoInsuredPersonModal,
  addCoInsuredHandler,
  changeActiveUserCardHandler,
}) {
  const triggerAddCoInsuredPersonDialog = () => {
    setAddCoInsuredPersonModal(true)
  }

  const { activeUser } = useAuth()
  const { translate } = useTranslation()

  return (
    <Fragment>
      <section style={{ marginBottom: '2em' }}>
        {renderCards(
          coInsuredPersons,
          changePersonHandler,
          changeActiveUserCardHandler
        )}
      </section>

      {activeUser.role === 'parent' && (
        <Fragment>
          <ButtonContainer>
            <button
              style={{ width: '100%', marginRight: '0' }}
              className="cm-button outlined"
              id="addCoInsuredPersonButton"
              onClick={triggerAddCoInsuredPersonDialog}
            >
              {translate(
                'c-client-dashboard.user.co-insured.co-insured-persons.add'
              )}
            </button>
          </ButtonContainer>

          <AddCoInsuredPerson
            open={addCoInsuredPersonModal}
            setOpen={setAddCoInsuredPersonModal}
            callback={addCoInsuredHandler}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default CoInsuredPersons
