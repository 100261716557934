import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '../../../../hooks/use-translation'
import { analytics } from '../../../../utils/firebase'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.primary.main,
  },
  spacingBottom: {
    marginBottom: '1.4em',
  },
}))

const StepMarketing = ({ isOnStepMarketing, setIsOnStepMarketing}) => {
  const { translate } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    if(!isOnStepMarketing) {
      analytics.logEvent(`register-other-choice-started`);
      setIsOnStepMarketing(true);
    }
  }, [isOnStepMarketing, setIsOnStepMarketing])

  return (
    <Box>
      <Typography variant="h5" className={`${classes.header} ${classes.spacingBottom}`}>
        {translate('register.step-marketing.marketing-subscription.title')}
      </Typography>

      <Typography className={classes.spacingBottom}>
        {translate('register.step-marketing.marketing-subscription.subtitle')}
      </Typography>

      <Typography className={classes.spacingBottom}>
        {translate(
          'register.step-marketing.marketing-subscription.description'
        )}
      </Typography>
    </Box>
  )
}

export default StepMarketing
