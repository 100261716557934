import { Container } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import setAuthDialog from '../../actions/snackbar/setAuthDialog'
import { useTranslation } from '../../hooks/use-translation'
import s from './s.module.css'

export default function About() {
  const dispatch = useDispatch()
  const { translate: t } = useTranslation()

  return (
    <Container maxWidth="lg" className={s.container}>
      <h1 className={s.heading}>{t('about.title')}</h1>

      <article className={s['timeline-wrapper']}>
        <div className={s['timeline']}></div>

        <div className={s['timeline-content-block']}>
          <span className={s['timeline-year']}>
            {t('about.periods.2013-2019.date')}
          </span>
          <img
            className={s.logo}
            src="/images/old-b-assist-logo.png"
            alt="Old B-assist Logo"
          />
          <p>{t('about.periods.2013-2019.p1')}</p>
          <p>{t('about.periods.2013-2019.p2')}</p>
        </div>

        <div className={s['timeline-content-block']}>
          <span className={s['timeline-year']}>
            {t('about.periods.2020.date')}
          </span>
          <img
            className={s.logo}
            src="/images/logo.png"
            alt="Current B-assist Logo"
          />
          <p>{t('about.periods.2020.p1')}</p>
          <p>{t('about.periods.2020.p2')}</p>
          <ul className={s.list}>
            <li>{t('about.periods.2020.options.0')}</li>
            <li>{t('about.periods.2020.options.1')}</li>
            <li>
              <span>{t('about.periods.2020.options.2.label')}</span>
              <ul>
                <li>{t('about.periods.2020.options.2.options.0')}</li>
                <li>{t('about.periods.2020.options.2.options.1')}</li>
                <li>{t('about.periods.2020.options.2.options.2')}</li>
                <li>{t('about.periods.2020.options.2.options.3')}</li>
              </ul>
            </li>
            <li>{t('about.periods.2020.options.3')}</li>
          </ul>
          <p>{t('about.periods.2020.p3')}</p>
        </div>

        <div className={s['timeline-content-block']}>
          <span className={s['timeline-year']}>
            {t('about.periods.2021.date')}
          </span>
          <p>{t('about.periods.2021.p1')}</p>
          <ul>
            <li>{t('about.periods.2021.options.0')}</li>
            <li>{t('about.periods.2021.options.1')}</li>
          </ul>
          <p>{t('about.periods.2021.p2')}</p>
        </div>
      </article>

      <p>
        <i>
          {t('about.additional-info.0')}{' '}
          <span className={s.red} onClick={() => dispatch(setAuthDialog(true))}>
            {t('about.additional-info.1')}
          </span>
        </i>
      </p>

      <div>
        <p>{t('about.additional-info.p1')}</p>
        <p>{t('about.additional-info.p2')}</p>
      </div>

      <article className={s['mobile-app-box']}>
        <div className={s['mobile-app-header']}>
          <div className={s['mobile-app-icon']}>
            <img src="/images/b-assist-app.png" alt="B-Assist" />
            <span>B-Assist</span>
          </div>
          <h2>{t('about.app-box.title')}</h2>
        </div>

        <p className={s['mobile-app-description']}>
          {t('about.app-box.description')}
        </p>

        <div className={s['mobile-app-icons']}>
          <a
            className={s['mobile-app-icon']}
            href="https://play.google.com/store/apps/details?id=com.bulstrad"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/google-play.png"
              alt="Download B-Assist on Google Play"
            />
          </a>
          <a
            className={s['mobile-app-icon']}
            href="https://apps.apple.com/bg/app/b-assist/id1070622471"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/app-store.png"
              alt="Download B-Assist on App Store"
            />
          </a>
        </div>
      </article>
    </Container>
  )
}
