import React, { useRef, useEffect } from 'react'
import { originPath } from '../../utils/config'
import { useRouter } from '../../hooks/use-router'
import { analytics } from '../../utils/firebase'

export default function BotRedirect({ type }) {
  // This component handles redirects from chatbot
  // types can be credit-card-redirect and epay-redirect
  const epayForm = useRef()
  const { location } = useRouter()

  useEffect(() => {
    ;(async () => {
      try {
        const params = new URLSearchParams(location.search)

        epayForm.current.children['PAGE'].value = params.get('page')
        epayForm.current.children['ENCODED'].value = params.get('encoded')
        epayForm.current.children['CHECKSUM'].value = params.get('checksum')

        // set lang only if type is credit-card-redirect
        if (type === 'credit-card-redirect') {
          epayForm.current.children['LANG'].value = params.get('lang')
        }

        analytics.logEvent(`chatbot-buy-ins-last-step`)

        epayForm.current.submit()
      } catch (e) {
        alert(e.message)
        window.location.replace(originPath)
      }
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <form ref={epayForm} action={process.env.REACT_APP_EPAY_URL} method="POST">
      <input type="hidden" name="PAGE" />
      <input type="hidden" name="ENCODED" />
      {type && type === 'credit-card-redirect' && (
        <input type="hidden" name="LANG" />
      )}
      <input type="hidden" name="CHECKSUM" />
      <input type="hidden" name="URL_OK" value={`${originPath}/thank-you`} />
      <input type="hidden" name="URL_CANCEL" value={`${originPath}/`} />
    </form>
  )
}
