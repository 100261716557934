// Libraries
import React, { useState } from 'react'
import {
  TextField,
  InputAdornment,
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import has from 'lodash/has'
// Hooks
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from '../../hooks/use-router'
import { usePersistentForm } from '../../hooks/use-persistent-form'
import { useTranslation } from '../../hooks/use-translation'
// Form Validators
import { changePasswordValidate } from '../../formValidators/resetPassword'

function ChangePassword({ code, email }) {
  const { changePasswordWithResetPassword } = useAuth()
  const router = useRouter()
  const { translate } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const INITIAL_STATE = {
    password: '',
    rePassword: '',
    code,
    email,
  }

  const {
    values,
    errors,
    isSubmitting,
    validate,
    handleChange,
    update,
  } = usePersistentForm('changePassword', INITIAL_STATE)

  const handleSubmit = async (e) => {
    e.preventDefault()
    // validate form step
    const hasErrors = validate(changePasswordValidate)
    // check for errors
    if (hasErrors) {
      return
    }

    update('isSubmitting', true)

    try {
      await changePasswordWithResetPassword({
        code: values.code,
        password: values.password,
        email: values.email,
      })
      router.push('/')
      update('isSubmitting', false)
    } catch (e) {
      update('isSubmitting', false)

      console.error(e, 'Change password:')
    }
  }

  return (
    <div className="w-container">
      <div style={{ margin: '2em auto', maxWidth: '350px' }}>
        <div style={{ margin: '0 0 10px' }}>
          <form onSubmit={handleSubmit}>
            <TextField
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: '0', color: 'rgba(0, 0, 0, 0.87)' }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword === false && <Visibility />}
                      {showPassword === true && <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={has(errors, 'password')}
              label={translate('c-change-password.label-pass')}
              type={showPassword === true ? 'text' : 'password'}
              helperText={has(errors, 'password') && errors.password}
              value={values.password}
              onChange={handleChange}
            />

            <div style={{ height: '10px' }}></div>

            <TextField
              name="rePassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ padding: '0', color: 'rgba(0, 0, 0, 0.87)' }}
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                      {showRepeatPassword === false && <Visibility />}
                      {showRepeatPassword === true && <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={has(errors, 'rePassword')}
              label={translate('c-change-password.label-pass-repeat')}
              type={showRepeatPassword === true ? 'text' : 'password'}
              helperText={has(errors, 'rePassword') && errors.rePassword}
              value={values.rePassword}
              onChange={handleChange}
            />

            <div style={{ height: '20px' }}></div>

            <Box display="flex" alignItems="cener" justifyContent="flex-end">
              <Button
                disabled={isSubmitting}
                variant="outlined"
                color="primary"
                type="submit"
              >
                {isSubmitting ? (
                  <CircularProgress size={25} thickness={2.7} color="primary" />
                ) : (
                  translate('c-change-password.label-change')
                )}
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
