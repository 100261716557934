import ApiClient from 'infrastructure/api/ApiClient'

export const getReimbursementStructure = async (clientId) => {
  return ApiClient.get('/getReimbursementStructure', {
    params: { clientId },
  }).then((response) => response?.data)
}

export const getAllClaims = async (clientId) => {
  return ApiClient.get('/claims', {
    params: { clientId, archiveData: 'true' },
  }).then((response) => sortByDesc(response?.data))
}

export const loadActiveClaims = async (clientId) => {
  return ApiClient.get('/claims', {
    params: { clientId, archiveData: 'true' },
  }).then((response) =>
    sortByDesc(response?.data)?.filter(
      (reimbursement) => reimbursement?.isArchive === 'false'
    )
  )
}

export const loadArchivedClaims = async (clientId) => {
  return ApiClient.get('/claims', {
    params: { clientId, archiveData: 'true' },
  }).then((response) =>
    sortByDesc(response?.data)?.filter(
      (reimbursement) => reimbursement?.isArchive === 'true'
    )
  )
}

export const makeReimbursement = async (data) => {
  return ApiClient.post('/createClaim', data)
}

const sortByDesc = (data, key = 'createDate') =>
  data?.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime())
