import React, { useState } from 'react'
import { Box, Button, Tooltip } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOffOutlined'
import { registerNotificationListener } from '../../../services/notificationService'
import { useSnackbar } from 'notistack'
import { useRouter } from '../../../hooks/use-router'
import { useAuth } from '../../../hooks/use-auth'
import { useTranslation } from '../../../hooks/use-translation'

const PermissionHandler = ({ handleToken }) => {
  const [shouldShowOverlay, setOverlay] = useState(false)
  const router = useRouter()
  const notifier = useSnackbar()
  const auth = useAuth()
  const { translate } = useTranslation()

  const getPermission = async () => {
    try {
      setOverlay(true)
      await Notification.requestPermission()
      await handleToken()
      // register message listener
      registerNotificationListener({ router, notifier }, auth)
    } catch (e) {
      console.error(e)
    } finally {
      setOverlay(false)
    }
  }

  return (
    <>
      <Box>
        {Notification.permission === 'granted' && (
          <Alert
            variant="outlined"
            icon={<NotificationsIcon fontSize="inherit" />}
            severity="success"
          >
            {translate('c-notifications.permission-handler.granted')}
          </Alert>
        )}
        {Notification.permission === 'default' && (
          <Tooltip title="notification" position="bottom">
            <Button
              startIcon={<NotificationsOffIcon fontSize="inherit" />}
              onClick={() => getPermission()}
            >
              {translate('c-notifications.permission-handler.turn-on')}
            </Button>
          </Tooltip>
        )}
        {Notification.permission === 'denied' && (
          <Alert
            variant="outlined"
            icon={<NotificationsOffIcon fontSize="inherit" />}
            severity="warning"
          >
            {translate('c-notifications.permission-handler.denied')}
          </Alert>
        )}
      </Box>
      {shouldShowOverlay && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, .4)',
            position: 'fixed',
            zIndex: 99999999999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100vw',
            height: '100vh',
          }}
        ></div>
      )}
    </>
  )
}

export default PermissionHandler
