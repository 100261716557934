import { fetch as fetcher } from '../hooks/use-fetch'

export const loadCitites = async (clientId, auth) => {
  return await fetcher.get(
    '/cities',
    {
      params: {
        clientId,
        allTypes: 'false',
      },
    },
    auth
  )
}

export const loadFacilities = async (clientId, auth) => {
  return await fetcher.get(
    '/cityHospitals',
    {
      params: {
        clientId,
        allTypes: 'false',
      },
    },
    auth
  )
}

export const loadSpecialties = async (clientId, auth) => {
  return await fetcher.get(
    '/medSpecialty',
    {
      params: {
        clientId,
      },
    },
    auth
  )
}

export const loadSingleAppointment = async (caseId, emailCode) => {
  return await fetcher.get(`/clientCases`, {
    params: {
      caseId,
    },
    headers: {
      code: emailCode,
    },
  })
}

export const loadAllAppointments = async (clientId, auth) => {
  return await fetcher.get(
    '/clientCases',
    {
      params: {
        clientId,
      },
    },
    auth
  )
}

export const loadHolidays = async (clientId, auth) => {
  return await fetcher.get(
    '/hodidays',
    {
      params: {
        clientId,
      },
    },
    auth
  )
}

export const makeAppointment = async (data, auth) => {
  return await fetcher.post(
    '/appointments',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const getAvailableDoctorsFromSuperDoc = async ({
  citySuperDocId,
  specialtySuperDocId,
  userCardNumber,
}) => {
  try {
    const reqParams = [
      `?specialty_id=${specialtySuperDocId}`,
      `city_id=${citySuperDocId}`,
      `user_policy=${userCardNumber}`,
      'insurance_id=4',
    ].join('&')

    const response = await fetch(
      `${process.env.REACT_APP_SUPERDOC_API}${reqParams}`,
      {
        headers: {
          Authorization: 'Basic dGVzdDpzdXBlcmRlbW8xJQ==',
          Accept: 'application/json',
        },
      }
    )

    const data = await response.json()

    return response.ok ? data.found : Promise.reject(data.message)
  } catch (e) {
    throw new Error(e)
  }
}

export const changeTicketStatus = async (data, auth, emailCode) => {
  return await fetcher.post(
    '/ticketStatus',
    {
      body: JSON.stringify(data),
      headers: { code: emailCode },
    },
    auth
  )
}

export const changeTicketRate = async (data, auth) => {
  return await fetcher.post('/ticketRate', { body: JSON.stringify(data) }, auth)
}
