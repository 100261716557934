import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const ActiveReimbursementsSkeleton = () => {
  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    padding: '1.7em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',
  }

  return (
    <Box style={{ width: '100%' }}>
      <Box style={styles}>
        <Skeleton variant="text" width="20%" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginRight: '2em' }}>
            <Skeleton variant="text" width="7em" />
            <Skeleton variant="text" width="7em" />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={styles}>
        <Skeleton variant="text" width="20%" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginRight: '2em' }}>
            <Skeleton variant="text" width="7em" />
            <Skeleton variant="text" width="7em" />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={styles}>
        <Skeleton variant="text" width="20%" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginRight: '2em' }}>
            <Skeleton variant="text" width="7em" />
            <Skeleton variant="text" width="7em" />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={styles}>
        <Skeleton variant="text" width="20%" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginRight: '2em' }}>
            <Skeleton variant="text" width="7em" />
            <Skeleton variant="text" width="7em" />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box style={{ ...styles, borderBottom: '2px solid rgba(0, 0, 0, 0.12)' }}>
        <Skeleton variant="text" width="20%" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginRight: '2em' }}>
            <Skeleton variant="text" width="7em" />
            <Skeleton variant="text" width="7em" />
          </Box>
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '1.7em 0',
        }}
      >
        <Skeleton variant="text" width="7%" style={{ marginRight: '1em' }} />
        <Skeleton
          variant="circle"
          width="2em"
          height="2em"
          style={{ marginRight: '1em' }}
        />
        <Skeleton variant="circle" width="2em" height="2em" />
      </Box>
    </Box>
  )
}

export default ActiveReimbursementsSkeleton
