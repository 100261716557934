import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { analytics } from '../../../../utils/firebase'
import FilledData from '../FilledData'
import StepConfirm from '../StepConfirm'
import StepDetails from '../StepDetails'
import BoxSkeleton from '../BoxSkeleton'
import BoxSkeletonImages from '../../FileUploadContainer/BoxSkeleton'
import FileUploadContainer from '../../FileUploadContainer'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
import { useReimbursementStructure } from 'pages/Reimbursement/hooks/useReimbursementStructure'

function Request({ files, setFiles, typeDocumentsList }) {
  const [step, setStep] = useState(0)
  const isMobile = useMobileVersion()
  const { isLoading: isServicesLoading } = useReimbursementStructure()

  const nextStep = () => {
    if (step === 0) {
      analytics.logEvent('ZG_razhodi_zaiavka_details')
    }

    setStep(step + 1)
  }
  const prevStep = () => setStep(step - 1)

  const maximumAllowedFiles = 5

  const steps = [
    <StepDetails nextStep={nextStep} files={files} />,
    <StepConfirm />,
  ]

  if (step < 0) {
    return <Redirect to="/" />
  }

  const wrapperStyles = {
    display: 'flex',
    flexDirection: isMobile ? 'column-reverse' : 'flex-row',
    border: '2px solid #e1e1e1',
    borderRadius: '3px',
  }

  return (
    <div style={wrapperStyles}>
      <Grid item lg={6} xs={12}>
        {step === 0 ? (
          isServicesLoading ? (
            <BoxSkeletonImages />
          ) : (
            <FileUploadContainer
              uploadContainers={typeDocumentsList}
              maximumAllowedFiles={maximumAllowedFiles}
              filesSetter={setFiles}
              uploadedFiles={files}
            />
          )
        ) : null}

        {step > 0 && <FilledData prevStep={prevStep} />}
      </Grid>

      <Grid item lg={6} xs={12} style={{ padding: '30px' }}>
        {isServicesLoading ? (
          <BoxSkeleton />
        ) : (
          <div style={{ padding: '30px 0', height: '100%' }}>{steps[step]}</div>
        )}
      </Grid>
    </div>
  )
}

export default Request
