import React from 'react'
import { Box } from '@material-ui/core'

import { useTranslation } from '../../hooks/use-translation'
import { BAssistLogo } from './Logo'
import { Typography } from '@material-ui/core'

export default function CapacitorNavbar() {
  const { translate } = useTranslation()
  return (
    <Box py={2} width="100%">
      <Box display="flex" alignItems="center" justifyContent="center">
        <BAssistLogo />
        <Box ml={2} fontWeight="regular" fontSize={20}>
          <Typography color="primary" variant="inherit">
            {translate('header.login-button.secondary-text')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
