import React from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Container, Button } from '@material-ui/core'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import styles from './back-btn.module.css'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

export default function GoBack({ customBackFn }) {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const { translate } = useTranslation()

  const logoutHandler = async () => {
    try {
      await auth.signout()

      navigate('/')
    } catch (e) {
      console.error('User Social Logout', e)
    }
  }

  const shouldShowLogout =
    location.pathname === '/user' && auth.claims.zg === 'true'

  const back = () => navigate(-1)

  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <div
        className={styles['back-btn']}
        onClick={customBackFn ? customBackFn : back}
      >
        <img
          src="/images/ch_left.svg"
          alt="Left Arrow"
          className={styles.icon}
        />
        <span className={styles['span-style']}>
          {translate('c-go-back.back')}
        </span>
      </div>

      {shouldShowLogout && (
        <Button
          className={styles['logout-button']}
          startIcon={<ExitToAppIcon />}
          onClick={logoutHandler}
        >
          {translate('c-go-back.exit')}
        </Button>
      )}
    </Container>
  )
}
