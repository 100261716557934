import { useEffect } from 'react'

import { App } from '@capacitor/app'
import { useNavigate } from 'react-router-dom-v5-compat'

export default function BackButtonListener() {
  const navigate = useNavigate()

  useEffect(() => {
    App.addListener('backButton', () => navigate(-1))
  }, [navigate])

  return null
}
