import {
  ADD_FILTERED_DENTAL_CLINICS,
  ADD_FILTERED_MEDICAL_INSTITUTIONS,
  ADD_FILTERED_OPTICS
} from '../actions/map/actionTypes'

/**
 * Holds state for map
 */
export const map = (state = {}, action) => {
  switch (action.type) {
    case ADD_FILTERED_MEDICAL_INSTITUTIONS:
      return {
        ...state,
        medicalInstitutions: action.payload
      }
    case ADD_FILTERED_DENTAL_CLINICS:
      return {
        ...state,
        dentalClinics: action.payload
      }
    case ADD_FILTERED_OPTICS:
      return {
        ...state,
        optics: action.payload
      }
    default:
      return state
  }
}
