import { SET_AUTH_DIALOG, SET_GLOBAL_ERROR } from '../actions/snackbar/actionTypes';

export const snackbar = (state = { authDialog: false }, action) => {
  switch (action.type) {
    case SET_GLOBAL_ERROR:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.message
      }
    case SET_AUTH_DIALOG:
      return {
        ...state,
        authDialog: action.payload
      }
    default:
      return state
  }
}
