import {
  validateEgn,
  validateIdentificationNumber,
} from './egnAndIdentificationNumber'
import { translate } from '../utils/lang'
import moment from 'moment'

export const createBankAccountValidate = ({ nameMobile, iban }) => {
  const errors = {}
  const ibanRegex = /^BG[0-9]{2}[A-Z]{4}([A-Z0-9]{4}){3}[A-Z0-9]{2}$/

  if (!iban || !ibanRegex.test(iban))
    errors.iban = translate('validate-user-page.iban')

  if (!nameMobile || nameMobile.length < 3)
    errors.nameMobile = translate('validate-user-page.name-mobile')

  return errors
}

export const addCoInsuredValidate = ({
  personalNumber,
  cardNumber,
  radioChoice,
  dateOfBirth,
}) => {
  const errors = {}

  const cardNumberRegex = /^[0-9]{10}$/
  const dateFormat = 'YYYY-MM-DD'
  const dateToFormat = moment(new Date(dateOfBirth)).format(dateFormat)
  const isDateOfBirthValid = moment(dateToFormat, dateFormat, true).isValid()

  // Disable card number validation
  if (!Boolean(process.env.REACT_APP_FEATURE_DISABLE_CARD_VALIDATION)) {
    if (!cardNumber || !cardNumberRegex.test(cardNumber)) {
      errors.cardNumber = translate('validate-user-page.card-number')
    }
  }

  switch (radioChoice) {
    case 'personalNumber':
      const isEGN = validateEgn(personalNumber)
      const lnchError = validateIdentificationNumber(personalNumber)

      const egnIsValid = isEGN.error === ''
      const lnchIsValid = lnchError === ''

      if (egnIsValid || lnchIsValid) break

      errors.personalNumber = translate(
        'c-form.add-co-insured-person.eng-lnch-error'
      )

      break
    case 'dateOfBirth':
      if (!dateOfBirth) {
        errors.dateOfBirth = translate(
          'installments.regular-payment.validate.birthdate'
        )
      }

      if (!isDateOfBirthValid) {
        errors.dateOfBirth = translate(
          'c-form.add-co-insured-person.dateOfBirthFormatError'
        )
      }
      break
    default:
      break
  }

  return errors
}

export const changePasswordValidate = ({
  oldPass,
  newPass,
  newPassConfirm,
}) => {
  const errors = {}

  if (!oldPass) errors.oldPass = translate('validate-user-page.old-pass')

  if (!newPass || newPass.length < 8)
    errors.newPass = translate('validate-user-page.new-pass')

  if (!newPassConfirm || newPassConfirm.length < 8)
    errors.newPassConfirm = translate('validate-user-page.new-pass')

  if (newPassConfirm !== newPass)
    errors.newPassConfirm = translate('validate-user-page.new-pass-match')

  return errors
}

export const changeEmailAndPhoneValidate = ({ email, phoneNumber }) => {
  const errors = {}

  let phoneRegex = /^\+?\d{10,14}$/
  let emailRegex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
    errors.phoneNumber = translate('validate-user-page.phone')
  }

  if (!email || !emailRegex.test(email)) {
    errors.email = translate('validate-user-page.email')
  }

  return errors
}
