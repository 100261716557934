import { Container } from '@material-ui/core'
import React from 'react'

const NotFound = () => {
  return (
    <Container className="custom-spacing" style={{ marginTop: 20 }}>
      <h1>404 - Страницата не бе намерена.</h1>
    </Container>
  )
}

export default NotFound
