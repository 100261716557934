import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  TablePagination,
  IconButton,
} from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { useMobileVersion } from 'hooks/use-mobile-version'
import { useTranslation } from 'hooks/use-translation'
import ArchivedReimbursementsSkeleton from 'components/ClientDashboard/Reimbursement/ArchivedReimbursementsSkeleton'
import { useArchivedClaims } from 'pages/Reimbursement/hooks/useClaims'

const PER_PAGE = 5

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  title: {
    marginRight: '2em',
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  additionalInfoWrapper: {
    textAlign: 'right',
    marginRight: '1em',
  },
  zeroMarginBottom: {
    marginBottom: 0,
  },
  largeMarginRight: {
    marginRight: '4.4em',
  },
  status: {
    textTransform: 'capitalize',
  },
  message: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
  mobileButton: {
    paddingRight: 0,
  },
})

const labelDisplayedRows = ({ from, to, count }) => {
  return `${from}-${to === -1 ? count : to} от ${
    count !== -1 ? count : `повече от ${to}`
  }`
}

const ArchivedReimbursements = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()

  const { data, isLoading } = useArchivedClaims()

  const hasResults = data && data?.length > 0

  const renderReimbursements = () => {
    if (!data) return null

    let startIndex = page === 0 ? 0 : page * PER_PAGE
    let endIndex = page === 0 ? PER_PAGE : startIndex + PER_PAGE

    let filtered = data
      ?.filter((reimbursement, idx) => idx >= startIndex && idx < endIndex)
      .sort(
        (a, b) =>
          new Date(b?.claimDate).getTime() - new Date(a?.claimDate).getTime()
      )

    return (
      filtered &&
      filtered.map((reimbursement) => {
        return (
          <Fragment key={reimbursement.claimId}>
            <ListItem className={classes.listItem}>
              <ListItemText
                className={classes.title}
                primary={reimbursement.coverName}
                secondary={`${reimbursement.med_name}`}
              />

              <section className={classes.additionalInfo}>
                <section
                  className={`${classes.additionalInfoWrapper} ${
                    reimbursement.type === 'Rec' ? '' : classes.largeMarginRight
                  }`}
                >
                  <p className={classes.zeroMarginBottom}>
                    {moment(reimbursement.claimDate).format('DD MMMM YYYY')}
                  </p>
                  <p className={classes.zeroMarginBottom}>
                    {reimbursement.type === 'Rec'
                      ? reimbursement.amountClaimed
                      : translate(
                          'c-client-dashboard.reimbursement.archive.subscription'
                        )}
                  </p>
                </section>

                {reimbursement.type === 'Rec' && (
                  <Link to={`/reimbursements/archive/${reimbursement.claimId}`}>
                    <IconButton
                      className={isMobile ? classes.mobileButton : ''}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Link>
                )}
              </section>
            </ListItem>
          </Fragment>
        )
      })
    )
  }

  if (isLoading) {
    return <ArchivedReimbursementsSkeleton />
  }

  return (
    <Fragment>
      <List className={classes.root}>
        {!hasResults ? (
          <p className={classes.message}>
            {translate('c-client-dashboard.reimbursement.archive.empty')}
          </p>
        ) : (
          renderReimbursements()
        )}
      </List>

      {hasResults && (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={PER_PAGE}
          rowsPerPageOptions={[PER_PAGE]}
          count={data?.length}
          onPageChange={(e, newPage) => setPage(newPage)}
          labelDisplayedRows={labelDisplayedRows}
        />
      )}
    </Fragment>
  )
}

export default ArchivedReimbursements
