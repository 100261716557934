import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'
import { analytics } from '../../../../../utils/firebase'
import { useTranslation } from '../../../../../hooks/use-translation'
import CheckboxLink from '../../../CheckboxLink'

const useStyles = makeStyles((theme) => ({
  detailsPanel: {
    flexDirection: 'column',
  },
  spacingBottom: {
    marginBottom: '2em',
  },
}))

const MarketingSubscription = ({
  isSubscribeForMarketing,
  changeMarketingSubsriptionHandler,
}) => {
  const { translate } = useTranslation()
  const classes = useStyles()

  const handleClick = (event, expanded) => {
    if(expanded) {
      analytics.logEvent('profile_marketingova_open')
    }
  }

  return (
    <div style={{ marginBottom: '2em' }}>
      <Accordion  onChange={handleClick}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {translate('user-page.profile.marketing-subscription.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsPanel}>
          <Typography className={classes.spacingBottom}>
            {translate('user-page.profile.marketing-subscription.subtitle')}
          </Typography>

          <Typography className={classes.spacingBottom}>
            {translate('user-page.profile.marketing-subscription.description')}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={isSubscribeForMarketing}
                onChange={changeMarketingSubsriptionHandler}
                value="marketing"
                color="primary"
              />
            }
            label={
              <CheckboxLink
                primaryText={translate('user-page.profile.marketing-subscription.primary-button-text')}
                linkText={translate('user-page.profile.marketing-subscription.primary-link-text')}
                link={translate('user-page.profile.marketing-subscription.primary-link')}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default MarketingSubscription
