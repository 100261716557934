import React from 'react'
import moment from 'moment'
import {
  FormControl,
  Slider,
  Grid,
  Button,
  FormHelperText,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import { makeAppointmentValidateStepDate } from '../../../../formValidators/makeAppointment'
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { manipulateDateAndTime } from './timeAndDateUtil'
import { useTranslation } from '../../../../hooks/use-translation'
import { LANG_EN } from '../../../../config/languageTypes'

/**
 * The marks array for the slider
 */
const marks = (() => {
  const r = []

  // labels are disabled. not enough space

  for (let i = 8; i <= 18; i++) {
    if (i !== 18)
      r.push(
        {
          value: i,
          //label: `${i}`.padStart(2, '0') + ':00'
        },
        {
          value: i + 0.5,
          //label: `${i}`.padStart(2, '0') + ':30'
        }
      )
    else
      r.push({
        value: i,
        //label: `${i}:00`
      })
  }

  return r
})()

/**
 * The restyled slider
 */
const CustomSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    '& > span': {
      left: 'calc(-50% + -7px)',
      top: '-36px',
    },
    '& > span > span': {
      width: 37,
      height: 37,
    },
  },
  track: {
    height: 3,
    borderRadius: 4,
  },
  rail: {
    height: 3,
    borderRadius: 4,
  },
  mark: {
    display: 'none',
  },
})(Slider)

/**
 * formats the float from the value to
 * display as an hour and minutes
 */
const formatLabel = (value) => {
  const [hours, minutes] = `${value}`.split('.')
  let p1 = hours.padStart(2, '0')
  let p2 = !!minutes ? '30' : '00'

  return `${p1}:${p2}`
}

const handleTimeChange = (value, date) => {
  let [v1, v2] = value

  // eslint-disable-next-line
  let { time } = manipulateDateAndTime(date, v1)

  // eslint-disable-next-line
  let [from] = time.replace('[', '').replace(']', '').split(',')

  if (v1 < from) {
    return
  }

  // 2 hours is the min interval for now
  if (Math.abs(v2 - v1) < 2) return

  return value
}

const StepDate = ({ prevStep, nextStep }) => {
  // use persistent form values and methods
  const { values, errors, validate, handleChange } =
    usePersistentForm('make-appointment')

  const { translate } = useTranslation()

  const disableDates = (day) => {
    for (let holiday of values.holidays) {
      let { nonWorkingDay } = holiday

      let formattedDay = moment(day).format('D-M-YYYY')

      if (
        formattedDay === nonWorkingDay ||
        day.locale(LANG_EN).format('dddd') === 'Sunday' ||
        day.locale(LANG_EN).format('dddd') === 'Saturday'
      ) {
        return true
      }
    }

    if (day.isSame(moment(), 'day')) {
      let currentHours = moment().format('HH')
      currentHours = +currentHours

      if (currentHours >= 12) {
        return true
      }
    }

    return day.isBefore(moment(), 'day') ? true : false
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // validate form step
    const hasErrors = validate(makeAppointmentValidateStepDate)
    // check for errors
    if (hasErrors) {
      return
    }
    // go to next step if we pass the validation
    nextStep()
  }

  const handleDatePicker = (e) => {
    const { date, time } = manipulateDateAndTime(e, values.time)

    const formattedTime = handleTimeChange(JSON.parse(time), date)

    if (formattedTime) {
      handleChange(
        { target: { name: 'date', value: date } },
        { time: JSON.stringify(formattedTime) }
      )
    } else {
      handleChange({ target: { name: 'date', value: date } })
    }
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit}
    >
      <FormControl
        error={errors && errors.date ? true : false}
        style={{ marginBottom: '5.3em' }}
      >
        <DatePicker
          name="date"
          label={translate(
            'c-client-dashboard.make-appointment.step-date.label-day'
          )}
          format="DD.MM.YYYY"
          value={values.date}
          onChange={handleDatePicker}
          shouldDisableDate={disableDates}
        />
        <FormHelperText>{errors && errors.date}</FormHelperText>
      </FormControl>

      <FormControl style={{ marginBottom: '4em' }}>
        <CustomSlider
          value={values.time && JSON.parse(values.time)}
          step={null} // ensures only the values from marks are useable
          min={8}
          max={18}
          onChange={(e, value) => {
            const _time = handleTimeChange(value, values.date)

            if (_time) {
              handleChange({
                target: { name: 'time', value: JSON.stringify(_time) },
              })
            } else {
              handleChange({
                target: { name: 'time', value: values.time },
              })
            }
          }}
          valueLabelDisplay="on"
          valueLabelFormat={formatLabel}
          marks={marks}
          name="time"
        />
      </FormControl>

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            onClick={prevStep}
          >
            {translate(
              'c-client-dashboard.make-appointment.step-date.label-back'
            )}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {translate(
              'c-client-dashboard.make-appointment.step-date.label-continue'
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default StepDate
