import { useMutation } from 'react-query'
import {
  createBankAccount,
  disableBankAccount,
} from 'services/bankAccountService'

export default function useBankAccountMutations() {
  const disableAccountMutation = useMutation({
    mutationFn: disableBankAccount,
  })

  const createAccountMutation = useMutation({
    mutationFn: createBankAccount,
  })

  return {
    disableBankAccount: disableAccountMutation,
    createBankAccount: createAccountMutation,
  }
}
