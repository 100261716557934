import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMobileVersion } from '../../../hooks/use-mobile-version'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },

  title: {
    display: 'flex',
    alignItems: 'center',
  },

  info: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },

  closeIcon: {
    marginLeft: theme.spacing(2),
    color: '#B6B6B6',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  description: {
    whiteSpace: 'normal',
  },
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.2)',
  },
  mobile: {
    width: '90%',
    margin: '0 auto',
  },
  desktop: {
    width: '50%',
    minWidth: '500px',
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
}))

const LogoutNotification = React.forwardRef(
  ({ payload, closeNotification, config }, ref) => {
    const { auth, router } = config
    const classes = useStyles()
    const isMobile = useMobileVersion()

    useEffect(() => {
      const timer = setTimeout(() => {
        auth.signout()
        router.push('/')
        closeNotification()
      }, 3000)

      return () => clearTimeout(timer)
    }, [auth, router, closeNotification])

    return (
      <Box
        ref={ref}
        className={`${classes.menuItem} ${
          isMobile ? classes.mobile : classes.desktop
        }`}
      >
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Box>{payload.title}</Box>
          </Box>
        </Box>
        <Box className={classes.description}>{payload.body}</Box>
      </Box>
    )
  }
)

LogoutNotification.propTypes = {
  payload: PropTypes.object.isRequired,
}
export default LogoutNotification
