import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

function BoxSkeleton() {
  return (
    <Box style={{ padding: '30px 0' }}>
      {/* Select Lists */}
      <Skeleton
        style={{ marginBottom: '2em' }}
        variant="rect"
        width="100%"
        height="3em"
      />

      {/* Inputs */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2em'
        }}
      >
        <Skeleton variant="rect" width="49%" height="3em" />
        <Skeleton variant="rect" width="49%" height="3em" />
      </Box>

      {/* Inputs */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2em'
        }}
      >
        <Skeleton variant="rect" width="49%" height="3em" />
        <Skeleton variant="rect" width="49%" height="3em" />
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2em'
        }}
      >
        {/* Select Lists */}
        <Skeleton
          style={{ marginBottom: '2em' }}
          variant="rect"
          width="89%"
          height="3em"
        />

        <Skeleton
          style={{ marginBottom: '2em' }}
          variant="rect"
          width="9%"
          height="3em"
        />
      </Box>

      {/* Buttons */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2em'
        }}
      >
        <Skeleton variant="rect" width="10em" height="3em" />
        <Skeleton variant="rect" width="10em" height="3em" />
      </Box>
    </Box>
  )
}

export default BoxSkeleton
