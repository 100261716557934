// Libraries
import React from 'react'
import has from 'lodash/has'
// Components
import BtnLoginSocial from '../index'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useRouter } from '../../../hooks/use-router'
import { useTranslation } from '../../../hooks/use-translation'
// Utils
import { analytics } from '../../../utils/firebase'

const BtnLoginApple = () => {
  const auth = useAuth()
  const router = useRouter()
  const { translate } = useTranslation()

  let channelType, channelId

  if (has(router.query, 'channelType') && has(router.query, 'channelId')) {
    channelType = router.query.channelType
    channelId = router.query.channelId
  }

  return (
    <BtnLoginSocial
      text={translate('c-btn-login-social.title-apple')}
      backgroundColor="#000"
      textColor="#fff"
      iconSrc="/images/apple_logo.svg"
      onClick={async () => {
        await auth.signinWithApple(channelType, channelId)
        router.push('/')
        analytics.logEvent('vhod_apple')
      }}
      style={{
        borderRadius: 6,
      }}
    />
  )
}

export default BtnLoginApple
