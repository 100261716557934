import React from 'react'
import { Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '../../../hooks/use-translation'
import BrokerNavigation from '../../../components/BrokerNavigation'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  message: {
    color: '#D42F42',
    fontSize: theme.typography.header3.fontSize,
    marginBottom: theme.spacing(4),
  },
}))

const BrokerHome = () => {
  const classes = useStyles()
  const { translate } = useTranslation()

  return (
    <Container className={classes.root} maxWidth="lg">
      <Typography className={classes.message}>
        {translate('home-broker.title')}
      </Typography>

      <BrokerNavigation />
    </Container>
  )
}

export default BrokerHome
