import { Box, Typography } from '@material-ui/core'
import { Facebook, LinkedIn } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from '../../hooks/use-translation'
import { isMobile as isCapacitor, linkers } from '../../mobile'
import ChangeLanguage from '../ChangeLanguage'
import s from './s.module.css'

const Footer = () => {
  const { translate } = useTranslation()

  return (
    <footer className={s.container}>
      <Box className={`${s.grid} ${isCapacitor ? s['capacitor'] : null}`}>
        {isCapacitor && <ChangeLanguage />}
        <Box>
          <a
            className={s.link}
            href="https://www.bulstradlife.bg/personal-data-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('c-footer.personal-data-policy')}
          </a>
          <a
            className={s.link}
            href="https://www.bulstradlife.bg/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('c-footer.privacy-policy')}
          </a>
        </Box>
        <Box>
          <a
            className={s.link}
            href="https://www.bulstradlife.bg/storage_files/Uslovia_za_polzvane_B-Assist-4666.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('c-footer.terms-portal')}
          </a>
          <a
            className={s.link}
            href="https://www.bulstradlife.bg/storage_files/Pravila_za_predostiaviane_na_uslugi-2084.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('c-footer.terms-remote')}
          </a>
          {isCapacitor && (
            <a className={s.link} href="/about">
              {translate('about.menu-label')}
            </a>
          )}
        </Box>
        {!isCapacitor && (
          <>
            <Box>
              <a
                className={s.link}
                href="https://www.bulstradlife.bg"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate('c-footer.learn-more')}
              </a>

              <p className={s.link}>{translate('c-footer.discover-social')}</p>

              <Box className={s['icons-container']}>
                <a
                  className={s.link}
                  href="https://www.facebook.com/BulstradLife/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook fontSize="large" />
                </a>
                <a
                  className={s.link}
                  href="https://www.linkedin.com/company/bulstrad-life/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn fontSize="large" />
                </a>
              </Box>
            </Box>

            <Box className={`${s.link} ${s['language-container']}`}>
              <p>{translate('c-footer.label-lang')}</p>
              <ChangeLanguage />
            </Box>
          </>
        )}
      </Box>

      <Typography style={{ fontWeight: 300 }} className={s.text}>
        &copy; {translate('c-footer.copyright')} {new Date().getFullYear()}{' '}
        {linkers.portalVersion.value && (
          <>
            <br /> <small>v.{linkers.portalVersion.value}</small>
          </>
        )}
      </Typography>
    </footer>
  )
}

export default Footer
