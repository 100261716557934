import React, { Fragment, useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
import { useTranslation } from '../../../../hooks/use-translation'
import ArchiveSkeleton from './Skeleton'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  specialist: {
    fontSize: '0.875rem',
  },
  italic: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontStyle: 'italic',
  },
  // On mobile
  headers: {
    '& > span': {
      fontSize: '0.95rem',
    },

    '& > p': {
      fontSize: '0.8rem',
    },
  },

  mobileDate: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  date: {
    textAlign: 'right',
  },
  message: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
})

const labelDisplayedRows = ({
  from,
  to,
  count,
  diplayRowFromLabel,
  displayRowMoreThanLabel,
}) => {
  return `${from}-${to === -1 ? count : to} ${diplayRowFromLabel} ${
    count !== -1 ? count : `${displayRowMoreThanLabel} ${to}`
  }`
}

function Archive({ archivedAppointments }) {
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()
  const [page, setPage] = useState(0)

  const diplayRowFromLabel = translate(
    'c-client-dashboard.make-appointment.archive.display-row-from-label'
  )
  const displayRowMoreThanLabel = translate(
    'c-client-dashboard.make-appointment.archive.display-row-more-than-label'
  )

  const rowsPerPage = 5

  const renderAppointments = () => {
    let startIndex = page === 0 ? 0 : page * rowsPerPage
    let endIndex = page === 0 ? rowsPerPage : startIndex + rowsPerPage

    let filtered =
      archivedAppointments &&
      archivedAppointments.filter(
        (appointment, idx) => idx >= startIndex && idx < endIndex
      )

    return filtered.map((appointment) => {
      return (
        <Fragment key={appointment.caseId}>
          <ListItem className={classes.listItem}>
            <div>
              <ListItemText
                className={isMobile ? classes.headers : ''}
                primary={
                  appointment.speciality
                    ? appointment.speciality
                    : appointment.requestedSpeciality
                }
                secondary={
                  <span>
                    {appointment.city
                      ? appointment.city
                      : appointment.requestedCity}
                    {(appointment.hospital ||
                      appointment.requestedHospital) && (
                      <span>
                        ,{' '}
                        {appointment.hospital
                          ? appointment.hospital
                          : appointment.requestedHospital}
                      </span>
                    )}
                  </span>
                }
              />

              <Typography
                className={`${classes.specialist} ${
                  appointment.status === 'Customer rejected' && classes.italic
                }`}
              >
                {appointment.status === 'Customer rejected'
                  ? translate(
                      'c-client-dashboard.make-appointment.archive.rejected'
                    )
                  : appointment.specialistName}
              </Typography>
            </div>

            <div className={isMobile ? classes.mobileDate : classes.date}>
              {moment(
                appointment.approvedTimeDate
                  ? appointment.approvedTimeDate
                  : appointment.requestedDate
              ).format('DD MMMM YYYY')}
            </div>
          </ListItem>
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      {archivedAppointments ? (
        <Fragment>
          <List className={classes.root}>
            {archivedAppointments.length === 0 ? (
              <p className={classes.message}>
                {translate('c-client-dashboard.make-appointment.archive.empty')}
              </p>
            ) : (
              renderAppointments()
            )}
          </List>
          {archivedAppointments.length > 0 && (
            <TablePagination
              component="nav"
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              count={archivedAppointments.length}
              onChangePage={(e, newPage) => setPage(newPage)}
              labelDisplayedRows={({ from, to, count }) =>
                labelDisplayedRows({
                  from,
                  to,
                  count,
                  diplayRowFromLabel,
                  displayRowMoreThanLabel,
                })
              }
            />
          )}
        </Fragment>
      ) : (
        <ArchiveSkeleton />
      )}
    </Fragment>
  )
}

export default Archive
