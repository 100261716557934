// Libraries
import React, { useState, Fragment } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
// Components
import StepFields from './StepFields'
import StepMarketing from './StepMarketing'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useForm } from '../../../hooks/use-form'
import { useRouter } from '../../../hooks/use-router'
import { useTranslation } from '../../../hooks/use-translation'
import { useRegisterSubmit } from '../../../hooks/use-submit'
import { useMobileVersion } from '../../../hooks/use-mobile-version'
// Form Validators
import validateRegister from '../../../formValidators/register'

const INITIAL_FORM_STATE = {
  // personalNumber: '',
  phoneNumber: '',
  names: '',
  email: '',
  password: '',
  rePassword: '',
  gdpr: false,
  marketing: false,
  privacy: false,
  step: 0,
}

const RegisterForm = () => {
  const auth = useAuth()
  const router = useRouter()
  const { translate } = useTranslation()
  const isMobile = useMobileVersion()
  const [isOnStepMarketing, setIsOnStepMarketing] = useState(false)

  const {
    values,
    errors,
    setErrors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = useForm(
    INITIAL_FORM_STATE,
    validateRegister,
    useRegisterSubmit,
    auth,
    router,
    'async'
  )

  const handleChangeStep = () => {
    const errors = validateRegister(values)

    if (!isEmpty(errors)) {
      setErrors(errors)

      return
    }

    handleChange({ target: { name: 'step', value: values.step + 1 } })
    setErrors({})
  }

  return (
    <form onSubmit={handleSubmit}>
      {values.step === 0 && (
        <StepFields
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
        />
      )}

      {values.step === 1 && (
        <StepMarketing
          isOnStepMarketing={isOnStepMarketing}
          setIsOnStepMarketing={setIsOnStepMarketing}
        />
      )}

      {values.step === 2 && (
        <Typography style={{ marginBottom: '1em' }}>
          {translate('register.step-message.description')}
        </Typography>
      )}

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent={
          values.step === 3 || values.step === 0 ? 'flex-end' : 'space-between'
        }
      >
        {values.step > 0 && (
          <Button
            style={
              isMobile
                ? { marginBottom: '1.5em', width: '100%' }
                : { marginRight: '1em' }
            }
            variant="outlined"
            color="primary"
            type="button"
            onClick={() =>
              handleChange({ target: { name: 'step', value: values.step - 1 } })
            }
          >
            {translate('c-form.register-form.back')}
          </Button>
        )}

        <ButtonGroup style={isMobile ? { width: '100%' } : {}}>
          {(values.step === 0 || values.step === 1) && (
            <Button
              style={isMobile ? { width: '100%' } : {}}
              variant="outlined"
              color="primary"
              type="button"
              onClick={handleChangeStep}
            >
              {translate('c-form.register-form.other-choice')}
            </Button>
          )}

          <Button
            style={isMobile ? { width: '100%' } : {}}
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size={25} thickness={2.7} color="primary" />
            ) : (
              <Fragment>
                {values.step === 0 &&
                  translate('c-form.register-form.accept-all')}
                {values.step === 1 && translate('c-form.register-form.agree')}
                {values.step === 2 && translate('c-form.register-form.confirm')}
              </Fragment>
            )}
          </Button>
        </ButtonGroup>
      </Box>
    </form>
  )
}

export default RegisterForm
