// Libraries
import React, { useEffect, Fragment, useMemo, useState } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  Grid,
  Button,
  IconButton,
  FormHelperText,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import { AddBoxOutlined } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment/moment'
import { useSnackbar } from 'notistack'
import has from 'lodash/has'
import CreateBankAccountModal from 'components/ClientDashboard/User/Bank/CreateBankAccountModal'
import { usePersistentForm } from 'hooks/use-persistent-form'
import { useAuth } from 'hooks/use-auth'
import { useTranslation } from 'hooks/use-translation'
import { reimbursementValidateStepDetails } from 'formValidators/reimbursement'
import { LANG_BG, LANG_EN } from 'config/languageTypes'
import { sumRegex } from 'utils/regex'
import useBankAccounts, {
  BANK_ACCOUNTS_QUERY_KEY,
} from 'hooks/BankAccount/useBankAccounts'
import useBankAccountMutations from 'hooks/BankAccount/useBankAccountMutations'
import { useQueryClient } from 'react-query'
import { useReimbursementStructure } from 'pages/Reimbursement/hooks/useReimbursementStructure'

const StepDetails = ({ files, nextStep }) => {
  const snackbar = useSnackbar()
  const { activeUser } = useAuth()
  const { translate, activeLanguage } = useTranslation()
  const queryClient = useQueryClient()
  const [isCreateBankAccountModalOpen, setIsCreateBankAccountModalOpen] =
    useState(false)

  const { createBankAccount } = useBankAccountMutations()

  const { data: bankAccounts, isError: isBankAccountsError } = useBankAccounts(
    activeUser.clientId
  )

  const { data: services } = useReimbursementStructure()

  const activeBankAccounts = useMemo(() => {
    if (!bankAccounts) return []

    return bankAccounts?.filter((acc) => acc.deactivated === 'false')
  }, [bankAccounts])

  const { values, errors, handleChange, validate } =
    usePersistentForm('reimbursement')

  const handleSubmit = (e) => {
    e.preventDefault()
    // validate form step
    const hasErrors = validate(reimbursementValidateStepDetails)
    // check for errors
    if (hasErrors) {
      return
    }
    // go to next step if we pass the validation
    nextStep()
  }

  const dateInvoice = (day) => {
    return day.isBefore(moment(values.dateEvent).subtract(1, 'day')) ||
      day.isAfter(moment().add(1))
      ? true
      : false
  }

  const dateEvent = (day) => {
    return day.isAfter(moment().add(1))
  }

  const handleSumChange = (e) => {
    const sum = e.target.value

    // validate and allow only numbers, dot and a commas with no more than 2 decimal places
    if (sum.match(sumRegex)) {
      handleChange({ target: { name: 'sum', value: sum.replace(/,/g, '.') } })
      return
    }
  }

  const createBankAccountHandler = async (nameMobile, iban) => {
    await createBankAccount.mutateAsync(
      {
        clientId: activeUser.clientId,
        nameMobile,
        iban,
      },
      {
        onSuccess: async (data) => {
          await queryClient.invalidateQueries(BANK_ACCOUNTS_QUERY_KEY)

          snackbar.enqueueSnackbar(data?.message, {
            variant: 'success',
          })
        },
        onError: (error) => {
          snackbar.enqueueSnackbar(error?.response?.data?.message, {
            variant: 'error',
          })
        },
      }
    )
  }

  useEffect(() => {
    if (
      activeBankAccounts &&
      activeBankAccounts?.length > 0 &&
      !isBankAccountsError &&
      !values?.bankAccount
    ) {
      handleChange({
        target: { name: 'bankAccount', value: activeBankAccounts[0]?.iban },
      })
    }
    // eslint-disable-next-line
  }, [activeBankAccounts, isBankAccountsError, values?.bankAccount])

  useEffect(() => {
    if (has(errors, 'files')) {
      snackbar.enqueueSnackbar(errors.files, {
        variant: 'error',
        preventDuplicate: true,
      })
    }

    // eslint-disable-next-line
  }, [errors])

  // Set default service values
  useEffect(() => {
    if (!values.service && services?.length > 0) {
      handleChange({
        target: { name: 'service', value: JSON.stringify(services[0]) },
      })
    }
    // eslint-disable-next-line
  }, [services, values.services])

  return (
    <form
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      onSubmit={handleSubmit}
    >
      <FormControl style={{ marginBottom: '2em' }}>
        <InputLabel htmlFor="service">
          {translate('c-client-dashboard.reimbursement.step-details.service')}
        </InputLabel>
        <Select
          native
          value={values.service}
          labelWidth={37}
          onChange={handleChange}
          name="service"
          error={errors && errors.service ? true : false}
        >
          {services.map((service) => (
            <option key={service.id} value={JSON.stringify(service)}>
              {activeLanguage === LANG_BG && service.title}
              {activeLanguage === LANG_EN && service.title_en}
            </option>
          ))}
        </Select>
        <FormHelperText>{errors && errors.service}</FormHelperText>
      </FormControl>

      <div
        style={{
          display: 'grid',
          gap: '1em',
          gridTemplateColumns: '1fr 1fr',
          marginBottom: '2em',
        }}
        className="grid-m-c-1"
      >
        {/* Date event field */}
        <FormControl error={errors && errors.dateEvent ? true : false}>
          <KeyboardDatePicker
            name="dateEvent"
            format="DD/MM/YYYY"
            invalidDateMessage={
              <span style={{ color: '#D42F42' }}>
                {translate(
                  'c-client-dashboard.reimbursement.step-details.info-date-fmt'
                )}
              </span>
            }
            label={translate(
              'c-client-dashboard.reimbursement.step-details.event-date'
            )}
            value={values.dateEvent}
            onChange={(e) =>
              handleChange(
                { target: { name: 'dateEvent', value: e } },
                { dateInvoice: e }
              )
            }
            error={errors && errors.dateEvent ? true : false}
            shouldDisableDate={dateEvent}
          />
          {errors && errors.dateEvent && (
            <FormHelperText>{errors.dateEvent}</FormHelperText>
          )}
        </FormControl>

        {/* Date invoice field */}
        <FormControl error={errors && errors.dateInvoice ? true : false}>
          <KeyboardDatePicker
            name="dateInvoice"
            label={translate(
              'c-client-dashboard.reimbursement.step-details.invoice-date'
            )}
            format="DD/MM/YYYY"
            invalidDateMessage={
              <span style={{ color: '#D42F42' }}>
                {translate(
                  'c-client-dashboard.reimbursement.step-details.info-date-fmt'
                )}
              </span>
            }
            value={values.dateInvoice}
            onChange={(e) =>
              handleChange({ target: { name: 'dateInvoice', value: e } })
            }
            shouldDisableDate={dateInvoice}
            error={errors && errors.dateInvoice ? true : false}
          />
          {errors && errors.dateInvoice && (
            <FormHelperText>{errors.dateInvoice}</FormHelperText>
          )}
        </FormControl>
      </div>

      <div
        style={{
          display: 'grid',
          gap: '1em',
          gridTemplateColumns: '1fr 1fr',
          marginBottom: '2em',
        }}
        className="grid-m-c-1"
      >
        {/* Invoice number field */}
        <TextField
          name="invoiceNumber"
          label={translate(
            'c-client-dashboard.reimbursement.step-details.invoice-number'
          )}
          type="number"
          value={values.invoiceNumber}
          onChange={handleChange}
          error={errors && errors.invoiceNumber ? true : false}
          helperText={errors && errors.invoiceNumber}
        />
        {/* ! Invoice number field */}

        {/* Sum field */}
        <TextField
          name="sum"
          label={translate('c-client-dashboard.reimbursement.step-details.sum')}
          type="text"
          value={values.sum}
          onChange={handleSumChange}
          error={errors && errors.sum ? true : false}
          helperText={errors && errors.sum}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {translate(
                  'c-client-dashboard.reimbursement.step-details.sum-currency'
                )}
              </InputAdornment>
            ),
          }}
        />
        {/* ! Sum field */}
      </div>

      <div
        style={{
          display: 'grid',
          gap: '2em',
          gridTemplateColumns: '89% 9%',
          marginBottom: '2em',
        }}
      >
        {/* Bank Account field start */}
        <FormControl style={{ marginBottom: '2em' }}>
          <InputLabel shrink={!!values.bankAccount} htmlFor="bankAccount">
            {translate(
              'c-client-dashboard.reimbursement.step-details.bank-acc'
            )}
          </InputLabel>
          <Select
            native
            value={values.bankAccount}
            labelWidth={37}
            onChange={handleChange}
            name="bankAccount"
            disabled={activeBankAccounts?.length === 0 || isBankAccountsError}
            error={errors && errors.bankAccount}
          >
            {activeBankAccounts.map((account) => (
              <option key={account.iban} value={account.iban}>
                IBAN: {account.iban}
              </option>
            ))}
          </Select>
          <FormHelperText>{errors && errors.bankAccount}</FormHelperText>
        </FormControl>
        {/* Bank Account field end */}

        {/* Add Bank Account Button */}
        {activeUser.role === 'parent' && (
          <Fragment>
            <IconButton
              style={{
                padding: '0',
                height: '2em',
                width: '2em',
                top: '0.5em',
                right: '0em',
                justifySelf: 'flex-end',
              }}
              id="addAccountButton"
              onClick={() => setIsCreateBankAccountModalOpen(true)}
              disabled={createBankAccount.isLoading}
            >
              {createBankAccount.isLoading ? (
                <CircularProgress size={25} thickness={2.7} color="primary" />
              ) : (
                <AddBoxOutlined fontSize="large" />
              )}
            </IconButton>

            <CreateBankAccountModal
              open={isCreateBankAccountModalOpen}
              setOpen={setIsCreateBankAccountModalOpen}
              callback={createBankAccountHandler}
            />
          </Fragment>
        )}
      </div>

      <Grid container style={{ marginTop: 'auto' }}>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to="/"
          >
            {translate('c-client-dashboard.reimbursement.step-details.reject')}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {translate('c-client-dashboard.reimbursement.step-details.next')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default StepDetails
