import React from 'react'
import { useAuth } from '../../hooks/use-auth'
import { Navigate } from 'react-router-dom-v5-compat'

export default function PublicRoute(props) {
  const { user } = useAuth()

  if (user) {
    return <Navigate to="/" />
  }

  return props?.children
}
