import React, { createContext, useContext } from 'react'
import { useImmer } from 'use-immer'

const FormStoreContext = createContext()

function FormStoreProvider({ children, initialState = {} }) {
  const contextValue = useProvider(initialState)

  return (
    <FormStoreContext.Provider value={contextValue}>
      {children}
    </FormStoreContext.Provider>
  )
}

const useFormStore = () => useContext(FormStoreContext)

const useClearForm = (name) => {
  // eslint-disable-next-line
  const [formStore, setFormStore] = useFormStore()

  const clear = () =>
    setFormStore((draftState) => {
      draftState[name] = undefined
    })

  return { clear }
}

const useProvider = (initialState) => {
  const [formStore, setFormStore] = useImmer(initialState)

  return [formStore, setFormStore]
}

export { FormStoreProvider, useFormStore, useClearForm }
