// Libraries
import React, { useState } from 'react'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { AccessTime, Room, LocalHospital } from '@material-ui/icons'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'
// Services
import { changeTicketStatus } from '../../../../services/makeAppointmentService'
// Config
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'

const getFieldValue = (activeLanguage, data, field) => {
  let value = ''

  let fieldBg = `${field}BG`
  let fieldEn = `${field}EN`
  let capitalizeField = field[0].toUpperCase() + field.slice(1)

  if (activeLanguage === LANG_BG) {
    data[fieldBg].length > 0
      ? (value = data[fieldBg])
      : (value = data[`requested${capitalizeField}`])
  } else if (activeLanguage === LANG_EN) {
    data[fieldEn].length > 0
      ? (value = data[fieldEn])
      : (value = data[`requested${capitalizeField}`])
  }

  return value
}

const InProgress = ({ classes, data, closeNotification, config }) => {
  const notistack = useSnackbar()
  const { auth, router } = config
  const { activeLanguage } = useTranslation()
  const [isSubmittingReject, setIsSubmittingReject] = useState(false)
  const [isSubmittingConfirm, setIsSubmittingConfirm] = useState(false)
  const { translate } = useTranslation()

  let city = getFieldValue(activeLanguage, data, 'city')
  let hospital = getFieldValue(activeLanguage, data, 'hospital')
  let speciality = getFieldValue(activeLanguage, data, 'speciality')

  const handleTicketStatus = async (status) => {
    try {
      const res = await changeTicketStatus(
        {
          clientId: auth.activeUser.clientId,
          caseId: data.caseId,
          status,
        },
        auth
      )

      setIsSubmittingConfirm(false)
      setIsSubmittingReject(false)

      notistack.enqueueSnackbar(res.message, { variant: 'success' })
      closeNotification()

      let tab = status === 'Cancel' ? 1 : 0

      router.push('/make-appointment', { tab })
    } catch (e) {
      setIsSubmittingConfirm(false)
      setIsSubmittingReject(false)

      notistack.enqueueSnackbar(e.message, { variant: 'success' })
      console.error('Error during Status Update: ', e)
    }
  }

  return (
    <Box className={`${classes.marginTop} ${classes.inProgressContainer}`}>
      <Box className={classes.dateAndPlace}>
        <AccessTime className={classes.icon} />
        <span>{moment(data.approvedTimeDate).format('LLLL')}</span>
      </Box>

      <Box className={classes.dateAndPlace}>
        <Room className={classes.icon} />
        <span>
          {city}, {hospital}
        </span>
      </Box>

      <Box className={classes.dateAndPlace}>
        <LocalHospital className={classes.icon} />
        <span>{speciality}</span>
      </Box>

      <Box className={classes.marginTop}>
        <Button
          className={classes.cancelButton}
          disabled={isSubmittingConfirm || isSubmittingReject}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => {
            setIsSubmittingReject(true)
            handleTicketStatus('Cancel')
          }}
        >
          {isSubmittingReject ? (
            <CircularProgress size={25} thickness={2.7} color="primary" />
          ) : (
            translate('c-notifications.appointment.in-progress.reject')
          )}
        </Button>

        <Button
          disabled={isSubmittingConfirm || isSubmittingReject}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            setIsSubmittingConfirm(true)
            handleTicketStatus('Closed')
          }}
        >
          {isSubmittingConfirm ? (
            <CircularProgress size={25} thickness={2.7} color="primary" />
          ) : (
            translate('c-notifications.appointment.in-progress.confirm')
          )}
        </Button>
      </Box>
    </Box>
  )
}

export default InProgress
