import ApiClient from 'infrastructure/api/ApiClient'
import { analytics } from 'utils/firebase'

export const loadBankAccounts = async (clientId) => {
  return ApiClient.get('/getBankAccs', {
    params: { clientId },
  }).then((response) => response.data)
}

export const createBankAccount = async ({ clientId, nameMobile, iban }) => {
  analytics.logEvent('profile_add_bank_account')

  return ApiClient.post('/createBankAcc', {
    clientId,
    nameMobile,
    iban,
    currencyStr: 'BGN',
  }).then((response) => response.data)
}

export const disableBankAccount = async ({ clientId, iban }) => {
  analytics.logEvent('profile_close_bank_account')

  return ApiClient.post('/updBankAcc', {
    clientId,
    iban,
    deactivated: 'true',
  })
    .then((response) => response.data)
    .catch((e) => e.response.data)
}
