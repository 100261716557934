// Libraries
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
// Hooks
import { useTranslation } from '../../../hooks/use-translation'
// Images
import Marker from './marker'
import useGeolocation from '../../../hooks/useGeolocation'
import { isMobile as isCapacitor } from '../../../mobile'
import UserPosition from '../../UserPosition'

function FacilitiesMap({ markers }) {
  const { activeLanguage } = useTranslation()
  const userPosition = useGeolocation()

  const defaultCenter = {
    lat: userPosition?.latitude || 42.69917,
    lng: userPosition?.longitude || 23.3225,
  }

  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)

  const handleApiLoaded = (map, maps) => {
    setMap(map)
    setMaps(maps)
  }

  useEffect(() => {
    if (map && maps && markers.length > 0) {
      const bounds = new maps.LatLngBounds()

      for (const marker of markers) {
        const position = new maps.LatLng(marker.latitude, marker.longitude)
        bounds.extend(position)
      }

      if (isCapacitor && userPosition) {
        const newPosition = new maps.LatLng(
          userPosition.latitude,
          userPosition.longitude
        )
        bounds.extend(newPosition)
      }

      map.fitBounds(bounds)
    }
  }, [markers, maps, map, userPosition])

  useEffect(() => {
    if (isCapacitor && userPosition && map && maps) {
      map.panTo(new maps.LatLng(userPosition.latitude, userPosition.longitude))
      map.setZoom(14)
    }
  }, [maps, map, userPosition])

  return (
    // Important! Always set the container height explicitly
    <div
      style={{
        border: '0',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        minHeight: '250px',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GMAPS_API_KEY,
          v: '3.32',
          language: activeLanguage,
        }}
        defaultCenter={defaultCenter}
        defaultZoom={12}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {markers.length > 0 &&
          markers.map(({ latitude, longitude, name, hospitalId, address }) => {
            return (
              <Marker
                key={hospitalId}
                lat={latitude}
                lng={longitude}
                text={name}
                address={address}
              />
            )
          })}
        {isCapacitor && userPosition && (
          <UserPosition
            lat={userPosition?.latitude}
            lng={userPosition?.longitude}
          />
        )}
      </GoogleMapReact>
    </div>
  )
}

FacilitiesMap.propTypes = {
  markers: PropTypes.array,
}

FacilitiesMap.defaultProps = {
  markers: [],
}

export default FacilitiesMap
