// Libraries
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Radio,
  RadioGroup,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useState } from 'react'
import has from 'lodash/has'
// import isEmpty from 'lodash/isEmpty'
// PhoneInputField
import PhoneInputField from '../../PhoneInputField'
// Hooks
import { useForm } from '../../../hooks/use-form'
import { useAuth } from '../../../hooks/use-auth'
import { useTranslation } from '../../../hooks/use-translation'
// Utils
import { analytics } from '../../../utils/firebase'
// Form Validators
import { connectWithHealthCareValidate } from '../../../formValidators/connectWithHealthCare'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  radio: {
    marginTop: theme.spacing(2),
    marginBottom: -theme.spacing(1),
  },
  datepicker: {
    marginTop: 8,
    paddingTop: 12,
  },
  error: {
    color: theme.palette.primary.main,
  },
}))

function ConnectWithHealthCare({
  open,
  setOpen,
  connectWithHealthCareHandler,
  isSubmitting,
  setIsSubmitting,
}) {
  const auth = useAuth()
  const classes = useStyles()

  const [errors, setErrors] = useState({})

  const { translate } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  const INITIAL_FORM_STATE = {
    names: '',
    email: '',
    phoneNumber: '',
    cardNumber: '',
    personalNumber: '',
    radioChoice: 'personalNumber',
    dateOfBirth: new Date(),
    gdpr: false,
  }

  const { values, handleChange, reset } = useForm(
    INITIAL_FORM_STATE,
    () => {},
    () => {},
    auth,
    null,
    'async'
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validationErrors = connectWithHealthCareValidate(values)

    setErrors(validationErrors)

    const canSubmit = Object.keys(validationErrors).length === 0

    try {
      if (canSubmit) {
        setIsSubmitting(true)

        analytics.logEvent('connect_ZG_submit')

        const payload = {
          names: values.names,
          email: values.email,
          phoneNumber: values.phoneNumber,
          cardNumber: values.cardNumber,
          ...(values.radioChoice === 'personalNumber'
            ? { personalNumber: values.personalNumber }
            : {
                dateOfBirth: moment(values.dateOfBirth).format('DD.MM.YYYY'),
              }),
        }

        await connectWithHealthCareHandler(payload)

        reset({
          names: '',
          email: '',
          phoneNumber: '',
          personalNumber: '',
          radioChoice: 'personalNumber',
          dateOfBirth: new Date(),
          cardNumber: '',
          gdpr: false,
        })
      }
    } catch (e) {
      console.error(e)
      setIsSubmitting(false)
    }
  }

  return (
    <form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {translate('c-form.connect-with-health-care.info')}
          </DialogContentText>
          <FormControl>
            <TextField
              margin="dense"
              name="names"
              label={translate('c-form.connect-with-health-care.names')}
              type="text"
              error={errors && errors.names ? true : false}
              helperText={errors && errors.names}
              value={values.names}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <div style={{ height: '10px' }}></div>

          <FormControl>
            <TextField
              margin="dense"
              name="email"
              label={translate('c-form.connect-with-health-care.email')}
              type="text"
              error={errors && errors.email ? true : false}
              helperText={errors && errors.email}
              value={values.email}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <div style={{ height: '10px' }}></div>

          <FormControl>
            <PhoneInputField
              variant="standart"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={handleChange}
              errorMessage={errors && errors.phoneNumber}
              placeholder={translate('c-form.connect-with-health-care.phone')}
            />
          </FormControl>

          <div style={{ height: '10px' }}></div>

          <FormControl>
            <TextField
              margin="dense"
              name="cardNumber"
              label={translate('c-form.connect-with-health-care.cardNumber')}
              type="text"
              error={errors && errors.cardNumber ? true : false}
              helperText={errors && errors.cardNumber}
              value={values.cardNumber}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <div style={{ height: '10px' }}></div>

          <FormControl component="fieldset" className={classes.radio}>
            <RadioGroup
              onChange={handleChange}
              name="radioChoice"
              value={values.radioChoice}
              row
            >
              <FormControlLabel
                value="personalNumber"
                control={<Radio />}
                label={translate('c-form.add-co-insured-person.egn')}
              />
              <FormControlLabel
                value="dateOfBirth"
                control={<Radio />}
                label={translate(
                  'c-form.add-co-insured-person.dateOfBirthLabel'
                )}
              />
            </RadioGroup>
          </FormControl>

          {values.radioChoice === 'personalNumber' && (
            <TextField
              margin="dense"
              name="personalNumber"
              label={translate('c-form.add-co-insured-person.egn')}
              type="text"
              error={errors && errors.personalNumber ? true : false}
              helperText={errors && errors.personalNumber}
              value={values.personalNumber}
              onChange={handleChange}
              fullWidth
            />
          )}

          {values.radioChoice === 'dateOfBirth' && (
            <FormControl error={errors && errors.dateOfBirth ? true : false}>
              <KeyboardDatePicker
                className={classes.datepicker}
                error={errors && errors.dateOfBirth ? true : false}
                name="dateOfBirth"
                format="YYYY-MM-DD"
                invalidDateMessage={null}
                value={values.dateOfBirth}
                onChange={(date) =>
                  handleChange({ target: { name: 'dateOfBirth', value: date } })
                }
                autoComplete="off"
                InputAdornmentProps={{ position: 'end' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors && errors.dateOfBirth && (
                <FormHelperText>{errors.dateOfBirth}</FormHelperText>
              )}
            </FormControl>
          )}

          <div style={{ height: '30px' }}></div>

          <FormControl error={has(errors, 'gdpr')}>
            <FormControlLabel
              style={{ margin: '0' }}
              control={
                <Checkbox
                  style={{ padding: '0 0.5em 0 0' }}
                  name="gdpr"
                  checked={values.gdpr}
                  onChange={(e) =>
                    handleChange({
                      target: { name: 'gdpr', value: e.target.checked },
                    })
                  }
                  value={values.gdpr}
                  color="primary"
                />
              }
              label={
                <div>
                  <span>
                    {translate('c-form.connect-with-health-care.agree') + ' '}
                  </span>
                  <a
                    href="https://www.bulstradlife.bg/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate(
                      'c-form.connect-with-health-care.privacy-policy'
                    )}
                  </a>
                </div>
              }
            />
            {errors && errors.gdpr && (
              <FormHelperText>{errors.gdpr}</FormHelperText>
            )}
          </FormControl>

          <div style={{ height: '20px' }}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('c-form.connect-with-health-care.close')}
          </Button>

          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <CircularProgress size={25} thickness={2.7} color="primary" />
            ) : (
              translate('c-form.connect-with-health-care.connect')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default ConnectWithHealthCare
