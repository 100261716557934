import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Badge } from '@material-ui/core'
import { analytics } from '../../../utils/firebase'
import { useTranslation } from '../../../hooks/use-translation'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'

export const AntTabs = withStyles((theme) => ({
  root: {
    marginBottom: '1em 0',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs)

export const useTabsStyle = makeStyles((theme) => ({
  tab: {
    opacity: 1,

    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },

    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },

    '&$selected': {
      color: theme.palette.primary.main,
    },
  },
}))

function AllTabs({
  tab,
  setTab,
  isFromSpecializedExamination,
  id,
  upToDateAppointmentsLength,
}) {
  const classes = useTabsStyle()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { translate } = useTranslation()

  const handleChange = (e, newValue, setTab, id) => {
    if (id) {
      navigate(-1)
    }

    setTab(newValue)

    // Register Events
    if (pathname === '/make-appointment' || pathname === '/reimbursements') {
      if (newValue === 0) {
        analytics.logEvent(
          pathname === '/make-appointment'
            ? 'ZG_pregledi_aktualni'
            : 'ZG_razhodi_aktualni'
        )
      } else if (newValue === 1) {
        analytics.logEvent(
          pathname === '/make-appointment'
            ? 'ZG_pregledi_arhiv'
            : 'ZG_razhodi_arhiv'
        )
      } else if (newValue === 2) {
        analytics.logEvent(
          pathname === '/make-appointment'
            ? 'ZG_pregledi_zaiavka'
            : 'ZG_razhodi_zaiavka'
        )
      }
    } else if (pathname === '/make-specialized-examination') {
      if (newValue === 0) {
        analytics.logEvent('ZG_izsledvania_arhiv')
      } else if (newValue === 1) {
        analytics.logEvent('ZG_izsledvania_zaiavka')
      }
    }
  }

  return (
    <AntTabs
      value={tab}
      onChange={(e, newValue) => handleChange(e, newValue, setTab, id)}
    >
      {!isFromSpecializedExamination && (
        <Tab
          className={classes.tab}
          disableRipple
          label={
            <Badge
              className={classes.badge}
              color="primary"
              badgeContent={upToDateAppointmentsLength}
              max={10}
            >
              {translate('c-client-dashboard.slider-tabs.latest')}
            </Badge>
          }
        />
      )}

      <Tab
        className={classes.tab}
        disableRipple
        label={translate('c-client-dashboard.slider-tabs.archive')}
      />
      <Tab
        className={classes.tab}
        disableRipple
        label={translate('c-client-dashboard.slider-tabs.request')}
      />
    </AntTabs>
  )
}

export default AllTabs
