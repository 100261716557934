import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const renderListElements = (listElementsCount, styles) => {
  const allElements = [];

  for (let i = 0; i < listElementsCount; i++) {
    allElements.push(
      <Box key={i} style={i === listElementsCount - 1 ? { ...styles, borderBottom: '2px solid rgba(0, 0, 0, 0.12)' } : styles}>
        <Skeleton variant="text" width="20%" />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant="text" width="5em" style={{ marginRight: '2em' }} />
          <Skeleton variant="text" width="5em" style={{ marginRight: '2em' }} />
          <Skeleton variant="circle" width="2em" height="2em" />
        </Box>
      </Box>
    )
  }

  return allElements
}

function SkeletonList() {
  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    padding: '1.7em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)'
  }

  const listElementsCount = 5;

  return (
    <Box style={{ width: '100%' }}>
      {renderListElements(listElementsCount, styles)}

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '1.7em 0'
        }}
      >
        <Skeleton variant="text" width="7%" style={{ marginRight: '2em' }} />
        <Skeleton
          variant="circle"
          width="2em"
          height="2em"
          style={{ marginRight: '1em' }}
        />
        <Skeleton variant="circle" width="2em" height="2em" />
      </Box>
    </Box>
  )
}

export default SkeletonList
