// Libraries
import React from 'react'
import {
  Box,
  ClickAwayListener,
  makeStyles,
  Tooltip,
  List,
  ListItem,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline',

    '& + .MuiTooltip-popper': {
      padding: theme.spacing(3),
      boxShadow: '0 0 0.3em 0 rgba(0, 0, 0, 0.3)',
      border: '#2A2E43',
      background: '#FFF',

      '@media (max-width: 375px)': {
        maxWidth: '365px',
      },

      '@media (max-width: 360px)': {
        maxWidth: '310px',
      },

      '& .MuiTooltip-tooltip': {
        margin: 0,
        padding: 0,
        background: '#FFF',
        color: '#2A2E43',

        '@media (max-width: 375px)': {
          maxWidth: '365px',
        },

        '@media (max-width: 360px)': {
          maxWidth: '310px',
        },
      },
    },
  },
}))

const ClickAwayTooltip = ({
  isOpenTooltip,
  setIsOpenTooltip,
  title,
  children,
}) => {
  const classes = useStyles()

  return (
    <ClickAwayListener onClickAway={() => setIsOpenTooltip(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setIsOpenTooltip(false)}
        open={isOpenTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
      >
        <Box className={classes.container}>{children}</Box>
      </Tooltip>
    </ClickAwayListener>
  )
}

const useTooltipTextStyles = makeStyles((theme) => ({
  descriptionList: {
    padding: 0,
  },
  descriptionListItem: {
    fontSize: '1rem',
    padding: '0.2em 0',
    '&:not(:last-child)': {
      paddingBottom: '0.3em',
    },
  },
}))

export const TooltipText = ({ insurances }) => {
  const classes = useTooltipTextStyles()

  return (
    <List className={classes.descriptionList}>
      {insurances.map((insurance) => {
        return (
          <ListItem className={classes.descriptionListItem} key={insurance}>
            {insurance}
          </ListItem>
        )
      })}
    </List>
  )
}

export default ClickAwayTooltip
