// Libraries
import React, { useState, useMemo, useEffect } from 'react'
import { Grid, Box } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
// Components
import CoverageList from '../../components/ClientDashboard/Coverage/CoverageList'
import CoverageItem from '../../components/ClientDashboard/Coverage/CoverageItem'
import SkeletonList from '../../components/ClientDashboard/Coverage/SkeletonList'
import SkeletonItem from '../../components/ClientDashboard/Coverage/SkeletonItem'
// Hooks
import { useAuth } from '../../hooks/use-auth'
// Services
import { loadCoverages } from '../../services/coverageService'

const CoveragesList = ({ pathname }) => {
  const auth = useAuth()
  const snackbar = useSnackbar()
  const [coverages, setCoverages] = useState([])
  const [currentCoverage, setCurrentCoverage] = useState({})

  const id = pathname?.split('/')[2]

  // Fetch Coverages
  useMemo(async () => {
    try {
      const { activeUser, activeUserCard } = auth
      const { clientId } = activeUser
      const { contractId, cardId } = activeUserCard

      const fetchedCoverages = await loadCoverages(
        clientId,
        auth,
        contractId,
        cardId
      )

      setCoverages(fetchedCoverages)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const filteredCoverage = coverages.filter(
      (coverage) => coverage.id === id
    )[0]

    setCurrentCoverage(filteredCoverage)

    // eslint-disable-next-line
  }, [coverages, id])

  return (
    <Box mt={4}>
      <Grid container justifyContent="center" className="grid-m-c-1">
        {!id &&
          (coverages.length > 0 ? (
            <CoverageList coverages={coverages} />
          ) : (
            <SkeletonList />
          ))}

        {id &&
          (!isEmpty(currentCoverage) ? (
            <CoverageItem {...currentCoverage} />
          ) : (
            <SkeletonItem />
          ))}
      </Grid>
    </Box>
  )
}

export default CoveragesList
