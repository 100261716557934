import React from 'react'
import { Box, Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

function SkeletonItem() {
  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    padding: '1.7em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',
  }

  return (
    <Box style={{ width: '100%' }}>
      <Skeleton variant="text" width="20%" style={{ marginBottom: '2em' }} />

      <Grid
        container
        justifyContent="space-between"
        className="grid-m-c-1"
        spacing={6}
      >
        <Grid item xs={12} md={6}>
          <Box
            style={{ padding: '2em', border: '2px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>
            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>

            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>

            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>

            <Box
              style={{
                ...styles,
                borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            style={{ padding: '2em', border: '2px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>
            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>

            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>

            <Box style={styles}>
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>

            <Box
              style={{
                ...styles,
                borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <Skeleton variant="text" width="40%" />
              <Skeleton variant="circle" width="2em" height="2em" />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SkeletonItem
