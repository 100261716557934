import React, { Suspense } from 'react'
import BackButtonListener from 'mobile/components/BackButtonListener'
import MsgTokenSenderNative from 'mobile/components/MsgTokenSenderNative'
import UrlListener from 'mobile/components/UrlListener'
import { isMobile as isCapacitor } from 'mobile'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import ScrollToTop from 'components/ScrollToTop'
import NotificationsNative from 'components/NotificationsNative'
import URLParamParser from 'components/URLParamParser'
import CookiesDialog from 'components/CookiesDialog'
import NewVersionDialog from 'components/NewVersionDialog'
import LoadingSpinner from 'components/LoadingSpinner'

const RootLayout = (props) => {
  return (
    <Suspense fallback={<LoadingSpinner global />}>
      <ScrollToTop />

      {isCapacitor && (
        <>
          {/* Listen to native back button */}
          <BackButtonListener />
          {/* Listen to capacitor deep links */}
          <UrlListener />
          {/* Listen to native messaging tokens */}
          <MsgTokenSenderNative />
          {/* Listen to native notifications */}
          <NotificationsNative />
        </>
      )}

      <URLParamParser />

      <Navbar />

      {props?.children}

      {!isCapacitor && <CookiesDialog />}

      {isCapacitor && <NewVersionDialog />}

      <Footer />
    </Suspense>
  )
}

export default RootLayout
