import { firebase } from '../utils/firebase'

export const getIdToken = () => {
  return firebase.auth().currentUser.getIdToken()
}

export const sendPasswordResetEmail = (email) =>
  firebase.auth().sendPasswordResetEmail(email)

export const getUserDetails = async () => {
  const { claims, signInProvider } = await firebase
    .auth()
    .currentUser.getIdTokenResult()

  return {
    claims,
    isClient: claims?.role === 'client',
    isBroker: claims?.role === 'broker',
    hasHealthCare: claims?.zg === 'true',
    clientId: claims?.clientId,
    signInProvider,
  }
}
