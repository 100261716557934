import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '60px 30px',
  },

  fileBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2em',
    background: '#F2F5F7',
    borderRadius: '0.5em',
    padding: '1em',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&:not(:last-child)': {
      marginRight: '1.5em',
    },
  },
  text: {
    marginBottom: '2em',
  },
}))

function BoxSkeletonImages({ hasTextEnabled }) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {/* File upload Container */}
      {hasTextEnabled && (
        <Skeleton
          variant="text"
          width="100%"
          height="3em"
          className={classes.text}
        />
      )}

      <Box className={classes.fileBoxContainer}>
        <Skeleton variant="text" width="25%" height="3em" />
        {/* Icons */}
        <Box className={classes.iconsContainer}>
          <Box className={classes.icon}>
            <Skeleton variant="circle" width="3em" height="3em" />
            <Skeleton variant="text" width="4em" height="2em" />
          </Box>

          <Box className={classes.icon}>
            <Skeleton variant="circle" width="3em" height="3em" />
            <Skeleton variant="text" width="4em" height="2em" />
          </Box>
        </Box>
      </Box>

      {/* File upload Container */}
      <Box className={classes.fileBoxContainer}>
        <Skeleton variant="text" width="25%" height="3em" />
        {/* Icons */}
        <Box className={classes.iconsContainer}>
          <Box className={classes.icon}>
            <Skeleton variant="circle" width="3em" height="3em" />
            <Skeleton variant="text" width="4em" height="2em" />
          </Box>

          <Box className={classes.icon}>
            <Skeleton variant="circle" width="3em" height="3em" />
            <Skeleton variant="text" width="4em" height="2em" />
          </Box>
        </Box>
      </Box>

      {/* File upload Container */}
      <Box className={classes.fileBoxContainer}>
        <Skeleton variant="text" width="25%" height="3em" />
        {/* Icons */}
        <Box className={classes.iconsContainer}>
          <Box className={classes.icon}>
            <Skeleton variant="circle" width="3em" height="3em" />
            <Skeleton variant="text" width="4em" height="2em" />
          </Box>

          <Box className={classes.icon}>
            <Skeleton variant="circle" width="3em" height="3em" />
            <Skeleton variant="text" width="4em" height="2em" />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BoxSkeletonImages
