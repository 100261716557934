// Libraries
import React from 'react'
import { Link } from 'react-router-dom-v5-compat'
import { Button, Typography, Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// Hooks
import { useTranslation } from '../../../hooks/use-translation'
// Utils
import { analytics } from '../../../utils/firebase'
import { phoneTabletMediaQuery } from '../../../config/mediaQueryTypes'

const useStyles = makeStyles((theme) => ({
  container: {},
  mobileContainer: {
    paddingTop: theme.spacing(4.5),
  },
  header: {
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase',
    color: '#2A2E43',
  },
  button: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    textTransform: 'none',
    fontSize: '1rem',
    background: '#F5F6F8',
    border: '2px solid #F5F6F8',
    borderRadius: '0',
    color: '#D42F42',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
}))

function BuyOnline() {
  const classes = useStyles()
  const { translate } = useTranslation()
  const isMatchesCustomPhoneTabletMediaQuery = useMediaQuery(
    phoneTabletMediaQuery
  )

  return (
    <Grid
      item
      xs={12}
      md={5}
      className={`${classes.container} ${
        isMatchesCustomPhoneTabletMediaQuery ? classes.mobileContainer : ''
      }`}
    >
      <Typography variant="h5" className={classes.header}>
        {translate('c-client-dashboard.buy-online.title')}
      </Typography>

      <Link
        to="/buy-travel-insurance"
        onClick={() => analytics.logEvent('buy-travel-insurance-dashboard')}
      >
        <Button fullWidth className={classes.button}>
          {translate('c-client-dashboard.buy-online.label-travel')}
        </Button>
      </Link>

      <Link
        to="/buy-multitrip-insurance"
        onClick={() => analytics.logEvent('buy-multitrip-insurance-dashboard')}
      >
        <Button fullWidth className={classes.button}>
          {translate('c-client-dashboard.buy-online.label-multitrip')}
        </Button>
      </Link>

      <Link
        to="/buy-foreigners-insurance"
        onClick={() => analytics.logEvent('buy-foreigners-insurance-dashboard')}
      >
        <Button fullWidth className={classes.button}>
          {translate('c-client-dashboard.buy-online.label-foreigners')}
        </Button>
      </Link>

      <Link
        to="/buy-accident-insurance"
        onClick={() => analytics.logEvent('buy-accident-insurance-dashboard')}
      >
        <Button fullWidth className={classes.button}>
          {translate('c-client-dashboard.buy-online.label-accident')}
        </Button>
      </Link>
    </Grid>
  )
}

export default BuyOnline
