import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useMobileVersion } from '../../../../../hooks/use-mobile-version'

export const CalendarSkeleton = () => {
  const isMobile = useMobileVersion()

  return (
    <Box>
      <Skeleton style={isMobile ? {marginBottom: '1em'} : {}} variant="rect" width="100%" height="330px" />
    </Box>
  )
}

export const AppointmentsListSkeleton = () => {

  const styles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)'
  }

  return (
    <Box>
      <Box style={styles}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="60%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '6em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
        </Box>
      </Box>

      <Box style={styles}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="60%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '6em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
        </Box>
      </Box>

      <Box style={{ ...styles, borderBottom: '2px solid rgba(0, 0, 0, 0.12)' }}>
        <Box style={{ width: '30%' }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="60%" />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '6em' }}>
            <Skeleton variant="text" width="100%" />
            <Skeleton
              style={{ marginLeft: 'auto' }}
              variant="text"
              width="80%"
            />
          </Box>
        </Box>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '1.5em 1em 0 0'
        }}
      >
        <Skeleton variant="text" width="10%" style={{ marginRight: '2em' }} />
        <Skeleton
          variant="circle"
          width="2em"
          height="2em"
          style={{ marginRight: '1em' }}
        />
        <Skeleton variant="circle" width="2em" height="2em" />
      </Box>
    </Box>
  )
}
