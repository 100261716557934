import React, { useState } from 'react'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom-v5-compat'
import { useSnackbar } from 'notistack'
import { useAuth } from '../../../../hooks/use-auth'
import { reimburesmentSubmit } from '../../../../hooks/use-submit'
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { analytics } from '../../../../utils/firebase'
import { useTranslation } from '../../../../hooks/use-translation'
import styles from './step-confirm.module.css'
import { getAllClaims } from 'services/reimbursementService'
import { useQueryClient } from 'react-query'
import {
  ACTIVE_CLAIMS_KEY,
  ARCHIVED_CLAIMS_KEY,
} from 'pages/Reimbursement/hooks/useClaims'
import { useRouter } from 'hooks/use-router'

const StepConfirm = () => {
  const auth = useAuth()
  const router = useRouter()
  const snackbar = useSnackbar()
  const { translate, activeLanguage } = useTranslation()
  const [isSubmitting, setSubmitting] = useState(false)
  const { values } = usePersistentForm('reimbursement')
  const queryClient = useQueryClient()

  const handleSubmit = async (e) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      analytics.logEvent('ZG_razhodi_zaiavka_send')

      const response = await reimburesmentSubmit(values, auth, activeLanguage)

      snackbar.enqueueSnackbar(response?.data?.message, {
        variant: 'success',
      })

      const allClaims = await getAllClaims(auth.activeUser.clientId)

      queryClient.setQueryData(
        ACTIVE_CLAIMS_KEY,
        allClaims.filter((reimbursement) => reimbursement.isArchive === 'false')
      )
      queryClient.setQueryData(
        ARCHIVED_CLAIMS_KEY,
        allClaims.filter((reimbursement) => reimbursement.isArchive === 'true')
      )

      await queryClient.invalidateQueries('get-message-count')

      router.replace({ pathname: '/reimbursements/active' })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <form
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      onSubmit={handleSubmit}
    >
      <div className={styles.container}>
        <div className={styles.declarations}>
          <p className={styles['declarations-label']}>
            {translate(
              'c-client-dashboard.reimbursement.step-confirm.role-of'
            ) + ' '}
            {auth.activeUser.role === 'parent'
              ? translate(
                  'c-client-dashboard.reimbursement.step-confirm.role-insured'
                )
              : translate(
                  'c-client-dashboard.reimbursement.step-confirm.role-parent'
                )}
          </p>

          <ul className={styles['declarations-list']}>
            <li>
              {translate('c-client-dashboard.reimbursement.step-confirm.dec-1')}
            </li>
            <li>
              {translate('c-client-dashboard.reimbursement.step-confirm.dec-2')}
            </li>
            <li>
              {translate('c-client-dashboard.reimbursement.step-confirm.dec-3')}
            </li>
            <li>
              {translate('c-client-dashboard.reimbursement.step-confirm.dec-4')}
            </li>
          </ul>
        </div>

        <p>
          {translate(
            'c-client-dashboard.reimbursement.step-confirm.warn-legal'
          )}
        </p>
      </div>

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to="/"
          >
            {translate('c-client-dashboard.reimbursement.step-confirm.reject')}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {isSubmitting ? (
              <CircularProgress size={25} thickness={2.7} color="primary" />
            ) : (
              translate('c-client-dashboard.reimbursement.step-confirm.restore')
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default StepConfirm
