import { translate } from '../utils/lang'

export default function validate({
  personalNumber,
  phoneNumber,
  names,
  email,
  password,
  rePassword,
}) {
  const errors = {}

  let phoneRegex = /^\+{1}\d{10,14}$/

  // eslint-disable-next-line
  let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  if (!email || !emailRegex.test(email)) {
    errors.email = translate('validate-register.email')
  }

  // Validate EGN
  // const data = validateEgn(personalNumber)

  // if (data.error) {
  //   errors.personalNumber = data.error
  // }

  if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
    errors.phoneNumber = translate('validate-register.phone')
  }

  if (!names) {
    errors.names = translate('validate-register.names')
  }

  if (!password || password.length < 8) {
    errors.password = translate('validate-register.pass')
  }

  if (!rePassword) {
    errors.rePassword = translate('validate-register.pass-confirm')
  }

  if (!errors.password && !errors.rePassword && rePassword !== password) {
    errors.rePassword = translate('validate-register.pass-no-match')
  }

  return errors
}
