import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMobileVersion } from '../../hooks/use-mobile-version'
import { useTranslation } from '../../hooks/use-translation'

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPaper-root': {
      padding: '2em',
      textAlign: 'center',
    },
  },
  logo: {
    width: '25%',
    marginBottom: '2em',
  },
  mobileLogo: {
    width: '45%',
  },
  title: {
    padding: '0',
    marginBottom: '2em',
  },
  description: {
    padding: '0',
    marginBottom: '2.5em',

    '& .MuiTypography-root': {
      marginBottom: '0',
    },
  },
  buttonContainer: {
    padding: '0',
    justifyContent: 'center',
    marginBottom: '1.1em',
  },
  button: {
    padding: '0.6em 2.3em',
  },
  link: {
    color: theme.palette.primary.main,
  },
}))

const CookiesDialog = () => {
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()

  const isOpen =
    localStorage.getItem('cookieAgreement') === 'accepted' ? false : true
  const [open, setOpen] = useState(isOpen)

  const handleClose = () => {
    localStorage.setItem('cookieAgreement', 'accepted')
    setOpen(false)
  }

  return (
    <Dialog
      className={classes.container}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.header}>
        <img
          className={`${classes.logo} ${isMobile ? classes.mobileLogo : ''}`}
          src="images/Bulstrad_Life_Logo_home.svg"
          alt="Bulstrad Life Logo"
        />

        <DialogTitle className={classes.title} id="alert-dialog-title">
          {translate('c-cookies-dialog.title')}
        </DialogTitle>
      </Box>
      <DialogContent className={classes.description}>
        <DialogContentText id="alert-dialog-description">
          {translate('c-cookies-dialog.text') + ' '}
          <a
            className={classes.link}
            href="https://www.bulstradlife.bg/terms-and-cookie-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate('c-cookies-dialog.link')}
          </a>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleClose}
          color="primary"
          autoFocus
        >
          {translate('c-cookies-dialog.btn')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CookiesDialog
