// Libraries
import React, { useCallback, useEffect } from 'react'
import has from 'lodash/has'
import moment from 'moment'
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  Typography,
  Box,
  FormHelperText,
  Grid,
  Button,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
// Hooks
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { useTranslation } from '../../../../hooks/use-translation'
// Config
import { validateStepContract } from '../../../../formValidators/other'
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'
import { useSnackbar } from 'notistack'

const renderInsuranceEvents = (structure, activeLanguage) => {
  return structure.claimOtherInsEvents.map((event) => {
    if (activeLanguage !== LANG_BG && activeLanguage !== LANG_EN) {
      return null
    }

    const eventName = activeLanguage === LANG_BG ? event.name : event.name_latin

    return (
      <option key={event.id} value={eventName}>
        {eventName}
      </option>
    )
  })
}

const StepDetails = ({ structure, files, prevStep, nextStep }) => {
  const { values, errors, handleChange, validate } = usePersistentForm('other')
  const { translate, activeLanguage } = useTranslation()
  const snackbar = useSnackbar()

  const handleSubmit = (e) => {
    e.preventDefault()
    // validate form step
    const hasErrors = validate(validateStepContract)

    // check for errors
    if (hasErrors) {
      return
    }

    // go to next step if we pass the validation
    nextStep()
  }

  const datePolicyDisableHandler = (day) => {
    return day.isAfter(moment().add(1))
  }

  const dateEventDisableHandler = (day) => {
    return day.isBefore(moment(values.datePolicy).startOf('day')) ||
      day.isAfter(moment().add(1))
      ? true
      : false
  }

  const renderAllInsuranceEvents = useCallback(() => {
    return renderInsuranceEvents(structure, activeLanguage)
  }, [structure, activeLanguage])

  useEffect(() => {
    if (has(errors, 'files')) {
      snackbar.enqueueSnackbar(errors.files, {
        variant: 'error',
        preventDuplicate: true,
      })
    }

    // eslint-disable-next-line
  }, [errors])

  return (
    <>
      <form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        onSubmit={handleSubmit}
      >
        <Box style={{ marginBottom: '2em' }}>
          <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
            {translate('c-client-dashboard.other.step-contract.title')}
          </Typography>

          <TextField
            style={{ marginBottom: '0.5em' }}
            name="policyNumber"
            label={translate(
              'c-client-dashboard.other.step-contract.policy-number'
            )}
            value={values.policyNumber}
            onChange={handleChange}
            error={errors && errors.policyNumber ? true : false}
            helperText={errors && errors.policyNumber}
          />

          <FormControl
            error={errors && errors.datePolicy ? true : false}
            style={{ marginBottom: '0.5em' }}
          >
            <KeyboardDatePicker
              name="datePolicy"
              label={translate(
                'c-client-dashboard.other.step-contract.policy-date'
              )}
              format="DD/MM/YYYY"
              invalidDateMessage={
                <span style={{ color: '#D42F42' }}>
                  {translate(
                    'c-client-dashboard.other.step-contract.policy-date-info'
                  )}
                </span>
              }
              value={values.datePolicy}
              onChange={(e) =>
                handleChange(
                  { target: { name: 'datePolicy', value: e } },
                  { dateEvent: e }
                )
              }
              shouldDisableDate={datePolicyDisableHandler}
              error={errors && errors.datePolicy ? true : false}
            />
            {errors && errors.datePolicy && (
              <FormHelperText>{errors.datePolicy}</FormHelperText>
            )}
          </FormControl>

          <TextField
            style={{ marginBottom: '0.5em' }}
            name="insurer"
            label={translate('c-client-dashboard.other.step-contract.insurer')}
            value={values.insurer}
            onChange={handleChange}
            error={errors && errors.insurer ? true : false}
            helperText={errors && errors.insurer}
          />
        </Box>

        <Box style={{ marginBottom: '2em' }}>
          <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
            {translate('c-client-dashboard.other.step-contract.title-details')}
          </Typography>

          <FormControl
            error={errors && errors.eventType ? true : false}
            style={{ marginBottom: '0.5em' }}
          >
            <InputLabel htmlFor="eventType">
              {translate(
                'c-client-dashboard.other.step-contract.title-event-type'
              )}
            </InputLabel>
            <Select
              native
              value={values.eventType}
              labelWidth={37}
              onChange={handleChange}
              name="eventType"
            >
              <>
                <option key="defaultEventType1" value="" disabled />

                {has(structure, 'claimOtherInsEvents') &&
                  renderAllInsuranceEvents()}
              </>
            </Select>
            <FormHelperText>{errors && errors.eventType}</FormHelperText>
          </FormControl>

          <FormControl
            error={errors && errors.dateEvent ? true : false}
            style={{ marginBottom: '0.5em' }}
          >
            <KeyboardDatePicker
              name="dateEvent"
              label={translate(
                'c-client-dashboard.other.step-contract.event-date'
              )}
              format="DD/MM/YYYY"
              invalidDateMessage={
                <span style={{ color: '#D42F42' }}>
                  {translate(
                    'c-client-dashboard.other.step-contract.policy-date-info'
                  )}
                </span>
              }
              value={values.dateEvent}
              onChange={(e) =>
                handleChange({ target: { name: 'dateEvent', value: e } })
              }
              shouldDisableDate={dateEventDisableHandler}
              error={errors && errors.dateEvent ? true : false}
            />
            {errors && errors.dateEvent && (
              <FormHelperText>{errors.dateEvent}</FormHelperText>
            )}
          </FormControl>

          <TextField
            style={{ marginBottom: '0.5em' }}
            name="comment"
            label={translate('c-client-dashboard.other.step-contract.comment')}
            value={values.comment}
            onChange={handleChange}
            error={errors && errors.comment ? true : false}
            helperText={errors && errors.comment}
          />
        </Box>

        <Grid style={{ marginTop: 'auto' }} container>
          <Grid item xs={6}>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              size="large"
              onClick={prevStep}
            >
              {translate('c-client-dashboard.other.step-contract.back')}
            </Button>
          </Grid>
          <Grid container item lg justifyContent="flex-end" xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {translate('c-client-dashboard.other.step-contract.next')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default StepDetails
