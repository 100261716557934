import React, { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  IconButton,
} from '@material-ui/core'
import { Person, VisibilityOff, Visibility } from '@material-ui/icons'
import has from 'lodash/has'

import { useAuth } from '../../../hooks/use-auth'
import { useForm } from '../../../hooks/use-form'
import { useRouter } from '../../../hooks/use-router'
import { useLoginSubmit } from '../../../hooks/use-submit'
import { useTranslation } from '../../../hooks/use-translation'

import validateLogin from '../../../formValidators/login'
import { isMobile as isCapacitor } from '../../../mobile'

const INITIAL_FORM_STATE = {
  email: '',
  password: '',
}

const LoginForm = () => {
  const auth = useAuth()
  const router = useRouter()
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleOnBlur,
  } = useForm(
    INITIAL_FORM_STATE,
    validateLogin,
    useLoginSubmit,
    auth,
    router,
    'async'
  )

  const { translate } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        style={{ marginBottom: '1.4em' }}
        autoFocus={true}
        name="email"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Person />
            </InputAdornment>
          ),
        }}
        error={has(errors, 'email')}
        label={translate('c-form.login-form.login')}
        helperText={has(errors, 'email') && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <TextField
        style={{ marginBottom: '1.4em' }}
        name="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ padding: '0', color: 'rgba(0, 0, 0, 0.87)' }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword === false && <Visibility />}
                {showPassword === true && <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={has(errors, 'password')}
        label={translate('c-form.login-form.pass')}
        type={showPassword === true ? 'text' : 'password'}
        helperText={has(errors, 'password') && errors.password}
        value={values.password}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <Box display="flex" alignItems="cener" justifyContent="flex-end">
        <Button
          disabled={isSubmitting}
          variant="outlined"
          color="primary"
          type="submit"
          {...(isCapacitor && { style: { width: '100%' } })}
        >
          {isSubmitting ? (
            <CircularProgress size={25} thickness={2.7} color="primary" />
          ) : (
            translate('c-form.login-form.submit')
          )}
        </Button>
      </Box>
    </form>
  )
}

export default LoginForm
