import moment from 'moment';


const manipulateDateAndTime = (date, time) => {
  let [currentHours, currentMinutes] = moment().format('HH:MM').split(':')
  currentHours = +currentHours
  currentMinutes = +currentMinutes

  let isCurrentDate = moment().isSame(date, 'day')
  let isNextDate = moment().add(1, 'day').isSame(date, 'day')

  if (isCurrentDate && currentHours < 12) {
    if (currentMinutes === 0) {
      time = `[${currentHours + 4}, 18]`
    } else if (currentMinutes > 0 && currentMinutes <= 30) {
      time = `[${currentHours + 4.5}, 18]`
    } else if (currentMinutes > 30 && currentMinutes <= 59) {
      time = `[${currentHours + 5}, 18]`
    }
  } else if (isNextDate || (isCurrentDate && currentHours >= 12)) {
    time = '[8, 18]'
    date = moment().add(1, 'day')
  } else {
    time = '[8, 18]'
  }

  return { date, time }
}

export { manipulateDateAndTime }
