import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import FileUpload from '../FileUpload'
import { useTranslation } from '../../../hooks/use-translation'

const renderFileUpload = (
  uploadContainers,
  maximumAllowedFiles,
  uploadedFiles,
  filesSetter
) => {
  return (
    uploadContainers &&
    uploadContainers.map((uploadContainer) => {
      return (
        <FileUpload
          key={uploadContainer.reimb_str_id + uploadContainer.doc_name}
          {...uploadContainer}
          maximumAllowedFiles={maximumAllowedFiles}
          uploadedFiles={uploadedFiles}
          filesSetter={filesSetter}
        />
      )
    })
  )
}

function FileUploadContainer({
  uploadContainers,
  maximumAllowedFiles,
  uploadedFiles,
  filesSetter,
  hasTextEnabled,
}) {
  const { translate } = useTranslation()
  return (
    <div
      style={{ padding: '30px 30px', background: '#F2F5F7', height: '100%' }}
    >
      <div
        style={{ paddingBottom: '20px', fontSize: 16 }}
        dangerouslySetInnerHTML={{
          __html: translate(
            'c-client-dashboard.reimbursement.step-details.file-prompt'
          ),
        }}
      />

      {hasTextEnabled && (
        <Typography style={{ marginBottom: '0.5rem' }}>
          {translate('c-client-dashboard.file-upload-container.info-1') + ' '}
        </Typography>
      )}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {renderFileUpload(
          uploadContainers,
          maximumAllowedFiles,
          uploadedFiles,
          filesSetter
        )}
      </Grid>
    </div>
  )
}

FileUploadContainer.propTypes = {
  maximumAllowedFiles: PropTypes.number.isRequired,
}

export default FileUploadContainer
