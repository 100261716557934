import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import {
  Grid,
  Button,
  CircularProgress,
  Typography,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { analytics } from '../../../../utils/firebase'
import { makeAppointmentSubmit } from '../../../../hooks/use-submit'
import { useAuth } from '../../../../hooks/use-auth'
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { useClearForm } from '../../../../hooks/use-form-store'
import { useTranslation } from '../../../../hooks/use-translation'
import { loadAllAppointments } from '../../../../services/makeAppointmentService'
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 0 2em 0',
    textAlign: 'center',
    fontSize: '1.3em',
  },

  infoBox: {
    marginBottom: '2em',
    lineHeight: '0.6',
  },

  infoBoxTitle: {
    color: '#AAA',
    fontSize: '0.9rem',
  },

  date: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}))

const StepConfirm = ({
  prevStep,
  setTab,
  setUpToDateAppointments,
  setHospitalsOnMap,
  facilites,
}) => {
  const auth = useAuth()
  const classes = useStyles()
  const { translate, activeLanguage } = useTranslation()
  // get all form value from context
  const { values } = usePersistentForm('make-appointment')

  const snackbar = useSnackbar()
  const { clear } = useClearForm('make-appointment')
  const [isSubmitting, setSubmitting] = useState(false)
  const [validTimeFormat, setValidTimeFormat] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitting(true)

    try {
      analytics.logEvent('ZG_pregledi_zaiavka_send')

      const res = await makeAppointmentSubmit(values, auth, activeLanguage)

      let fetchedAllAppointments = await loadAllAppointments(
        auth.activeUser.clientId,
        auth
      )

      // Up to date appointments
      const fetchedUpToDateAppointments = fetchedAllAppointments.filter(
        (appointment) =>
          appointment.status === 'New' || appointment.status === 'In Progress'
      )

      setUpToDateAppointments(fetchedUpToDateAppointments)

      setTab(0)
      setSubmitting(false)
      clear()
      setHospitalsOnMap(facilites)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })
    } catch (e) {
      setSubmitting(false)
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    let [from, to] = values.time
      .replace('[', '')
      .replace(']', '')
      .replace('.5', ':30')
      .replace('.5', ':30')
      .replace(' ', '')
      .split(',')

    if (!from.includes(':30')) {
      from = `${from}:00`
    }

    if (!to.includes(':30')) {
      to = `${to}:00`
    }

    const newValidFormat = `${from}-${to}`
    setValidTimeFormat(newValidFormat)

    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Typography className={classes.title}>
        {translate('make-appointment.step-confirm.title')}
      </Typography>

      <Box className={classes.infoBox}>
        <Typography className={classes.infoBoxTitle}>
          {translate('make-appointment.step-confirm.date')}
        </Typography>
        <Typography className={classes.date}>
          {moment(values.date).lang(activeLanguage).format('dddd, LL')},{' '}
          {translate('make-appointment.step-confirm.date-helper-text')}{' '}
          {validTimeFormat}
        </Typography>
      </Box>

      <Box className={classes.infoBox}>
        <Typography className={classes.infoBoxTitle}>
          {translate('make-appointment.step-confirm.location')}
        </Typography>
        <Typography>
          {activeLanguage === LANG_BG && (
            <>
              {JSON.parse(values.city).city}
              {values.hospital && (
                <span>, {JSON.parse(values.hospital).name}</span>
              )}
            </>
          )}

          {activeLanguage === LANG_EN && (
            <>
              {JSON.parse(values.city).city_en}
              {values.hospital && (
                <span>, {JSON.parse(values.hospital).name_en}</span>
              )}
            </>
          )}
        </Typography>
      </Box>

      <Box className={classes.infoBox}>
        <Typography className={classes.infoBoxTitle}>
          {translate('make-appointment.step-confirm.speciality')}
        </Typography>
        <Typography>
          {values.speciality &&
            activeLanguage === LANG_BG &&
            JSON.parse(values.speciality).name}
          {values.speciality &&
            activeLanguage === LANG_EN &&
            JSON.parse(values.speciality).name_latin}
        </Typography>
      </Box>

      <Box className={classes.infoBox}>
        <Typography className={classes.infoBoxTitle}>
          {translate('make-appointment.step-confirm.symptoms')}
        </Typography>
        <Typography>{values.symptoms}</Typography>
      </Box>

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            onClick={prevStep}
          >
            {translate('make-appointment.step-confirm.backButton')}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {isSubmitting ? (
              <CircularProgress size={25} thickness={2.7} color="primary" />
            ) : (
              translate('make-appointment.step-confirm.nextButton')
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default StepConfirm
