import React from 'react'
import RegisterForm from '../../components/Form/RegisterForm'
import BtnLoginFacebook from '../../components/BtnLoginSocial/BtnLoginFacebook'
import BtnLoginGoogle from '../../components/BtnLoginSocial/BtnLoginGoogle'
import { useRouter } from '../../hooks/use-router'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'

const Divider = ({ text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
      margin: '1.4em 0',
    }}
  >
    <div
      style={{
        backgroundColor: '#CDCBC3',
        position: 'absolute',
        left: 0,
        right: 0,
        height: '2px',
        zIndex: 1,
      }}
    ></div>
    <span
      style={{
        backgroundColor: '#fff',
        zIndex: 2,
        padding: '0 5px',
        color: '#CDCBC3',
      }}
    >
      {text}
    </span>
  </div>
)

const Register = () => {
  const router = useRouter()
  const auth = useAuth()
  const { translate } = useTranslation()

  if (auth.activeUser) {
    router.push('/')
  }

  return (
    <div className="w-container custom-spacing">
      <div style={{ margin: '0 auto', maxWidth: '350px' }}>
        {/* social buttons */}
        <div style={{ marginTop: '1.4em' }}>
          <div style={{ marginBottom: '1.4em' }}>
            <BtnLoginFacebook />
          </div>
          <BtnLoginGoogle />
        </div>

        <Divider text={translate('other.or')} />

        <RegisterForm />
      </div>
    </div>
  )
}

export default Register
