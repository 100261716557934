import React from 'react'

const CheckboxLink = ({ link, primaryText, linkText }) => {
  return (
    <div>
      <span>{primaryText}</span>
      <a
        className="custom-link"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText}
      </a>
    </div>
  )
}

export default CheckboxLink
