import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import { useSnackbar } from 'notistack'
import { changeTicketRate } from '../../../../services/makeAppointmentService'
import { useTranslation } from '../../../../hooks/use-translation'
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'

const Rate = ({ classes, data, closeNotification, config }) => {
  const notistack = useSnackbar()
  const { auth } = config
  const { activeLanguage } = useTranslation()
  const [rating, setRating] = useState(null)
  const [rated, setRated] = useState(false)

  let hospital = ''

  if (activeLanguage === LANG_BG) {
    data.hospitalBG.length > 0
      ? (hospital = data.hospitalBG)
      : (hospital = data.requestedHospital)
  } else if (activeLanguage === LANG_EN) {
    data.hospitalEN.length > 0
      ? (hospital = data.hospitalEN)
      : (hospital = data.requestedHospital)
  }

  const handleRate = async (rate) => {
    try {
      const res = await changeTicketRate(
        {
          caseId: data.caseId,
          clientId: auth.activeUser.clientId,
          rate,
          description: '',
        },
        auth
      )

      notistack.enqueueSnackbar(res.message, { variant: 'success' })
      setRated(true)

      closeNotification()
    } catch (e) {
      notistack.enqueueSnackbar(e.message, { variant: 'error' })
      console.error('Error during rate', e)
    }
  }

  if (rated) return null

  return (
    <Box
      component="fieldset"
      mb={3}
      borderColor="transparent"
      className={classes.starsContainer}
    >
      <Typography component="legend">{hospital}</Typography>
      <Rating
        className={classes.stars}
        name="simple-controlled"
        defaultValue={rating}
        onChange={(_, rate) => {
          setRating(rate)
          handleRate(rate)
        }}
      />
    </Box>
  )
}

export default Rate
