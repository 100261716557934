// Lib
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from '../../hooks/use-router'
// Mobile integration
import { useCapacitorEvents, linkers } from '../../mobile'
import BassistNotification from '../Notifications/BassistNotification'
import { useQueryClient } from 'react-query'

/**
 * Listens for notification events from capacitor
 */
export default function NotificationsNative() {
  const payload = useCapacitorEvents('pushNotification')
  const actionPayload = useCapacitorEvents('pushNotificationAction')
  const queryClient = useQueryClient()

  const notifier = useSnackbar()
  const auth = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (linkers.appConfig?.value?.maintenance_mode) return

    queryClient.invalidateQueries('get-message-count')

    // Payload required
    if (!payload || !payload.data) return

    notifier.enqueueSnackbar(payload.data.title, {
      content: (key, _) => (
        <BassistNotification
          style={{ background: 'red' }}
          id={key}
          notificationPayload={payload.data}
          message={payload.data.title}
          config={{ auth, router }}
        />
      ),
      autoHideDuration: 20000,
      dense: 'true',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload])

  useEffect(() => {
    if (linkers.appConfig?.value?.maintenance_mode) return

    queryClient.invalidateQueries('get-message-count')

    // Payload required
    if (!actionPayload || !actionPayload.data) return

    notifier.enqueueSnackbar(actionPayload.data.title, {
      content: (key, _) => (
        <BassistNotification
          style={{ background: 'red' }}
          id={key}
          notificationPayload={actionPayload.data}
          message={actionPayload.data.title}
          config={{ auth, router }}
        />
      ),
      autoHideDuration: 20000,
      dense: 'true',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionPayload])

  return null
}
