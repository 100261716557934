import { LANG_CHANGE } from '../actions/lang/actionTypes'

const LANG_EN = {
  code: 'en',
  codeDisplay: 'EN',
  data: {
    buyOnline: 'buy online',
    login: 'login',
    logout: 'logout',
    getDiscount: 'Get 5% discount as a new user!'
  }
}

const LANG_BG = {
  code: 'bg',
  codeDisplay: 'BG',
  data: {
    buyOnline: 'купи онлайн',
    login: 'ВХОД B-Assist',
    logout: 'Изход',
    getDiscount: 'Получете своите 5% отстъпка при покупка на нова застраховка!'
  }
}

/**
 * The language state reducer.
 */
const defaultLang = localStorage.getItem('language') === 'bg' ? LANG_BG : LANG_EN
export const lang = (state = defaultLang, action) => {
  switch (action.type) {
    case LANG_CHANGE:
      const lang = action.code.toLowerCase()
      localStorage.setItem('language', lang)
      switch (lang) {
        case 'bg':
          return LANG_BG
        case 'en':
          return LANG_EN
        default:
          return LANG_BG
      }
    default:
      return state
  }
}
