import { Box } from '@material-ui/core'
import { useTranslation } from '../../hooks/use-translation'
import React from 'react'

const Information = () => {
  const { translate } = useTranslation()

  const infoLinks = [
    {
      text: translate('coverage-info.links.l1.text'),
      href: translate('coverage-info.links.l1.href'),
    },
    {
      text: translate('coverage-info.links.l2.text'),
      href: translate('coverage-info.links.l2.href'),
    },
    {
      text: translate('coverage-info.links.l3.text'),
      href: translate('coverage-info.links.l3.href'),
    },
  ]

  return (
    <Box fontSize="large" mt={4}>
      <ul>
        {infoLinks.map((link, idx) => (
          <a key={idx} href={link.href}>
            <li style={{ color: '#D42F42', marginBottom: '1em' }}>
              {link.text}
            </li>
          </a>
        ))}
      </ul>
    </Box>
  )
}

export default Information
