import React, { useState, useMemo, Fragment } from 'react'
import moment from 'moment'
import { Grid, TablePagination, Badge } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import { AddBox } from '@material-ui/icons'
import AppointmentList from './AppointmentList'
import { CalendarSkeleton, AppointmentsListSkeleton } from './Skeleton'
import { useTranslation } from '../../../../hooks/use-translation'

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& .MuiPickersStaticWrapper-staticWrapperRoot': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
    },

    '& .MuiPickersBasePicker-pickerView': {
      maxWidth: 'none',
      width: '100%',
      minHeight: '330px',
    },

    '& .MuiPickersCalendarHeader-switchHeader': {
      color: theme.palette.primary.main,
    },

    '& .MuiPickersCalendarHeader-iconButton': {
      color: theme.palette.primary.main,
    },

    '& .MuiPickersDay-current': {
      color: 'rgba(0, 0, 0, 0.87)',
      background: 'rgba(0, 0, 0, 0.08)',
    },

    '& .MuiBadge-anchorOriginTopRightRectangle': {
      left: '-0.8em',
      top: '2.7em',
      bottom: '-1.4em',
      right: '1.7em',

      '& .MuiSvgIcon-root': {
        width: '0.7em',
        height: '0.7em',
      },
    },
  },
}))

const labelDisplayedRows = ({
  from,
  to,
  count,
  diplayRowFromLabel,
  displayRowMoreThanLabel,
}) => {
  return `${from}-${to === -1 ? count : to} ${diplayRowFromLabel} ${
    count !== -1 ? count : `${displayRowMoreThanLabel} ${to}`
  }`
}

const renderDays = (
  day,
  selectedDate,
  isInCurrentMonth,
  dayComponent,
  date,
  upToDateAppointments
) => {
  let appointmentInThisDay = upToDateAppointments.filter((appointment) =>
    moment(day).isSame(
      appointment.approvedTimeDate
        ? appointment.approvedTimeDate
        : appointment.requestedDate,
      'day'
    )
  )

  if (
    (date === null && moment(day).isSame(selectedDate, 'day')) ||
    moment(day).isSame(date, 'day')
  ) {
    return (
      <Fragment>
        <div role="presentation">
          <span className="MuiBadge-root">
            <button
              className="MuiButtonBase-root MuiIconButton-root MuiPickersDay-day MuiPickersDay-current"
              tabIndex="0"
              type="button"
              style={
                date === null
                  ? {
                      background: 'rgba(0, 0, 0, 0.08)',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }
                  : {
                      background: '#D42F42',
                      color: '#FFFFFF',
                    }
              }
            >
              <span className="MuiIconButton-label">
                <p className="MuiTypography-root MuiTypography-body2 MuiTypography-colorInherit">
                  {moment(day).format('D')}
                </p>
              </span>
              <span className="MuiTouchRipple-root"></span>
            </button>
            {appointmentInThisDay.length > 0 && isInCurrentMonth && (
              <span className="MuiBadge-badge MuiBadge-anchorOriginTopRightRectangle">
                <AddBox
                  color={
                    upToDateAppointments
                      .filter((appointment) =>
                        moment(date || selectedDate).isSame(
                          appointment.approvedTimeDate
                            ? appointment.approvedTimeDate
                            : appointment.requestedDate,
                          'day'
                        )
                      )
                      .filter((appointment) => appointment.status === 'Closed')
                      .length > 0
                      ? 'primary'
                      : 'disabled'
                  }
                />
              </span>
            )}
          </span>
        </div>
      </Fragment>
    )
  }

  if (appointmentInThisDay.length > 0 && isInCurrentMonth) {
    let closedAppointments = appointmentInThisDay.filter(
      (appointment) => appointment.status === 'Closed'
    )

    return (
      <Badge
        badgeContent={
          <AddBox
            color={closedAppointments.length > 0 ? 'primary' : 'disabled'}
          />
        }
      >
        {dayComponent}
      </Badge>
    )
  }

  return dayComponent
}

function UpToDate({
  upToDateAppointments,
  ticketStatusData,
  setTicketStatusData,
  isSubmitting,
  setIsSubmitting,
}) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [date, setDate] = useState(null)
  const [dataForRender, setDataForRender] = useState(upToDateAppointments)

  const { translate } = useTranslation()

  const diplayRowFromLabel = translate(
    'c-client-dashboard.make-appointment.up-to-date.display-row-from-label'
  )
  const displayRowMoreThanLabel = translate(
    'c-client-dashboard.make-appointment.up-to-date.display-row-more-than-label'
  )

  const rowsPerPage = 3

  useMemo(() => {
    if (date) {
      let filtered = upToDateAppointments.filter((appointment) =>
        date
          ? moment(date).isSame(
              appointment.approvedTimeDate
                ? appointment.approvedTimeDate
                : appointment.requestedDate,
              'day'
            )
          : appointment
      )

      setDataForRender(filtered)
    } else {
      setDataForRender(upToDateAppointments)
    }
  }, [date, upToDateAppointments])

  return (
    <Grid container justifyContent="space-between" className="grid-m-c-1">
      <Grid item xs={12} md={4} className={classes.calendar}>
        {upToDateAppointments === null ? (
          <CalendarSkeleton />
        ) : (
          <DatePicker
            disableToolbar={true}
            orientation="landscape"
            variant="static"
            value={date}
            onChange={(e) => {
              setDate(e)
              setPage(0)
            }}
            renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
              renderDays(
                day,
                selectedDate,
                isInCurrentMonth,
                dayComponent,
                date,
                upToDateAppointments
              )
            }
          />
        )}
      </Grid>

      <Grid item xs={12} md={7} className={classes.grid}>
        {upToDateAppointments === null ? (
          <AppointmentsListSkeleton />
        ) : (
          <AppointmentList
            dataForRender={dataForRender}
            page={page}
            rowsPerPage={rowsPerPage}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            ticketStatusData={ticketStatusData}
            setTicketStatusData={setTicketStatusData}
            upToDateAppointments={upToDateAppointments}
            date={date}
            setDate={setDate}
          />
        )}

        {dataForRender && dataForRender.length > 0 && (
          <TablePagination
            justifyContent="center"
            component="nav"
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            count={dataForRender.length}
            onChangePage={(e, newPage) => setPage(newPage)}
            labelDisplayedRows={({ from, to, count }) =>
              labelDisplayedRows({
                from,
                to,
                count,
                diplayRowFromLabel,
                displayRowMoreThanLabel,
              })
            }
          />
        )}
      </Grid>
    </Grid>
  )
}

export default UpToDate
