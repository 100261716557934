// Libraries
import React from 'react'
import { IconButton, Button, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
// Components
import ExtraCareDialog from './ExtraCareDialog'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
// Styles
import iconStyles from './icon-styles.module.css'
import { analytics } from '../../../../utils/firebase'

const useStyles = makeStyles((theme) => ({
  newServiceButton: {
    paddingRight: '0.8em',
  },
  newServiceButtonLabel: {
    color: 'rgba(0, 0, 0, 0.26)',
    marginRight: 5,
    fontSize: '0.6em',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
}))

export default function ExtraCareButtonWithoutHealthCare({ classes }) {
  const extraCareButtonClasses = useStyles()
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()
  // Extra care dialog controls
  const [extraCareDialogOpen, setExtraCareDialogOpen] = React.useState(false)

  return (
    <>
      <Button
        style={isMobile ? { paddingLeft: '1.55rem' } : {}}
        disabled={true}
        fullWidth
        classes={{
          root: classes._button,
          label: classes._buttonLabel,
          iconSizeMedium: classes._buttonEndIconSize,
          startIcon: classes._buttonStartIcon,
        }}
        startIcon={
          <span
            className={`${iconStyles.icon} ${iconStyles['extra-care-icon']} ${iconStyles.disabled}`}
            aria-label={translate('extra-care.label')}
          />
        }
      >
        <span>{translate('extra-care.label')}</span>
      </Button>

      <IconButton
        disabled={true}
        className={extraCareButtonClasses.newServiceButton}
        onClick={() => {
          analytics.logEvent('dashboard_ZG_extracare_info_inactive')
          setExtraCareDialogOpen(true)
        }}
      >
        <span className={extraCareButtonClasses.newServiceButtonLabel}>
          {translate('extra-care.icon-button-label')}
        </span>
        <InfoOutlined color={'disabled'} />
      </IconButton>

      <ExtraCareDialog
        open={extraCareDialogOpen}
        setOpen={setExtraCareDialogOpen}
        hasExtraCare={false}
      />
    </>
  )
}
