// Libraries
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import { useSnackbar } from 'notistack'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
// Hooks
import { useForm } from '../../../../hooks/use-form'
import { useAuth } from '../../../../hooks/use-auth'
import { useRouter } from '../../../../hooks/use-router'
import { makeSpecializedExaminationSubmit } from '../../../../hooks/use-submit'
import { useTranslation } from '../../../../hooks/use-translation'
// Services
import { loadAllSpecializedExaminations } from '../../../../services/makeSpecializedExaminationService'
// Utils
import { analytics } from '../../../../utils/firebase'
// Form Validators
import { makeSpecializedExaminationValidate } from '../../../../formValidators/makeSpecializedExamination'

const StepDetails = ({
  specialties,
  files,
  setFiles,
  setAllSpecializedExaminations,
  setTab,
}) => {
  const auth = useAuth()
  const router = useRouter()
  const snackbar = useSnackbar()
  const [isSubmitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const { translate } = useTranslation()

  const INITIAL_FORM_STATE = {
    examination: specialties[0],
    comment: '',
    files,
  }

  const { values, handleChange, handleOnBlur } = useForm(
    INITIAL_FORM_STATE,
    () => {},
    () => {},
    auth,
    null,
    'async'
  )

  useEffect(() => {
    handleChange({ target: { name: 'files', value: files } })
    // eslint-disable-next-line
  }, [files])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const newErrors = makeSpecializedExaminationValidate(values.comment, files)

    if (isEmpty(newErrors)) {
      setSubmitting(true)

      try {
        analytics.logEvent('ZG_izsledvania_zaiavka_send')

        const res = await makeSpecializedExaminationSubmit(values, auth, router)

        snackbar.enqueueSnackbar(res.message, {
          variant: 'success',
        })

        let fetchedAllSpecializedExamination =
          await loadAllSpecializedExaminations(
            auth.activeUser.clientId,
            auth,
            auth.activeUserCard.contractId
          )

        setAllSpecializedExaminations(fetchedAllSpecializedExamination)
        setTab(0)

        setFiles({})

        setSubmitting(false)
      } catch (e) {
        setSubmitting(false)
        snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      }
    } else {
      setErrors(newErrors)

      if (has(newErrors, 'files')) {
        snackbar.enqueueSnackbar(newErrors.files, {
          variant: 'error',
          preventDuplicate: true,
        })
      }
    }
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit}
    >
      <FormControl style={{ marginBottom: '2em' }}>
        <InputLabel htmlFor="examination">
          {translate(
            'c-client-dashboard.make-specialized-examination.step-details.label-examination'
          )}
        </InputLabel>
        <Select
          native
          value={values.examination}
          labelWidth={37}
          onChange={handleChange}
          name="examination"
        >
          {specialties.map((speciality, idx) => (
            <option key={idx} value={speciality}>
              {speciality}
            </option>
          ))}
        </Select>
      </FormControl>

      <TextField
        style={{ marginBottom: '4em' }}
        error={errors && errors.comment ? true : false}
        multiline
        rowsMax={3}
        name="comment"
        label={translate(
          'c-client-dashboard.make-specialized-examination.step-details.label-comment'
        )}
        value={values.comment}
        onChange={handleChange}
        onBlur={handleOnBlur}
        helperText={errors && errors.comment}
      />

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to="/"
          >
            {translate(
              'c-client-dashboard.make-specialized-examination.step-details.label-reject-button'
            )}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {isSubmitting ? (
              <CircularProgress size={25} thickness={2.7} color="primary" />
            ) : (
              `${translate(
                'c-client-dashboard.make-specialized-examination.step-details.label-request-button'
              )}`
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default StepDetails
