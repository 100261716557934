import { fetch } from '../hooks/use-fetch'

export const loadStructure = async (auth) => {
  return await fetch.get(
    '/getProductClaimStructureGeneral',
    {
      params: {
        ...(auth.activeUser === null
          ? { clientId: '' }
          : { clientId: auth.activeUser.clientId }),
      },
    },
    auth
  )
}

export const makeClaim = async (data, auth) => {
  return await fetch.post(
    '/createClaimOther',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const resendEmail = async (data, auth) => {
  return await fetch.post(
    '/sendVerificationCodeClaim',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const verifyCode = async (data, auth) => {
  return await fetch.post(
    '/checkCodeClaimOtherEmail',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const confirmClaim = async (data, clientId, auth) => {
  return await fetch.post(
    '/confirmClaimOther',
    {
      body: JSON.stringify(data),
      headers: { clientId },
    },
    auth
  )
}

export const setDiagnoseMessageVisibility = async (data, auth) => {
  return await fetch.post(
    '/updDiagnoseMeMsg',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const setSuperDocHealeeMsgVisibility = async (data, auth) => {
  return await fetch.post(
    '/updSuperDocHealeeMsg',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}
