import { useAuth } from 'hooks/use-auth'
import { useQuery } from 'react-query'
import { getReimbursementStructure } from 'services/reimbursementService'

export const useReimbursementStructure = () => {
  const { activeUser } = useAuth()

  return useQuery({
    queryKey: 'reimbursement-structure',
    queryFn: () => getReimbursementStructure(activeUser.clientId),
  })
}
