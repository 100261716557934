// Libraries
import React from 'react'
import { useAuth } from '../../../../hooks/use-auth'
import { IconButton, Button, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
// Components
import ExtraCareDialog from './ExtraCareDialog'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
// Styles
import iconStyles from './icon-styles.module.css'
import { analytics } from '../../../../utils/firebase'
import openInNewTab from '../../../../utils/openInNewTab'

const useStyles = makeStyles((theme) => ({
  newServiceButton: {
    paddingRight: '0.78em !important',
  },
  newServiceButtonLabel: {
    marginRight: 5,
    fontSize: '0.6em',
    color: '#D42F42',
    textTransform: 'uppercase',
  },
}))

export default function ExtraCareButtonWithHealthCare({ classes }) {
  const extraCareButtonClasses = useStyles()

  const isMobile = useMobileVersion()
  const { translate } = useTranslation()
  const auth = useAuth()

  // Extra care dialog controls
  const [extraCareDialogOpen, setExtraCareDialogOpen] = React.useState(false)

  if (!auth.activeUserCard) return null

  // Get extra care option for current card
  const { extraCare, cardNumber, diagnoseMeMsg } = auth.activeUserCard
  // has extra care predicate
  const hasExtraCare = extraCare === 'yes'
  // DiagnoseMe Link
  const diagnoseMeLink = `https://diagnose.me/bg/partner/bulstradlife/?partnerId=${cardNumber}`

  return (
    <>
      <Button
        style={isMobile ? { paddingLeft: '1.55rem' } : {}}
        disabled={!hasExtraCare}
        onClick={() => {
          analytics.logEvent('dashboard_ZG_extracare')
          openInNewTab(diagnoseMeLink)
        }}
        fullWidth
        classes={{
          root: classes._button,
          label: classes._buttonLabel,
          iconSizeMedium: classes._buttonEndIconSize,
          startIcon: classes._buttonStartIcon,
        }}
        startIcon={
          <span
            className={`${iconStyles.icon} ${iconStyles['extra-care-icon']} ${iconStyles.enabled}`}
            aria-label={translate('extra-care.label')}
          />
        }
      >
        <span>{translate('extra-care.label')}</span>
      </Button>
      {!diagnoseMeMsg && (
        <IconButton
          className={`${classes.iconButton} ${extraCareButtonClasses.newServiceButton}`}
          onClick={() => {
            analytics.logEvent('dashboard_ZG_extracare_info')
            setExtraCareDialogOpen(true)
          }}
        >
          <span className={extraCareButtonClasses.newServiceButtonLabel}>
            {translate('extra-care.icon-button-label')}
          </span>
          <InfoOutlined color={'primary'} />
        </IconButton>
      )}

      <ExtraCareDialog
        open={extraCareDialogOpen}
        setOpen={setExtraCareDialogOpen}
        hasExtraCare={hasExtraCare}
      />
    </>
  )
}
