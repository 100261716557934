import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { useMobileVersion } from '../../../hooks/use-mobile-version'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
  },

  titleIcon: {
    marginRight: theme.spacing(2),
  },

  info: {
    display: 'flex',
    alignItems: 'center',
  },

  closeIcon: {
    marginLeft: theme.spacing(2),
    color: '#B6B6B6',
    cursor: 'pointer',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  description: {
    whiteSpace: 'normal',
  },
  menuItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.2)',
  },
  mobile: {
    width: '90%',
    margin: '0 auto',
  },
  desktop: {
    width: '50%',
    minWidth: '500px',
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
}))

// ! Handle notification ACTION
const SpecializedExaminationNotification = React.forwardRef(
  ({ payload, closeNotification }, ref) => {
    const classes = useStyles()
    const isMobile = useMobileVersion()
    const title = payload.title.split(' - ')[1]

    return (
      <Box
        ref={ref}
        className={`${classes.menuItem} ${
          isMobile ? classes.mobile : classes.desktop
        }`}
      >
        <Box className={classes.header}>
          <Box className={classes.title}>
            <img
              className={classes.titleIcon}
              src="images/tests.svg"
              alt="Високоспециализирано изследване"
            />

            <Box>{title}</Box>
          </Box>

          <Box className={classes.info}>
            <Box>
              {moment(payload.bodyPortal.requestedDate).format('DD MMMM YYYY')}
            </Box>
            <Close onClick={closeNotification} className={classes.closeIcon} />
          </Box>
        </Box>
        <Box className={classes.description}>
          {payload.bodyPortal.description}
        </Box>
      </Box>
    )
  }
)

SpecializedExaminationNotification.propTypes = {
  payload: PropTypes.object.isRequired,
  action: PropTypes.func,
}
export default SpecializedExaminationNotification
