import { Button, FormControl, FormHelperText, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import { LANG_BG } from '../../../../config/languageTypes'
import { makeAppointmentValidateStepLocation } from '../../../../formValidators/makeAppointment'
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { useTranslation } from '../../../../hooks/use-translation'

const StepLocation = ({ nextStep, cities, facilities }) => {
  const [filteredFacilities, setFilteredFacilities] = useState([])
  const { values, errors, handleChange, validate, update } =
    usePersistentForm('make-appointment')

  const { translate, activeLanguage } = useTranslation()

  useEffect(() => {
    if (values.city) {
      const selectedLocation = JSON.parse(values.city)

      if (!selectedLocation) {
        setFilteredFacilities([])
        return
      }

      const newFilteredFacilities = facilities.filter(
        (facility) => facility.city === selectedLocation?.city
      )
      setFilteredFacilities(newFilteredFacilities)
    }
  }, [values.city, facilities])

  const handleSubmit = (e) => {
    e.preventDefault()
    // validate form step
    let hasErrors = validate(makeAppointmentValidateStepLocation)

    // check for errors
    if (hasErrors) {
      return
    }

    if (values.city && filteredFacilities.length === 0) {
      update('errors', { hospital: true })
      return
    }
    // go to next step if we pass the validation
    nextStep()
  }

  const selectCityOption = useCallback(
    (option) =>
      activeLanguage === LANG_BG ? option.city || '' : option.city_en || '',
    [activeLanguage]
  )

  const selectHospitalOption = useCallback(
    (option) =>
      activeLanguage === LANG_BG ? option.name || '' : option.name_en || '',
    [activeLanguage]
  )

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit}
    >
      <FormControl
        error={errors && errors.city ? true : false}
        style={{ marginBottom: '2em' }}
      >
        <Autocomplete
          noOptionsText={translate('no-results')}
          name="city"
          options={cities}
          value={values.city ? JSON.parse(values.city) : ''}
          getOptionLabel={selectCityOption}
          onChange={(e, value) => {
            handleChange(e, {
              city: JSON.stringify(value),
              hospital: '',
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate(
                [
                  'c-client-dashboard',
                  'make-appointment',
                  'step-location',
                  'label-city',
                ].join('.')
              )}
            />
          )}
        />

        <FormHelperText>{errors && errors.city}</FormHelperText>
      </FormControl>

      <FormControl
        error={errors && errors.hospital ? true : false}
        style={{ marginBottom: '4em' }}
      >
        <Autocomplete
          disabled={
            values.city && filteredFacilities.length === 0 ? true : false
          }
          noOptionsText={translate('no-results')}
          name="hospital"
          options={filteredFacilities}
          value={values.hospital ? JSON.parse(values.hospital) : ''}
          getOptionLabel={selectHospitalOption}
          onChange={(e, value) => {
            handleChange(e, {
              hospital: !value ? '' : JSON.stringify(value),
            })
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate(
                [
                  'c-client-dashboard',
                  'make-appointment',
                  'step-location',
                  'label-hospital',
                ].join('.')
              )}
            />
          )}
        />

        <FormHelperText>
          {values.city &&
            filteredFacilities.length === 0 &&
            translate(
              [
                'c-client-dashboard',
                'make-appointment',
                'step-location',
                'info-no-hospitals',
              ].join('.')
            )}
        </FormHelperText>
      </FormControl>

      <Grid style={{ marginTop: 'auto' }} container>
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to="/"
          >
            {translate(
              [
                'c-client-dashboard',
                'make-appointment',
                'step-location',
                'reject',
              ].join('.')
            )}
          </Button>
        </Grid>
        <Grid container item lg justifyContent="flex-end" xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {translate(
              [
                'c-client-dashboard',
                'make-appointment',
                'step-location',
                'next',
              ].join('.')
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

StepLocation.propTypes = {
  cities: PropTypes.array.isRequired,
  facilities: PropTypes.array.isRequired,
  nextStep: PropTypes.func.isRequired,
}

StepLocation.defaultProps = {
  cities: [],
  facilities: [],
}

export default StepLocation
