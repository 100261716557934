import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  TablePagination,
  IconButton,
} from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { useMobileVersion } from 'hooks/use-mobile-version'
import { useTranslation } from 'hooks/use-translation'
import SkeletonList from 'components/ClientDashboard/Reimbursement/ActiveReimbursementsSkeleton'
import { useActiveClaims } from 'pages/Reimbursement/hooks/useClaims'

const PER_PAGE = 5

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  title: {
    marginRight: '2em',
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  additionalInfoWrapper: {
    textAlign: 'right',
    marginRight: '1em',
  },
  zeroMarginBottom: {
    marginBottom: 0,
  },
  status: {
    textTransform: 'capitalize',
  },
  message: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
  mobileButton: {
    paddingRight: 0,
  },
})

const labelDisplayedRows =
  (textFrom, textMoreThan) =>
  ({ from, to, count }) => {
    return `${from}-${to === -1 ? count : to} ${textFrom} ${
      count !== -1 ? count : `${textMoreThan} ${to}`
    }`
  }

const ActiveReimbursements = () => {
  const classes = useStyles()
  const { translate } = useTranslation()
  const [page, setPage] = useState(0)
  const isMobile = useMobileVersion()

  const { data, isLoading } = useActiveClaims()

  const hasResults = data && data?.length > 0

  const renderReimbursements = () => {
    if (!data) return null

    let startIndex = page === 0 ? 0 : page * PER_PAGE
    let endIndex = page === 0 ? PER_PAGE : startIndex + PER_PAGE

    let results = data
      ?.filter((reimbursement, idx) => idx >= startIndex && idx < endIndex)
      .sort(
        (a, b) =>
          new Date(b?.claimDate).getTime() - new Date(a?.claimDate).getTime()
      )

    return results.map((reimbursement) => {
      return (
        <Fragment key={reimbursement.claimId}>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.title}
              primary={reimbursement.coverName}
            />

            <section className={classes.additionalInfo}>
              <section className={classes.additionalInfoWrapper}>
                <p className={classes.zeroMarginBottom}>
                  {moment(reimbursement.createDate).format('DD MMMM YYYY')}
                </p>
                <p className={`${classes.status} ${classes.zeroMarginBottom}`}>
                  {reimbursement.claimstatus}
                </p>
              </section>

              <Link to={`/reimbursements/active/${reimbursement.claimId}`}>
                <IconButton className={isMobile ? classes.mobileButton : ''}>
                  <ArrowForwardIos />
                </IconButton>
              </Link>
            </section>
          </ListItem>
        </Fragment>
      )
    })
  }

  if (isLoading) {
    return <SkeletonList />
  }

  return (
    <>
      <List className={classes.root}>
        {!hasResults ? (
          <p className={classes.message}>
            {translate('c-client-dashboard.reimbursement.up-to-date.not-found')}
          </p>
        ) : (
          renderReimbursements()
        )}
      </List>

      {hasResults && (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={PER_PAGE}
          rowsPerPageOptions={[PER_PAGE]}
          count={data?.length}
          onPageChange={(e, newPage) => setPage(newPage)}
          labelDisplayedRows={labelDisplayedRows(
            translate('c-client-dashboard.reimbursement.up-to-date.w-from'),
            translate('c-client-dashboard.reimbursement.up-to-date.w-more-than')
          )}
        />
      )}
    </>
  )
}

export default ActiveReimbursements
