import React, { Fragment, memo, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import InfoCard from 'components/InfoCard'
import DisableBankAccountModal from './DisableBankAccountModal'
import { useMobileVersion } from 'hooks/use-mobile-version'
import { useTranslation } from 'hooks/use-translation'
import useBankAccountMutations from 'hooks/BankAccount/useBankAccountMutations'
import { useAuth } from 'hooks/use-auth'
import { useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import { useClearForm } from 'hooks/use-form-store'
import { BANK_ACCOUNTS_QUERY_KEY } from 'hooks/BankAccount/useBankAccounts'

const useStyles = makeStyles((theme) => ({
  deactivated: {
    color: theme.palette.primary.main,
  },
  active: {
    color: '#43a047',
  },
}))

function BankAccountCard({ iban, currencyStr, deactivated, nameMobile }) {
  const classes = useStyles()
  const isMobile = useMobileVersion()
  const snackbar = useSnackbar()
  const { translate } = useTranslation()
  const [selectedIban, setSelectedIban] = useState(null)
  const { disableBankAccount } = useBankAccountMutations()
  const { activeUser } = useAuth()
  const queryClient = useQueryClient()
  const { clear: clearReimbursementForm } = useClearForm('reimbursement')
  const [isDisableBankAccModalOpen, setIsDisableBankAccModalOpen] =
    useState(false)

  const isParent = activeUser?.role === 'parent'

  const handleClick = () => {
    if (!disableBankAccount.isLoading) {
      setSelectedIban(iban)
      setIsDisableBankAccModalOpen(true)
    }
  }

  const handleBankAccountDisable = async () => {
    try {
      const mutationResult = await disableBankAccount.mutateAsync({
        clientId: activeUser?.clientId,
        iban: selectedIban,
      })

      await queryClient.invalidateQueries(BANK_ACCOUNTS_QUERY_KEY)

      clearReimbursementForm()

      snackbar.enqueueSnackbar(mutationResult.message, { variant: 'success' })
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const isActive = deactivated === 'false'

  return (
    <InfoCard directionRow directionColumn={isMobile}>
      <div>
        {isActive ? (
          <p>
            {nameMobile} -{' '}
            <span className={classes.active}>
              {translate('c-client-dashboard.user.bank.bank-card.active')}
            </span>
          </p>
        ) : (
          <p>
            {nameMobile} -{' '}
            <span className={classes.deactivated}>
              {translate('c-client-dashboard.user.bank.bank-card.deactivated')}
            </span>
          </p>
        )}
        <p className="subtitle">
          IBAN: {iban} {currencyStr}
        </p>
      </div>

      {isActive && isParent && (
        <Fragment>
          <Button
            disabled={disableBankAccount.isLoading}
            onClick={handleClick}
            color="primary"
          >
            {disableBankAccount.isLoading && selectedIban === iban ? (
              <CircularProgress size={30} thickness={2.7} color="primary" />
            ) : (
              translate('c-client-dashboard.user.bank.bank-card.deactivate')
            )}
          </Button>

          {selectedIban && (
            <DisableBankAccountModal
              open={isDisableBankAccModalOpen}
              setOpen={setIsDisableBankAccModalOpen}
              callback={handleBankAccountDisable}
            />
          )}
        </Fragment>
      )}
    </InfoCard>
  )
}

BankAccountCard.defaultProps = {
  nameMobile: '',
}

BankAccountCard.propTypes = {
  name: PropTypes.string.isRequired,
  nameMobile: PropTypes.string.isRequired,
  iban: PropTypes.string.isRequired,
  deactivated: PropTypes.string.isRequired,
  currencyStr: PropTypes.string.isRequired,
}

export default memo(BankAccountCard)
