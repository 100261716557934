// Libraries
import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSnackbar } from 'notistack'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'
import { useAuth } from '../../../../hooks/use-auth'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
// Services
import { loadUserCards } from '../../../../services/userDataService'
import { setSuperDocHealeeMsgVisibility } from '../../../../services/otherService'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  mobileDialogActions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  mobileCloseButton: {
    marginLeft: '0 !important',
  },
  checkboxContainer: {
    '& .MuiFormControlLabel-label': {
      lineHeight: 1,
    },
  },
  mobileCheckboxContainer: {
    marginBottom: theme.spacing(3.25),
  },
}))

export default function HealeeDialog({ open, setOpen }) {
  const { translate } = useTranslation()
  const isMobile = useMobileVersion()
  const classes = useStyles()
  const auth = useAuth()
  const snackbar = useSnackbar()

  // Flag for diagnose me message checkbox
  const [msgChecked, setMsgChecked] = useState(
    auth?.activeUserCard?.healeeMsg || false
  )

  // Close dialog and update diagnose me message if user is checked diagnose me message
  const handleClose = async () => {
    try {
      setOpen(false)

      if (msgChecked) {
        await updateDiagnoseMeMessage()
      }
    } catch (e) {
      console.error(e, 'Error while closing dialog')
    }
  }

  const updateDiagnoseMeMessage = async () => {
    try {
      const cardId =
        JSON.parse(localStorage.getItem('activeUserCard')).cardId || ''

      // Set diagnose me message status to read
      await setSuperDocHealeeMsgVisibility(
        {
          cardId,
          clientId: auth.claims.clientId,
          healeeMsg: true,
          flagSuperDocHealee: 'healee',
        },
        auth
      )
      // Refetch cards
      const clientCards = await loadUserCards(auth.claims.clientId, auth)

      // Get the current client by the set client id
      const currentClient = clientCards.filter(
        (person) => person.clientId === auth.claims.clientId
      )[0]

      // Get the current card
      const currentCard = currentClient.validCards.filter(
        (card) => card.cardId === cardId
      )[0]

      // Update the state with this card
      auth.setState((draft) => {
        draft.activeUserCard = currentCard
      })

      // Update localStorage, too
      localStorage.setItem('activeUserCard', JSON.stringify(currentCard))
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{
            position: 'absolute',
            top: 5,
            right: 10,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <p>{translate('healee.p1')}</p>
        <p>{translate('healee.p2')}</p>
      </DialogContent>

      <DialogActions
        className={`${classes.dialogActions} ${
          isMobile ? classes.mobileDialogActions : ''
        }`}
      >
        <FormControlLabel
          className={`${classes.checkboxContainer} ${
            isMobile ? classes.mobileCheckboxContainer : ''
          }`}
          control={
            <Checkbox
              color="primary"
              checked={msgChecked}
              onChange={(e) => setMsgChecked(e.target.checked)}
              name="diagnoseMeMsg"
            />
          }
          label={translate('superdoc.modal.checkbox-label')}
        />

        <Button
          className={isMobile ? classes.mobileCloseButton : ''}
          fullWidth={isMobile ? true : false}
          onClick={handleClose}
          color="primary"
          type="button"
          variant="contained"
        >
          {translate('superdoc.modal.close-btn')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
