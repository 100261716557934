import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { useEffect, useState } from 'react'

export default function useGeolocation() {
  const [position, setPosition] = useState()

  useEffect(() => {
    async function init() {
      try {
        const permissionStatus = await Geolocation.checkPermissions()

        if (permissionStatus.location === 'prompt') {
          await Geolocation.requestPermissions()
        }

        if (permissionStatus.location !== 'granted') {
          console.error('Location permission denied')
        }

        const currentPosition = await Geolocation.getCurrentPosition()

        setPosition({
          latitude: currentPosition.coords.latitude,
          longitude: currentPosition.coords.longitude,
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (Capacitor.isNativePlatform()) {
      init()
    }
  }, [])

  return position
}
