import React, { useState, useEffect } from 'react'
import { List, ListItem, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import openInNewTab from '../../utils/openInNewTab'
import { getIdToken } from 'services/auth'

const useStyles = makeStyles((theme) => ({
  list: {
    padding: '0',
    marginTop: '0',
    border: '2px solid #E1E1E1',
    minWidth: '350px',
  },
  listItem: {
    padding: `${theme.spacing(2)}px 1.5rem`,
    justifyContent: 'center',

    '&:not(:last-child)': {
      borderBottom: '2px solid #E1E1E1',
    },
  },
}))

/**

Allowed broker roles mapped to the services they can access

--service--     -- broker roles----------------------------

calculator =>   ["fin", "fin_ins", "fin_ins_post", "fin_post"]
medex      =>   ["ins", "fin_ins", "fin_ins_post", "ins_post"]
bunit      =>   ["post", "fin_ins_post", "fin_post", "ins_post"]

*/

const LoginBrokerToExternalService = ({ actionUrl, jwt, label }) => {
  if (!jwt) return null

  return (
    <form method="post" action={actionUrl}>
      <input
        hidden
        placeholder="token"
        type="text"
        id="firebase_token"
        name="firebase_token"
        value={jwt}
        readOnly
        required
      />
      <Button
        style={{
          background: 'transparent',
          boxShadow: 'none',
          padding: '0',
          color: '#333333',
          fontWeight: '400',
        }}
        variant="contained"
        type="submit"
      >
        {label}
      </Button>
    </form>
  )
}

const BrokerNavItem = ({ children, brokerRolesThatHaveAccess, ...props }) => {
  const classes = useStyles()
  const auth = useAuth()
  const brokerRole = auth.claims.brtype

  if (!brokerRolesThatHaveAccess || brokerRolesThatHaveAccess.length === 0) {
    throw new Error(
      'The prop brokerRolesThatHaveAccess is not defined on empty!'
    )
  }

  if (!brokerRolesThatHaveAccess.includes(brokerRole)) return null

  return (
    <ListItem
      className={classes.listItem}
      style={{ padding: '15px 1.5rem', cursor: 'pointer' }}
      {...props}
    >
      {children}
    </ListItem>
  )
}

const BrokerNavigation = () => {
  const classes = useStyles()
  const { translate } = useTranslation()
  const auth = useAuth()
  const [token, setToken] = useState('')

  useEffect(() => {
    async function getToken() {
      try {
        const newToken = await getIdToken()
        setToken(newToken)
      } catch (e) {
        console.error('Error in Broker Home get token ', e)
      }
    }

    if (!token) {
      getToken()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <List className={classes.list}>
      <BrokerNavItem
        brokerRolesThatHaveAccess={[
          'ins',
          'fin_ins',
          'fin_ins_post',
          'ins_post',
        ]}
      >
        {/* Medex */}
        <LoginBrokerToExternalService
          actionUrl={process.env.REACT_APP_MEDEX_URL}
          jwt={token}
          label={translate('home-broker.label-medex')}
        />
      </BrokerNavItem>

      {/* References */}
      <BrokerNavItem
        brokerRolesThatHaveAccess={[
          'ins',
          'fin',
          'fin_ins',
          'fin_ins_post',
          'fin_post',
          'ins_post',
          // 'post',
        ]}
      >
        {translate('home-broker.label-info')}
      </BrokerNavItem>

      {/* E-Learn Portal */}
      <BrokerNavItem
        onClick={() =>
          openInNewTab(process.env.REACT_APP_BULSTRAD_E_LEARN_PORTAL)
        }
        brokerRolesThatHaveAccess={[
          'ins',
          'fin',
          'fin_ins',
          'fin_ins_post',
          'fin_post',
          'ins_post',
          // 'post',
        ]}
      >
        {translate('home-broker.label-learning')}
      </BrokerNavItem>

      {/* Calculator */}
      <BrokerNavItem
        brokerRolesThatHaveAccess={[
          'fin',
          'fin_ins',
          'fin_ins_post',
          'fin_post',
        ]}
      >
        <LoginBrokerToExternalService
          actionUrl={process.env.REACT_APP_CALCULATOR_URL}
          jwt={token}
          label={translate('home-broker.label-calculator')}
        />
      </BrokerNavItem>

      {/* BUnit */}
      <BrokerNavItem
        brokerRolesThatHaveAccess={[
          'post',
          'fin_ins_post',
          'fin_post',
          'ins_post',
        ]}
      >
        <LoginBrokerToExternalService
          actionUrl={process.env.REACT_APP_B_UNIT_URL}
          jwt={token}
          label={
            auth.claims.brtype === 'post'
              ? translate('broker-label-post')
              : translate('home-broker.label-bunit')
          }
        />
      </BrokerNavItem>

      {/* BUnit2 */}
      <BrokerNavItem brokerRolesThatHaveAccess={['bunit2']}>
        <LoginBrokerToExternalService
          actionUrl={'https://b-unit2-test.herokuapp.com/broker-access'}
          jwt={token}
          label={translate('home-broker.label-bunit') + ' 2'}
        />
      </BrokerNavItem>

      {/* Health Club */}
      <BrokerNavItem
        brokerRolesThatHaveAccess={['post']}
        onClick={() => openInNewTab(translate('health-club.link'))}
      >
        {translate('health-club.title')}
      </BrokerNavItem>
    </List>
  )
}

export default BrokerNavigation
