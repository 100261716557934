import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  bankCardsContainer: {
    padding: '1.5em',
    border: '2px solid #e1e1e1',
    borderRadius: '0.5em',
  },
  title: {
    marginBottom: '1.5em',
  },
  spacingBottom: {
    marginBottom: '2em',
  },
  logoutButton: {
    marginTop: 'auto',
  },
}))

function BankSkeleton() {
  const customClasses = useStyles()

  return (
    <Box className={customClasses.container}>
      {/* Title */}
      <Skeleton
        className={customClasses.spacingBottom}
        variant="text"
        width="30%"
        height="2.5em"
      />

      <Box
        className={`${customClasses.bankCardsContainer} ${customClasses.spacingBottom}`}
      >
        <Skeleton variant="text" width="30%" height="1.5em" />
        <Skeleton variant="text" width="60%" height="1.5em" />
      </Box>

      <Box
        className={`${customClasses.bankCardsContainer} ${customClasses.spacingBottom}`}
      >
        <Skeleton variant="text" width="30%" height="1.5em" />
        <Skeleton variant="text" width="60%" height="1.5em" />
      </Box>

      <Box
        className={`${customClasses.bankCardsContainer} ${customClasses.spacingBottom}`}
      >
        <Skeleton variant="text" width="30%" height="1.5em" />
        <Skeleton variant="text" width="60%" height="1.5em" />
      </Box>

      {/* Add bank account button */}
      <Skeleton
        className={customClasses.logoutButton}
        variant="rect"
        width="100%"
        height="4em"
      />
    </Box>
  )
}

export default BankSkeleton
