import React from 'react'
import styles from './info-card.module.css'

const InfoCard = ({ children, directionColumn, directionRow }) => {
  return (
    <div
      className={`${styles.container} ${
        directionColumn ? styles['direction-column'] : ''
      } ${directionRow ? styles['direction-row'] : ''}`}
    >
      {children}
    </div>
  )
}

export default InfoCard
