import React, { useEffect } from 'react'
import { useRouter } from '../../hooks/use-router'
import { analytics } from '../../utils/firebase'
import { enabledRoutes } from '../../config/referralRoutes'

const URLParamParser = () => {
  const { query, pathname } = useRouter()

  useEffect(
    () => {
      // Query from referral link is ?sid="copr_slider"

      // Check if queries includes "sid" as key and enabled routes includes current pathname
      if (
        Object.keys(query).indexOf('sid') >= 0 &&
        enabledRoutes.indexOf(pathname) >= 0
      ) {
        const analyticEventName = query.sid.replace('_', '-')
        const analyticPathname = pathname.replace('/', '')

        analytics.logEvent(`${analyticPathname}-${analyticEventName}`)
      }
    },
    // eslint-disable-next-line
    []
  )

  return <></>
}

export default URLParamParser
