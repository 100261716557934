// Libraries
import React, { useState, Fragment } from 'react'
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Person, VisibilityOff, Visibility } from '@material-ui/icons'
import has from 'lodash/has'
// Components
import PhoneInputField from '../../../PhoneInputField'
// Hooks
import { useTranslation } from '../../../../hooks/use-translation'

const PrivacyLink = ({ isRequired, classes, link, primaryText, linkText }) => {
  return (
    <Fragment>
      <div className={classes.spacingBottom}>
        <span>{primaryText}</span>
        <a
          className="custom-link"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </a>

        {isRequired && <span>*</span>}
      </div>
    </Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  spacingBottom: {
    marginBottom: '1.4em',
  },

  zeroMargin: {
    marginBottom: '0',
  },

  secondaryText: {
    fontStyle: 'italic',
    color: '#999',
    fontSize: '0.7rem',
    marginBottom: '1.75em',
  },
}))

const StepFields = ({ values, errors, handleChange, handleOnBlur }) => {
  const { translate } = useTranslation()
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  return (
    <Fragment>
      {/* <TextField
       className={classes.spacingBottom}
        name="personalNumber"
        error={has(errors, 'personalNumber')}
        label="ЕГН / ЛНЧ"
        helperText={has(errors, 'personalNumber') && errors.personalNumber}
        value={values.personalNumber}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />
      */}

      <PhoneInputField
        variant="standart"
        classes={classes.spacingBottom}
        name="phoneNumber"
        value={values.phoneNumber}
        onChange={handleChange}
        onBlur={handleOnBlur}
        errorMessage={has(errors, 'phoneNumber') ? errors.phoneNumber : ''}
        placeholder={translate('c-form.register-form.step-fields.phone')}
      />

      <TextField
        className={classes.spacingBottom}
        name="names"
        error={has(errors, 'names')}
        label={translate('c-form.register-form.step-fields.names')}
        helperText={has(errors, 'names') && errors.names}
        value={values.names}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <TextField
        className={classes.spacingBottom}
        name="email"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Person />
            </InputAdornment>
          ),
        }}
        error={has(errors, 'email')}
        label={translate('c-form.register-form.step-fields.email')}
        helperText={has(errors, 'email') && errors.email}
        value={values.email}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <TextField
        className={classes.spacingBottom}
        name="password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ padding: '0', color: 'rgba(0, 0, 0, 0.87)' }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword === false && <Visibility />}
                {showPassword === true && <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={has(errors, 'password')}
        label={translate('c-form.register-form.step-fields.pass')}
        type={showPassword === true ? 'text' : 'password'}
        helperText={has(errors, 'password') && errors.password}
        value={values.password}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <TextField
        className={classes.spacingBottom}
        name="rePassword"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{ padding: '0', color: 'rgba(0, 0, 0, 0.87)' }}
                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
              >
                {showRepeatPassword === false && <Visibility />}
                {showRepeatPassword === true && <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={has(errors, 'rePassword')}
        label={translate('c-form.register-form.step-fields.pass-repeat')}
        type={showRepeatPassword === true ? 'text' : 'password'}
        helperText={has(errors, 'rePassword') && errors.rePassword}
        value={values.rePassword}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />

      <PrivacyLink
        classes={classes}
        primaryText={translate('register.step-fields.gdpr.primary-text')}
        linkText={translate('register.step-fields.gdpr.primary-link-text')}
        link={translate('register.step-fields.gdpr.primary-link')}
        isRequired={true}
      />

      <PrivacyLink
        classes={classes}
        primaryText={translate('register.step-fields.privacy.primary-text')}
        linkText={translate('register.step-fields.privacy.primary-link-text')}
        link={translate('register.step-fields.privacy.primary-link')}
        isRequired={true}
      />

      <PrivacyLink
        classes={classes}
        primaryText={translate('register.step-fields.marketing.primary-text')}
        linkText={translate('register.step-fields.marketing.primary-link-text')}
        link={translate('register.step-fields.marketing.primary-link')}
      />

      <Typography className={classes.secondaryText}>
        {translate('register.step-fields.legend')}
      </Typography>
    </Fragment>
  )
}

export default StepFields
