import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom-v5-compat'
import { useAuth } from 'hooks/use-auth'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import PrivateRoute from 'components/PrivateRoute'

const BrokerLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const brokerStatus = sessionStorage.getItem('brokerStatus')

  const auth = useAuth()

  useEffect(() => {
    if (
      auth.claims.role === 'broker' &&
      brokerStatus === 'changePassword' &&
      location.pathname !== '/change-broker-password'
    ) {
      navigate('/change-broker-password')
    }

    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <PrivateRoute>
      <Outlet />
    </PrivateRoute>
  )
}

export default BrokerLayout
