// Libraries
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  CircularProgress,
} from '@material-ui/core'
import React, { useState } from 'react'
import has from 'lodash/has'
// Hooks
import { useForm } from '../../../hooks/use-form'
import { useAuth } from '../../../hooks/use-auth'
import { useTranslation } from '../../../hooks/use-translation'
// Form Validators
import { changePasswordValidate } from '../../../formValidators/userPage'

function PasswordChange({
  open,
  setOpen,
  callback,
  setIsSubmitting,
  isSubmitting,
}) {
  const auth = useAuth()
  const [allErrors, setErrors] = useState({})
  const { translate } = useTranslation()

  const handleClose = () => {
    setOpen(false)
  }

  const INITIAL_FORM_STATE = {
    oldPass: '',
    newPass: '',
    newPassConfirm: '',
  }

  const { values, handleChange } = useForm(
    INITIAL_FORM_STATE,
    () => {},
    () => {},
    auth,
    null,
    'async'
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    const errors = changePasswordValidate(values)
    setErrors(errors)

    try {
      if (
        !has(errors, 'oldPass') &&
        !has(errors, 'newPass') &&
        !has(errors, 'newPassConfirm')
      ) {
        setIsSubmitting(true)
        await callback(values.oldPass, values.newPass)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {translate('c-form.password-change.info')}
          </DialogContentText>
          <FormControl>
            <TextField
              autoFocus
              margin="dense"
              name="oldPass"
              label={translate('c-form.password-change.old-pass')}
              type="password"
              error={allErrors && allErrors.oldPass ? true : false}
              helperText={allErrors && allErrors.oldPass}
              value={values.oldPass}
              onChange={handleChange}
              fullWidth
            />

            <TextField
              margin="dense"
              name="newPass"
              label={translate('c-form.password-change.new-pass')}
              type="password"
              error={allErrors && allErrors.newPass ? true : false}
              helperText={allErrors && allErrors.newPass}
              value={values.newPass}
              onChange={handleChange}
              fullWidth
            />

            <TextField
              margin="dense"
              name="newPassConfirm"
              label={translate('c-form.password-change.new-pass-confirm')}
              type="password"
              error={allErrors && allErrors.newPassConfirm ? true : false}
              helperText={allErrors && allErrors.newPassConfirm}
              value={values.newPassConfirm}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('c-form.password-change.close')}
          </Button>

          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <CircularProgress size={25} thickness={2.7} color="primary" />
            ) : (
              translate('c-form.password-change.confirm')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default PasswordChange
