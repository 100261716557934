import { fetch } from '../hooks/use-fetch';
import { LANG_LOCAL_STORAGE_KEY } from '../config/languageTypes'

export const loadCoverage = async (clientId, auth, contractId, cardId) => {
  return await fetch.get('/clientsCoverages', {
    params: {
      clientId,
      contractId,
      cardId,
      lang: localStorage.getItem(LANG_LOCAL_STORAGE_KEY)
    }
  }, auth);
}

export const loadUserCards = async (clientId, auth) => {
  return await fetch.get('/clientsCards', {
    params: {
      clientId,
    }
  }, auth);
}

export const createEmptyUser = async (data, auth) => {
  return await fetch.post('/createEmptyUser', {
    body: JSON.stringify(data)
  }, auth)
}

export const connectWithHealthCare = async (data, auth) => {
  return await fetch.post('/connectUser', {
    body: JSON.stringify(data)
  }, auth)
}

export const updateEmailAndPhone = async (data, auth) => {
  return await fetch.post('/updateUser', {
    body: JSON.stringify(data)
  }, auth)
}

export const loadMarketingSubscription = async (clientId, auth) => {
  return await fetch.get('/getMarketing', {
    params: {
      clientId,
    }
  }, auth);
}

export const changeMarketingSubscription = async (data, auth) => {
  return await fetch.post('/changeMarketing', {
    body: JSON.stringify(data)
  }, auth)
}

export const changePassword = async (data, auth) => {
  return await fetch.post('/changePw', {
    body: JSON.stringify(data)
  }, auth)
}

export const linkEmail = async (data, auth) => {
  return await fetch.post('/connectEmailPw', {
    body: JSON.stringify(data)
  }, auth)
}