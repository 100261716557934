import React, { Fragment, useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from '@material-ui/core'
import ArchiveSkeleton from './Skeleton'
import { useTranslation } from '../../../../hooks/use-translation'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    padding: '1.5em 0',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',

    '&:last-child': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  header: {
    '& > p:last-child': {
      fontStyle: 'italic',
    },
  },
  message: {
    textAlign: 'center',
    fontSize: '1.1em',
  },
})

const labelDisplayedRows = ({
  from,
  to,
  count,
  diplayRowFromLabel,
  displayRowMoreThanLabel,
}) => {
  return `${from}-${to === -1 ? count : to} ${diplayRowFromLabel} ${
    count !== -1 ? count : `${displayRowMoreThanLabel} ${to}`
  }`
}

function Claimed({ allSpecializedExaminations }) {
  const classes = useStyles()
  const { translate } = useTranslation()

  const diplayRowFromLabel = translate(
    'c-client-dashboard.make-specialized-examination.claimed.display-row-from-label'
  )
  const displayRowMoreThanLabel = translate(
    'c-client-dashboard.make-specialized-examination.claimed.display-row-more-than-label'
  )

  const noMoreRecords = translate(
    'c-client-dashboard.make-specialized-examination.claimed.no-records-for'
  )

  const [page, setPage] = useState(0)

  const rowsPerPage = 5

  const renderExaminations = () => {
    let startIndex = page === 0 ? 0 : page * rowsPerPage
    let endIndex = page === 0 ? rowsPerPage : startIndex + rowsPerPage

    let filtered = allSpecializedExaminations.filter(
      (examination, idx) => idx >= startIndex && idx < endIndex
    )

    return filtered.map((examination) => {
      return (
        <Fragment key={examination.caseId}>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.header}
              primary={examination.subject}
              secondary={examination.description}
            />

            <div>{moment(examination.createdDate).format('DD MMMM YYYY')}</div>
          </ListItem>
        </Fragment>
      )
    })
  }

  return (
    <Fragment>
      {allSpecializedExaminations === null ? (
        <ArchiveSkeleton />
      ) : (
        <List className={classes.root}>
          {allSpecializedExaminations.length === 0 ? (
            <p className={classes.message}>{noMoreRecords}</p>
          ) : (
            renderExaminations()
          )}
        </List>
      )}

      {allSpecializedExaminations && allSpecializedExaminations.length > 0 && (
        <TablePagination
          component="nav"
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[rowsPerPage]}
          count={allSpecializedExaminations.length}
          onChangePage={(e, newPage) => setPage(newPage)}
          labelDisplayedRows={({ from, to, count }) =>
            labelDisplayedRows({
              from,
              to,
              count,
              diplayRowFromLabel,
              displayRowMoreThanLabel,
            })
          }
        />
      )}
    </Fragment>
  )
}

export default Claimed
