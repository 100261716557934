import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import FacilitiesMap from '../../components/ClientDashboard/GoogleMap/FacilitiesMap'
import GoBack from '../../components/GoBack'
import { LANG_BG } from '../../config/languageTypes'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import { loadCities, loadMedicalFacilities } from '../../services/mapService'
import styles from './map.module.css'

function Map() {
  const auth = useAuth()

  const { translate, activeLanguage } = useTranslation()

  const [cities, setCities] = useState([])

  const [allFacilities, setAllFacilities] = useState([])

  const [selectedCity, setSelectedCity] = useState()

  const [markers, setMarkers] = useState([])

  const [filterOptions, setFilterOptions] = useState({
    0: {
      // title: 'Лечебни заведения',
      title: translate('page-map.label-hospitals'),
      show: true,
      _ftr: (h) => h.type !== 'ОПТИКА' && h.type !== 'ДЕНТАЛНА',
    },
    1: {
      // title: 'Дентална помощ',
      title: translate('page-map.label-dental'),
      show: true,
      _ftr: (h) => h.type === 'ДЕНТАЛНА',
    },
    2: {
      // title: 'Оптики',
      title: translate('page-map.label-optic'),
      show: true,
      _ftr: (h) => h.type === 'ОПТИКА',
    },
  })

  useEffect(() => {
    const _fetchFacilities = async () => {
      try {
        const fetchedAllMedicalFacilities = await loadMedicalFacilities(
          auth.activeUser ? auth.activeUser.clientId : null,
          auth,
          selectedCity?.city
        )

        setAllFacilities(fetchedAllMedicalFacilities)
      } catch (e) {
        console.error('Error fetch facilities in map page:', e)
      }
    }

    if (!!selectedCity) {
      _fetchFacilities()
    }

    // eslint-disable-next-line
  }, [selectedCity])

  useEffect(() => {
    const _fetchCities = async () => {
      try {
        const fetchedCities = await loadCities(
          auth.activeUser ? auth.activeUser.clientId : null,
          auth
        )

        setCities(fetchedCities)

        const sofia = selectSofiaCity(fetchedCities)

        setSelectedCity(sofia)
      } catch (e) {
        console.error('Error during cities fetch: ', e)
      }
    }

    _fetchCities()
    // eslint-disable-next-line
  }, [])

  function selectSofiaCity(cities) {
    const [city] = cities.filter((c) => c.city.toLowerCase() === 'софия')

    return city
  }

  // Should refactor to use useReducer
  const handleCheckboxes = (e, key) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        show: e.target.checked,
      },
    }))
  }

  const filterMarkers = useCallback(
    (allFacilities) => {
      let markers = []

      for (const key of Object.keys(filterOptions)) {
        if (filterOptions[key].show) {
          markers = [
            ...markers,
            ...allFacilities.filter(filterOptions[key]._ftr),
          ]
        }
      }

      setMarkers(markers)
    },
    [filterOptions]
  )

  useEffect(() => {
    if (allFacilities.length > 0) {
      filterMarkers(allFacilities)
    }
  }, [filterOptions, allFacilities, filterMarkers])

  return (
    <Fragment>
      <GoBack />
      <Container className="custom-spacing">
        <h1 className="cm-heading-onboarding">
          <span className={`${styles.header} text-span`}>
            {translate('page-map.title-map-1')}
          </span>{' '}
          <span className={`${styles.header} text-span-2`}>
            {translate('page-map.title-map-2')}
          </span>
        </h1>

        <div style={{ height: '10px' }} />

        <div className={`grid-m-c-1 ${styles.items}`}>
          <div className={styles['first-column']}>
            <h3>{translate('page-map.label-find')}</h3>
            <FormControl component="fieldset" className={styles.form}>
              <FormGroup>
                {Object.keys(filterOptions).map((key, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        checked={filterOptions[key].show}
                        onChange={(e) => handleCheckboxes(e, idx)}
                      />
                    }
                    label={filterOptions[key].title}
                  />
                ))}
              </FormGroup>
            </FormControl>

            {cities && activeLanguage && (
              <FormControl variant="outlined">
                <Autocomplete
                  noOptionsText={translate('no-results')}
                  name="speciality"
                  options={cities}
                  value={selectedCity || ''}
                  getOptionLabel={(option) =>
                    activeLanguage === LANG_BG
                      ? option.city || ''
                      : option.city_en || ''
                  }
                  onChange={(e, city) => setSelectedCity(city)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate('page-map.label-find-city')}
                    />
                  )}
                />
              </FormControl>
            )}
          </div>

          <FacilitiesMap markers={markers} />
        </div>
      </Container>
    </Fragment>
  )
}

export default Map
