import { useAuth } from 'hooks/use-auth'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom-v5-compat'
import {
  getAllClaims,
  loadActiveClaims,
  loadArchivedClaims,
} from 'services/reimbursementService'

export const ALL_CLAIMS_KEY = 'all-claims'
export const ACTIVE_CLAIMS_KEY = 'active-claims'
export const ARCHIVED_CLAIMS_KEY = 'archived-claims'

export const useAllClaims = () => {
  const { activeUser } = useAuth()

  const query = useQuery({
    queryKey: [ALL_CLAIMS_KEY],
    queryFn: () => getAllClaims(activeUser.clientId),
  })

  return query
}

export const useActiveClaims = (options) => {
  const { activeUser } = useAuth()

  const query = useQuery({
    queryKey: [ACTIVE_CLAIMS_KEY],
    queryFn: () => loadActiveClaims(activeUser.clientId),
    ...options,
  })

  return query
}

export const useArchivedClaims = () => {
  const { activeUser } = useAuth()

  const query = useQuery({
    queryKey: [ARCHIVED_CLAIMS_KEY],
    queryFn: () => loadArchivedClaims(activeUser.clientId),
  })

  return query
}

export const useClaim = () => {
  const { reimbursementUuid } = useParams()
  const { data: claims } = useAllClaims()

  const claim = useMemo(() => {
    if (!claims || !reimbursementUuid) return null

    return claims?.find((claim) => claim.claimId === reimbursementUuid)
  }, [claims, reimbursementUuid])

  return claim
}
