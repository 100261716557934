import { useMediaQuery } from '@material-ui/core'
import React, { createContext, useContext } from 'react'

const mobileContext = createContext()

export const useMobileVersion = () => {
  return useContext(mobileContext)
}

export function ProvideMobileVersion({ children }) {
  const isMobile = useMobileBreakPoint()
  return (
    <mobileContext.Provider value={isMobile}>{children}</mobileContext.Provider>
  )
}

function useMobileBreakPoint() {
  const isMobile = useMediaQuery('(max-width: 768px)')
  return isMobile
}
