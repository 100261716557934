import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginBottom: '1em',
  },
  oneEmSpacing: {
    marginBottom: '1em',
  },
  moreSpacing: {
    marginBottom: '2em',
  },
  spacingRight: {
    marginRight: '1em',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '79% 20%',
    gridGap: '0.5em',
  },
  mobileGrid: {
    gridTemplateColumns: '1fr',
    gridGap: '0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2em',
  },
  container: {
    display: 'flex',
    marginBottom: '2em',
    alignItems: 'center',
  },
  mobileContainer: {
    flexDirection: 'column'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}))

function BoxSkeleton() {
  const isMobile = useMobileVersion()
  const classes = useStyles()

  return (
    <Box>
      <Skeleton
        className={classes.oneEmSpacing}
        variant="text"
        width="100%"
        height="3em"
      />

      <Box className={`${classes.container} ${isMobile ? classes.mobileContainer : ''}`}>
        {/* Checkbox */}
        <Box className={`${classes.checkboxContainer} ${isMobile ? classes.marginRight : ''}`}>
          <Skeleton
            className={classes.spacingRight}
            variant="circle"
            width={isMobile ? '2.3em' : '2em'}
            height="2em"
          />

          <Skeleton variant="text" width={isMobile ? '100%' : '80%'} height="3em" />
        </Box>

        <Box className={classes.checkboxContainer}>
          <Skeleton
            className={classes.spacingRight}
            variant="circle"
            width={isMobile ? '2.3em' : '2em'}
            height="2em"
          />

          <Skeleton variant="text" width={isMobile ? '100%' : '80%'} height="3em" />
        </Box>
      </Box>

      <Skeleton
        className={classes.spacing}
        variant="rect"
        width="100%"
        height="3em"
      />

      <Skeleton
        className={classes.spacing}
        variant="rect"
        width="100%"
        height="3em"
      />

      <Box className={`${classes.grid} ${isMobile ? classes.mobileGrid : ''}`}>
        <Skeleton
          className={classes.spacing}
          variant="rect"
          width="100%"
          height="3em"
        />

        <Skeleton
          className={classes.spacing}
          variant="rect"
          width="100%"
          height="3em"
        />
      </Box>

      <Skeleton
        className={classes.spacing}
        variant="rect"
        width="100%"
        height="3em"
      />

      <Skeleton
        className={classes.spacing}
        variant="rect"
        width="100%"
        height="3em"
      />

      <Skeleton
        className={classes.moreSpacing}
        variant="rect"
        width="100%"
        height="3em"
      />

      {/* Buttons */}
      <Box className={classes.buttonContainer}>
        <Skeleton variant="rect" width="10em" height="3em" />
        <Skeleton variant="rect" width="10em" height="3em" />
      </Box>
    </Box>
  )
}

export default BoxSkeleton
