import ButtonContainer from 'components/ButtonContainer'
import CreateBankAccountModal from './CreateBankAccountModal'
import useBankAccountMutations from 'hooks/BankAccount/useBankAccountMutations'
import { useAuth } from 'hooks/use-auth'
import { useTranslation } from 'hooks/use-translation'
import { useSnackbar } from 'notistack'
import React, { useState, memo } from 'react'
import { useQueryClient } from 'react-query'
import { BANK_ACCOUNTS_QUERY_KEY } from 'hooks/BankAccount/useBankAccounts'

const CreateBankAccountButton = () => {
  const { activeUser } = useAuth()
  const { translate } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)
  const { createBankAccount } = useBankAccountMutations()

  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const handleBankAccountCreate = async (name, iban) => {
    try {
      const mutationResponse = await createBankAccount.mutateAsync({
        clientId: activeUser?.clientId,
        nameMobile: name,
        iban,
      })

      await queryClient.invalidateQueries(BANK_ACCOUNTS_QUERY_KEY)

      snackbar.enqueueSnackbar(mutationResponse?.message, {
        variant: 'success',
      })
    } catch (e) {
      snackbar.enqueueSnackbar(e?.message, { variant: 'error' })
    }
  }

  return (
    <>
      <ButtonContainer>
        <button
          style={{ width: '100%', marginRight: '0' }}
          className="cm-button outlined"
          id="addAccountButton"
          onClick={() => setModalOpen(true)}
        >
          {translate('c-client-dashboard.user.bank.bank-accounts.add')}
        </button>
      </ButtonContainer>

      <CreateBankAccountModal
        open={isModalOpen}
        setOpen={setModalOpen}
        callback={handleBankAccountCreate}
      />
    </>
  )
}

export default memo(CreateBankAccountButton)
