// IE 11 Polyfill
import { Capacitor } from '@capacitor/core'
import MomentUtils from '@date-io/moment'
// Mobile integration
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import 'moment/locale/bg'
import React from 'react'
import registerNotificationListener from './mobile/actions/registerNotifications'
import * as Sentry from '@sentry/react'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import Fallback from './components/Fallback'
import App from './components/Main/App'
import { LANG_BG, LANG_LOCAL_STORAGE_KEY } from './config/languageTypes'
import { linkers } from './mobile'
import * as reducers from './reducers'
import {
  getPortalConfig,
  getPortalCurrentVersion,
  getPortalLatestVersion,
} from './versioning'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

// Langugea
const currentLanguage = localStorage.getItem(LANG_LOCAL_STORAGE_KEY)
let locale = null

if (currentLanguage) {
  locale = moment.locale(currentLanguage)
  moment.locale(currentLanguage)
} else {
  // Set default language
  localStorage.setItem(LANG_LOCAL_STORAGE_KEY, LANG_BG)

  // Set moment default local
  locale = moment.locale(LANG_BG)
  moment.locale(LANG_BG)
}

const rootReducer = combineReducers(reducers)
/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
/* eslint-enable no-underscore-dangle */
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

;(async () => {
  if (Capacitor.isNativePlatform()) {
    const { build_number: currentBuildVersion } =
      await getPortalCurrentVersion()

    linkers.portalVersion.next(currentBuildVersion)

    console.info('Setting portal version on init: ', currentBuildVersion)

    function checkPortalVersion() {
      setInterval(async () => {
        // Don't check for when the update dialog is present
        if (linkers.shouldUpdateApp.value) return

        console.info('Running version check...')
        const { build_number: latestBuildVersion } =
          await getPortalLatestVersion()

        console.info('Latest portal version: ', latestBuildVersion)
        console.info('Current app version: ', currentBuildVersion)

        if (latestBuildVersion > currentBuildVersion) {
          console.info('New portal version available!')
          linkers.shouldUpdateApp.next(true)
          return
        }

        console.info('No new portal version available.')
        linkers.shouldUpdateApp.next(false)
      }, 1000 * 60 * 5) // 5 minutes
    }

    checkPortalVersion()
  }
})()

const initializeApp = async () => {
  Sentry.init({
    dsn: 'https://c08f80f370a3da9b314b1e2d7d80158f@o4505205167685632.ingest.sentry.io/4505743081144320',
    enabled: process.env.NODE_ENV === 'production',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  await registerNotificationListener()

  let config = await getPortalConfig()

  if (
    config?.maintenance_from &&
    config?.maintenance_to &&
    !config?.maintenance_mode
  ) {
    const from = new Date(config?.maintenance_from)
    const to = new Date(config?.maintenance_to)

    if (moment(new Date()).isBetween(from, to)) {
      config = {
        ...config,
        maintenance_mode: true,
      }
    }
  }

  linkers.appConfig.next(config)

  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={Fallback}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            locale={locale}
          >
            <App />
          </MuiPickersUtilsProvider>
        </Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  )

  // Mobile integration: Define custom elements after initial render / init
  defineCustomElements(window)
}

initializeApp()
