export const specializedExaminationTypes = {
  bg: [
    'Компютърна томография (скенер)',
    'Ядрено-магнитен резонанс',
    'Езофагогастродуоденоскопия',
    'Фиброколоноскопия',
    'Сцинтиграфско изследване',
    'Хормони (над 3 бр.)',
    'Имунологично изследване (над 3 бр.)',
  ],
  en: [
    'Computer tomography (CT)scan',
    'Magnetic resonance imaging',
    'Esophagogastroduodenoscopy',
    'Colonoscopy',
    'Scintigraphy',
    'Hormones (over 3)',
    'Immunoassay (over 3)',
  ],
}
