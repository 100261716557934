import PrivateRoute from 'components/PrivateRoute'
import React from 'react'
import { Outlet } from 'react-router-dom-v5-compat'

const ClientLayout = () => {
  return (
    <>
      <PrivateRoute>
        <Outlet />
      </PrivateRoute>
    </>
  )
}

export default ClientLayout
