import React from 'react'
import moment from 'moment'
import { Box, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '../../hooks/use-translation'
import { useClaim } from 'pages/Reimbursement/hooks/useClaims'

const useStyles = makeStyles({
  root: {
    width: '100%',
    borderTop: '2px solid rgba(0, 0, 0, 0.12)',
    fontSize: '1.1em',

    '& .MuiList-root': {
      padding: '0',
    },

    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    aListItemgnItems: 'center',
    margin: '2em 0',
  },
  kind: {
    textTransform: 'uppercase',
  },
  marginBottom: {
    marginBottom: '1em',
  },
  status: {
    textTransform: 'capitalize',
  },
})

const ClaimInfo = () => {
  const classes = useStyles()
  const { translate } = useTranslation()
  const currentClaim = useClaim()

  if (!currentClaim) return null

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.kind}>{currentClaim.coverName}</Box>
        <Box className={classes.date}>
          {moment(currentClaim.claimDate).format('DD MMMM YYYY')}
        </Box>
      </Box>

      <List>
        <ListItem className={classes.marginBottom}>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.claim-number'
            )}
          </span>
          <span>{currentClaim.claimNumber}</span>
        </ListItem>
        <ListItem>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.invoice-number'
            )}
          </span>
          <span>{currentClaim.invoiceNumber}</span>
        </ListItem>
        <ListItem className={classes.marginBottom}>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.date-invoice'
            )}
          </span>
          <span>{currentClaim.invoiceDate}</span>
        </ListItem>

        <ListItem>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.deductibles'
            )}
          </span>
          <span>{currentClaim.deductibles}%</span>
        </ListItem>

        <ListItem>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.amount-claimed'
            )}
          </span>
          <span>{currentClaim.amountClaimed}</span>
        </ListItem>
        <ListItem>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.amount-paid'
            )}
          </span>
          <span>{currentClaim.amountPaid}</span>
        </ListItem>
        <ListItem className={classes.marginBottom}>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.date-payment'
            )}
          </span>
          <span>{currentClaim.paymentdate}</span>
        </ListItem>

        <ListItem>
          <span>
            {translate(
              'c-client-dashboard.reimbursement.reimbursement-item.status'
            )}
          </span>
          <span className={classes.status}>{currentClaim.claimstatus}</span>
        </ListItem>
      </List>
    </Box>
  )
}

export default ClaimInfo
