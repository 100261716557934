import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Box } from '@material-ui/core'

import setAuthDialog from '../../actions/snackbar/setAuthDialog'
import { useTranslation } from '../../hooks/use-translation'
import { useMobileVersion } from '../../hooks/use-mobile-version'
import { analytics } from '../../utils/firebase'
import { isMobile as isCapacitor } from '../../mobile'

const useStyles = makeStyles(() => ({
  buttonContainer: {
    backgroundColor: '#d42f42',
    color: 'white',
    padding: '7px 30px',

    '&:hover': {
      backgroundColor: '#d42f42',
    },
  },
  mobileButtonContainer: {
    padding: '7px 7px',
    gridRow: '1',
    gridColumn: '2',
  },
  image: {
    width: '26px',
    height: '26px',
    margin: '-0.3em 0.5em 0 1em',
  },
  mobileImage: {
    margin: '-0.3em 0em 0 1em',
  },
}))

const LoginButton = () => {
  const classes = useStyles()
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useMobileVersion()

  return (
    <Button
      className={`${classes.buttonContainer} ${
        isMobile ? classes.mobileButtonContainer : {}
      }`}
      variant="contained"
      type="button"
      onClick={() => {
        dispatch(setAuthDialog(true))
        analytics.logEvent('login-navbar-dialog-opened')
      }}
      {...(isCapacitor && { style: { width: '100%' }, size: 'large' })}
    >
      <Box>
        {translate('header.login-button.primary-text')}
        {isCapacitor && ' '}
        {!isCapacitor && (
          <img
            className={`${classes.image} ${
              isMobile ? classes.mobileImage : {}
            }`}
            src="/images/b-assist_heart_white.png"
            alt="B-Assist"
          />
        )}
        {(!isMobile || isCapacitor) &&
          translate('header.login-button.secondary-text')}
      </Box>
    </Button>
  )
}

export default LoginButton
