import React, { Fragment } from 'react'
import { Grid } from '@material-ui/core'
import StepDetails from '../StepDetails'
import BoxSkeleton from '../BoxSkeleton'
import FileUploadContainer from '../../FileUploadContainer'
import BoxSkeletonImages from '../../FileUploadContainer/BoxSkeleton'

function Request({
  specialties,
  files,
  setFiles,
  maximumAllowedFiles,
  uploadContainers,
  setAllSpecializedExaminations,
  setTab
}) {
  return (
    <Fragment>
      <Grid item lg={6} xs={12}>
        {specialties.length === 0 ? (
          <BoxSkeletonImages />
        ) : (
          <FileUploadContainer
            uploadContainers={uploadContainers}
            maximumAllowedFiles={maximumAllowedFiles}
            filesSetter={setFiles}
            uploadedFiles={files}
          />
        )}
      </Grid>

      <Grid item lg={6} xs={12} style={{ padding: '30px' }}>
        <div style={{ padding: '30px 0', height: '100%' }}>
          {specialties.length === 0 ? (
            <BoxSkeleton />
          ) : (
            <StepDetails
              specialties={specialties}
              files={files}
              setFiles={setFiles}
              setAllSpecializedExaminations={setAllSpecializedExaminations}
              setTab={setTab}
            />
          )}
        </div>
      </Grid>
    </Fragment>
  )
}

export default Request
