// Libraries
import React from 'react'
import has from 'lodash/has'
import { Container } from '@material-ui/core'
// Components
import BtnLoginFacebook from '../../components/BtnLoginSocial/BtnLoginFacebook'
import BtnLoginGoogle from '../../components/BtnLoginSocial/BtnLoginGoogle'
import BtnLoginApple from '../../components/BtnLoginSocial/BtnLoginApple'
import LoginForm from '../../components/Form/LoginForm'
// Hooks
import { useRouter } from '../../hooks/use-router'
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
import { canLoginWithApple } from '../../mobile'

const Divider = ({ text }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      justifyContent: 'center',
      margin: '1.4em 0',
    }}
  >
    <div
      style={{
        backgroundColor: '#CDCBC3',
        position: 'absolute',
        left: 0,
        right: 0,
        height: '2px',
        zIndex: 1,
      }}
    ></div>
    <span
      style={{
        backgroundColor: '#fff',
        zIndex: 2,
        padding: '0 5px',
        color: '#CDCBC3',
      }}
    >
      {text}
    </span>
  </div>
)

export default function Login() {
  const router = useRouter()
  const auth = useAuth()
  const { translate } = useTranslation()

  const isBroker = has(router.query, 'b') && router.query.b === '1'

  if (auth.activeUser) {
    router.push('/')
  }

  return (
    <Container className="custom-spacing">
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        {/* social buttons */}
        {!isBroker && (
          <div>
            <div style={{ marginTop: '1.4em' }}>
              <div style={{ marginBottom: '1.4em' }}>
                <BtnLoginFacebook />
              </div>
              <div style={{ marginBottom: '1.4em' }}>
                <BtnLoginGoogle />
              </div>
              {canLoginWithApple && (
                <div style={{ marginBottom: '1.4em' }}>
                  <BtnLoginApple />
                </div>
              )}
            </div>

            <Divider text={translate('other.or')} />
          </div>
        )}

        <div style={isBroker ? { marginTop: '1.4em' } : {}}>
          <LoginForm />
        </div>
      </div>
    </Container>
  )
}
