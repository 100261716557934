import { _get, _post } from '../utils/fetch'
import { LANG_LOCAL_STORAGE_KEY, LANG_BG } from '../config/languageTypes'
import { getIdToken } from 'services/auth'

export const fetch = {
  get: async (url, { params, headers }, auth) => {
    try {
      const token = auth ? await getIdToken() : null

      const activeLanguge =
        localStorage.getItem(LANG_LOCAL_STORAGE_KEY) || LANG_BG

      let _headers = {
        language: activeLanguge,
      }

      if (token) {
        _headers = headers
          ? { ..._headers, ...headers, token }
          : { ..._headers, token }
      } else {
        _headers = { ..._headers, ...headers }
      }

      const result = await _get(url, params, _headers)
      return result
    } catch (error) {
      throw new Error(error)
    }
  },

  post: async (url, { body, headers }, auth) => {
    try {
      const activeLanguge =
        localStorage.getItem(LANG_LOCAL_STORAGE_KEY) || LANG_BG

      const newHeaders = {
        ...headers,
        ...(auth.user && { token: await getIdToken() }),
        'Content-Type': 'application/json',
        language: activeLanguge,
      }

      const result = await _post(url, body, newHeaders)

      return result
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  },
}
