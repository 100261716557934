import moment from 'moment'
import { translate } from '../utils/lang'
import { LANG_EN } from '../config/languageTypes'

export const makeAppointmentValidateStepLocation = ({ city }) => {
  const errors = {}

  if (!city) {
    errors.city = translate('validate-make-appointment.city')
  }

  return errors
}

export const makeAppointmentValidateStepDetails = ({ symptoms }) => {
  const errors = {}

  if (!symptoms) {
    errors.symptoms = translate('validate-make-appointment.symptoms')
  }

  if (symptoms.length > 1024) {
    errors.symptoms = translate('validate-make-appointment.symptoms-length')
  }

  return errors
}

export const makeAppointmentValidateStepDate = ({ date, holidays }) => {
  const errors = {}

  for (let holiday of holidays) {
    let { nonWorkingDay } = holiday

    let formattedDate = moment(date).format('D-M-YYYY')

    if (
      formattedDate === nonWorkingDay ||
      date.locale(LANG_EN).format('dddd') === 'Sunday' ||
      date.locale(LANG_EN).format('dddd') === 'Saturday'
    ) {
      errors.date = translate('validate-make-appointment.date')
    }
  }

  if (date.isBefore(moment(), 'day')) {
    errors.date = translate('validate-make-appointment.date')
  }

  return errors
}
