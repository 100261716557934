import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

import {
  CarouselProvider,
  Slide,
  Slider
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { useMobileVersion } from '../../../hooks/use-mobile-version'

const useStyles = makeStyles(theme => ({
  rootContainer: {
    height: '100%'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1em'
  },
  arrow: {
    marginLeft: 'auto'
  },
  rightBox: {
    display: 'flex',
    alignItems: 'center'
  },
  info: {
    marginRight: '4em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  button: {
    marginTop: '4em'
  }
}))

const SLIDE_HEIGHT_MOBILE = 90
const SLIDE_HEIGHT_DESKTOP = 30

function BoxSkeleton() {
  const classes = useStyles()

  const [slideHeight, setSlideHeight] = useState(SLIDE_HEIGHT_MOBILE)
  const isMobile = useMobileVersion()

  const handleResize = mq => {
    setSlideHeight(mq.matches ? SLIDE_HEIGHT_MOBILE : SLIDE_HEIGHT_DESKTOP)
  }

  useEffect(() => {
    const mq = window.matchMedia('(max-width: 1000px)')
    mq.addListener(handleResize)
    setSlideHeight(mq.matches ? SLIDE_HEIGHT_MOBILE : SLIDE_HEIGHT_DESKTOP)
   
    return () => mq.removeListener(handleResize)
  }, [isMobile])

  return (
    <div style={{ position: 'relative' }}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={slideHeight}
        totalSlides={1}
      >
        <Slider>
          <Slide index={0}>
            <Box className={classes.rootContainer}>
              <Skeleton
                className={classes.container}
                variant="rect"
                width="100%"
                height="100%"
              >
                {/* Arrow */}
                <Skeleton variant="circle" width="4em" height="4em" />

                <Box className={classes.rightBox}>
                  {/* Header with buy button */}
                  <Box className={classes.info}>
                    <Skeleton variant="text" width="20em" height="3em" />
                    <Skeleton variant="text" width="20em" height="3em" />
                    <Skeleton variant="text" width="20em" height="3em" />
                    <Skeleton
                      className={classes.button}
                      variant="rect"
                      width="14em"
                      height="2.5em"
                    />
                  </Box>

                  {/* Arrow */}
                  <Skeleton
                    className={classes.arrow}
                    variant="circle"
                    width="4em"
                    height="4em"
                  />
                </Box>
              </Skeleton>
            </Box>
          </Slide>
        </Slider>
      </CarouselProvider>
    </div>
  )
}

export default BoxSkeleton
