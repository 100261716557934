import { fetch } from '../hooks/use-fetch';

export const loadAllSpecializedExaminations = async (clientId, auth, contractId) => {
  return await fetch.get('/clientCasesHighSpec', {
    params: {
      clientId,
      contractId
    }
  }, auth);
}

export const makeSpecializedExamination = async (data, auth) => {
  return await fetch.post('/appointments', {
    body: JSON.stringify(data)
  }, auth)
}
