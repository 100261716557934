// Libraries
import React from 'react'
import {
  makeStyles,
  Paper,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@material-ui/core'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
// Components
import HealthCareTab from './HealthCareTab'
import OtherTab from './OtherTab'
// Hooks
import { useAuth } from '../../../hooks/use-auth'
import { useRouter } from '../../../hooks/use-router'
import { useTranslation } from '../../../hooks/use-translation'
// Utils
import { analytics } from '../../../utils/firebase'

const useStyles = makeStyles((theme) => ({
  container: {},
  mobileContainer: {
    paddingBottom: theme.spacing(4.5),
  },
  header: {
    marginBottom: theme.spacing(3),
    textTransform: 'uppercase',
    color: '#2A2E43',
  },
  subtitle: {
    fontStyle: 'italic',
    margin: `${theme.spacing(4)}px 0 0 ${theme.spacing(3)}px`,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.875rem',
  },
  _button: {
    padding: `${theme.spacing(2)}px 1.5rem`,
    textTransform: 'none',
    fontSize: '1rem',
    border: '1px solid #F5F6F8',
    borderRadius: '0',
    justifyContent: 'flex-start',
    margin: 0,

    '&.Mui-disabled .MuiButton-label': {
      color: 'rgba(0, 0, 0, 0.26)',
      fontWeight: '400',
    },

    '& .MuiButton-endIcon': {
      marginLeft: 'auto',
    },

    '&:last-of-type': {
      borderBottom: '2px solid #F5F6F8',
    },
  },
  _buttonLabel: {
    fontWeight: 300,
    fontSize: '1.1rem',
    textAlign: 'left',
    lineHeight: '1.1',
    color: '#2A2E43',

    '&:disabled': {
      color: 'red',
      background: 'red',
    },
  },
  _buttonStartIcon: {
    marginRight: '1rem',
    width: '22px',
    height: '22px',
    minWidth: '22px',
    minHeight: '22px',
  },
  _materialStartIcon: {
    marginRight: '0.8rem',
  },
  fieldContainer: {
    display: 'flex',

    '& > button:first-child': {
      borderRight: '0',
    },
  },
  iconButton: {
    padding: '0.7em',
    border: '1px solid #F5F6F8',
    borderRadius: '0',
    borderLeft: 0,

    '& svg:first-child': {
      fontSize: '1.79rem',
    },
  },
  _PaperTile: {
    backgroundColor: '#fff',
  },
  _PaperTileHeading: {
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    marginRight: 'auto',
    padding: '.7rem 0',
  },
  _Box: {
    backgroundColor: '#f5f6f8',
    padding: '0.5rem 0.5rem 0.5rem 0.8rem',
  },
  buyButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buyButton: {
    '@media (max-width: 550px)': {
      width: '100%',
    },
  },
  infoContainer: {
    padding: '1.2em',

    '&:not(:last-child)': {
      borderBottom: '2px solid #F5F6F8',
    },
  },
  description: {
    marginBottom: '2em',

    '& .MuiTooltip-popper': {
      padding: theme.spacing(3),
      boxShadow: '0 0 0.3em 0 rgba(0, 0, 0, 0.3)',
      border: '#2A2E43',
      background: '#FFF',

      '@media (max-width: 375px)': {
        maxWidth: '365px',
      },

      '@media (max-width: 360px)': {
        maxWidth: '310px',
      },

      '& .MuiTooltip-tooltip': {
        margin: 0,
        padding: 0,
        background: '#FFF',
        color: '#2A2E43',

        '@media (max-width: 375px)': {
          maxWidth: '365px',
        },

        '@media (max-width: 360px)': {
          maxWidth: '310px',
        },
      },
    },
  },
  descriptionTitle: {
    display: 'inline',
    padding: '0 0.3em 0 0',
  },

  deactivatedHealthCareText: {
    width: '100%',
    textAlign: 'right',
    marginTop: '1.1em',
  },
  healthCareBox: {
    position: 'relative',
    cursor: 'help',
  },
  healthCareMessage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1em 1.5em',
    background: '#767676',
    color: '#FFF',
    borderRadius: '0.5em',

    '@media (max-width: 475px)': {
      width: '86%',
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    padding: 0,
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '1rem',
  },
  // Extra care
  extraCare: {
    box: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}))

function MyInsurence({
  healthCareModal,
  setHealthCareModal,
  connectWithHealthCareHandler,
  isSubmitting,
  setIsSubmitting,
  currentTab,
  setCurrentTab,
  isMatchesCustomMediaQuery,
}) {
  const classes = useStyles()
  const { claims } = useAuth()
  const router = useRouter()
  const { translate } = useTranslation()

  // Get from translations
  const allTabs = 2

  const isDisabled = claims.zg === 'true' ? false : true

  const shouldDisplayImmediateDialog = sessionStorage.getItem(
    'displayImmediateDialog'
  )

  if (shouldDisplayImmediateDialog === 'true' && claims.zg !== 'true') {
    setHealthCareModal(true)
    sessionStorage.removeItem('displayImmediateDialog')
  }

  const handlePush = (pathname, tab) => {
    router.push({ pathname, state: { tab } })
  }

  const handleChangeTab = (action) => {
    let newTab

    if (action === 'next') {
      newTab = currentTab === allTabs - 1 ? 0 : currentTab + 1
    } else if (action === 'previous') {
      newTab = currentTab === 0 ? allTabs - 1 : currentTab - 1
    }

    setCurrentTab(newTab)
    analytics.logEvent('dashboard_nav')
  }

  return (
    <Grid
      item
      xs={12}
      md={7}
      className={`${classes.container} ${
        isMatchesCustomMediaQuery ? classes.mobileContainer : ''
      }`}
    >
      <Typography variant="h5" className={classes.header}>
        {translate('my-insurance.title')}
      </Typography>

      <Paper className={classes._PaperTile} elevation={1} square>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          classes={{
            root: classes._Box,
          }}
        >
          <IconButton onClick={() => handleChangeTab('previous')}>
            <ArrowBackIos color="primary" />
          </IconButton>
          <Typography className={classes._PaperTileHeading} color="primary">
            {translate(`my-insurance.${currentTab}`)}
          </Typography>
          <IconButton onClick={() => handleChangeTab('next')}>
            <ArrowForwardIos color="primary" />
          </IconButton>
        </Box>

        {currentTab === 0 && (
          <HealthCareTab
            handlePush={handlePush}
            isDisabled={isDisabled}
            classes={classes}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            healthCareModal={healthCareModal}
            setHealthCareModal={setHealthCareModal}
            connectWithHealthCareHandler={connectWithHealthCareHandler}
          />
        )}

        {currentTab === 1 && (
          <OtherTab
            handlePush={handlePush}
            isDisabled={isDisabled}
            classes={classes}
          />
        )}
      </Paper>
    </Grid>
  )
}

export default MyInsurence
