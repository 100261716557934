import { fetch } from '../hooks/use-fetch'
import { LANG_LOCAL_STORAGE_KEY } from '../config/languageTypes'

export const getCoInsuredChild = async (
  clientId,
  auth,
  cardNumber,
  personalNumber,
  dateOfBirth
) => {
  return await fetch.get(
    '/clientsChild',
    {
      params: {
        clientId,
        cardNumber,
        ...(!!personalNumber && { personalNumber }),
        ...(!!dateOfBirth && { dateOfBirth }),
        language: localStorage.getItem(LANG_LOCAL_STORAGE_KEY),
      },
    },
    auth
  )
}

// Data => { clientId: childClientId, primary_insured: parentClientId, cardNumber, personalNumber, deviseOs, release_version}
export const addCoInsuredChild = async (auth, data) => {
  return await fetch.post(
    '/addChild',
    {
      body: JSON.stringify(data),
    },
    auth
  )
}
