import { Snackbar } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import setGlobalError from '../../actions/snackbar/setGlobalError';
import SnackbarWrapper from '../SnackbarWrapper';

const ErrorHandler = () => {

  const dispatch = useDispatch();
  const { error, message } = useSelector(({ snackbar }) => ({
    error: snackbar.error,
    message: snackbar.message
  }))

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      setGlobalError({ error: false, message: null })
    );
  };

  return (
    <Snackbar
      style={{ zIndex: 99999999 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={error}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarWrapper
        onClose={handleClose}
        variant="error"
        message={message}
      />
    </Snackbar>

  )
}

export default ErrorHandler
