import { fetch } from '../hooks/use-fetch';

export const loadCities = async (clientId, auth) => {
  return await fetch.get('/cities', {
    headers: {
      ...clientId === null && {Authorization: "0D1l0000000Q6y!AQwAQBTxilJY1D84K.MxMCI16pKm4HsF"}
    },
    params: {
      ...clientId !== null && {clientId},
      allTypes: 'false'
    }
  }, auth);
}

export const loadMedicalFacilities = async (clientId, auth, city) => {
  return await fetch.get('/cityHospitals', {
    headers: {
      ...clientId === null && {Authorization: "0D1l0000000Q6y!AQwAQBTxilJY1D84K.MxMCI16pKm4HsF"}
    },
    params: {
      ...clientId !== null && {clientId},
      city,
      allTypes: 'true',
    }
  }, auth);
}