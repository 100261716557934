import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Grow,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { updateMessageStatus } from '../../services/messagesService'
import { useAuth } from '../../hooks/use-auth'
import { useQueryClient } from 'react-query'

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50%',
    flexShrink: 0,
    marginLeft: '.5rem',
  },
}))

const Message = ({
  id,
  title,
  message,
  read,
  clientId,
  defaultExpanded = false,
}) => {
  const auth = useAuth()
  const classes = useStyles()
  const [expanded, setExpanded] = useState(defaultExpanded)
  const [loading, setLoading] = useState()

  const queryClient = useQueryClient()

  const isRead = read === 'true'

  const markAsRead = async () => {
    setExpanded(!expanded)

    if (!clientId) return

    if (!isRead && !expanded) {
      setLoading(true)

      try {
        await updateMessageStatus(
          '/updMessage',
          {
            clientId,
            id,
          },
          auth
        )

        await queryClient.invalidateQueries('messages')
        await queryClient.invalidateQueries('get-message-count')
      } catch (error) {
        console.error('Failed silently updating message: ', error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Accordion
      className={classes.container}
      expanded={expanded}
      onClick={markAsRead}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {loading && (
          <Grow in={loading}>
            <CircularProgress size={25} thickness={2.7} color="primary" />
          </Grow>
        )}

        <Typography
          className={classes.heading}
          style={{ color: !isRead ? 'red' : 'currentColor' }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography dangerouslySetInnerHTML={{ __html: message }}></Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default Message
