import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import useMediaQuery from '@material-ui/core/useMediaQuery';

function BoxSkeleton() {
  const matches = useMediaQuery('(max-width:450px)');

  return (
    <Box>
      {/* Stepper */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '24px 0',
          marginBottom: '2em'
        }}
      >
        <Skeleton variant="circle" width={matches ? '5em' : '4em'} height={matches ? '3em' : '4em'} />
        <Skeleton variant="text" width="9em" height="0.5em" />
        <Skeleton variant="circle" width={matches ? '5em' : '4em'} height={matches ? '3em' : '4em'} />
        <Skeleton variant="text" width="9em" height="0.5em" />
        <Skeleton variant="circle" width={matches ? '5em' : '4em'} height={matches ? '3em' : '4em'} />
      </Box>

      {/* Select lists */}
      <Skeleton
        style={{ marginBottom: '4em' }}
        variant="rect"
        width="100%"
        height="3em"
      />
      <Skeleton
        style={{ marginBottom: '4em' }}
        variant="rect"
        width="100%"
        height="3em"
      />

      {/* Buttons */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2em'
        }}
      >
        <Skeleton variant="rect" width="10em" height="3em" />
        <Skeleton variant="rect" width="10em" height="3em" />
      </Box>
    </Box>
  )
}

export default BoxSkeleton
