import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React from 'react'

const BtnLoginSocial = ({
  text,
  backgroundColor,
  border,
  textColor,
  iconSrc,
  onClick,
  ...props
}) => (
  <Button
    variant="contained"
    onClick={onClick || (() => {})}
    style={{
      width: '100%',
      color: textColor,
      backgroundColor: backgroundColor,
      border: border,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '7px 10px',
      ...props.style,
    }}
  >
    <img
      src={iconSrc}
      alt="Social Icon"
      style={{ width: '20px', height: '20px' }}
    />
    <span
      style={{
        width: '100%',
        display: 'inline-block',
        marginLeft: '-20px',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
        fontFamily: 'Roboto',
      }}
    >
      {text}
    </span>
  </Button>
)

BtnLoginSocial.propTypes = {
  text: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  border: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default BtnLoginSocial
