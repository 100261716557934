import { fetch } from '../hooks/use-fetch';
import { LANG_LOCAL_STORAGE_KEY } from '../config/languageTypes'

export const loadCoverages = async (clientId, auth, contractId, cardId) => {
  return await fetch.get('/clientsCoverages', {
    params: {
      clientId,
      contractId,
      cardId,
      lang: localStorage.getItem(LANG_LOCAL_STORAGE_KEY)
    }
  }, auth);
}