import React, { useEffect, useState } from 'react'
import { linkers } from '..'
import { useTranslation } from '../../hooks/use-translation'

const UpdateAppListener = () => {
  const [shouldUpdate, setShouldUpdate] = useState()
  const { translate } = useTranslation()

  useEffect(() => {
    const sub = linkers.shouldUpdateApp.subscribe((value) => {
      setShouldUpdate(value)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  if (!shouldUpdate) return null

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999999,
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 2rem',
        gap: '2rem',
        textAlign: 'center',
      }}
    >
      <img src="/images/b-assist_heart_red.png" alt="" />
      <span style={{ fontSize: '17px' }}>
        {translate('new-version-dialog.updated-text')}
      </span>
      <button
        style={{
          backgroundColor: '#D42F42',
          padding: '1rem 2rem',
          color: '#fff',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '18px',
        }}
        onClick={() =>
          window.location.replace(window.location.href + '?updated=1')
        }
      >
        {translate('new-version-dialog.updated-btn')}
      </button>
    </div>
  )
}

export default UpdateAppListener
