import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import AppointmentNotification from '../AppointmentNotification'
import SpecializedExaminationNotification from '../SpecializedExaminationNotification'
import ReimbursementNotification from '../ReimbursementNotification'
import MessageNotification from '../MessageNotification'
import LogoutNotification from '../LogoutNotification'

const BassistNotification = React.forwardRef(
  ({ id, notificationPayload, config }, ref) => {
    const { title, bodyPortal, body, type } = notificationPayload

    const payload = {
      title,
      ...(bodyPortal && { bodyPortal: JSON.parse(bodyPortal) }),
    }
    const { closeSnackbar } = useSnackbar()

    const handleDismiss = useCallback(() => {
      closeSnackbar(id)
    }, [closeSnackbar, id])

    // Check notification type and display the corresponding component
    switch (type) {
      case 'appointment':
        const actionAppointment = () =>
          console.log('Action for appointment notification.')

        return (
          <AppointmentNotification
            payload={payload}
            action={actionAppointment}
            closeNotification={handleDismiss}
            config={config}
            ref={ref}
          />
        )
      case 'specializedAppointment':
        const actionSpecializedExamination = () =>
          console.log('Action for specialized examination notification.')

        return (
          <SpecializedExaminationNotification
            payload={payload}
            action={actionSpecializedExamination}
            closeNotification={handleDismiss}
            ref={ref}
          />
        )
      case 'paidClaim':
        const actionReimbursement = () =>
          console.log('Action for reimbursement notification.')

        return (
          <ReimbursementNotification
            payload={payload}
            action={actionReimbursement}
            closeNotification={handleDismiss}
            ref={ref}
          />
        )
      case 'message':
        return (
          <MessageNotification
            payload={{ title, body }}
            closeNotification={handleDismiss}
            ref={ref}
          />
        )
      case 'logout':
        return (
          <LogoutNotification
            payload={{ title, body }}
            config={config}
            closeNotification={handleDismiss}
            ref={ref}
          />
        )
      default:
        return null
    }
  }
)

BassistNotification.propTypes = {
  id: PropTypes.number.isRequired,
  notificationPayload: PropTypes.object.isRequired,
}

export default BassistNotification
