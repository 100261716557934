export const API = process.env.REACT_APP_TBI_INFO_API_ENDPOINT

export const _get = async (url, params, headers) => {
  try {
    function objectToQueryString(obj) {
      return Object.keys(obj)
        .map((key) => key + '=' + obj[key])
        .join('&')
    }

    const requestUrl = params ? (url += '?' + objectToQueryString(params)) : url

    const response = await fetch(API + requestUrl, {
      method: 'GET',
      headers,
    })

    const data = await response.json()

    return response.ok ? data : Promise.reject(data.message)
  } catch (e) {
    console.error('Error during GET ', e)
    throw new Error(e)
  }
}

export const _post = async (url, body, headers) => {
  try {
    const res = await fetch(API + url, {
      method: 'POST',
      body,
      headers,
    })

    const data = await res.json()
    return res.ok ? data : Promise.reject(data.message)
  } catch (error) {
    console.error('POST ERROR: ', error)
    throw new Error(error)
  }
}
