import React from 'react'
import { makeStyles } from '@material-ui/core'

import ClickAwayTooltip, { TooltipText } from '../ClickAwayTooltip'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    padding: 0,
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '1rem',
  },
}))

export default function InsuranceTooltip(props) {
  const { insurances, isOpen, setIsOpen, buttonText } = props
  const classes = useStyles()

  return (
    <ClickAwayTooltip
      title={
        insurances &&
        insurances.length > 0 && <TooltipText insurances={insurances} />
      }
      isOpenTooltip={isOpen}
      setIsOpenTooltip={setIsOpen}
    >
      <span onClick={() => setIsOpen(true)} className={classes.link}>
        {buttonText}
      </span>
    </ClickAwayTooltip>
  )
}
