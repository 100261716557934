// Libraries
import { Container, Grid, Box } from '@material-ui/core'
import React, { useState, useMemo, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import has from 'lodash/has'
// Components
import Request from '../../components/ClientDashboard/MakeSpecializedExamination/Request'
import SliderTabs from '../../components/ClientDashboard/SliderTabs'
import Claimed from '../../components/ClientDashboard/MakeSpecializedExamination/Claimed'
import Modal from '../../components/Modal'
// Hooks
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
// Services
import { loadAllSpecializedExaminations } from '../../services/makeSpecializedExaminationService'
// Config
import { LANG_BG, LANG_EN } from '../../config/languageTypes'
import { specializedExaminationTypes } from '../../config/specializedExaminationTypes'
import { useLocation } from 'react-router-dom-v5-compat'
import GoBack from 'components/GoBack'

const MakeSpecializedExamination = (props) => {
  const location = useLocation()
  const defaultTab = has(location.state, 'tab') ? location.state.tab : 1

  const auth = useAuth()
  const snackbar = useSnackbar()
  const { translate, activeLanguage } = useTranslation()
  const [tab, setTab] = useState(defaultTab)
  const [header, setHeader] = useState(null)
  const [specialties, setSpecialties] = useState([])
  const [allSpecializedExaminations, setAllSpecializedExaminations] =
    useState(null)
  const [files, setFiles] = useState(null)

  const maximumAllowedFiles = 5

  const uploadContainers = [
    {
      doc_name: 'Високоспециализирано изследване документ',
      doc_name_en: 'Specialized examination document',
      doc_info: 'Прикачи докумeнт',
      doc_info_en: 'Attach document',
    },
  ]

  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    message: '',
  })

  const isAllowedToMakeSpecializedExamination =
    auth.activeUserCard.shouldBeActive === 'yes'

  const showModalMessageAndChangeToArchiveTab = () => {
    const translatedModalMessage = translate('make-appointment.modal-message')

    setModalOptions({ isOpen: true, message: translatedModalMessage })
    setTab(0)
  }

  // If user card is deactivated, user cannot make specialized examination
  useMemo(() => {
    if (tab === 1 && !isAllowedToMakeSpecializedExamination) {
      showModalMessageAndChangeToArchiveTab()
      return
    }

    // eslint-disable-next-line
  }, [tab])

  const closeModalHandler = () => {
    setModalOptions({ isOpen: false, message: '' })
    window.history.replaceState({}, document.title)
  }

  useMemo(async () => {
    try {
      const fetchedExaminations = await loadAllSpecializedExaminations(
        auth.activeUser.clientId,
        auth,
        auth.activeUserCard.contractId
      )

      setAllSpecializedExaminations(fetchedExaminations)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const allHeaders = translate('make-specialized-examination.header')

    // Set Headers
    setHeader({
      0: allHeaders['archive'],
      1: allHeaders['request'],
    })

    // Set specialties
    setSpecialties(specializedExaminationTypes[activeLanguage])

    // Set file structure
    let allFilesTypes = {}

    uploadContainers.forEach((type) => {
      if (activeLanguage === LANG_BG) {
        allFilesTypes[type.doc_name] = []
      } else if (activeLanguage === LANG_EN) {
        allFilesTypes[type.doc_name_en] = []
      }
    })

    setFiles(allFilesTypes)

    // eslint-disable-next-line
  }, [activeLanguage])

  return (
    <>
      <GoBack />
      <Container className="custom-spacing">
        <Grid
          style={{ justifyContent: 'space-between' }}
          container
          className="grid-m-c-1"
          alignItems="center"
        >
          <Box style={{ wordBreak: 'break-word' }}>
            <h1 style={{ margin: '1em 0' }} className="cm-heading-onboarding">
              <span className="text-span">
                {header && header[tab].primary.toUpperCase()}
              </span>
              <span className="text-span-2">
                {' '}
                {header && header[tab].secondary.toUpperCase()}
              </span>
            </h1>
          </Box>

          <SliderTabs
            tab={tab}
            setTab={setTab}
            isFromSpecializedExamination={true}
          />
        </Grid>

        <div style={{ height: '10px' }} />

        <Grid
          container
          justifyContent="center"
          style={
            tab === 1
              ? {
                  border: '2px solid #e1e1e1',
                  borderRadius: '3px',
                }
              : {}
          }
          className="grid-m-c-1"
        >
          {tab === 0 && (
            <Claimed allSpecializedExaminations={allSpecializedExaminations} />
          )}

          {tab === 1 && files && specialties.length > 0 && (
            <Request
              specialties={specialties}
              files={files}
              maximumAllowedFiles={maximumAllowedFiles}
              uploadContainers={uploadContainers}
              setFiles={setFiles}
              setAllSpecializedExaminations={setAllSpecializedExaminations}
              setTab={setTab}
            />
          )}
        </Grid>

        {/* Modal */}
        {modalOptions.isOpen && (
          <Modal
            isOpen={modalOptions.isOpen}
            closeModalHandler={closeModalHandler}
            message={modalOptions.message}
          />
        )}
      </Container>
    </>
  )
}

export default MakeSpecializedExamination
