import React, { useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'

import { useTranslation } from '../../../../hooks/use-translation'
import { analytics } from '../../../../utils/firebase'
import InsuranceTooltip from '../../../InsuranceTooltip'

function OtherTab({ handlePush, classes }) {
  const { translate } = useTranslation()
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const handleClickOtherPage = () => {
    handlePush('/other')
    analytics.logEvent('uvedomlenie_init')
  }

  const tooltipInsurance = [
    translate('life-insurance-types.life'),
    translate('life-insurance-types.child'),
    translate('life-insurance-types.unit-linked'),
    translate('life-insurance-types.term'),
  ]

  return (
    <Box>
      <Box>
        <Box className={classes.infoContainer}>
          <Box className={classes.description}>
            <Typography className={classes.descriptionTitle}>
              {translate(
                'c-client-dashboard.my-insurance.other-tab.installment-page.title'
              )}
            </Typography>{' '}
            <InsuranceTooltip
              insurances={tooltipInsurance}
              isOpen={isOpenTooltip}
              setIsOpen={setIsOpenTooltip}
              buttonText={translate(
                'c-client-dashboard.my-insurance.other-tab.installment-page.title-link'
              )}
            />
          </Box>

          <Box className={classes.buyButtonContainer}>
            <Button
              className={classes.buyButton}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                analytics.logEvent('dashboard_pay_online')
                handlePush('/installments/regular-payment')
              }}
            >
              {translate(
                'c-client-dashboard.my-insurance.other-tab.installment-page.label-button'
              )}
            </Button>
          </Box>
        </Box>

        <Box className={classes.infoContainer}>
          <Typography className={classes.description}>
            {translate(
              'c-client-dashboard.my-insurance.other-tab.other-page.title'
            )}
          </Typography>

          <Box className={classes.buyButtonContainer}>
            <Button
              className={classes.buyButton}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClickOtherPage}
            >
              {translate(
                'c-client-dashboard.my-insurance.other-tab.other-page.label-button'
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OtherTab
