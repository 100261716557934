import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from '../../hooks/use-auth'
import { useRouter } from '../../hooks/use-router'
import { useTranslation } from '../../hooks/use-translation'
import UserGridCard from '../../components/UserGrid/UserGridCard'
import EditableCard from '../../components/ClientDashboard/User/EditableCard'
import UserSocialCard from '../../components/ClientDashboard/User/Profile/UserSocialCard'
import MarketingSubscription from '../../components/ClientDashboard/User/Profile/MarketingSubscription'
import ProfileSkeleton from '../../components/ClientDashboard/User/Profile/ProfileSkeleton'
import GoBack from '../../components/GoBack'
import {
  loadMarketingSubscription,
  changeMarketingSubscription,
} from '../../services/userDataService'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '2em',
  },
}))

function UserSocial() {
  const { translate } = useTranslation()
  const auth = useAuth()
  const router = useRouter()
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [isSubscribeForMarketing, setIsSubscribeForMarketing] = useState(null)

  const clientId = auth.activeUser ? auth.activeUser.clientId : ''

  const logoutHandler = async () => {
    try {
      await auth.signout()

      router.push('/')
    } catch (e) {
      console.error('User Social Logout', e)
    }
  }

  const changeMarketingSubsriptionHandler = async (e) => {
    try {
      const isChecked = e.target.checked

      const data = {
        clientId,
        check_marketing: isChecked ? 3 : 2,
      }

      const res = await changeMarketingSubscription(data, auth)

      snackbar.enqueueSnackbar(res.message, { variant: 'success' })
      setIsSubscribeForMarketing(isChecked)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    ;(async function getProfileData() {
      try {
        const fetchedMarketingSubscription = await loadMarketingSubscription(
          clientId,
          auth
        )

        const isSubscribe =
          fetchedMarketingSubscription.check_marketing === 3 ? true : false

        setIsSubscribeForMarketing(isSubscribe)
      } catch (e) {
        snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      }
    })()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <GoBack />

      <Container
        maxWidth="lg"
        className={`${classes.container} custom-spacing`}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <UserGridCard>
              {isSubscribeForMarketing === null ? (
                <ProfileSkeleton isHasHealthCare={false} />
              ) : (
                <EditableCard
                  title={translate('page-user-social.title-profile')}
                  body={
                    <>
                      <MarketingSubscription
                        isSubscribeForMarketing={isSubscribeForMarketing}
                        changeMarketingSubsriptionHandler={
                          changeMarketingSubsriptionHandler
                        }
                      />

                      <UserSocialCard
                        isShowedUserCard={true}
                        logoutHandler={logoutHandler}
                      />
                    </>
                  }
                />
              )}
            </UserGridCard>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default UserSocial
