// Libraries
import React from 'react'
import { useSnackbar } from 'notistack'
// Hooks
import { useAuth } from '../../hooks/use-auth'
import { useTranslation } from '../../hooks/use-translation'
// Services
import { changeLanguageOnServer } from '../../services/appService'
// Config
import { LANG_BG, LANG_EN } from '../../config/languageTypes'
import { analytics } from '../../utils/firebase'
import { isMobile as isCapacitor } from '../../mobile'

const styles = {
  cursor: 'pointer',
  ...(isCapacitor && {
    border: '1px solid #FFF',
    margin: 'auto auto 2rem auto',
    padding: '5px 8px',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
  }),
}

const ChangeLanguage = () => {
  const { changeLanguage, activeLanguage } = useTranslation()
  const snackbar = useSnackbar()
  const auth = useAuth()

  const updateLanguage = async (newLanguage) => {
    try {
      const clientId = auth?.activeUser?.clientId || null

      const data = {
        clientId,
        language: newLanguage.toUpperCase(),
      }

      // Change language on server
      await changeLanguageOnServer(data, auth)

      // Change language on client
      changeLanguage(newLanguage)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  return (
    <>
      {activeLanguage === LANG_EN && (
        <div
          style={styles}
          onClick={() => {
            analytics.logEvent('change_language_bg')
            updateLanguage(LANG_BG)
          }}
        >
          {isCapacitor ? LANG_BG : 'Български'}
        </div>
      )}

      {activeLanguage === LANG_BG && (
        <div
          style={styles}
          onClick={() => {
            analytics.logEvent('change_language_en')
            updateLanguage(LANG_EN)
          }}
        >
          {isCapacitor ? LANG_EN : 'English'}
        </div>
      )}
    </>
  )
}

export default ChangeLanguage
