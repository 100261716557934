import React, { useState } from 'react'
import { firebase } from '../../../utils/firebase'
import { Container, Grid, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import BuyOnline from '../../../components/ClientDashboard/BuyOnline'
import MyInsurance from '../../../components/ClientDashboard/MyInsurance'
import { connectWithHealthCare } from '../../../services/userDataService'
import { useAuth } from '../../../hooks/use-auth'
import { useTranslation } from '../../../hooks/use-translation'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2.5rem',
    marginBottom: '2.5rem',
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textTransform: 'none',
    fontSize: '1rem',
  },
  customGrid: {
    margin: '2.5rem auto 4.75rem auto',
    width: '88.5%',
    // borderBottom: '2px solid #979797',
    // paddingBottom: `${theme.spacing(8)}px`
  },
  customPadding: {
    paddingBottom: `${theme.spacing(10)}px`,
  },
  customMobileGrid: {
    width: '100%',
    borderBottom: 'none',
    paddingBottom: 0,
  },
}))

const ClientHome = () => {
  const auth = useAuth()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { activeLanguage, translate } = useTranslation()
  const isMatchesCustomMediaQuery = useMediaQuery('(max-width: 959px)')

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [healthCareModal, setHealthCareModal] = useState(false)
  const [myInsuranceCurrentTab, setMyInsuranceCurrentTab] = useState(0)

  const connectWithHealthCareHandler = async ({
    names,
    email,
    phoneNumber,
    personalNumber,
    dateOfBirth,
    cardNumber,
  }) => {
    try {
      const channelType = sessionStorage.getItem('channelType')
      const channelId = sessionStorage.getItem('channelId')
      const language = activeLanguage.toUpperCase()

      const data = {
        egn_eik: personalNumber || null,
        type_egn_eik: 'egn',
        ...(!!dateOfBirth && { dateOfBirth }),

        names,
        email,
        cardNumber,
        phoneNumber,
        deviceId: 'Web123',
        deviceOs: 'Web',
        language,
        release_version: '3.0.2',
        check_marketing: 3,
        check_gdpr: true,
        userId: auth.claims.userId,
        channelId,
        channelType,
        typeToken: 'healthcare',
      }

      await connectWithHealthCare(data, auth)

      const currentToken = await firebase
        .auth()
        .currentUser.getIdTokenResult(true)

      if (currentToken.claims.zg === 'false') {
        snackbar.enqueueSnackbar(
          // 'Нямате активна застраховка "Здравна грижа".',
          translate('home-client.messages.no-active-insurance'),
          {
            variant: 'error',
          }
        )
      } else if (currentToken.claims.zg === 'true') {
        if (channelType && channelId) {
          await auth.authenticateChatBotUserInPortal(channelType, channelId)
        }
        snackbar.enqueueSnackbar(
          // 'Успешно свързахте вашия акаунт със "Здравна грижа".',
          translate('home-client.messages.insurance-connection-success'),
          { variant: 'success' }
        )
      }

      setHealthCareModal(false)
      setIsSubmitting(false)
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: 'error' })
      setIsSubmitting(false)
    }
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={isMatchesCustomMediaQuery ? 0 : 9}
        className={`${classes.customGrid} ${
          myInsuranceCurrentTab === 1 ? classes.customPadding : ''
        } ${isMatchesCustomMediaQuery ? classes.customMobileGrid : ''}`}
      >
        <MyInsurance
          isMatchesCustomMediaQuery={isMatchesCustomMediaQuery}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          connectWithHealthCareHandler={connectWithHealthCareHandler}
          healthCareModal={healthCareModal}
          setHealthCareModal={setHealthCareModal}
          currentTab={myInsuranceCurrentTab}
          setCurrentTab={setMyInsuranceCurrentTab}
        />

        <BuyOnline isMatchesCustomMediaQuery={isMatchesCustomMediaQuery} />
      </Grid>
    </Container>
  )
}

export default ClientHome
