// Libraries
import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import { AddBoxOutlined, RoomOutlined } from '@material-ui/icons'
// Components
import ConnectWithHealthCare from '../../../Form/ConnectWithHealthCare'
import ExtraCareButtonWithHealthCare from './ExtraCareButtonWithHealthCare'
import ExtraCareButtonWithoutHealthCare from './ExtraCareButtonWithoutHealthCare'
// Hooks
import { useMobileVersion } from 'hooks/use-mobile-version'
import { useTranslation } from 'hooks/use-translation'
// Utils
import { analytics } from 'utils/firebase'
// Styles
import iconStyles from './icon-styles.module.css'
import HealeeButton from './HealeeButton'
import { useRouter } from 'hooks/use-router'

function HealthCareTab({
  healthCareModal,
  setHealthCareModal,
  connectWithHealthCareHandler,
  isSubmitting,
  setIsSubmitting,
  handlePush,
  classes,
  isDisabled,
}) {
  const isMobile = useMobileVersion()
  const { translate } = useTranslation()
  const router = useRouter()

  const [isShownHealthCareMessage, setIsShownHealthCareMessage] =
    useState(false)

  const onMouseHandler = (value) => {
    if (isDisabled) {
      setIsShownHealthCareMessage(value)
    }
  }

  const registerAnalyticEvents = (path, tab) => {
    handlePush(path, tab)

    if (path === '/make-appointment') {
      analytics.logEvent(
        tab === 0 ? 'dashboard_ZG_pregledi' : 'dashboard_ZG_pregledi_new'
      )
    } else if (path === '/make-specialized-examination') {
      analytics.logEvent(
        tab === 0 ? 'dashboard_ZG_izsledvania' : 'dashboard_ZG_izsledvania_new'
      )
    } else if (path === '/user') {
      analytics.logEvent('dashboard_ZG_myCard')
    } else if (path === '/coverages') {
      analytics.logEvent('dashboard_ZG_pokritia')
    } else if (path === '/map') {
      analytics.logEvent('dashboard_map')
    }
  }

  // Prepare mouseEnter && mouseLeave handlers for spreading when "Здравна Грижа" is disabled
  const mouseHandlers = {
    onMouseEnter: () => onMouseHandler(true),
    onMouseLeave: () => onMouseHandler(false),
  }

  const clickHandlers = {
    onClick: () => analytics.logEvent('connect_ZG_functionality'),
  }

  const onClickToOpenHealthCareModalHandler = (e) => {
    setHealthCareModal(true)
    analytics.logEvent('connect_ZG_button')
  }

  const appointmentLabel = translate(
    'c-client-dashboard.my-insurance.health-care-tab.label-appointment'
  )

  const specializedExaminationLabel = translate(
    'c-client-dashboard.my-insurance.health-care-tab.label-specialized-examination'
  )

  const reimbursementsLabel = translate(
    'c-client-dashboard.my-insurance.health-care-tab.label-reimbursements'
  )

  const userMapLabel = translate(
    'c-client-dashboard.my-insurance.health-care-tab.label-user-map'
  )

  const coveragesLabel = translate(
    'c-client-dashboard.my-insurance.health-care-tab.label-coverages'
  )

  const mapLabel = translate(
    'c-client-dashboard.my-insurance.health-care-tab.label-map'
  )

  const handleReimbursementNavigation = (pathname, eventname) => {
    analytics.logEvent(eventname)
    router.push(pathname)
  }

  return (
    <Box>
      <Box
        className={classes.healthCareBox}
        // Spread the mouseEnter && mouseLeave handlers
        {...(isDisabled ? mouseHandlers : null)}
        {...(isDisabled ? clickHandlers : null)}
      >
        <Box className={classes.fieldContainer}>
          <Button
            disabled={isDisabled}
            onClick={() => registerAnalyticEvents('/make-appointment', 0)}
            fullWidth
            classes={{
              root: classes._button,
              label: classes._buttonLabel,
              iconSizeMedium: classes._buttonEndIconSize,
              startIcon: classes._buttonStartIcon,
            }}
            startIcon={
              <span
                className={`${iconStyles.icon} ${
                  iconStyles['appointment-icon']
                } ${isDisabled ? iconStyles.disabled : iconStyles.enabled}`}
                aria-label={appointmentLabel}
              />
            }
          >
            {appointmentLabel}
          </Button>

          <IconButton
            disabled={isDisabled}
            className={classes.iconButton}
            onClick={() => registerAnalyticEvents('/make-appointment', 2)}
          >
            <AddBoxOutlined color={isDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        </Box>

        <Box className={classes.fieldContainer}>
          <HealeeButton classes={classes} disabled={isDisabled} />
        </Box>

        <Box className={classes.fieldContainer}>
          <Button
            disabled={isDisabled}
            onClick={() =>
              registerAnalyticEvents('/make-specialized-examination', 0)
            }
            fullWidth
            classes={{
              root: classes._button,
              label: classes._buttonLabel,
              iconSizeMedium: classes._buttonEndIconSize,
              startIcon: classes._buttonStartIcon,
            }}
            startIcon={
              <span
                className={`${iconStyles.icon} ${iconStyles['test-icon']} ${
                  isDisabled ? iconStyles.disabled : iconStyles.enabled
                }`}
                aria-label={specializedExaminationLabel}
              />
            }
          >
            {' '}
            {specializedExaminationLabel}
          </Button>

          <IconButton
            disabled={isDisabled}
            className={classes.iconButton}
            onClick={() =>
              registerAnalyticEvents('/make-specialized-examination', 1)
            }
          >
            <AddBoxOutlined color={isDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        </Box>

        <Box className={classes.fieldContainer}>
          <Button
            style={isMobile ? { paddingLeft: '1.55rem' } : {}}
            disabled={isDisabled}
            onClick={() =>
              handleReimbursementNavigation(
                '/reimbursements/active',
                'dashboard_ZG_razhodi'
              )
            }
            fullWidth
            classes={{
              root: classes._button,
              label: classes._buttonLabel,
              iconSizeMedium: classes._buttonEndIconSize,
              startIcon: classes._buttonStartIcon,
            }}
            startIcon={
              <span
                className={`${iconStyles.icon} ${
                  iconStyles['reimbursement-icon']
                } ${isDisabled ? iconStyles.disabled : iconStyles.enabled}`}
                aria-label={reimbursementsLabel}
              />
            }
          >
            {reimbursementsLabel}
          </Button>

          <IconButton
            disabled={isDisabled}
            className={classes.iconButton}
            onClick={() =>
              handleReimbursementNavigation(
                '/reimbursements/request',
                'dashboard_ZG_razhodi_new'
              )
            }
          >
            <AddBoxOutlined color={isDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        </Box>

        {/* Extra care */}

        <Box className={classes.fieldContainer}>
          {isDisabled ? (
            <ExtraCareButtonWithoutHealthCare classes={classes} />
          ) : (
            <ExtraCareButtonWithHealthCare classes={classes} />
          )}
        </Box>

        <Button
          disabled={isDisabled}
          style={isMobile ? { paddingLeft: '1.55rem' } : {}}
          onClick={() => registerAnalyticEvents('/user')}
          fullWidth
          classes={{
            root: classes._button,
            label: classes._buttonLabel,
            iconSizeMedium: classes._buttonEndIconSize,
            startIcon: classes._buttonStartIcon,
          }}
          startIcon={
            <span
              className={`${iconStyles.icon} ${iconStyles['map-icon']} ${
                isDisabled ? iconStyles.disabled : iconStyles.enabled
              }`}
              aria-label={userMapLabel}
            />
          }
        >
          {userMapLabel}
        </Button>

        <Button
          style={{ paddingLeft: '1.7rem' }}
          disabled={isDisabled}
          onClick={() => registerAnalyticEvents('/coverages', 0)}
          fullWidth
          classes={{
            root: classes._button,
            label: classes._buttonLabel,
            iconSizeMedium: classes._buttonEndIconSize,
            startIcon: classes._buttonStartIcon,
          }}
          startIcon={
            <span
              className={`${iconStyles.icon} ${iconStyles['coverage-icon']} ${
                isDisabled ? iconStyles.disabled : iconStyles.enabled
              }`}
              aria-label={coveragesLabel}
            />
          }
        >
          {coveragesLabel}
        </Button>

        {isDisabled && isShownHealthCareMessage && (
          <Box className={classes.healthCareMessage}>
            {translate(
              'c-client-dashboard.my-insurance.health-care-tab.health-care-message'
            )}
          </Box>
        )}
      </Box>

      <Button
        style={{ padding: '1em 1.2em 1em 1.3em' }}
        onClick={() => registerAnalyticEvents('/map')}
        fullWidth
        classes={{
          root: classes._button,
          label: classes._buttonLabel,
          iconSizeMedium: classes._buttonEndIconSize,
          startIcon: classes._materialStartIcon,
        }}
        startIcon={
          <RoomOutlined
            style={{ fontSize: '1.69em', color: '#2A2E43' }}
            fontSize="large"
          />
        }
      >
        {mapLabel}
      </Button>

      {isDisabled && (
        <Grid
          container
          item
          lg
          justifyContent="flex-end"
          xs={12}
          style={{ padding: '1.4em' }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickToOpenHealthCareModalHandler}
          >
            {translate(
              'c-client-dashboard.my-insurance.health-care-tab.label-connect-health-care-button'
            )}
          </Button>

          <Typography className={classes.deactivatedHealthCareText}>
            {translate(
              'c-client-dashboard.my-insurance.health-care-tab.deactivated-health-care-info'
            )}{' '}
            <a
              className="custom-link"
              href="https://www.bulstradlife.bg/products/za-firmata-mi-zastrakhovka-zdravna-grizha"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate(
                'c-client-dashboard.my-insurance.health-care-tab.deactivated-health-care-text'
              )}
            </a>
            .
          </Typography>

          <ConnectWithHealthCare
            open={healthCareModal}
            setOpen={setHealthCareModal}
            connectWithHealthCareHandler={connectWithHealthCareHandler}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </Grid>
      )}
    </Box>
  )
}

export default HealthCareTab
