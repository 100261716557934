import PropTypes from 'prop-types'
import React from 'react'
import styles from './editable-card.module.css'

export default function EditableCard({ title, body, footer }) {
  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      {body}
      {footer}
    </>
  )
}

EditableCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object,
  footer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}
