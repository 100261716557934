import React from 'react'
import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: '1em',
    border: `2px solid ${theme.palette.primary.grey}`,
    padding: '2em'
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2em'
  },

  margin: {
    marginBottom: '1.5em'
  }
}))

function BoxSkeleton() {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {/* Header */}
      <Box className={classes.header}>
        <Skeleton variant="rect" width={'40%'} height={'3em'} />

        <Box>
          <Skeleton variant="text" width="10em" height={'1.5em'} />
          <Skeleton variant="text" width="10em" height={'1.5em'} />
          <Skeleton variant="text" width="10em" height={'1.5em'} />
          <Skeleton variant="text" width="10em" height={'1.5em'} />
        </Box>
      </Box>

      {/* User Data */}
      <Box className={classes.margin}>
        <Skeleton variant="text" width="75%" height={'1.5em'} />
        <Skeleton variant="text" width="75%" height={'1.5em'} />
        <Skeleton variant="text" width="75%" height={'1.5em'} />
        <Skeleton variant="text" width="75%" height={'1.5em'} />
        <Skeleton variant="text" width="75%" height={'1.5em'} />
      </Box>

      {/* Table */}
      <Box className={classes.margin}>
        <Skeleton variant="rect" width={'100%'} height={'5em'} />
      </Box>

      {/* Footer */}
      <Skeleton className={classes.margin} variant="text" width="100%" height={'1.5em'} />
      <Skeleton variant="text" width="100%" height={'1.5em'} />
    </Box>
  )
}

export default BoxSkeleton
