import { useQuery } from 'react-query'
import { loadBankAccounts } from 'services/bankAccountService'

export const BANK_ACCOUNTS_QUERY_KEY = 'bank-accounts'

export default function useBankAccounts(clientId) {
  return useQuery({
    queryKey: [BANK_ACCOUNTS_QUERY_KEY, clientId],
    queryFn: () => loadBankAccounts(clientId),
  })
}
