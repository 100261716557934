// Libraries
import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom-v5-compat'
import has from 'lodash/has'
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  Typography,
  Box,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// Hooks
import { usePersistentForm } from '../../../../hooks/use-persistent-form'
import { useMobileVersion } from '../../../../hooks/use-mobile-version'
import { useTranslation } from '../../../../hooks/use-translation'
// Config
import { validateStepDetails } from '../../../../formValidators/other'
import { LANG_BG, LANG_EN } from '../../../../config/languageTypes'
import PhoneInputField from '../../../PhoneInputField'
import s from './phone-field.module.css'
import { useSnackbar } from 'notistack'

const useStyle = makeStyles((theme) => ({
  spacing: {
    marginBottom: '0.5em',
  },
  spacingBottom: {
    marginBottom: '2em',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '79% 20%',
    gridGap: '0.5em',
  },
  mobileGrid: {
    gridTemplateColumns: '1fr',
    gridGap: '0',
  },
}))

const renderNationalities = (structure, values, activeLanguage) => {
  return structure.claimOtherCitizenships
    .filter((nationality) => nationality.typeEntity === values.personType)
    .map((nationality) => {
      if (activeLanguage !== LANG_BG && activeLanguage !== LANG_EN) {
        return null
      }

      const nationalityName =
        activeLanguage === LANG_BG ? nationality.name : nationality.name_latin

      return (
        <option key={nationality.id} value={nationalityName}>
          {nationalityName}
        </option>
      )
    })
}

const renderLegalPositions = (structure, activeLanguage) => {
  return structure.claimOtherNotifierLegalEntity.map((legalPosition) => {
    if (activeLanguage !== LANG_BG && activeLanguage !== LANG_EN) {
      return null
    }

    const legalPositionName =
      activeLanguage === LANG_BG ? legalPosition.name : legalPosition.name_latin

    return (
      <option key={legalPosition.id} value={legalPositionName}>
        {legalPositionName}
      </option>
    )
  })
}

const renderNotifierTypes = (structure, values, activeLanguage) => {
  const filteredTypes =
    values.personType === 'legal'
      ? structure.claimOtherNotifierType.filter(
          (type) =>
            type.name === 'Застраховащ' || type.name === 'Ползващо се лице'
        )
      : structure.claimOtherNotifierType

  return filteredTypes.map((type) => {
    if (activeLanguage !== LANG_BG && activeLanguage !== LANG_EN) {
      return null
    }

    const typeName = activeLanguage === LANG_BG ? type.name : type.name_latin

    return (
      <option key={type.id} value={typeName}>
        {typeName}
      </option>
    )
  })
}

const StepDetails = ({
  structure,
  files,
  setFiles,
  nextStep,
  initialState,
  setEmailInfo,
}) => {
  const { translate, activeLanguage } = useTranslation()
  const classes = useStyle()
  const isMobile = useMobileVersion()
  const snackbar = useSnackbar()
  const { values, validate, errors, handleChange, update } =
    usePersistentForm('other')

  const handleChangePersonType = (e) => {
    setFiles({ 'Заявяване на претенция документ': [] })
    setEmailInfo({ isSubmitting: false, isSent: false })
    update('values', { ...initialState, personType: e.target.value })
  }

  const renderAllNationalities = useCallback(() => {
    return renderNationalities(structure, values, activeLanguage)
  }, [structure, values, activeLanguage])

  const renderAllNotifierTypes = useCallback(() => {
    return renderNotifierTypes(structure, values, activeLanguage)
  }, [structure, values, activeLanguage])

  const renderAllLegalPositions = useCallback(() => {
    return renderLegalPositions(structure, activeLanguage)
  }, [structure, activeLanguage])

  const handleSubmit = (e) => {
    e.preventDefault()
    // validate form step
    const hasErrors = validate(validateStepDetails)

    // check for errors
    if (hasErrors) {
      return
    }

    // go to next step if we pass the validation
    nextStep()
  }

  useEffect(() => {
    if (has(errors, 'files')) {
      snackbar.enqueueSnackbar(errors.files, {
        variant: 'error',
        preventDuplicate: true,
      })
    }

    // eslint-disable-next-line
  }, [errors])

  return (
    <>
      <form
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
          {translate('c-client-dashboard.other.step-details.title')}
        </Typography>

        <FormControl component="fieldset" className={classes.spacing}>
          <RadioGroup
            aria-label="personType"
            name="personType"
            value={values.personType}
            onChange={(e) => handleChangePersonType(e)}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel
              style={{ marginBottom: '0' }}
              value="person"
              control={<Radio color="primary" />}
              label={translate('c-client-dashboard.other.step-details.person')}
            />

            <FormControlLabel
              style={{ marginBottom: '0' }}
              value="legal"
              control={<Radio color="primary" />}
              label={translate('c-client-dashboard.other.step-details.legal')}
            />
          </RadioGroup>
        </FormControl>

        {values.personType === 'legal' && (
          <TextField
            className={classes.spacing}
            name="companyName"
            label={translate(
              'c-client-dashboard.other.step-details.entity-name'
            )}
            value={values.companyName}
            onChange={handleChange}
            error={errors && errors.companyName ? true : false}
            helperText={errors && errors.companyName}
          />
        )}

        {values.personType === 'person' && (
          <>
            <TextField
              className={classes.spacing}
              name="name"
              label={translate('c-client-dashboard.other.step-details.name')}
              value={values.name}
              onChange={handleChange}
              error={errors && errors.name ? true : false}
              helperText={errors && errors.name}
            />

            <TextField
              className={classes.spacing}
              name="egn"
              label={translate('c-client-dashboard.other.step-details.egn')}
              value={values.egn}
              onChange={handleChange}
              error={errors && errors.egn ? true : false}
              helperText={errors && errors.egn}
            />
          </>
        )}

        {values.personType === 'legal' && (
          <TextField
            className={classes.spacing}
            name="eik"
            label={translate('c-client-dashboard.other.step-details.eik')}
            value={values.eik}
            onChange={handleChange}
            error={errors && errors.eik ? true : false}
            helperText={errors && errors.eik}
          />
        )}

        <FormControl
          className={classes.spacing}
          error={errors && errors.type ? true : false}
        >
          <InputLabel htmlFor="type">
            {translate('c-client-dashboard.other.step-details.type')}
          </InputLabel>
          <Select
            native
            value={values.type}
            labelWidth={37}
            onChange={handleChange}
            name="type"
          >
            <>
              <option key="defaultType1" value="" disabled />
              {has(structure, 'claimOtherNotifierType') &&
                renderAllNotifierTypes()}
            </>
          </Select>
          <FormHelperText>{errors && errors.type}</FormHelperText>
        </FormControl>

        <Box
          className={`${classes.grid} ${isMobile ? classes.mobileGrid : ''}`}
        >
          <TextField
            className={classes.spacing}
            name="street"
            label={translate('c-client-dashboard.other.step-details.street')}
            value={values.street}
            onChange={handleChange}
            error={errors && errors.street ? true : false}
            helperText={errors && errors.street}
          />

          <TextField
            className={classes.spacing}
            name="streetNumber"
            label="No*"
            value={values.streetNumber}
            onChange={handleChange}
            error={errors && errors.streetNumber ? true : false}
            helperText={errors && errors.streetNumber}
          />
        </Box>

        <TextField
          className={classes.spacing}
          name="city"
          label={translate('c-client-dashboard.other.step-details.city')}
          value={values.city}
          onChange={handleChange}
          error={errors && errors.city ? true : false}
          helperText={errors && errors.city}
        />

        <PhoneInputField
          variant="standart"
          style={{ marginTop: '1em' }}
          classes={s.phone}
          name="phoneNumber"
          value={values.phoneNumber}
          onChange={handleChange}
          errorMessage={has(errors, 'phoneNumber') ? errors.phoneNumber : ''}
          placeholder={translate('c-client-dashboard.other.step-details.phone')}
        />

        <FormControl
          className={classes.spacingBottom}
          error={errors && errors.nationality ? true : false}
        >
          <InputLabel htmlFor="nationality">
            {values.personType === 'person'
              ? translate('c-client-dashboard.other.step-details.nationality')
              : translate(
                  'c-client-dashboard.other.step-details.legal-country'
                )}
          </InputLabel>
          <Select
            native
            value={values.nationality}
            labelWidth={37}
            onChange={handleChange}
            name="nationality"
          >
            <>
              <option key="defaultNationality1" value="" disabled />

              {has(structure, 'claimOtherCitizenships') &&
                renderAllNationalities()}
            </>
          </Select>
          <FormHelperText>{errors && errors.nationality}</FormHelperText>
        </FormControl>

        {values.personType === 'legal' && (
          <>
            <TextField
              className={classes.spacing}
              name="name"
              label={translate('c-client-dashboard.other.step-details.name-2')}
              value={values.name}
              onChange={handleChange}
              error={errors && errors.name ? true : false}
              helperText={errors && errors.name}
            />

            <TextField
              className={classes.spacing}
              name="egn"
              label={translate('c-client-dashboard.other.step-details.egn')}
              value={values.egn}
              onChange={handleChange}
              error={errors && errors.egn ? true : false}
              helperText={errors && errors.egn}
            />
          </>
        )}

        {values.personType === 'legal' && (
          <FormControl
            className={classes.spacingBottom}
            error={errors && errors.legalPosition ? true : false}
          >
            <InputLabel htmlFor="legalPosition">
              {translate('c-client-dashboard.other.step-details.legal-pos')}
            </InputLabel>
            <Select
              native
              value={values.legalPosition}
              labelWidth={37}
              onChange={handleChange}
              name="legalPosition"
            >
              <>
                <option key="defaultPosition1" value="" disabled />

                {has(structure, 'claimOtherNotifierLegalEntity') &&
                  renderAllLegalPositions()}
              </>
            </Select>
            <FormHelperText>{errors && errors.legalPosition}</FormHelperText>
          </FormControl>
        )}

        <Grid style={{ marginTop: 'auto' }} container>
          <Grid item xs={6}>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              size="large"
              component={Link}
              to="/"
            >
              {translate('c-client-dashboard.other.step-details.reject')}
            </Button>
          </Grid>
          <Grid container item lg justifyContent="flex-end" xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {translate('c-client-dashboard.other.step-details.next')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default StepDetails
