import React, { Fragment } from 'react'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '../../../../hooks/use-translation'
import PasswordChange from '../../../Form/PasswordChange'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 'auto',
    justifyContent: 'space-between',
  },
  button: {
    width: '100%',

    '&:hover': {
      opacity: '1',
    },
  },
  logoutButton: {
    height: '100%',
    marginRight: '0',
  },
}))

const PersonalInfo = ({
  logoutHandler,
  updatePasswordHandler,
  isSubmitting,
  setIsSubmitting,
  passwordChangeModal,
  setPasswordChangeModal,
}) => {
  const classes = useStyles()
  const { translate } = useTranslation()

  const triggerConfirmPasswordDialog = () => {
    setPasswordChangeModal(true)
  }

  return (
    <Fragment>
      <Grid className={classes.container} container>
        <Grid item xs={5}>
          <Button
            className={`${classes.button} cm-button outlined`}
            onClick={triggerConfirmPasswordDialog}
          >
            {translate('c-broker-dashboard.change-pass')}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            className={`${classes.button} ${classes.logoutButton} cm-button outlined`}
            onClick={logoutHandler}
          >
            {translate('c-broker-dashboard.exit')}
          </Button>
        </Grid>
      </Grid>

      {/* Password change Dialog */}
      <PasswordChange
        open={passwordChangeModal}
        setOpen={setPasswordChangeModal}
        callback={updatePasswordHandler}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
      />
    </Fragment>
  )
}

export default PersonalInfo
