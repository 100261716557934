import moment from 'moment'
import {
  validateEgn,
  validateIdentificationNumber,
} from './egnAndIdentificationNumber'
import { translate } from '../utils/lang'
import { MAX_UPLOAD_FILE_SIZE } from '../utils/config'

export const validateStepDetails = ({
  name,
  companyName,
  personType,
  egn,
  eik,
  legalPosition,
  type,
  street,
  streetNumber,
  city,
  phoneNumber,
  nationality,
  files,
}) => {
  const errors = {}

  const phoneRegex = /^\+{1}\d{10,14}$/
  const streetNumberRegex = /^[0-9]+$/

  if (!personType) {
    errors.personType = translate('validate-other.person-type')
  }

  if (!name) {
    errors.name = translate('validate-other.name')
  }

  if (personType === 'legal' && !companyName) {
    errors.companyName = translate('validate-other.company-name')
  }

  // Both EGN and personal number are supposed to be valid here
  const personalNumber = validateEgn(egn)
  const idNumError = validateIdentificationNumber(egn)

  const isEgnValid = personalNumber.error === ''
  const isLnchValid = idNumError === ''

  if (isEgnValid || isLnchValid) {
    delete errors.egn
  } else {
    errors.egn = translate('validate-connect-with-healthcare.personal-number')
  }

  if (personType === 'legal' && !eik) {
    errors.eik = translate('validate-other.eik')
  }

  if (personType === 'legal' && !legalPosition) {
    errors.legalPosition = translate('validate-other.legal-pos')
  }

  if (!type) {
    errors.type = translate('validate-other.type')
  }

  if (!street) {
    errors.street = translate('validate-other.street')
  }

  if (!streetNumber || !streetNumberRegex.test(streetNumber)) {
    errors.streetNumber = translate('validate-other.street-number')
  }

  if (!city) {
    errors.city = translate('validate-other.city')
  }

  if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
    errors.phoneNumber = translate('validate-other.phone')
  }

  if (!nationality) {
    errors.nationality = translate('validate-other.nationality')
  }

  const fileWithInvalidSize = Object.values(files ?? {})
    .flatMap((file) => file)
    .find((file) => file.size > MAX_UPLOAD_FILE_SIZE)

  if (fileWithInvalidSize) {
    errors.files = `${translate('validate-reimbursement.files-max-size-1')} ${
      fileWithInvalidSize.name
    } ${translate('validate-reimbursement.files-max-size-2')}`
  }

  return errors
}

export const validateStepContract = ({
  policyNumber,
  datePolicy,
  insurer,
  eventType,
  dateEvent,
  comment,
  files,
}) => {
  const errors = {}

  if (!policyNumber) {
    errors.policyNumber = translate('validate-other.policy-number')
  }

  if (!datePolicy || !moment(datePolicy).isValid()) {
    errors.datePolicy = translate('validate-other.policy-date-val')
  }

  if (moment(datePolicy).isAfter(moment())) {
    errors.datePolicy = translate('validate-other.policy-date')
  }

  if (!insurer) {
    errors.insurer = translate('validate-other.insurer')
  }

  if (!eventType) {
    errors.eventType = translate('validate-other.event-type')
  }

  if (!dateEvent || !moment(dateEvent).isValid()) {
    errors.dateEvent = translate('validate-other.event-date-val')
  }

  if (moment(dateEvent).isAfter(moment())) {
    errors.dateEvent = translate('validate-other.event-date-after')
  }

  if (moment(dateEvent).endOf('day').isBefore(moment(datePolicy))) {
    errors.dateEvent = translate('validate-other.event-date-before')
  }

  if (!comment) {
    errors.comment = translate('validate-other.summary')
  }

  const fileWithInvalidSize = Object.values(files ?? {})
    .flatMap((file) => file)
    .find((file) => file.size > MAX_UPLOAD_FILE_SIZE)

  if (fileWithInvalidSize) {
    errors.files = `${translate('validate-reimbursement.files-max-size-1')} ${
      fileWithInvalidSize.name
    } ${translate('validate-reimbursement.files-max-size-2')}`
  }

  return errors
}

export const validateStepConfirm = ({ email, privacy, files }) => {
  const errors = {}

  let emailRegex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  if (!email || !emailRegex.test(email)) {
    errors.email = translate('validate-other.email')
  }

  if (!privacy) {
    errors.privacy = translate('validate-other.terms')
  }

  const fileWithInvalidSize = Object.values(files ?? {})
    .flatMap((file) => file)
    .find((file) => file.size > MAX_UPLOAD_FILE_SIZE)

  if (fileWithInvalidSize) {
    errors.files = `${translate('validate-reimbursement.files-max-size-1')} ${
      fileWithInvalidSize.name
    } ${translate('validate-reimbursement.files-max-size-2')}`
  }

  return errors
}
