import React from 'react'
import styles from '../user-grid.module.css'

const UserGridCard = ({ twoColumns, children }) => {
  return (
    <section
      className={`${styles.card} ${twoColumns ? styles['two-columns'] : ''}`}
    >
      {children}
    </section>
  )
}

export default UserGridCard
