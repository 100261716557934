import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import { useTranslation } from 'hooks/use-translation'

function DisableBankAccountModal({ open, setOpen, callback }) {
  const { translate } = useTranslation()

  const handleClose = () => setOpen(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    await callback()

    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form>
        <DialogContent>
          <DialogContentText>
            {translate('c-form.disable-bank-account.info')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('c-form.disable-bank-account.reject')}
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
          >
            {translate('c-form.disable-bank-account.close')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DisableBankAccountModal
