// Libraries
import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'

// Hooks
import { useTranslation } from '../../../hooks/use-translation'
// Images

import UserPosition from '../../UserPosition'
import useGeolocation from '../../../hooks/useGeolocation'
import { isMobile as isCapacitor } from '../../../mobile'
import AppointmentMarker from './AppointmentMarker'

function MakeAppointmentMap({ markers }) {
  const { activeLanguage } = useTranslation()
  const userPosition = useGeolocation()

  const defaultCenter = {
    lat: userPosition?.latitude || 42.69917,
    lng: userPosition?.longitude || 23.3225,
  }

  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)

  const handleApiLoaded = (map, maps) => {
    setMap(map)
    setMaps(maps)
  }

  useEffect(() => {
    if (map && maps && markers.length > 0) {
      const bounds = new maps.LatLngBounds()

      for (const marker of markers) {
        const position = new maps.LatLng(marker.latitude, marker.longitude)
        bounds.extend(position)
      }

      if (isCapacitor && userPosition) {
        const newPosition = new maps.LatLng(
          userPosition.latitude,
          userPosition.longitude
        )
        bounds.extend(newPosition)
      }

      map.fitBounds(bounds)

      if (markers.length === 1) {
        map.setZoom(15)
      }
    }
  }, [markers, maps, map, userPosition])

  useEffect(() => {
    if (isCapacitor && userPosition && map && maps) {
      map.panTo(new maps.LatLng(userPosition.latitude, userPosition.longitude))
      map.setZoom(14)
    }
  }, [maps, map, userPosition])

  return (
    <div
      style={{
        border: '0',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        minHeight: '250px',
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GMAPS_API_KEY,
          language: activeLanguage,
        }}
        defaultCenter={defaultCenter}
        defaultZoom={isCapacitor ? 10 : 6}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {markers &&
          markers.length > 0 &&
          markers.map((hospital) => (
            <AppointmentMarker
              key={hospital.hospitalId}
              lat={hospital.latitude}
              lng={hospital.longitude}
              text={hospital.name}
              address={hospital.address}
              dto={hospital}
            />
          ))}
        {isCapacitor && userPosition && (
          <UserPosition
            lat={userPosition?.latitude}
            lng={userPosition?.longitude}
          />
        )}
      </GoogleMapReact>
    </div>
  )
}

MakeAppointmentMap.propTypes = {
  medicalInstitutions: PropTypes.array,
  dentalClinics: PropTypes.array,
  optics: PropTypes.array,
}

export default MakeAppointmentMap
