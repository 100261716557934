import { fetch } from '../hooks/use-fetch'
import ApiClient from 'infrastructure/api/ApiClient'

export const updateMessageStatus = (url, data, auth) => {
  return fetch.post(
    url,
    {
      body: JSON.stringify(data),
    },
    auth
  )
}

export const getMessages = (clientId) => {
  return ApiClient.get('/getMessages', {
    params: {
      clientId,
    },
  }).then((response) => response?.data)
}

export const getMessageCount = (clientId) => {
  return ApiClient.get('/getMessageCount', {
    params: {
      clientId,
    },
  }).then((response) => response?.data)
}
