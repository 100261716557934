import React, { useEffect, useState } from 'react'
import { Badge, Container, Grid, Tab } from '@material-ui/core'
import { AntTabs, useTabsStyle } from 'components/ClientDashboard/SliderTabs'
import { useTranslation } from 'hooks/use-translation'
import GoBack from 'components/GoBack'
import ReimbursementWrapperTitle from './ReimbursementWrapperTitle'
import { Outlet, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useActiveClaims } from 'pages/Reimbursement/hooks/useClaims'

export const reimbursementRoutes = [
  '/reimbursements/active',
  '/reimbursements/archive',
  '/reimbursements/request',
]

const ACTIVE_CLAIMS_BADGE_TOTAL = 10

const ReimbursementLayout = () => {
  const { translate } = useTranslation()
  const tabsStyles = useTabsStyle()
  const [activeTabIndex, setActiveTabIndex] = useState()
  const navigate = useNavigate()
  const location = useLocation()

  const { data: activeClaims } = useActiveClaims()

  const activeClaimsBadgeContent = activeClaims
    ? activeClaims?.length > ACTIVE_CLAIMS_BADGE_TOTAL
      ? '10+'
      : activeClaims.length
    : undefined

  const handleTabChange = (e, tabIndex) => {
    navigate(reimbursementRoutes[tabIndex], { replace: true })
    setActiveTabIndex(tabIndex)
  }

  useEffect(() => {
    const matchedRouteIndex = reimbursementRoutes.findIndex((route) =>
      location.pathname.includes(route)
    )

    if (matchedRouteIndex !== -1) {
      setActiveTabIndex(matchedRouteIndex)
    }
  }, [location.pathname])

  if (activeTabIndex === undefined) return null

  return (
    <Container className="custom-spacing">
      <GoBack />

      <Grid
        justifyContent="space-between"
        container
        className="grid-m-c-1"
        alignItems="center"
      >
        <ReimbursementWrapperTitle />

        <AntTabs value={activeTabIndex} onChange={handleTabChange}>
          <Tab
            className={tabsStyles.tab}
            disableRipple
            label={
              <Badge
                color="primary"
                badgeContent={activeClaimsBadgeContent}
                max={ACTIVE_CLAIMS_BADGE_TOTAL}
              >
                {translate('c-client-dashboard.slider-tabs.latest')}
              </Badge>
            }
          />
          <Tab
            className={tabsStyles.tab}
            disableRipple
            label={translate('c-client-dashboard.slider-tabs.archive')}
          />
          <Tab
            className={tabsStyles.tab}
            disableRipple
            label={translate('c-client-dashboard.slider-tabs.request')}
          />
        </AntTabs>
      </Grid>

      <div style={{ height: '10px' }} />

      <Outlet />
    </Container>
  )
}

export default ReimbursementLayout
